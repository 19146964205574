import { Observable } from "rxjs";
import { Injectable, Inject } from "@angular/core";
import { RequestParam } from './assets/request-param.model';
import { ApiCaller } from './api-caller';
import { RequestParams } from './assets/request-params.model';
import { HttpMethod } from './assets/http-method.enum';
import { RequestParamType } from './assets/request-param-type.enum';

// tslint:disable: no-namespace
// tslint:disable: max-line-length
// tslint:disable: no-trailing-whitespace
export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {


            @Injectable()
            export class UpsertController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantVoucher/upsert`;

                public Upsert(type: string, actiontype: Honeycomb.Common.Enums.UpsertAction, models: Array<any>): Observable<Array<any>> {
                    const args = [new RequestParam('type', RequestParamType.ROUTE, type), new RequestParam('actiontype', RequestParamType.ROUTE, actiontype), new RequestParam('models', RequestParamType.BODY, models)];
                    const requestParams = new RequestParams('{type}/{actiontype?}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Vouchers {
            export namespace IService {


                @Injectable()
                export class VoucherAttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantVoucher/VoucherAttributeTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttributeType>> {
                        const args = [];
                        const requestParams = new RequestParams('GetList', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttributeType>>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttributeType>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttributeType>>;
                    }

                    public EnsureEditionAttributes(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('EnsureEditionAttributes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class VouchersController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantVoucher/Vouchers`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Vouchers.IService.Model.Voucher> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>;
                    }

                    public GetPrintable(id: number): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherPrintable> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetPrintable/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherPrintable>;
                    }

                    public GetForUser(userId: number, templateId: number): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>> {
                        const args = [new RequestParam('userId', RequestParamType.QUERY, userId), new RequestParam('templateId', RequestParamType.QUERY, templateId)];
                        const requestParams = new RequestParams('GetForUser', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>>;
                    }

                    public GetForCurrent(templateId: number): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>> {
                        const args = [new RequestParam('templateId', RequestParamType.QUERY, templateId)];
                        const requestParams = new RequestParams('GetForCurrent', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>>;
                    }

                    public GetForUserNumber(userNumber: string, onlyUsable: boolean, locationNumber: string): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>> {
                        const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('onlyUsable', RequestParamType.QUERY, onlyUsable), new RequestParam('locationNumber', RequestParamType.QUERY, locationNumber)];
                        const requestParams = new RequestParams('GetForUserNumber', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>>;
                    }

                    public DeleteForUser(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('DeleteForUser/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetHistory(id: number): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherHistory>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetHistory/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherHistory>>;
                    }

                    public GetHistoryForUser(userId: number): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherHistory>> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId)];
                        const requestParams = new RequestParams('GetHistoryForUser/{userId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherHistory>>;
                    }

                    public GetByNumber(includeHistory: boolean, id: string): Observable<Honeycomb.Tenant.Vouchers.IService.Model.Voucher> {
                        const args = [new RequestParam('includeHistory', RequestParamType.QUERY, includeHistory), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetByNumber/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>;
                    }

                    public GetIDByCode(id: string): Observable<number> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetIDByCode/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public Post(templateType: Honeycomb.Tenant.Vouchers.IService.Model.Voucher): Observable<any> {
                        const args = [new RequestParam('templateType', RequestParamType.BODY, templateType)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateVoucher(createRequest: Honeycomb.Tenant.Vouchers.IService.Model.VoucherCreateRequest): Observable<Honeycomb.Tenant.Vouchers.IService.Model.Voucher> {
                        const args = [new RequestParam('createRequest', RequestParamType.BODY, createRequest)];
                        const requestParams = new RequestParams('CreateVoucher', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>;
                    }

                    public CreateVouchers(createRequests: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherCreateRequest>): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>> {
                        const args = [new RequestParam('createRequests', RequestParamType.BODY, createRequests)];
                        const requestParams = new RequestParams('CreateVouchers', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>>;
                    }

                    public CreateVoucherExistingNumber(createRequest: Honeycomb.Tenant.Vouchers.IService.Model.VoucherCreateExistingNumberRequest): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherCreateExistingNumberResponse> {
                        const args = [new RequestParam('createRequest', RequestParamType.BODY, createRequest)];
                        const requestParams = new RequestParams('CreateVoucherExistingNumber', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherCreateExistingNumberResponse>;
                    }

                    public ValidateVoucher(voucherUseRequest: Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseRequest): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseResponse> {
                        const args = [new RequestParam('voucherUseRequest', RequestParamType.BODY, voucherUseRequest)];
                        const requestParams = new RequestParams('ValidateVoucher', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseResponse>;
                    }

                    public UseVoucher(voucherUseRequest: Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseRequest): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseResponse> {
                        const args = [new RequestParam('voucherUseRequest', RequestParamType.BODY, voucherUseRequest)];
                        const requestParams = new RequestParams('UseVoucher', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseResponse>;
                    }

                    public UseVoucherWithoutCheck(voucherUseRequest: Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseRequest): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseResponse> {
                        const args = [new RequestParam('voucherUseRequest', RequestParamType.BODY, voucherUseRequest)];
                        const requestParams = new RequestParams('UseVoucherWithoutCheck', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseResponse>;
                    }

                    public RechargeVoucher(voucherRechargeRequest: Honeycomb.Tenant.Vouchers.IService.Model.VoucherRechargeRequest): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherRechargeResponse> {
                        const args = [new RequestParam('voucherRechargeRequest', RequestParamType.BODY, voucherRechargeRequest)];
                        const requestParams = new RequestParams('RechargeVoucher', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherRechargeResponse>;
                    }

                    public ReleaseVoucherBlocation(request: Honeycomb.Tenant.Vouchers.IService.Model.ReleaseVoucherBlocationRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('ReleaseVoucherBlocation', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateVoucherAttribute(attributeName: string, attributeValue: string, id: number): Observable<any> {
                        const args = [new RequestParam('attributeName', RequestParamType.QUERY, attributeName), new RequestParam('attributeValue', RequestParamType.QUERY, attributeValue), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('UpdateVoucherAttribute/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateVoucherAttributes(attributes: { [key: number]: any }): Observable<any> {
                        const args = [new RequestParam('attributes', RequestParamType.BODY, attributes)];
                        const requestParams = new RequestParams('UpdateVoucherAttributes', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SetVouchersStatus(request: Honeycomb.Tenant.Vouchers.IService.Model.VouchersChangeStatusRequest): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('SetVouchersStatus', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.Voucher>>;
                    }

                    public UpdateVoucherValidity(id: number, model: Honeycomb.Tenant.Vouchers.IService.Model.Voucher): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('UpdateVoucherValidity/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckExpiredVouchers(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CheckExpiredVouchers', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetEditableDetail(id: number): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherEditableDetail> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('EditableDetail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherEditableDetail>;
                    }

                    public SetEditableDetail(id: number, detail: Honeycomb.Tenant.Vouchers.IService.Model.VoucherEditableDetail): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('detail', RequestParamType.BODY, detail)];
                        const requestParams = new RequestParams('SetEditableDetail/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetVoucherTemplates(validOnly: boolean, loyaltyPointsExchangeOnly: boolean): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>> {
                        const args = [new RequestParam('validOnly', RequestParamType.QUERY, validOnly), new RequestParam('loyaltyPointsExchangeOnly', RequestParamType.QUERY, loyaltyPointsExchangeOnly)];
                        const requestParams = new RequestParams('GetVoucherTemplates', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>>;
                    }

                    public AssignVoucherToUser(voucherNumber: string, userNumber: string): Observable<any> {
                        const args = [new RequestParam('voucherNumber', RequestParamType.ROUTE, voucherNumber), new RequestParam('userNumber', RequestParamType.ROUTE, userNumber)];
                        const requestParams = new RequestParams('AssignVoucherToUser', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class VouchersImportExportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantVoucher/VouchersImportExport`;

                    public Import(request: Honeycomb.Common.ImportExport.Model.ImportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                    }

                    public Export(request: Honeycomb.Common.ImportExport.Model.ExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ExportResult>;
                    }

                    public DialogModel(interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                        const args = [new RequestParam('interfaceModel', RequestParamType.BODY, interfaceModel)];
                        const requestParams = new RequestParams('dialogModel', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                    }
                }

                @Injectable()
                export class VoucherTemplatesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantVoucher/VoucherTemplates`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>>;
                    }

                    public ListLoyaltyExchangeable(): Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>> {
                        const args = [];
                        const requestParams = new RequestParams('LoyaltyExchangeable', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>;
                    }

                    public GetByName(name: string): Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('getByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate>;
                    }

                    public UpsertByName(vt: Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate): Observable<any> {
                        const args = [new RequestParam('vt', RequestParamType.BODY, vt)];
                        const requestParams = new RequestParams('UpsertByName', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Post(templateType: Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate): Observable<any> {
                        const args = [new RequestParam('templateType', RequestParamType.BODY, templateType)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, templateType: Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('templateType', RequestParamType.BODY, templateType)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ImportStack(templateId: number): Observable<any> {
                        const args = [new RequestParam('templateId', RequestParamType.ROUTE, templateId)];
                        const requestParams = new RequestParams('importStack/{templateId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ImportStackContent(templateId: number, request: Honeycomb.Tenant.Vouchers.IService.Model.VoucherStackCodesImportRequest): Observable<any> {
                        const args = [new RequestParam('templateId', RequestParamType.ROUTE, templateId), new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('importStackContent/{templateId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetStack(templateId: number): Observable<any> {
                        const args = [new RequestParam('templateId', RequestParamType.ROUTE, templateId)];
                        const requestParams = new RequestParams('stack/{templateId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateCreatedVouchersValidity(templateId: number): Observable<any> {
                        const args = [new RequestParam('templateId', RequestParamType.ROUTE, templateId)];
                        const requestParams = new RequestParams('updateCreatedVouchersValidity/{templateId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {


            @Injectable()
            export class ApiController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantVoucher/`;

                public Ping(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public CacheClear(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Version(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {


                @Injectable()
                export class HeaderAttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/HeaderAttributeTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>>;
                    }

                    public Filter(pageSize: number, page: number, id: string): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>> {
                        const args = [new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>>;
                    }

                    public Post(value: Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.BODY, value)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public EnsureEditionAttributes(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('EnsureEditionAttributes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, attribute: Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attribute', RequestParamType.BODY, attribute)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class OnlinePaymentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/onlinepayment`;

                    public GetOnlinePayment(encryptedHeaderId: string): Observable<string> {
                        const args = [new RequestParam('encryptedHeaderId', RequestParamType.ROUTE, encryptedHeaderId)];
                        const requestParams = new RequestParams('{encryptedHeaderId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public CheckPaymentStatus(encryptedPaymentId: string): Observable<Array<string>> {
                        const args = [new RequestParam('encryptedPaymentId', RequestParamType.ROUTE, encryptedPaymentId)];
                        const requestParams = new RequestParams('status/{encryptedPaymentId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                    }

                    public InitiatePayment(encryptedPaymentId: string): Observable<Array<string>> {
                        const args = [new RequestParam('encryptedPaymentId', RequestParamType.ROUTE, encryptedPaymentId)];
                        const requestParams = new RequestParams('{encryptedPaymentId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                    }
                }

                @Injectable()
                export class OrderController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/order`;

                    public Get(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public OrderItem(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('item/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item>;
                    }

                    public GetUserBasket(): Observable<Honeycomb.Tenant.Transactions.IService.Model.UserBasket> {
                        const args = [];
                        const requestParams = new RequestParams('GetUserBasket', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.UserBasket>;
                    }

                    public CreateAuth(order: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSaveResult> {
                        const args = [new RequestParam('order', RequestParamType.BODY, order)];
                        const requestParams = new RequestParams('CreateAuth', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSaveResult>;
                    }

                    public Post(order: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSaveResult> {
                        const args = [new RequestParam('order', RequestParamType.BODY, order)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSaveResult>;
                    }

                    public PostOrderInternal(orderAndUser: Honeycomb.Tenant.Transactions.IService.Model.OrderAndUserContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('orderAndUser', RequestParamType.BODY, orderAndUser)];
                        const requestParams = new RequestParams('PostOrderInternal', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public PostOrderInternalSplit(orderAndUser: Honeycomb.Tenant.Transactions.IService.Model.OrderAndUserContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSplitResult> {
                        const args = [new RequestParam('orderAndUser', RequestParamType.BODY, orderAndUser)];
                        const requestParams = new RequestParams('PostOrderInternalSplit', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSplitResult>;
                    }

                    public PostWithUser(orderAndUser: Honeycomb.Tenant.Transactions.IService.Model.OrderAndUserContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('orderAndUser', RequestParamType.BODY, orderAndUser)];
                        const requestParams = new RequestParams('PostWithUser', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public Basket(order: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer> {
                        const args = [new RequestParam('order', RequestParamType.BODY, order)];
                        const requestParams = new RequestParams('Basket', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer>;
                    }

                    public BasketAuth(order: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer> {
                        const args = [new RequestParam('order', RequestParamType.BODY, order)];
                        const requestParams = new RequestParams('BasketAuth', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer>;
                    }

                    public Evaluate(order: Honeycomb.Tenant.Transactions.IService.Model.OrderAndUserContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer> {
                        const args = [new RequestParam('order', RequestParamType.BODY, order)];
                        const requestParams = new RequestParams('Evaluate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer>;
                    }

                    public PostOrderForVoucher(programName: string, container: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.BasketForVoucherResult> {
                        const args = [new RequestParam('programName', RequestParamType.QUERY, programName), new RequestParam('container', RequestParamType.BODY, container)];
                        const requestParams = new RequestParams('PostOrderForVoucher', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.BasketForVoucherResult>;
                    }

                    public ExchangeVoucherForLoyaltyPoints(programName: string, request: Honeycomb.Tenant.Transactions.IService.Model.VoucherExchangeRequest): Observable<Honeycomb.Tenant.Transactions.IService.Model.BasketForVoucherResult> {
                        const args = [new RequestParam('programName', RequestParamType.QUERY, programName), new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('ExchangeVoucherForLoyaltyPoints', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.BasketForVoucherResult>;
                    }

                    public OrderItemUpdate(id: number, orderItem: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('orderItem', RequestParamType.BODY, orderItem)];
                        const requestParams = new RequestParams('item/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item>;
                    }

                    public OrderNotesUpdate(id: number, orderItem: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('orderItem', RequestParamType.BODY, orderItem)];
                        const requestParams = new RequestParams('notes/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item>;
                    }

                    public OrderItemCreate(headerID: number, orderItem: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item> {
                        const args = [new RequestParam('headerID', RequestParamType.ROUTE, headerID), new RequestParam('orderItem', RequestParamType.BODY, orderItem)];
                        const requestParams = new RequestParams('item/{headerID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item>;
                    }

                    public OrderPayment(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('payment/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment>;
                    }

                    public OrderPaymentUpdate(id: number, orderPayment: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('orderPayment', RequestParamType.BODY, orderPayment)];
                        const requestParams = new RequestParams('payment/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment>;
                    }

                    public OrderPaymentCreate(headerID: number, orderItem: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment> {
                        const args = [new RequestParam('headerID', RequestParamType.ROUTE, headerID), new RequestParam('orderItem', RequestParamType.BODY, orderItem)];
                        const requestParams = new RequestParams('payment/{headerID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment>;
                    }

                    public OrderShipping(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('shipping/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping>;
                    }

                    public OrderShippingUpdate(id: number, orderShipping: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('orderShipping', RequestParamType.BODY, orderShipping)];
                        const requestParams = new RequestParams('shipping/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping>;
                    }

                    public OrderShippingCreate(headerID: number, orderShipping: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping> {
                        const args = [new RequestParam('headerID', RequestParamType.ROUTE, headerID), new RequestParam('orderShipping', RequestParamType.BODY, orderShipping)];
                        const requestParams = new RequestParams('shipping/{headerID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping>;
                    }

                    public GetProductAvailability(availabilityRequest: Honeycomb.Tenant.Transactions.IService.Model.Omnichannel.ProductAvailabilityRequest): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Omnichannel.ProductAvailability>> {
                        const args = [new RequestParam('availabilityRequest', RequestParamType.BODY, availabilityRequest)];
                        const requestParams = new RequestParams('productAvailability', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Omnichannel.ProductAvailability>>;
                    }

                    public DeleteUserBasket(olderThenHours: number): Observable<any> {
                        const args = [new RequestParam('olderThenHours', RequestParamType.QUERY, olderThenHours)];
                        const requestParams = new RequestParams('deleteUserBasket', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserBasketAsTransaction(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetUserBasketAsTransaction/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public SendAbandonedBasketMessage(olderThenHours: number): Observable<any> {
                        const args = [new RequestParam('olderThenHours', RequestParamType.QUERY, olderThenHours)];
                        const requestParams = new RequestParams('SendAbandonedBasketMessage', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UploadAttachment(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('UploadAttachment', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckVATNumber(countryCode: string, vatNumber: string): Observable<any> {
                        const args = [new RequestParam('countryCode', RequestParamType.ROUTE, countryCode), new RequestParam('vatNumber', RequestParamType.ROUTE, vatNumber)];
                        const requestParams = new RequestParams('vatcheck/{countryCode}/{vatNumber}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetShippingsForItem(variantId: number, country: string): Observable<{ [key: string]: number }> {
                        const args = [new RequestParam('variantId', RequestParamType.ROUTE, variantId), new RequestParam('country', RequestParamType.ROUTE, country)];
                        const requestParams = new RequestParams('shippings/{variantId}/{country}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: number }>;
                    }
                }

                @Injectable()
                export class PDFFormController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/PDFForm`;

                    public Get(recordUID: string, itemUID: string, userUID: string, redirectURL: string, fileTableName: string): Observable<any> {
                        const args = [new RequestParam('recordUID', RequestParamType.QUERY, recordUID), new RequestParam('itemUID', RequestParamType.QUERY, itemUID), new RequestParam('userUID', RequestParamType.QUERY, userUID), new RequestParam('redirectURL', RequestParamType.QUERY, redirectURL), new RequestParam('fileTableName', RequestParamType.ROUTE, fileTableName)];
                        const requestParams = new RequestParams('{fileTableName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public FilledPDFForm(itemUID: string): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.PDFFormContent> {
                        const args = [new RequestParam('itemUID', RequestParamType.ROUTE, itemUID)];
                        const requestParams = new RequestParams('FilledPDFForm/{itemUID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.PDFFormContent>;
                    }

                    public Submit(itemUID: string, recordUID: string, fileTableName: string): Observable<any> {
                        const args = [new RequestParam('itemUID', RequestParamType.QUERY, itemUID), new RequestParam('recordUID', RequestParamType.QUERY, recordUID), new RequestParam('fileTableName', RequestParamType.QUERY, fileTableName)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class PromotionController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/promotion`;

                    public UpsertList(promotions: Array<Honeycomb.Tenant.Transactions.IService.Model.Promotion>): Observable<any> {
                        const args = [new RequestParam('promotions', RequestParamType.BODY, promotions)];
                        const requestParams = new RequestParams('upsertList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ListComplete(filter: Honeycomb.Tenant.Transactions.IService.Model.PromotionFilter): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('ListComplete', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public EditorModel(): Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionEditorModel> {
                        const args = [];
                        const requestParams = new RequestParams('EditorModel', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionEditorModel>;
                    }

                    public Import(files: Array<any>): Observable<any> {
                        const args = [new RequestParam('files', RequestParamType.BODY, files)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.uploadFile(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Export(request: Honeycomb.Tenant.Transactions.IService.Model.PromotionExportRequest): Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionExportResult>;
                    }

                    public DownloadExported(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('downloadExported/{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Evaluate(container: Honeycomb.Tenant.Transactions.IService.Model.PTContainer): Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer> {
                        const args = [new RequestParam('container', RequestParamType.BODY, container)];
                        const requestParams = new RequestParams('evaluate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.OrderContainer>;
                    }

                    public GetPromotionCalendar(filter: Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendarFilter): Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendar> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('CalendarByFilter', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendar>;
                    }

                    public GetPromotionCalendarByPromotion(promotionID: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendar> {
                        const args = [new RequestParam('promotionID', RequestParamType.ROUTE, promotionID)];
                        const requestParams = new RequestParams('CalendarByPromotion/{promotionID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendar>;
                    }
                }

                @Injectable()
                export class TransactionController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/transaction`;

                    public Get(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByHeaderUID(printable: boolean, id: string): Observable<any> {
                        const args = [new RequestParam('printable', RequestParamType.QUERY, printable), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('ByHeaderUID/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByTranNo(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('ByTranNo/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByUserID(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('ByUserID/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetHeader(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('getHeader/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public GetHeaderByUID(uid: string): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('uid', RequestParamType.ROUTE, uid)];
                        const requestParams = new RequestParams('getHeaderByUID/{uid}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public GetHeaderByNumber(number: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('getHeaderByNumber/{number}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public GetPrintableByEncryptedID(encryptedID: string): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('encryptedID', RequestParamType.ROUTE, encryptedID)];
                        const requestParams = new RequestParams('getPrintableByEncryptedID/{encryptedID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public GetPrintable(inclTranItemHistory: boolean, id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header> {
                        const args = [new RequestParam('inclTranItemHistory', RequestParamType.QUERY, inclTranItemHistory), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('getPrintable/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                    }

                    public GetByTimestamp(topCount: number, status: number, locationID: number, timestamp: number): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>> {
                        const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('locationID', RequestParamType.QUERY, locationID), new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                        const requestParams = new RequestParams('getByTimestamp/{timestamp}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>>;
                    }

                    public Contact(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Contact/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact>;
                    }

                    public GetChartData(startDate: string, endDate: string, granular: string, timeZoneId: string): Observable<any> {
                        const args = [new RequestParam('startDate', RequestParamType.ROUTE, startDate), new RequestParam('endDate', RequestParamType.ROUTE, endDate), new RequestParam('granular', RequestParamType.ROUTE, granular), new RequestParam('timeZoneId', RequestParamType.ROUTE, timeZoneId)];
                        const requestParams = new RequestParams('chartdata/{startDate}/{endDate}/{granular}/{timeZoneId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ContactUpdate(id: number, address: Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('address', RequestParamType.BODY, address)];
                        const requestParams = new RequestParams('Contact/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Post(header: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header): Observable<any> {
                        const args = [new RequestParam('header', RequestParamType.BODY, header)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateHeader(id: number, header: Honeycomb.Tenant.Transactions.IService.Model.Transaction.HeaderUpdate): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('header', RequestParamType.BODY, header)];
                        const requestParams = new RequestParams('header/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateHeaderByNumber(tranNo: number, header: Honeycomb.Tenant.Transactions.IService.Model.Transaction.HeaderUpdate): Observable<any> {
                        const args = [new RequestParam('tranNo', RequestParamType.ROUTE, tranNo), new RequestParam('header', RequestParamType.BODY, header)];
                        const requestParams = new RequestParams('headerByNumber/{tranNo}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateHeaderAttributes(id: number, attributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Attribute>): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attributes', RequestParamType.BODY, attributes)];
                        const requestParams = new RequestParams('headerAttributes/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SyncAll(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('sync', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SyncTransaction(transactionID: number): Observable<any> {
                        const args = [new RequestParam('transactionID', RequestParamType.ROUTE, transactionID)];
                        const requestParams = new RequestParams('sync/{transactionID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, headerUpdate: Honeycomb.Tenant.Transactions.IService.Model.Transaction.HeaderUpdate): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('headerUpdate', RequestParamType.BODY, headerUpdate)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Select(select: Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSelect): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>> {
                        const args = [new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>>;
                    }

                    public SelectItems(select: Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSelect): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemWithTransaction>> {
                        const args = [new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('selectItems', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemWithTransaction>>;
                    }

                    public SelectPage(select: Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSelect): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>> {
                        const args = [new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('selectpage', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>>;
                    }

                    public Document(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Document/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument>;
                    }

                    public AddDocument(select: Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument): Observable<any> {
                        const args = [new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('AddDocument', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateDocument(id: number, select: Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('UpdateDocument/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteDocument(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('DeleteDocument/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public EvaluatePromotions(header: Honeycomb.Tenant.Transactions.IService.Model.Transaction.HeaderWithPromotions): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.HeaderWithPromotions> {
                        const args = [new RequestParam('header', RequestParamType.BODY, header)];
                        const requestParams = new RequestParams('EvaluatePromotions', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.HeaderWithPromotions>;
                    }

                    public UpdateTransactionStatus(status: number, transactionUID: string): Observable<any> {
                        const args = [new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('transactionUID', RequestParamType.ROUTE, transactionUID)];
                        const requestParams = new RequestParams('UpdateTransactionStatus/{transactionUID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateItemStatus(status: number, itemUID: string): Observable<any> {
                        const args = [new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('itemUID', RequestParamType.ROUTE, itemUID)];
                        const requestParams = new RequestParams('UpdateItemStatus/{itemUID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Note(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Note> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('note/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Note>;
                    }

                    public AddNote(select: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Note): Observable<any> {
                        const args = [new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('note', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateNote(id: number, select: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Note): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('UpdateNote/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteNote(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('DeleteNote/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public HistoryTrans(id: number): Observable<Array<Honeycomb.Common.Db.HistoryChange>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('HistoryTrans/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.Db.HistoryChange>>;
                    }

                    public HistoryTransHeader(id: number): Observable<Array<Honeycomb.Common.Db.HistoryChange>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('HistoryTransHeader/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.Db.HistoryChange>>;
                    }

                    public HistoryTransContact(id: number): Observable<Array<Honeycomb.Common.Db.HistoryChange>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('HistoryTransContact/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.Db.HistoryChange>>;
                    }

                    public HistoryTransPayment(id: number): Observable<Array<Honeycomb.Common.Db.HistoryChange>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('HistoryTransPayment/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.Db.HistoryChange>>;
                    }

                    public HistoryTransShipping(id: number): Observable<Array<Honeycomb.Common.Db.HistoryChange>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('HistoryTransShipping/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.Db.HistoryChange>>;
                    }
                }

                @Injectable()
                export class TransactionEditController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/TransactionEdit`;

                    public TransactionUpsert(order: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer): Observable<any> {
                        const args = [new RequestParam('order', RequestParamType.BODY, order)];
                        const requestParams = new RequestParams('TransactionUpsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public TransactionLoad(createClone: boolean, id: string): Observable<any> {
                        const args = [new RequestParam('createClone', RequestParamType.QUERY, createClone), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('TransactionLoad/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TransactionImportExportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/TransactionImportExport`;

                    public Import(request: Honeycomb.Common.ImportExport.Model.ImportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                    }

                    public Export(request: Honeycomb.Common.ImportExport.Model.ExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ExportResult>;
                    }

                    public DialogModel(interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                        const args = [new RequestParam('interfaceModel', RequestParamType.BODY, interfaceModel)];
                        const requestParams = new RequestParams('dialogModel', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                    }
                }

                @Injectable()
                export class TransactionStatusController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/TransactionStatus`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionStatus>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionStatus>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionStatus> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionStatus>;
                    }

                    public Post(value: Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionStatus): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.BODY, value)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTransactions/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace TemplateEngine {
            export namespace IService {


                @Injectable()
                export class DistributionChannelsController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/distributionChannels`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel>;
                    }

                    public GetForMedia(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetForMedia/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel>;
                    }

                    public Post(channel: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannel): Observable<any> {
                        const args = [new RequestParam('channel', RequestParamType.BODY, channel)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DistributeTest(distributeTest: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeTest): Observable<any> {
                        const args = [new RequestParam('distributeTest', RequestParamType.BODY, distributeTest)];
                        const requestParams = new RequestParams('DistributeTest', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DistributeAsync(m: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel): Observable<any> {
                        const args = [new RequestParam('m', RequestParamType.BODY, m)];
                        const requestParams = new RequestParams('Distribute', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DistributeBatch(m: Honeycomb.Tenant.TemplateEngine.IService.Model.ProcessDistributionBatch): Observable<any> {
                        const args = [new RequestParam('m', RequestParamType.BODY, m)];
                        const requestParams = new RequestParams('DistributeBatch', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateDistributionBatch(batch: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchCreateRequest): Observable<any> {
                        const args = [new RequestParam('batch', RequestParamType.BODY, batch)];
                        const requestParams = new RequestParams('CreateDistributionBatch', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetBatchesToDistribute(includeBeingProcessed: boolean): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatch>> {
                        const args = [new RequestParam('includeBeingProcessed', RequestParamType.QUERY, includeBeingProcessed)];
                        const requestParams = new RequestParams('GetBatchesToDistribute', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatch>>;
                    }

                    public UpdateDistributionBatchState(distributionBatchID: number, distributionState: Honeycomb.Common.Enums.DistributionBatchState): Observable<any> {
                        const args = [new RequestParam('distributionBatchID', RequestParamType.QUERY, distributionBatchID), new RequestParam('distributionState', RequestParamType.QUERY, distributionState)];
                        const requestParams = new RequestParams('UpdateDistributionBatchState', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteBatch(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('DeleteBatch/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public BatchesHistory(mediaID: number, searchBase: Honeycomb.Common.SearchBase): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionHistory>> {
                        const args = [new RequestParam('mediaID', RequestParamType.ROUTE, mediaID), new RequestParam('searchBase', RequestParamType.BODY, searchBase)];
                        const requestParams = new RequestParams('BatchesHistory/{mediaID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionHistory>>;
                    }

                    public BatchHistoryItems(batchID: number, searchBase: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionItemsRequest): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItem>> {
                        const args = [new RequestParam('batchID', RequestParamType.ROUTE, batchID), new RequestParam('searchBase', RequestParamType.BODY, searchBase)];
                        const requestParams = new RequestParams('BatchHistoryItems/{batchID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItem>>;
                    }

                    public BatchHistoryItem(includeEmailContent: boolean, distributionBatchItemID: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItem> {
                        const args = [new RequestParam('includeEmailContent', RequestParamType.QUERY, includeEmailContent), new RequestParam('distributionBatchItemID', RequestParamType.ROUTE, distributionBatchItemID)];
                        const requestParams = new RequestParams('BatchHistoryItem/{distributionBatchItemID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItem>;
                    }

                    public SyncAll(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('syncAll', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class HtmlViewController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/htmlView`;

                    public List(): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView>;
                    }

                    public GetByName(name: string): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('ByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView>;
                    }

                    public Post(media: Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView): Observable<any> {
                        const args = [new RequestParam('media', RequestParamType.BODY, media)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertByName(view: Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView): Observable<any> {
                        const args = [new RequestParam('view', RequestParamType.BODY, view)];
                        const requestParams = new RequestParams('upsertByName', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Render(request: Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlViewRenderRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('render', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetData(request: Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlViewRenderRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('getData', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetTableScriptTag(tableName: string): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.ScriptTagResponse> {
                        const args = [new RequestParam('tableName', RequestParamType.ROUTE, tableName)];
                        const requestParams = new RequestParams('getTableScriptTag/{tableName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.ScriptTagResponse>;
                    }

                    public GetViewScriptTag(viewName: string): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.ScriptTagResponse> {
                        const args = [new RequestParam('viewName', RequestParamType.ROUTE, viewName)];
                        const requestParams = new RequestParams('getViewScriptTag/{viewName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.ScriptTagResponse>;
                    }

                    public EnsureHtmlTableConfig(request: Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlTableConfigRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('ensureHtmlTableConfig', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public HtmlView(viewName: string): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DataSourceInput>> {
                        const args = [new RequestParam('viewName', RequestParamType.ROUTE, viewName)];
                        const requestParams = new RequestParams('view/{viewName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DataSourceInput>>;
                    }
                }

                @Injectable()
                export class MediaController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/media`;

                    public List(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Groups(): Observable<Array<string>> {
                        const args = [];
                        const requestParams = new RequestParams('Groups', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                    }

                    public Select(filter: Honeycomb.Tenant.TemplateEngine.IService.ViewModel.MediaListFilter): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('Select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RenameGroup(model: Honeycomb.Tenant.TemplateEngine.IService.ViewModel.RenameGroupModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('RenameGroup', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Get(includeWallets: boolean, includeAttachments: boolean, id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media> {
                        const args = [new RequestParam('includeWallets', RequestParamType.QUERY, includeWallets), new RequestParam('includeAttachments', RequestParamType.QUERY, includeAttachments), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>;
                    }

                    public GetForVoucherTemplate(includeWallets: boolean, voucherTemplateId: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media> {
                        const args = [new RequestParam('includeWallets', RequestParamType.QUERY, includeWallets), new RequestParam('voucherTemplateId', RequestParamType.ROUTE, voucherTemplateId)];
                        const requestParams = new RequestParams('GetForVoucherTemplate/{voucherTemplateId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>;
                    }

                    public GetForVoucherTemplates(includeWallets: boolean, voucherTemplateIds: Array<number>): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>> {
                        const args = [new RequestParam('includeWallets', RequestParamType.QUERY, includeWallets), new RequestParam('voucherTemplateIds', RequestParamType.BODY, voucherTemplateIds)];
                        const requestParams = new RequestParams('GetForVoucherTemplates', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>>;
                    }

                    public GetVoucherTemplateAsMedia(voucherTemplateId: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media> {
                        const args = [new RequestParam('voucherTemplateId', RequestParamType.ROUTE, voucherTemplateId)];
                        const requestParams = new RequestParams('getVoucherTemplate/{voucherTemplateId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>;
                    }

                    public GetVoucherTemplateAsMediaByName(voucherTemplateName: string): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media> {
                        const args = [new RequestParam('voucherTemplateName', RequestParamType.ROUTE, voucherTemplateName)];
                        const requestParams = new RequestParams('getVoucherTemplateByName/{voucherTemplateName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>;
                    }

                    public UpdateVoucherTemplateFromMedia(media: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('media', RequestParamType.BODY, media)];
                        const requestParams = new RequestParams('updateVoucherTemplateFromMedia', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Short(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('short/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetForVoucherTemplateShort(voucherTemplateId: number): Observable<any> {
                        const args = [new RequestParam('voucherTemplateId', RequestParamType.ROUTE, voucherTemplateId)];
                        const requestParams = new RequestParams('GetForVoucherTemplateShort/{voucherTemplateId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Post(media: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('media', RequestParamType.BODY, media)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, media: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('media', RequestParamType.BODY, media)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertByName(media: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('media', RequestParamType.BODY, media)];
                        const requestParams = new RequestParams('UpsertByName', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckName(mediaID: number, name: string): Observable<any> {
                        const args = [new RequestParam('mediaID', RequestParamType.QUERY, mediaID), new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('CheckName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserDistribution(userID: number): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.MediaDistributionResult>> {
                        const args = [new RequestParam('userID', RequestParamType.ROUTE, userID)];
                        const requestParams = new RequestParams('GetUserDistribution/{userID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.MediaDistributionResult>>;
                    }

                    public GetTemplate(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetTemplate/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>;
                    }

                    public GetJSReportTemplate(templateId: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TemplateReport> {
                        const args = [new RequestParam('templateId', RequestParamType.ROUTE, templateId)];
                        const requestParams = new RequestParams('GetJSReportTemplate/{templateId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TemplateReport>;
                    }

                    public UpsertReportTemplate(template: Honeycomb.Tenant.TemplateEngine.IService.Model.JSReport): Observable<any> {
                        const args = [new RequestParam('template', RequestParamType.BODY, template)];
                        const requestParams = new RequestParams('UpsertReportTemplate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetVoucherTemplateIds(ids: Array<number>): Observable<{ [key: number]: number }> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('vouchertemplateids', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: number }>;
                    }
                }

                @Injectable()
                export class MediaFeedbackController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/mediaFeedback`;

                    public MailRead(tenantId: number, resultId: number, feedback: number, redirectURL: string): Observable<any> {
                        const args = [new RequestParam('tenantId', RequestParamType.QUERY, tenantId), new RequestParam('resultId', RequestParamType.QUERY, resultId), new RequestParam('feedback', RequestParamType.QUERY, feedback), new RequestParam('redirectURL', RequestParamType.QUERY, redirectURL)];
                        const requestParams = new RequestParams('mailRead', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public MailDeliveryResult(tenantId: number, batchItemID: string, SMTPResultCode: number, SMTPResultMessage: string): Observable<any> {
                        const args = [new RequestParam('tenantId', RequestParamType.QUERY, tenantId), new RequestParam('batchItemID', RequestParamType.QUERY, batchItemID), new RequestParam('SMTPResultCode', RequestParamType.QUERY, SMTPResultCode), new RequestParam('SMTPResultMessage', RequestParamType.ROUTE, SMTPResultMessage)];
                        const requestParams = new RequestParams('mailDeliveryResult', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TemplatePDFFormController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/templatePDFForm`;

                    public Get(batchItemID: number, redirectURL: string, pdfFileName: string): Observable<any> {
                        const args = [new RequestParam('batchItemID', RequestParamType.QUERY, batchItemID), new RequestParam('redirectURL', RequestParamType.QUERY, redirectURL), new RequestParam('pdfFileName', RequestParamType.ROUTE, pdfFileName)];
                        const requestParams = new RequestParams('{pdfFileName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Validate(files: Array<any>): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.PDFValidationResult> {
                        const args = [new RequestParam('files', RequestParamType.BODY, files)];
                        const requestParams = new RequestParams('validate', HttpMethod.POST, args);
                        return this.apiCaller.uploadFile(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.PDFValidationResult>;
                    }

                    public Submit(batchItemID: number): Observable<any> {
                        const args = [new RequestParam('batchItemID', RequestParamType.QUERY, batchItemID)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TemplateRenderController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/templateRender`;

                    public GetRenderedMedia(renderResult: Honeycomb.Common.Enums.RenderResult, tenantId: number, userUID: string, saveName: string, lang: string, transactionUID: string, statTransactionUID: string, tagID: number, entityId: string, voucherID: number, mediaName: string): Observable<any> {
                        const args = [new RequestParam('renderResult', RequestParamType.QUERY, renderResult), new RequestParam('tenantId', RequestParamType.QUERY, tenantId), new RequestParam('userUID', RequestParamType.QUERY, userUID), new RequestParam('saveName', RequestParamType.QUERY, saveName), new RequestParam('lang', RequestParamType.QUERY, lang), new RequestParam('transactionUID', RequestParamType.QUERY, transactionUID), new RequestParam('statTransactionUID', RequestParamType.QUERY, statTransactionUID), new RequestParam('tagID', RequestParamType.QUERY, tagID), new RequestParam('entityId', RequestParamType.QUERY, entityId), new RequestParam('voucherID', RequestParamType.QUERY, voucherID), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('getRenderedMedia/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetRenderedVoucher(userUID: string, renderResult: Honeycomb.Common.Enums.RenderResult, voucherNumber: string): Observable<any> {
                        const args = [new RequestParam('userUID', RequestParamType.QUERY, userUID), new RequestParam('renderResult', RequestParamType.QUERY, renderResult), new RequestParam('voucherNumber', RequestParamType.ROUTE, voucherNumber)];
                        const requestParams = new RequestParams('getRenderedVoucher/{voucherNumber}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Post(request: Honeycomb.Tenant.TemplateEngine.IService.Model.TemplateRenderRequestAnonymous): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public PostAuth(request: Honeycomb.Tenant.TemplateEngine.IService.Model.TemplateRenderRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('auth', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RenderMedia(request: Honeycomb.Tenant.TemplateEngine.IService.Model.MediaRenderRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('renderMedia', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Get(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetDataSourceInputs(entityDataType: Honeycomb.Common.Enums.EntityDataType): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DataSourceInput>> {
                        const args = [new RequestParam('entityDataType', RequestParamType.ROUTE, entityDataType)];
                        const requestParams = new RequestParams('GetDataSourceInputs/{entityDataType}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DataSourceInput>>;
                    }

                    public RenderPDF(request: Honeycomb.Tenant.TemplateEngine.IService.Model.PDFRenderRequest): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.PDFRenderResponse> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('renderPDF', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.PDFRenderResponse>;
                    }

                    public GetPDFRenderResult(resultID: string): Observable<any> {
                        const args = [new RequestParam('resultID', RequestParamType.QUERY, resultID)];
                        const requestParams = new RequestParams('GetPDFRenderResult', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TemplateStorageController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/templateStorage`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>;
                    }

                    public GetDetail(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetDetail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetDetailShort(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetDetailShort/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>;
                    }

                    public GetJSReport(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetJSReport/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetByJSReport(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetByJSReport/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckTenantTemplateExists(id: string): Observable<boolean> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('CheckTenantTemplateExists/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<boolean>;
                    }

                    public TryInsertTenantTemplate(tenantTemplate: Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate): Observable<any> {
                        const args = [new RequestParam('tenantTemplate', RequestParamType.BODY, tenantTemplate)];
                        const requestParams = new RequestParams('TryInsertTenantTemplate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public TryInsertJSReport(templateType: Honeycomb.Tenant.TemplateEngine.IService.Model.TemplateType): Observable<any> {
                        const args = [new RequestParam('templateType', RequestParamType.BODY, templateType)];
                        const requestParams = new RequestParams('TryInsertJSReport', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, templateType: Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('templateType', RequestParamType.BODY, templateType)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertTemplate(templateType: Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate): Observable<any> {
                        const args = [new RequestParam('templateType', RequestParamType.BODY, templateType)];
                        const requestParams = new RequestParams('UpsertTemplate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Select(select: Honeycomb.Tenant.TemplateEngine.IService.ViewModel.TemplateSelect): Observable<any> {
                        const args = [new RequestParam('select', RequestParamType.BODY, select)];
                        const requestParams = new RequestParams('select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public JsReportList(unused: boolean, tenantHash: string): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.ViewModel.TemplateTypeSelect>> {
                        const args = [new RequestParam('unused', RequestParamType.QUERY, unused), new RequestParam('tenantHash', RequestParamType.ROUTE, tenantHash)];
                        const requestParams = new RequestParams('jsReportList/{tenantId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.ViewModel.TemplateTypeSelect>>;
                    }
                }

                @Injectable()
                export class WalletController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/wallet`;

                    public GetAppleWalletPassbook(userUID: string, tenantId: number, voucherId: number, tagId: number, languageISOCode: string, mediaName: string): Observable<any> {
                        const args = [new RequestParam('userUID', RequestParamType.QUERY, userUID), new RequestParam('tenantId', RequestParamType.QUERY, tenantId), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('languageISOCode', RequestParamType.QUERY, languageISOCode), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('getAppleWalletPassbook/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetGooglePayObject(userUID: string, tenantId: number, voucherId: number, tagId: number, languageISOCode: string, mediaName: string): Observable<any> {
                        const args = [new RequestParam('userUID', RequestParamType.QUERY, userUID), new RequestParam('tenantId', RequestParamType.QUERY, tenantId), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('languageISOCode', RequestParamType.QUERY, languageISOCode), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('getGooglePayObject/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public AppleWalletByUserNumber(userNumber: string, voucherId: number, tagId: number, languageISOCode: string, mediaName: string): Observable<any> {
                        const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('languageISOCode', RequestParamType.QUERY, languageISOCode), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('appleWalletByUserNumber/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GooglePayObjectByUserNumber(userNumber: string, voucherId: number, tagId: number, languageISOCode: string, mediaName: string): Observable<any> {
                        const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('languageISOCode', RequestParamType.QUERY, languageISOCode), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('googlePayObjectByUserNumber/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public AppleWalletByUserNumberAlt(userNumber: string, voucherNumber: string, tagNumber: string, languageISOCode: string, mediaName: string): Observable<any> {
                        const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherNumber', RequestParamType.QUERY, voucherNumber), new RequestParam('tagNumber', RequestParamType.QUERY, tagNumber), new RequestParam('languageISOCode', RequestParamType.QUERY, languageISOCode), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('appleWalletByUserNumberAlt/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GooglePayObjectByUserNumberURLAlt(userNumber: string, voucherNumber: string, tagNumber: string, languageISOCode: string, mediaName: string): Observable<any> {
                        const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherNumber', RequestParamType.QUERY, voucherNumber), new RequestParam('tagNumber', RequestParamType.QUERY, tagNumber), new RequestParam('languageISOCode', RequestParamType.QUERY, languageISOCode), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('googlePayObjectByUserNumberURLAlt/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GooglePayObjectByUserNumberURL(userNumber: string, voucherId: number, tagId: number, languageISOCode: string, mediaName: string): Observable<any> {
                        const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('languageISOCode', RequestParamType.QUERY, languageISOCode), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('googlePayObjectByUserNumberURL/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateWallet(request: Honeycomb.Tenant.TemplateEngine.IService.Model.UpdateWalletRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('updateWallet', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateAllUsedWallets(mediaName: string): Observable<any> {
                        const args = [new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('updateAllUsedWallets/{mediaName}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ProcessWalletUpdates(maxCount: number): Observable<any> {
                        const args = [new RequestParam('maxCount', RequestParamType.ROUTE, maxCount)];
                        const requestParams = new RequestParams('processWalletUpdates/{maxCount}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class WalletTemplateController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/walletTemplate`;

                    public List(): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate>>;
                    }

                    public Select(filter: Honeycomb.Common.SearchBase): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate>;
                    }

                    public Post(template: Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate> {
                        const args = [new RequestParam('template', RequestParamType.BODY, template)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetGoolePayURL(mediaName: string): Observable<any> {
                        const args = [new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                        const requestParams = new RequestParams('GooglePayURL/{mediaName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace TemplateEngine {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantTemplateEngine/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {
                export namespace Controller {


                    @Injectable()
                    export class ActivityController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/activity`;

                        public List(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Activity>> {
                            const args = [];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Activity>>;
                        }

                        public Detail(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Activity> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Activity>;
                        }

                        public ActiveList(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Activity>> {
                            const args = [];
                            const requestParams = new RequestParams('active', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Activity>>;
                        }

                        public InactiveList(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Activity>> {
                            const args = [];
                            const requestParams = new RequestParams('inactive', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Activity>>;
                        }

                        public Insert(model: Honeycomb.Tenant.Tasker.IService.Model.Activity): Observable<number> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Update(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.Activity): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Deactivate(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Import(files: Array<any>): Observable<any> {
                            const args = [new RequestParam('files', RequestParamType.BODY, files)];
                            const requestParams = new RequestParams('import', HttpMethod.POST, args);
                            return this.apiCaller.uploadFile(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Export(selectedIds: Array<number>): Observable<string> {
                            const args = [new RequestParam('selectedIds', RequestParamType.BODY, selectedIds)];
                            const requestParams = new RequestParams('export', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                        }

                        public DownloadExported(token: string): Observable<any> {
                            const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                            const requestParams = new RequestParams('downloadExported/{token}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class FileController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/file`;

                        public UploadFile(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('uploadfile', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public GetTempFile(maxWidth: number, maxHeight: number, id: string): Observable<any> {
                            const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('gettempfile/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Get(TenantID: number, id: string): Observable<any> {
                            const args = [new RequestParam('TenantID', RequestParamType.QUERY, TenantID), new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CloneFile(id: string): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('cloneFile/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public GetMetadata(id: string): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public GetTaskFiles(id: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('taskfiles/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>>;
                        }

                        public MetadataForRecords(id: Array<string>): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.BODY, id)];
                            const requestParams = new RequestParams('metadataForRecords', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public SaveFile(id: string): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public StoreGlobal(id: string): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('StoreGlobal/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public InsertTaskAttachment(model: Honeycomb.Tenant.Tasker.IService.Model.TaskAttachment): Observable<any> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('taskattachment', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public UpdateTaskAttachment(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.TaskAttachment): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('taskattachment/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public DeleteTaskAttachment(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('taskattachment/{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public TaskAttachment(documentUID: string, fileName: string): Observable<any> {
                            const args = [new RequestParam('documentUID', RequestParamType.ROUTE, documentUID), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                            const requestParams = new RequestParams('taskattachment/{documentUID}/{fileName}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public DirectoryStructure(path: string, filter: string): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskFileInfo>> {
                            const args = [new RequestParam('path', RequestParamType.QUERY, path), new RequestParam('filter', RequestParamType.QUERY, filter)];
                            const requestParams = new RequestParams('directoryStructure', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskFileInfo>>;
                        }

                        public FileContent(path: string, secret: string, tenantID: number): Observable<any> {
                            const args = [new RequestParam('path', RequestParamType.QUERY, path), new RequestParam('secret', RequestParamType.QUERY, secret), new RequestParam('tenantID', RequestParamType.QUERY, tenantID)];
                            const requestParams = new RequestParams('fileContent', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public FileThumbnail(path: string, secret: string, tenantID: number): Observable<any> {
                            const args = [new RequestParam('path', RequestParamType.QUERY, path), new RequestParam('secret', RequestParamType.QUERY, secret), new RequestParam('tenantID', RequestParamType.QUERY, tenantID)];
                            const requestParams = new RequestParams('fileThumbnail', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public FileDownloadToken(path: string): Observable<any> {
                            const args = [new RequestParam('path', RequestParamType.QUERY, path)];
                            const requestParams = new RequestParams('fileDownloadToken', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Download(guid: string, fileName: string): Observable<any> {
                            const args = [new RequestParam('guid', RequestParamType.ROUTE, guid), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                            const requestParams = new RequestParams('download/{guid}/{fileName}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class InputController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/input`;

                        public List(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Input>> {
                            const args = [];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Input>>;
                        }

                        public Detail(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Input> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Input>;
                        }

                        public Insert(model: Honeycomb.Tenant.Tasker.IService.Model.Input): Observable<number> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public InfoText(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Input> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('infotext/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Input>;
                        }

                        public InfoTextSnapshot(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('InfoTextSnapshot/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Update(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.Input): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class JobToJobPermissionController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/JobToJobPermission`;

                        public Detail(JobGroupIDSrc: number, JobGroupIDDest: number, operationID: number, permissionType: Honeycomb.Common.Enums.PermissionType, taskRelationSrc: Honeycomb.Common.Enums.TaskRelation, taskRelationDest: Honeycomb.Common.Enums.TaskRelation): Observable<any> {
                            const args = [new RequestParam('JobGroupIDSrc', RequestParamType.QUERY, JobGroupIDSrc), new RequestParam('JobGroupIDDest', RequestParamType.QUERY, JobGroupIDDest), new RequestParam('operationID', RequestParamType.QUERY, operationID), new RequestParam('permissionType', RequestParamType.QUERY, permissionType), new RequestParam('taskRelationSrc', RequestParamType.QUERY, taskRelationSrc), new RequestParam('taskRelationDest', RequestParamType.QUERY, taskRelationDest)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public List(JobGroupIDSrc: number, JobGroupIDDest: number, operationID: number, permissionType: Honeycomb.Common.Enums.PermissionType, taskRelationSrc: Honeycomb.Common.Enums.TaskRelation, taskRelationDest: Honeycomb.Common.Enums.TaskRelation): Observable<any> {
                            const args = [new RequestParam('JobGroupIDSrc', RequestParamType.QUERY, JobGroupIDSrc), new RequestParam('JobGroupIDDest', RequestParamType.QUERY, JobGroupIDDest), new RequestParam('operationID', RequestParamType.QUERY, operationID), new RequestParam('permissionType', RequestParamType.QUERY, permissionType), new RequestParam('taskRelationSrc', RequestParamType.QUERY, taskRelationSrc), new RequestParam('taskRelationDest', RequestParamType.QUERY, taskRelationDest)];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Upsert(model: Honeycomb.Tenant.Tasker.IService.Model.JobToJobPermission): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.JobToJobPermission>> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.JobToJobPermission>>;
                        }

                        public JobPermissionTypes(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('JobPermissionTypes', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public UpsertBatch(model: Array<Honeycomb.Tenant.Tasker.IService.Model.JobToJobPermission>): Observable<any> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('UpsertBatch', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class LocationController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/location`;

                        public MyLocations(locationFilter: Honeycomb.Tenant.Tasker.IService.Model.TaskLocationFilter): Observable<{ [key: number]: string }> {
                            const args = [new RequestParam('locationFilter', RequestParamType.BODY, locationFilter)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: string }>;
                        }

                        public UserRelatedLocations(): Observable<Array<number>> {
                            const args = [];
                            const requestParams = new RequestParams('UserRelatedLocations', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                        }

                        public Record(locationRecord: Honeycomb.Tenant.Tasker.IService.Model.LocationRecord): Observable<number> {
                            const args = [new RequestParam('locationRecord', RequestParamType.BODY, locationRecord)];
                            const requestParams = new RequestParams('Record', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Filter(offset: number, limit: number, activeOnly: boolean, latitude: number, longitude: number, jobID: number, onlyUserRelated: boolean, search: string): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.LocationSearchResultItem>> {
                            const args = [new RequestParam('offset', RequestParamType.QUERY, offset), new RequestParam('limit', RequestParamType.QUERY, limit), new RequestParam('activeOnly', RequestParamType.QUERY, activeOnly), new RequestParam('latitude', RequestParamType.QUERY, latitude), new RequestParam('longitude', RequestParamType.QUERY, longitude), new RequestParam('jobID', RequestParamType.QUERY, jobID), new RequestParam('onlyUserRelated', RequestParamType.QUERY, onlyUserRelated), new RequestParam('search', RequestParamType.ROUTE, search)];
                            const requestParams = new RequestParams('filter/{search}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.LocationSearchResultItem>>;
                        }
                    }

                    @Injectable()
                    export class MeaningController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/meaning`;

                        public List(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Meaning>> {
                            const args = [];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Meaning>>;
                        }

                        public Detail(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Meaning> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Meaning>;
                        }

                        public ActiveList(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Meaning>> {
                            const args = [];
                            const requestParams = new RequestParams('active', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Meaning>>;
                        }

                        public InactiveList(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Meaning>> {
                            const args = [];
                            const requestParams = new RequestParams('inactive', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Meaning>>;
                        }

                        public Insert(model: Honeycomb.Tenant.Tasker.IService.Model.Meaning): Observable<number> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Update(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.Meaning): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Deactivate(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class MessageController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/message`;

                        public List(taskID: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>> {
                            const args = [new RequestParam('taskID', RequestParamType.ROUTE, taskID)];
                            const requestParams = new RequestParams('list/{taskID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>>;
                        }

                        public LocationMessages(locationID: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>> {
                            const args = [new RequestParam('locationID', RequestParamType.ROUTE, locationID)];
                            const requestParams = new RequestParams('location/{locationID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>>;
                        }

                        public ListSimple(taskID: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>> {
                            const args = [new RequestParam('taskID', RequestParamType.ROUTE, taskID)];
                            const requestParams = new RequestParams('listsimple/{taskID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>>;
                        }

                        public ListSimpleLocation(locationID: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>> {
                            const args = [new RequestParam('locationID', RequestParamType.ROUTE, locationID)];
                            const requestParams = new RequestParams('listsimple/location/{locationID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskMessage>>;
                        }

                        public Insert(model: Honeycomb.Tenant.Tasker.IService.Model.TaskMessage): Observable<number> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Update(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.TaskMessage): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public SetRead(taskID: number): Observable<any> {
                            const args = [new RequestParam('taskID', RequestParamType.ROUTE, taskID)];
                            const requestParams = new RequestParams('setRead/{taskID}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public SetReadLocation(locationID: number): Observable<any> {
                            const args = [new RequestParam('locationID', RequestParamType.ROUTE, locationID)];
                            const requestParams = new RequestParams('setRead/location/{locationID}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class NotificationController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/notification`;

                        public PushSubscription(pushSubscriptionModel: Honeycomb.Tenant.Tasker.IService.Model.Notification.PushSubscriptionModel): Observable<any> {
                            const args = [new RequestParam('pushSubscriptionModel', RequestParamType.BODY, pushSubscriptionModel)];
                            const requestParams = new RequestParams('PushSubscription', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public BellSubscribe(pushSubscriptionModel: Honeycomb.Tenant.Tasker.IService.Model.Notification.BellSubscriptionModel): Observable<any> {
                            const args = [new RequestParam('pushSubscriptionModel', RequestParamType.BODY, pushSubscriptionModel)];
                            const requestParams = new RequestParams('BellSubscribe', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Notifications(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Notification.Notification>> {
                            const args = [];
                            const requestParams = new RequestParams('list', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Notification.Notification>>;
                        }

                        public Detail(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.Notification> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.Notification>;
                        }

                        public Update(id: number, notification: Honeycomb.Tenant.Tasker.IService.Model.Notification.Notification): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('notification', RequestParamType.BODY, notification)];
                            const requestParams = new RequestParams('update/{id}', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public UserSettings(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationUserSetting>> {
                            const args = [];
                            const requestParams = new RequestParams('userSettings', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationUserSetting>>;
                        }

                        public SetUserSettings(userNotifications: Array<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationUserSetting>): Observable<any> {
                            const args = [new RequestParam('userNotifications', RequestParamType.BODY, userNotifications)];
                            const requestParams = new RequestParams('userSettings', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public QueueStatus(): Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationQueueLength> {
                            const args = [];
                            const requestParams = new RequestParams('queueStatus', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationQueueLength>;
                        }

                        public SendBell(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('SendBell', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public SendPush(): Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationQueueLength> {
                            const args = [];
                            const requestParams = new RequestParams('SendPush', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.NotificationQueueLength>;
                        }

                        public PushPublicKey(): Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.PushPubKey> {
                            const args = [];
                            const requestParams = new RequestParams('PushPublicKey', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Notification.PushPubKey>;
                        }
                    }

                    @Injectable()
                    export class OperationController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/operation`;

                        public List(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> {
                            const args = [];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>>;
                        }

                        public Detail(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Operation> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Operation>;
                        }

                        public ActiveList(taskType: Honeycomb.Common.Enums.TaskType): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> {
                            const args = [new RequestParam('taskType', RequestParamType.QUERY, taskType)];
                            const requestParams = new RequestParams('active', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>>;
                        }

                        public ValidForJob(lang: string, id: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> {
                            const args = [new RequestParam('lang', RequestParamType.QUERY, lang), new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('validForJob/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>>;
                        }

                        public InactiveList(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>> {
                            const args = [];
                            const requestParams = new RequestParams('inactive', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Operation>>;
                        }

                        public Insert(model: Honeycomb.Tenant.Tasker.IService.Model.Operation): Observable<number> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Update(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.Operation): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Deactivate(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Import(files: Array<any>): Observable<any> {
                            const args = [new RequestParam('files', RequestParamType.BODY, files)];
                            const requestParams = new RequestParams('import', HttpMethod.POST, args);
                            return this.apiCaller.uploadFile(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Export(selectedIds: Array<number>): Observable<string> {
                            const args = [new RequestParam('selectedIds', RequestParamType.BODY, selectedIds)];
                            const requestParams = new RequestParams('export', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                        }

                        public DownloadExported(token: string): Observable<any> {
                            const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                            const requestParams = new RequestParams('downloadExported/{token}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public OperationStatuses(operationID: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>> {
                            const args = [new RequestParam('operationID', RequestParamType.ROUTE, operationID)];
                            const requestParams = new RequestParams('OperationStatuses/{operationID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>>;
                        }
                    }

                    @Injectable()
                    export class SequenceController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/sequence`;

                        public GetPrescriptions(taskSequence: Honeycomb.Tenant.Tasker.IService.Model.TaskSequence): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPrescription>> {
                            const args = [new RequestParam('taskSequence', RequestParamType.BODY, taskSequence)];
                            const requestParams = new RequestParams('GetPrescriptions', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPrescription>>;
                        }

                        public Insert(taskSequence: Honeycomb.Tenant.Tasker.IService.Model.TaskSequence): Observable<number> {
                            const args = [new RequestParam('taskSequence', RequestParamType.BODY, taskSequence)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Update(id: number, taskSequence: Honeycomb.Tenant.Tasker.IService.Model.TaskSequence): Observable<number> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('taskSequence', RequestParamType.BODY, taskSequence)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Get(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskSequence> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskSequence>;
                        }

                        public GetPrescriptionsByID(id: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPrescription>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('GetPrescriptionsByID/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPrescription>>;
                        }
                    }

                    @Injectable()
                    export class StatusController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/status`;

                        public List(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>> {
                            const args = [];
                            const requestParams = new RequestParams('List', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>>;
                        }
                    }

                    @Injectable()
                    export class TaskActivityController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/taskactivity`;

                        public Statuses(): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>> {
                            const args = [];
                            const requestParams = new RequestParams('statuses', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>>;
                        }

                        public UpdateValue(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.TaskValue): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}/value', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Status>>;
                        }

                        public UpsertValue(taskValue: Honeycomb.Tenant.Tasker.IService.Model.TaskValueUpsert): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskValue> {
                            const args = [new RequestParam('taskValue', RequestParamType.BODY, taskValue)];
                            const requestParams = new RequestParams('UpsertValue', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskValue>;
                        }

                        public UpsertSnapshotValue(taskValue: Honeycomb.Tenant.Tasker.IService.Model.TaskValueSnapshotUpsert): Observable<any> {
                            const args = [new RequestParam('taskValue', RequestParamType.BODY, taskValue)];
                            const requestParams = new RequestParams('UpsertSnapshotValue', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public UpsertNote(taskNote: Honeycomb.Tenant.Tasker.IService.Model.TaskValueNoteUpsert): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskValue> {
                            const args = [new RequestParam('taskNote', RequestParamType.BODY, taskNote)];
                            const requestParams = new RequestParams('UpsertNote', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskValue>;
                        }

                        public UpsertSnapshotNote(taskNote: Honeycomb.Tenant.Tasker.IService.Model.TaskValueNoteSnapshotUpsert): Observable<Honeycomb.Tenant.Tasker.IService.Model.InputSnapshot> {
                            const args = [new RequestParam('taskNote', RequestParamType.BODY, taskNote)];
                            const requestParams = new RequestParams('UpsertSnapshotNote', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.InputSnapshot>;
                        }

                        public ValuePhotoInsert(attachmentInsert: Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhotoInsert): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhoto> {
                            const args = [new RequestParam('attachmentInsert', RequestParamType.BODY, attachmentInsert)];
                            const requestParams = new RequestParams('ValuePhotoInsert', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhoto>;
                        }

                        public ValuePhotoInsertSnapshot(attachmentInsert: Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhotoSnapshotInsert): Observable<Honeycomb.Tenant.Tasker.IService.Model.InputValuePhotoSnapshot> {
                            const args = [new RequestParam('attachmentInsert', RequestParamType.BODY, attachmentInsert)];
                            const requestParams = new RequestParams('ValuePhotoInsertSnapshot', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.InputValuePhotoSnapshot>;
                        }

                        public ValuePhotoRemove(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('ValuePhotoRemove/{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public ValuePhotoSnapshotRemove(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('ValuePhotoSnapshotRemove/{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public TaskActivityStatus(taskActivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivityStatusUpsert): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskStatusResult> {
                            const args = [new RequestParam('taskActivity', RequestParamType.BODY, taskActivity)];
                            const requestParams = new RequestParams('TaskActivityStatus', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskStatusResult>;
                        }

                        public TaskActivitySnapshotStatus(taskActivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivitySnapshotStatusUpsert): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskStatusResult> {
                            const args = [new RequestParam('taskActivity', RequestParamType.BODY, taskActivity)];
                            const requestParams = new RequestParams('TaskActivitySnapshotStatus', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskStatusResult>;
                        }
                    }

                    @Injectable()
                    export class TaskController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/task`;

                        public Detail(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Task> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Task>;
                        }

                        public TasksAssignedToMe(filter: Honeycomb.Tenant.Tasker.IService.TaskFilter): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> {
                            const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                            const requestParams = new RequestParams('assigned', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>>;
                        }

                        public TasksCreatedByMe(filter: Honeycomb.Tenant.Tasker.IService.TaskFilter): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> {
                            const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                            const requestParams = new RequestParams('created', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>>;
                        }

                        public Insert(model: Honeycomb.Tenant.Tasker.IService.Model.TaskUpsert): Observable<number> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public HasAccess(srcJobID: number, taskID: number): Observable<any> {
                            const args = [new RequestParam('srcJobID', RequestParamType.ROUTE, srcJobID), new RequestParam('taskID', RequestParamType.ROUTE, taskID)];
                            const requestParams = new RequestParams('hasAccess/{srcJobID}/{taskID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Update(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.Task): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CreateFollowUp(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.Task> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('CreateFollowUp/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.Task>;
                        }

                        public JobChange(request: Honeycomb.Tenant.Tasker.IService.UserJobChangeRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('jobchange', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public updateTask(id: number, model: Honeycomb.Tenant.Tasker.IService.Model.TaskUpsert): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('updateTask/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Page(taskPage: Honeycomb.Tenant.Tasker.IService.Model.TaskPage): Observable<{ pageIndex: number, pageSize: number, total: number, results: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> }> {
                            const args = [new RequestParam('taskPage', RequestParamType.BODY, taskPage)];
                            const requestParams = new RequestParams('page', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ pageIndex: number, pageSize: number, total: number, results: Array<Honeycomb.Tenant.Tasker.IService.Model.Task> }>;
                        }

                        public List(filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> {
                            const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                            const requestParams = new RequestParams('list', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>>;
                        }

                        public Dashboard(dashboardRequest: Honeycomb.Tenant.Tasker.IService.Model.DashboardRequest): Observable<{ [key: string]: string }> {
                            const args = [new RequestParam('dashboardRequest', RequestParamType.BODY, dashboardRequest)];
                            const requestParams = new RequestParams('dashboard', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                        }

                        public CreateBatch(model: Honeycomb.Tenant.Tasker.IService.Model.TaskBatchRequest): Observable<any> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('batch', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CreateSequence(model: Honeycomb.Tenant.Tasker.IService.Model.TaskSequenceRequest): Observable<any> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('Sequence', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public LocationList(filter: Honeycomb.Tenant.Tasker.IService.TaskFilter): Observable<{ [key: number]: any }> {
                            const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                            const requestParams = new RequestParams('locationlist', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: any }>;
                        }

                        public GetAdditionalLocationInfo(): Observable<Honeycomb.Tenant.Tasker.IService.Model.AdditionalLocationInfo> {
                            const args = [];
                            const requestParams = new RequestParams('additionalLocationInfo', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.AdditionalLocationInfo>;
                        }

                        public DeleteSequence(dateStart: Date, dateEnd: Date, id: number): Observable<Array<number>> {
                            const args = [new RequestParam('dateStart', RequestParamType.QUERY, dateStart), new RequestParam('dateEnd', RequestParamType.QUERY, dateEnd), new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('DeleteSequence/{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                        }

                        public UpdateStatuses(request: Honeycomb.Tenant.Tasker.IService.Model.TaskStatusUpdateRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('updateStatuses', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public GetOperationStatuses(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('getOperationStatuses', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public RunCustomSP(model: Honeycomb.Tenant.Tasker.IService.Model.CustomSpModel): Observable<any> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('RunCustomSP', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class TaskPlanController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/TaskPlan`;

                        public GenerateTaskPlan(startDate: Date, days: number, generateXlsx: boolean): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskPlanResult> {
                            const args = [new RequestParam('startDate', RequestParamType.QUERY, startDate), new RequestParam('days', RequestParamType.QUERY, days), new RequestParam('generateXlsx', RequestParamType.QUERY, generateXlsx)];
                            const requestParams = new RequestParams('GenerateTaskPlan', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskPlanResult>;
                        }

                        public ConfirmTaskPlan(taskPlan: Honeycomb.Tenant.Tasker.IService.TaskPlanConfirmation): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>> {
                            const args = [new RequestParam('taskPlan', RequestParamType.BODY, taskPlan)];
                            const requestParams = new RequestParams('ConfirmTaskPlan', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>>;
                        }

                        public List(startDate: Date, endDate: Date): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>> {
                            const args = [new RequestParam('startDate', RequestParamType.QUERY, startDate), new RequestParam('endDate', RequestParamType.QUERY, endDate)];
                            const requestParams = new RequestParams('List', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>>;
                        }

                        public LocationExceptions(locationID: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlanSourceTable>> {
                            const args = [new RequestParam('locationID', RequestParamType.ROUTE, locationID)];
                            const requestParams = new RequestParams('LocationExceptions/{locationID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlanSourceTable>>;
                        }

                        public AddPlans(taskPlans: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>> {
                            const args = [new RequestParam('taskPlans', RequestParamType.BODY, taskPlans)];
                            const requestParams = new RequestParams('AddPlans', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>>;
                        }

                        public UpdatePlan(id: number, taskPlan: Honeycomb.Tenant.Tasker.IService.Model.TaskPlan): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('taskPlan', RequestParamType.BODY, taskPlan)];
                            const requestParams = new RequestParams('UpdatePlan/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>;
                        }
                    }

                    @Injectable()
                    export class TaskStatusController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/TaskStatus`;

                        public List(operationID: number, jobID: number): Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>> {
                            const args = [new RequestParam('operationID', RequestParamType.QUERY, operationID), new RequestParam('jobID', RequestParamType.QUERY, jobID)];
                            const requestParams = new RequestParams('List', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>>;
                        }

                        public Insert(taskStatus: Honeycomb.Tenant.Tasker.IService.Model.TaskStatus): Observable<any> {
                            const args = [new RequestParam('taskStatus', RequestParamType.BODY, taskStatus)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Update(id: number, taskStatus: Honeycomb.Tenant.Tasker.IService.Model.TaskStatus): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('taskStatus', RequestParamType.BODY, taskStatus)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Detail(id: number): Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Tasker.IService.Model.TaskStatus>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {
                export namespace Controller {


                    @Injectable()
                    export class ApiController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantTasker/`;

                        public Ping(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CacheClear(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Version(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {


                @Injectable()
                export class CustomFunctionsController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/customFunctions`;

                    public Run(request: Honeycomb.Tenant.Reports.IService.Model.CustomFunctionRunRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.CustomFunctionRunResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('Run', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.CustomFunctionRunResult>;
                    }
                }

                @Injectable()
                export class PBIController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/PBI`;

                    public GetToken(): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.AAD> {
                        const args = [];
                        const requestParams = new RequestParams('token', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.AAD>;
                    }

                    public SetPBIConfig(pbiConfig: Honeycomb.Tenant.Reports.IService.Model.PBI.PBIConfig): Observable<any> {
                        const args = [new RequestParam('pbiConfig', RequestParamType.BODY, pbiConfig)];
                        const requestParams = new RequestParams('PBIConfig', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetWorkspaces(forTenant: boolean): Observable<Array<any>> {
                        const args = [new RequestParam('forTenant', RequestParamType.QUERY, forTenant)];
                        const requestParams = new RequestParams('workspaces', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public GetDatasets(forTenant: boolean): Observable<Array<any>> {
                        const args = [new RequestParam('forTenant', RequestParamType.QUERY, forTenant)];
                        const requestParams = new RequestParams('datasets', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public GetDatasources(): Observable<Array<any>> {
                        const args = [];
                        const requestParams = new RequestParams('datasources', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public GetReports(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>> {
                        const args = [];
                        const requestParams = new RequestParams('reports', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>>;
                    }

                    public GetReportsForRoles(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>> {
                        const args = [];
                        const requestParams = new RequestParams('reportsForRoles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>>;
                    }

                    public GetReport(reportName: string): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport> {
                        const args = [new RequestParam('reportName', RequestParamType.ROUTE, reportName)];
                        const requestParams = new RequestParams('report/{reportName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>;
                    }

                    public GetGateways(): Observable<Array<any>> {
                        const args = [];
                        const requestParams = new RequestParams('gateways', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public EnsureWorkspace(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('ensureWorkspace', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateReport(rep: Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport): Observable<any> {
                        const args = [new RequestParam('rep', RequestParamType.BODY, rep)];
                        const requestParams = new RequestParams('updateReport', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateReport(request: Honeycomb.Tenant.Reports.IService.Model.PBI.ReportCreateRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('createReport', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>;
                    }

                    public CloneReport(request: Honeycomb.Tenant.Reports.IService.Model.PBI.ReportCloneRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('cloneReport', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>;
                    }

                    public ImportReportFile(files: Array<any>): Observable<string> {
                        const args = [new RequestParam('files', RequestParamType.BODY, files)];
                        const requestParams = new RequestParams('ImportReportFile', HttpMethod.POST, args);
                        return this.apiCaller.uploadFile(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public ImportReport(createRequest: Honeycomb.Tenant.Reports.IService.Model.PBI.ReportCreateRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport> {
                        const args = [new RequestParam('createRequest', RequestParamType.BODY, createRequest)];
                        const requestParams = new RequestParams('importReport', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport>;
                    }

                    public ExportReport(reportId: string): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIExportResult> {
                        const args = [new RequestParam('reportId', RequestParamType.ROUTE, reportId)];
                        const requestParams = new RequestParams('exportReport/{reportId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIExportResult>;
                    }

                    public DownloadExported(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('downloadExported/{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateReportDataset(request: Honeycomb.Tenant.Reports.IService.Model.PBI.ReportUpdateDatasetRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('updateReportDataset', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public EnsureTenantDatasource(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('ensureTenantDatasource', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteTenantDatasource(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('deleteTenantDatasource', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteReport(pbiReportID: number): Observable<any> {
                        const args = [new RequestParam('pbiReportID', RequestParamType.ROUTE, pbiReportID)];
                        const requestParams = new RequestParams('deleteReport/{pbiReportID}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class PBILibraryController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/PBILibrary`;

                    public GetReportTemplates(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReportTemplate>> {
                        const args = [];
                        const requestParams = new RequestParams('reportTemplates', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReportTemplate>>;
                    }

                    public GetReportTemplate(name: string): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReportTemplate> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('reportTemplate/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReportTemplate>;
                    }

                    public ImportReportTemplate(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('importReportTemplate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public PostReportTemplate(template: Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReportTemplate): Observable<any> {
                        const args = [new RequestParam('template', RequestParamType.BODY, template)];
                        const requestParams = new RequestParams('postReportTemplate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateDescription(template: Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReportTemplate): Observable<any> {
                        const args = [new RequestParam('template', RequestParamType.BODY, template)];
                        const requestParams = new RequestParams('updateDescription', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Export(request: Honeycomb.Tenant.Reports.IService.Model.PBI.PBITemplateExportRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIExportResult>;
                    }

                    public DownloadExported(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('downloadExported/{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class QueryableController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/queryable`;

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Queryable>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Queryable>>;
                    }

                    public ListForCodeLists(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Queryable>> {
                        const args = [];
                        const requestParams = new RequestParams('forCodeLists', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Queryable>>;
                    }

                    public GetByID(id: number): Observable<Honeycomb.Tenant.Reports.IService.Model.Queryable> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Queryable>;
                    }

                    public GetByName(name: string): Observable<Honeycomb.Tenant.Reports.IService.Model.Queryable> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('byName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Queryable>;
                    }

                    public ImportUpsert(type: string, actiontype: Honeycomb.Common.Enums.UpsertAction, content: any): Observable<any> {
                        const args = [new RequestParam('type', RequestParamType.ROUTE, type), new RequestParam('actiontype', RequestParamType.ROUTE, actiontype), new RequestParam('content', RequestParamType.BODY, content)];
                        const requestParams = new RequestParams('importUpsert/{type}/{actiontype?}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Upsert(q: Honeycomb.Tenant.Reports.IService.Model.Queryable): Observable<any> {
                        const args = [new RequestParam('q', RequestParamType.BODY, q)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ExecuteForCodeList(id: number): Observable<{}> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('executeForCodeList/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{}>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class RawSalesContainerController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/RawSalesContainer`;

                    public RegisterSale(header: Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawHeader): Observable<any> {
                        const args = [new RequestParam('header', RequestParamType.BODY, header)];
                        const requestParams = new RequestParams('RegisterSale', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RegisterSales(header: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawHeader>): Observable<any> {
                        const args = [new RequestParam('header', RequestParamType.BODY, header)];
                        const requestParams = new RequestParams('RegisterSales', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ProcessErrorTransactions(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('ProcessErrorTransactions', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ProcessSaleContainer(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('ProcessSaleContainer', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateTransactionRelations(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CreateTransactionRelations', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class RelatedProductSuggesterController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/RelatedProductSuggester`;

                    public RelatedProducts(topCount: number, productID: number): Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult> {
                        const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('productID', RequestParamType.ROUTE, productID)];
                        const requestParams = new RequestParams('RelatedProducts/{productID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult>;
                    }

                    public RelatedProductsByNumber(topCount: number, productNumber: string): Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult> {
                        const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('productNumber', RequestParamType.ROUTE, productNumber)];
                        const requestParams = new RequestParams('RelatedProductsByNumber/{productNumber}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult>;
                    }

                    public OtherUsersViewedProducts(topCount: number, sessionID: number, userNumber: string, userID: number, productNumber: string): Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult> {
                        const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('sessionID', RequestParamType.QUERY, sessionID), new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('productNumber', RequestParamType.ROUTE, productNumber)];
                        const requestParams = new RequestParams('OtherUsersViewedProducts/{productNumber}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult>;
                    }

                    public RegisterUserInterest(userInterest: Honeycomb.Common.ViewModels.RegisterUserInterestRequest): Observable<any> {
                        const args = [new RequestParam('userInterest', RequestParamType.BODY, userInterest)];
                        const requestParams = new RequestParams('RegisterUserInterest', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UserInterestProducts(sessionID: number, userNumber: string, userID: number, topCount: number): Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult> {
                        const args = [new RequestParam('sessionID', RequestParamType.QUERY, sessionID), new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('topCount', RequestParamType.QUERY, topCount)];
                        const requestParams = new RequestParams('UserInterestProducts', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult>;
                    }

                    public UserSuggestedProducts(sessionID: number, userNumber: string, userID: number, topCount: number): Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult> {
                        const args = [new RequestParam('sessionID', RequestParamType.QUERY, sessionID), new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('topCount', RequestParamType.QUERY, topCount)];
                        const requestParams = new RequestParams('UserSuggestedProducts', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.RelatedProductResult>;
                    }
                }

                @Injectable()
                export class ReportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/Report`;

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>>;
                    }

                    public ListAllSimple(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>> {
                        const args = [];
                        const requestParams = new RequestParams('simpleList', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>>;
                    }

                    public ListAllWithSaved(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>> {
                        const args = [];
                        const requestParams = new RequestParams('listWithSaved', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>>;
                    }

                    public ListAllWithSavedForRoles(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>> {
                        const args = [];
                        const requestParams = new RequestParams('listWithSavedForRoles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>>;
                    }

                    public ListCustomFunctionsReport(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>> {
                        const args = [];
                        const requestParams = new RequestParams('listCustomFunctionsReport', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>>;
                    }

                    public ListForRoles(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>> {
                        const args = [];
                        const requestParams = new RequestParams('forRoles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Report>>;
                    }

                    public GetByID(id: number): Observable<Honeycomb.Tenant.Reports.IService.Model.Report> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Report>;
                    }

                    public GetByName(name: string): Observable<Honeycomb.Tenant.Reports.IService.Model.Report> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('byName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Report>;
                    }

                    public Upsert(r: Honeycomb.Tenant.Reports.IService.Model.Report): Observable<number> {
                        const args = [new RequestParam('r', RequestParamType.BODY, r)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Run(context: Honeycomb.Tenant.Reports.IService.Model.ReportContext): Observable<Honeycomb.Tenant.Reports.IService.Model.ReportResult> {
                        const args = [new RequestParam('context', RequestParamType.BODY, context)];
                        const requestParams = new RequestParams('run', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.ReportResult>;
                    }

                    public RunObjectResult(context: Honeycomb.Tenant.Reports.IService.Model.ReportContext): Observable<Honeycomb.Tenant.Reports.IService.Model.ReportObjectResult> {
                        const args = [new RequestParam('context', RequestParamType.BODY, context)];
                        const requestParams = new RequestParams('runObjectResult', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.ReportObjectResult>;
                    }

                    public RunTable(context: Honeycomb.Tenant.Reports.IService.Model.ReportTableRunRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.RunTableReportResult> {
                        const args = [new RequestParam('context', RequestParamType.BODY, context)];
                        const requestParams = new RequestParams('runTable', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.RunTableReportResult>;
                    }

                    public ImportData(langIsoCode: string, reportID: number, files: Array<any>): Observable<Honeycomb.Tenant.Reports.IService.Model.DataImportResult> {
                        const args = [new RequestParam('langIsoCode', RequestParamType.QUERY, langIsoCode), new RequestParam('reportID', RequestParamType.ROUTE, reportID), new RequestParam('files', RequestParamType.BODY, files)];
                        const requestParams = new RequestParams('importData/{reportID}', HttpMethod.POST, args);
                        return this.apiCaller.uploadFile(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.DataImportResult>;
                    }

                    public RunUpdateQuery(request: Honeycomb.Tenant.Reports.IService.Model.RunUpdateQueryRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('runUpdateQuery', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateBulkTable(filter: Honeycomb.Common.Elastic.ElasticFilterRequest): Observable<Honeycomb.Common.ViewModels.BulkInsertTableResult> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('createBulkTable', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ViewModels.BulkInsertTableResult>;
                    }

                    public DropBulkTable(name: string): Observable<any> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('dropBulkTable/{name}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Import(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ImportUpsert(type: string, actiontype: Honeycomb.Common.Enums.UpsertAction, content: any): Observable<any> {
                        const args = [new RequestParam('type', RequestParamType.ROUTE, type), new RequestParam('actiontype', RequestParamType.ROUTE, actiontype), new RequestParam('content', RequestParamType.BODY, content)];
                        const requestParams = new RequestParams('importUpsert/{type}/{actiontype?}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Export(request: Honeycomb.Tenant.Reports.IService.Model.ReportExportRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.ReportExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.ReportExportResult>;
                    }

                    public DownloadExported(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('downloadExported/{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SaveReport(request: Honeycomb.Tenant.Reports.IService.Model.SavedReport): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('saveReport', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteSavedReport(request: Honeycomb.Tenant.Reports.IService.Model.SavedReport): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('deleteSavedReport', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Print(context: Honeycomb.Tenant.Reports.IService.Model.ReportContext): Observable<Honeycomb.Tenant.Reports.IService.Model.ReportExportResult> {
                        const args = [new RequestParam('context', RequestParamType.BODY, context)];
                        const requestParams = new RequestParams('print', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.ReportExportResult>;
                    }

                    public DownloadPrinted(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('downloadPrinted/{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DownloadPrintedPreview(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('downloadPrintedPreview/{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetAllDisplayRulesSetting(): Observable<{ [key: string]: string }> {
                        const args = [];
                        const requestParams = new RequestParams('getAllDisplayRulesSetting', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                    }
                }

                @Injectable()
                export class SegmentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/segment`;

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment>>;
                    }

                    public ListAllSimple(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment>> {
                        const args = [];
                        const requestParams = new RequestParams('listAllSimple', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment>>;
                    }

                    public GetByID(id: number): Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment>;
                    }

                    public GetByName(name: string): Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('byName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.Segment>;
                    }

                    public GetHistory(id: number): Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.SegmentHistory> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('getHistory/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.SegmentHistory>;
                    }

                    public ImportUpsert(type: string, actiontype: Honeycomb.Common.Enums.UpsertAction, content: any): Observable<any> {
                        const args = [new RequestParam('type', RequestParamType.ROUTE, type), new RequestParam('actiontype', RequestParamType.ROUTE, actiontype), new RequestParam('content', RequestParamType.BODY, content)];
                        const requestParams = new RequestParams('importUpsert/{type}/{actiontype?}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Upsert(s: Honeycomb.Tenant.Reports.IService.Model.Segments.Segment): Observable<any> {
                        const args = [new RequestParam('s', RequestParamType.BODY, s)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RunSegment(request: Honeycomb.Tenant.Reports.IService.Model.Segments.RunSegmentRequest): Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.RunSegmentResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('RunSegment', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.Model.Segments.RunSegmentResult>;
                    }

                    public DeleteSession(sessionUID: string): Observable<any> {
                        const args = [new RequestParam('sessionUID', RequestParamType.ROUTE, sessionUID)];
                        const requestParams = new RequestParams('deleteSession/{sessionUID}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteUnusedSessionTables(olderThenHours: number): Observable<any> {
                        const args = [new RequestParam('olderThenHours', RequestParamType.ROUTE, olderThenHours)];
                        const requestParams = new RequestParams('deleteSession/{olderThenHours}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetFilterQueryables(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Queryable>> {
                        const args = [];
                        const requestParams = new RequestParams('FilterQueryables', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Queryable>>;
                    }
                }

                @Injectable()
                export class StatsController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/stats`;

                    public ChangeTransactionsUser(fromUserID: number, toUserID: number): Observable<any> {
                        const args = [new RequestParam('fromUserID', RequestParamType.QUERY, fromUserID), new RequestParam('toUserID', RequestParamType.QUERY, toUserID)];
                        const requestParams = new RequestParams('ChangeTransactionsUser', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetTransactionByUID(includeContacts: boolean, uid: string): Observable<any> {
                        const args = [new RequestParam('includeContacts', RequestParamType.QUERY, includeContacts), new RequestParam('uid', RequestParamType.ROUTE, uid)];
                        const requestParams = new RequestParams('TransactionByUID/{uid}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetHeader(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetHeader/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserTransactions(sortDirection: string, sortBy: string, type: string, fromIndex: number, topCount: number, includeContacts: boolean, includeRewards: boolean, dateFrom: string, dateTo: string, userID: number): Observable<Honeycomb.Tenant.Reports.IService.StatTransactionsListResponse> {
                        const args = [new RequestParam('sortDirection', RequestParamType.QUERY, sortDirection), new RequestParam('sortBy', RequestParamType.QUERY, sortBy), new RequestParam('type', RequestParamType.QUERY, type), new RequestParam('fromIndex', RequestParamType.QUERY, fromIndex), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('includeContacts', RequestParamType.QUERY, includeContacts), new RequestParam('includeRewards', RequestParamType.QUERY, includeRewards), new RequestParam('dateFrom', RequestParamType.QUERY, dateFrom), new RequestParam('dateTo', RequestParamType.QUERY, dateTo), new RequestParam('userID', RequestParamType.ROUTE, userID)];
                        const requestParams = new RequestParams('GetUserTransactions/{userID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.StatTransactionsListResponse>;
                    }

                    public GetUserHistoryTransactions(userID: number): Observable<Honeycomb.Tenant.Reports.IService.StatTransactionsListResponse> {
                        const args = [new RequestParam('userID', RequestParamType.ROUTE, userID)];
                        const requestParams = new RequestParams('GetUserHistoryTransactions/{userID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.StatTransactionsListResponse>;
                    }

                    public GetByTimestamp(userID: number, sortDirection: string, sortBy: string, type: string, topCount: number, timestamp: number): Observable<Honeycomb.Tenant.Reports.IService.StatTransactionsListResponse> {
                        const args = [new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('sortDirection', RequestParamType.QUERY, sortDirection), new RequestParam('sortBy', RequestParamType.QUERY, sortBy), new RequestParam('type', RequestParamType.QUERY, type), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                        const requestParams = new RequestParams('GetByTimestamp/{timestamp}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Reports.IService.StatTransactionsListResponse>;
                    }

                    public StatPromotionTypesList(): Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatPromotionType>> {
                        const args = [];
                        const requestParams = new RequestParams('StatPromotionTypeList', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatPromotionType>>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantReports/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Controllers {


                    @Injectable()
                    export class ContainerController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantPublicAPI/Container`;

                        public ImportCategories(request: Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportCategoriesRequest): Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('ImportCategories', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult>;
                        }

                        public ImportProducts(request: Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportProductsRequest): Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('ImportProducts', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult>;
                        }

                        public ImportStock(request: Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportStockRequest): Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('ImportStock', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult>;
                        }

                        public ImportUsers(request: Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportUsersRequest): Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('ImportUsers', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult>;
                        }

                        public ImportCustomers(request: Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportCustomersRequest): Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('ImportCustomers', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult>;
                        }

                        public ImportLocations(request: Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportLocationsRequest): Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('ImportLocations', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResult>;
                        }

                        public GetProductsTimeStamp(): Observable<number> {
                            const args = [];
                            const requestParams = new RequestParams('GetProductsTimeStamp', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public GetCategoriesTimeStamp(): Observable<number> {
                            const args = [];
                            const requestParams = new RequestParams('GetCategoriesTimeStamp', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public GetUsersTimeStamp(): Observable<number> {
                            const args = [];
                            const requestParams = new RequestParams('GetUsersTimeStamp', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public GetStockTimeStamp(): Observable<number> {
                            const args = [];
                            const requestParams = new RequestParams('GetStockTimeStamp', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public ElasticSyncProducts(timestamp: number): Observable<any> {
                            const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                            const requestParams = new RequestParams('ElasticSyncProducts/{timestamp}', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public ElasticSyncCategories(timestamp: number): Observable<any> {
                            const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                            const requestParams = new RequestParams('ElasticSyncCategories/{timestamp}', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public ElasticSyncUsers(timestamp: number): Observable<any> {
                            const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                            const requestParams = new RequestParams('ElasticSyncUsers/{timestamp}', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public ElasticSyncStock(timestamp: number): Observable<any> {
                            const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                            const requestParams = new RequestParams('ElasticSyncStock/{timestamp}', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Controllers {
                    export namespace v1 {


                        @Injectable()
                        export class PAContainerController {
                            constructor(private apiCaller: ApiCaller) { }
                            public controllerRoute: string = `api/TenantPublicAPI/v1/PAContainer`;

                            public ImportCategories(request: any): Observable<any> {
                                const args = [new RequestParam('request', RequestParamType.BODY, request)];
                                const requestParams = new RequestParams('ImportCategories', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public ImportProducts(request: any): Observable<any> {
                                const args = [new RequestParam('request', RequestParamType.BODY, request)];
                                const requestParams = new RequestParams('ImportProducts', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public ImportStock(request: any): Observable<any> {
                                const args = [new RequestParam('request', RequestParamType.BODY, request)];
                                const requestParams = new RequestParams('ImportStock', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public ImportLocations(request: any): Observable<any> {
                                const args = [new RequestParam('request', RequestParamType.BODY, request)];
                                const requestParams = new RequestParams('ImportLocations', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }
                        }

                        @Injectable()
                        export class PADatasourceController {
                            constructor(private apiCaller: ApiCaller) { }
                            public controllerRoute: string = `api/TenantPublicAPI/v1/PADatasource`;

                            public GetDataBA(format: string, rawContentType: string, bauser: string, bapassword: string, dataSource: string): Observable<any> {
                                const args = [new RequestParam('format', RequestParamType.QUERY, format), new RequestParam('rawContentType', RequestParamType.QUERY, rawContentType), new RequestParam('bauser', RequestParamType.QUERY, bauser), new RequestParam('bapassword', RequestParamType.QUERY, bapassword), new RequestParam('dataSource', RequestParamType.ROUTE, dataSource)];
                                const requestParams = new RequestParams('dataBA/{dataSource}', HttpMethod.GET, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public GetDataAN(format: string, rawContentType: string, dataSource: string): Observable<any> {
                                const args = [new RequestParam('format', RequestParamType.QUERY, format), new RequestParam('rawContentType', RequestParamType.QUERY, rawContentType), new RequestParam('dataSource', RequestParamType.ROUTE, dataSource)];
                                const requestParams = new RequestParams('dataAN/{dataSource}', HttpMethod.GET, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public GetData(format: string, rawContentType: string, dataSource: string): Observable<any> {
                                const args = [new RequestParam('format', RequestParamType.QUERY, format), new RequestParam('rawContentType', RequestParamType.QUERY, rawContentType), new RequestParam('dataSource', RequestParamType.ROUTE, dataSource)];
                                const requestParams = new RequestParams('data/{dataSource}', HttpMethod.GET, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }
                        }

                        @Injectable()
                        export class PAIntegrationController {
                            constructor(private apiCaller: ApiCaller) { }
                            public controllerRoute: string = `api/TenantPublicAPI/v1/PAIntegration`;

                            public MailkitUpdateOptionFromList(request: Honeycomb.Tenant.PublicAPI.IService.Controllers.v1.MailkitUpdateOptionRequest): Observable<any> {
                                const args = [new RequestParam('request', RequestParamType.BODY, request)];
                                const requestParams = new RequestParams('MailkitUpdateOptionFromList', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public MailkitUpdateUnsuscribeList(request: Honeycomb.Tenant.PublicAPI.IService.Controllers.v1.MailkitUpdateUnsuscribedList): Observable<any> {
                                const args = [new RequestParam('request', RequestParamType.BODY, request)];
                                const requestParams = new RequestParams('MailkitUpdateUnsuscribeList', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public CallSambaUnsubscribeApi(inputs: Honeycomb.Tenant.PublicAPI.IService.Controllers.v1.SambaUnsubscribeApi): Observable<any> {
                                const args = [new RequestParam('inputs', RequestParamType.BODY, inputs)];
                                const requestParams = new RequestParams('CallSambaUnsubscribeApi', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }
                        }

                        @Injectable()
                        export class PAMediaController {
                            constructor(private apiCaller: ApiCaller) { }
                            public controllerRoute: string = `api/TenantPublicAPI/v1/PAMedia`;

                            public Distribute(request: any): Observable<any> {
                                const args = [new RequestParam('request', RequestParamType.BODY, request)];
                                const requestParams = new RequestParams('Distribute', HttpMethod.POST, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public GetDistributionResults(modifiedSince: Date, topCount: number, mediaNameMask: string, recipientID: string): Observable<any> {
                                const args = [new RequestParam('modifiedSince', RequestParamType.QUERY, modifiedSince), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('mediaNameMask', RequestParamType.QUERY, mediaNameMask), new RequestParam('recipientID', RequestParamType.QUERY, recipientID)];
                                const requestParams = new RequestParams('DistributionResults', HttpMethod.GET, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }

                            public PDFGetDistributionResults(modifiedSince: Date, topCount: number, mediaNameMask: string, recipientID: string): Observable<any> {
                                const args = [new RequestParam('modifiedSince', RequestParamType.QUERY, modifiedSince), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('mediaNameMask', RequestParamType.QUERY, mediaNameMask), new RequestParam('recipientID', RequestParamType.QUERY, recipientID)];
                                const requestParams = new RequestParams('PDFDistributionResults', HttpMethod.GET, args);
                                return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                            }
                        }
                    }
                }
            }
        }
    }
}

export namespace PublicAPI {
    export namespace Controllers {
        export namespace v1 {


            @Injectable()
            export class PAAuthController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantPublicAPI/v1/PAAuth`;

                public Login(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PAProductsController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantPublicAPI/v1/PAProducts`;

                public ProductCategories(): Observable<Array<any>> {
                    const args = [];
                    const requestParams = new RequestParams('ProductCategories', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }
            }

            @Injectable()
            export class PARelatedProductsController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantPublicAPI/v1/paRelatedProducts`;

                public RelatedProducts(topCount: number, productID: number): Observable<any> {
                    const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('productID', RequestParamType.ROUTE, productID)];
                    const requestParams = new RequestParams('RelatedProducts/{productID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RelatedProductsArray(productIDs: Array<number>, topCount: number): Observable<any> {
                    const args = [new RequestParam('productIDs', RequestParamType.QUERY, productIDs), new RequestParam('topCount', RequestParamType.QUERY, topCount)];
                    const requestParams = new RequestParams('RelatedProductsArray', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RelatedProductsByNumber(topCount: number, productNumber: string): Observable<any> {
                    const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('productNumber', RequestParamType.ROUTE, productNumber)];
                    const requestParams = new RequestParams('RelatedProductsByNumber/{productNumber}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RelatedProductsByNumberArray(topCount: number, productNumbers: Array<string>): Observable<any> {
                    const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('productNumbers', RequestParamType.ROUTE, productNumbers)];
                    const requestParams = new RequestParams('RelatedProductsByNumberArray', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public OtherUsersViewedProducts(topCount: number, sessionID: string, userNumber: string, userID: number, productNumbers: Array<string>): Observable<any> {
                    const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('sessionID', RequestParamType.QUERY, sessionID), new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('productNumbers', RequestParamType.ROUTE, productNumbers)];
                    const requestParams = new RequestParams('OtherUsersViewedProducts', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RegisterUserInterest(userInterest: any): Observable<any> {
                    const args = [new RequestParam('userInterest', RequestParamType.BODY, userInterest)];
                    const requestParams = new RequestParams('RegisterUserInterest', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UserInterestProducts(sessionID: string, userNumber: string, userID: number, topCount: number): Observable<any> {
                    const args = [new RequestParam('sessionID', RequestParamType.QUERY, sessionID), new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('topCount', RequestParamType.QUERY, topCount)];
                    const requestParams = new RequestParams('UserInterestProducts', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UserSuggestedProducts(sessionID: string, userNumber: string, userID: number, topCount: number): Observable<any> {
                    const args = [new RequestParam('sessionID', RequestParamType.QUERY, sessionID), new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('topCount', RequestParamType.QUERY, topCount)];
                    const requestParams = new RequestParams('UserSuggestedProducts', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PATransactionsController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantPublicAPI/v1/PATransactions`;

                public EvaluateTransaction(header: any): Observable<any> {
                    const args = [new RequestParam('header', RequestParamType.BODY, header)];
                    const requestParams = new RequestParams('EvaluateTransaction', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public SaveTransaction(dontValidateVouchers: boolean, header: any): Observable<any> {
                    const args = [new RequestParam('dontValidateVouchers', RequestParamType.QUERY, dontValidateVouchers), new RequestParam('header', RequestParamType.BODY, header)];
                    const requestParams = new RequestParams('SaveTransaction', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public ImportTransactions(header: Array<any>): Observable<any> {
                    const args = [new RequestParam('header', RequestParamType.BODY, header)];
                    const requestParams = new RequestParams('ImportTransactions', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetTransaction(transactionUID: string): Observable<any> {
                    const args = [new RequestParam('transactionUID', RequestParamType.ROUTE, transactionUID)];
                    const requestParams = new RequestParams('GetTransaction/{transactionUID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetTransactions(topCount: number, status: number, locationNumber: string, timestamp: number): Observable<Array<any>> {
                    const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('locationNumber', RequestParamType.QUERY, locationNumber), new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                    const requestParams = new RequestParams('GetTransactions/{timeStamp}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public UpdateTransactionStatus(status: number, transactionUID: string): Observable<any> {
                    const args = [new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('transactionUID', RequestParamType.ROUTE, transactionUID)];
                    const requestParams = new RequestParams('UpdateTransactionStatus/{transactionUID}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateItemStatus(status: number, itemUID: string): Observable<any> {
                    const args = [new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('itemUID', RequestParamType.ROUTE, itemUID)];
                    const requestParams = new RequestParams('UpdateItemStatus/{itemUID}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateStatTransactionStatus(status: number, transactionUID: string): Observable<any> {
                    const args = [new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('transactionUID', RequestParamType.ROUTE, transactionUID)];
                    const requestParams = new RequestParams('UpdateStatTransactionStatus/{transactionUID}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateStatItemStatus(status: number, itemUID: string): Observable<any> {
                    const args = [new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('itemUID', RequestParamType.ROUTE, itemUID)];
                    const requestParams = new RequestParams('UpdateStatItemStatus/{itemUID}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetUserStatTransactions(sortDirection: string, sortBy: string, type: string, fromIndex: number, topCount: number, includeContacts: boolean, dateFrom: string, dateTo: string, userNumber: string): Observable<any> {
                    const args = [new RequestParam('sortDirection', RequestParamType.QUERY, sortDirection), new RequestParam('sortBy', RequestParamType.QUERY, sortBy), new RequestParam('type', RequestParamType.QUERY, type), new RequestParam('fromIndex', RequestParamType.QUERY, fromIndex), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('includeContacts', RequestParamType.QUERY, includeContacts), new RequestParam('dateFrom', RequestParamType.QUERY, dateFrom), new RequestParam('dateTo', RequestParamType.QUERY, dateTo), new RequestParam('userNumber', RequestParamType.ROUTE, userNumber)];
                    const requestParams = new RequestParams('GetUserStatTransactions/{userNumber}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetStatTransaction(includeContacts: boolean, transactionUID: string): Observable<any> {
                    const args = [new RequestParam('includeContacts', RequestParamType.QUERY, includeContacts), new RequestParam('transactionUID', RequestParamType.ROUTE, transactionUID)];
                    const requestParams = new RequestParams('GetStatTransaction/{transactionUID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetStatTransactions(userNumber: string, sortDirection: string, sortBy: string, type: string, topCount: number, timestamp: number): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('sortDirection', RequestParamType.QUERY, sortDirection), new RequestParam('sortBy', RequestParamType.QUERY, sortBy), new RequestParam('type', RequestParamType.QUERY, type), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                    const requestParams = new RequestParams('GetStatTransactions/{timestamp}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PAUsersController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantPublicAPI/v1/paUsers`;

                public GetUserByNumber(userNumber: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.ROUTE, userNumber)];
                    const requestParams = new RequestParams('byNumber/{number}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetUserByTagNumber(tagNumber: string): Observable<any> {
                    const args = [new RequestParam('tagNumber', RequestParamType.ROUTE, tagNumber)];
                    const requestParams = new RequestParams('byTagNumber/{number}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetUsersByProperty(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('getUsersByProperty', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetUserByLogin(login: string, loginRoute: string): Observable<any> {
                    const args = [new RequestParam('login', RequestParamType.QUERY, login), new RequestParam('loginRoute', RequestParamType.ROUTE, loginRoute)];
                    const requestParams = new RequestParams('byLogin/{*loginRoute}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetUserListByPhone(phoneNumber: string): Observable<Array<any>> {
                    const args = [new RequestParam('phoneNumber', RequestParamType.ROUTE, phoneNumber)];
                    const requestParams = new RequestParams('ListbyPhone/{*phoneNumber}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public GetUserByUID(userUID: string): Observable<any> {
                    const args = [new RequestParam('userUID', RequestParamType.ROUTE, userUID)];
                    const requestParams = new RequestParams('byUID/{userUID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetChanged(timestamp: number, topCount: number, onlyFilled: boolean, includeRewardHistory: boolean): Observable<Array<any>> {
                    const args = [new RequestParam('timestamp', RequestParamType.QUERY, timestamp), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('onlyFilled', RequestParamType.QUERY, onlyFilled), new RequestParam('includeRewardHistory', RequestParamType.QUERY, includeRewardHistory)];
                    const requestParams = new RequestParams('getChanged', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public RegisterUser(model: any): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('register', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpsertUser(model: any): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpsertUsersList(model: Array<any>): Observable<Array<any>> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('upsertList', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public Login(loginName: string): Observable<any> {
                    const args = [new RequestParam('loginName', RequestParamType.ROUTE, loginName)];
                    const requestParams = new RequestParams('login/{loginName}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public AppleWalletByUserNumber(userNumber: string, voucherId: number, tagId: number, fallbackURL: string, mediaName: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('fallbackURL', RequestParamType.QUERY, fallbackURL), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                    const requestParams = new RequestParams('appleWalletByUserNumber/{mediaName}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public AppleWalletByUserNumberAltByNumbers(userNumber: string, voucherNumber: string, tagNumber: string, fallbackURL: string, mediaName: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherNumber', RequestParamType.QUERY, voucherNumber), new RequestParam('tagNumber', RequestParamType.QUERY, tagNumber), new RequestParam('fallbackURL', RequestParamType.QUERY, fallbackURL), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                    const requestParams = new RequestParams('appleWalletByUserNumberAltByNumbers/{mediaName}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GooglePayObjectByUserNumberAltByNumbers(userNumber: string, voucherNumber: string, tagNumber: string, fallbackURL: string, returnRedirect: boolean, mediaName: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherNumber', RequestParamType.QUERY, voucherNumber), new RequestParam('tagNumber', RequestParamType.QUERY, tagNumber), new RequestParam('fallbackURL', RequestParamType.QUERY, fallbackURL), new RequestParam('returnRedirect', RequestParamType.QUERY, returnRedirect), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                    const requestParams = new RequestParams('googlePayObjectByUserNumberAltByNumbers/{mediaName}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GooglePayObjectByUserNumber(userNumber: string, voucherId: number, tagId: number, fallbackURL: string, returnRedirect: boolean, mediaName: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('fallbackURL', RequestParamType.QUERY, fallbackURL), new RequestParam('returnRedirect', RequestParamType.QUERY, returnRedirect), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                    const requestParams = new RequestParams('googlePayByUserNumber/{mediaName}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public AppleWalletByEncryptedUser(userNumber: string, voucherId: number, tagId: number, fallbackURL: string, mediaName: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('fallbackURL', RequestParamType.QUERY, fallbackURL), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                    const requestParams = new RequestParams('appleWalletByEncryptedUser/{mediaName}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GooglePayByEncryptedUser(userNumber: string, voucherId: number, tagId: number, fallbackURL: string, returnRedirect: boolean, mediaName: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherId', RequestParamType.QUERY, voucherId), new RequestParam('tagId', RequestParamType.QUERY, tagId), new RequestParam('fallbackURL', RequestParamType.QUERY, fallbackURL), new RequestParam('returnRedirect', RequestParamType.QUERY, returnRedirect), new RequestParam('mediaName', RequestParamType.ROUTE, mediaName)];
                    const requestParams = new RequestParams('googlePayByEncryptedUser/{mediaName}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpsertLoyaltyPoints(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('upsertLoyaltyPoints', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetLoyaltyPointsHistory(userNumber: string, rewardName: string): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.ROUTE, userNumber), new RequestParam('rewardName', RequestParamType.ROUTE, rewardName)];
                    const requestParams = new RequestParams('getLoyaltyPointsHistory/{userNumber}/{rewardName}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateLoyaltyPoints(request: Array<any>): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('updateLoyaltyPoints', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpsertUserData(userNumber: string, request: any): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.ROUTE, userNumber), new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('upsertUserData/{userNumber}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RegisterTagRequest(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('registerTagRequest', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public AddTag(numericSerieOrigin: string, type: any, validMonths: number, number: string, userNumber: string): Observable<any> {
                    const args = [new RequestParam('numericSerieOrigin', RequestParamType.QUERY, numericSerieOrigin), new RequestParam('type', RequestParamType.QUERY, type), new RequestParam('validMonths', RequestParamType.QUERY, validMonths), new RequestParam('number', RequestParamType.QUERY, number), new RequestParam('userNumber', RequestParamType.ROUTE, userNumber)];
                    const requestParams = new RequestParams('addTag/{userNumber}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateTagStatus(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('updateTagStatus', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetTags(userID: number): Observable<any> {
                    const args = [new RequestParam('userID', RequestParamType.ROUTE, userID)];
                    const requestParams = new RequestParams('getTags/{userId}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateUserOptions(userNumber: string, userOption: any): Observable<any> {
                    const args = [new RequestParam('userNumber', RequestParamType.ROUTE, userNumber), new RequestParam('userOption', RequestParamType.BODY, userOption)];
                    const requestParams = new RequestParams('UpdateUserOptions/{userNumber}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetTagsWithValue(userID: number): Observable<any> {
                    const args = [new RequestParam('userID', RequestParamType.ROUTE, userID)];
                    const requestParams = new RequestParams('getTagsValue/{userID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RewardUpdate(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('RewardUpdate', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public LoyaltyMember(user: string, loyaltyLevel: string): Observable<any> {
                    const args = [new RequestParam('user', RequestParamType.ROUTE, user), new RequestParam('loyaltyLevel', RequestParamType.ROUTE, loyaltyLevel)];
                    const requestParams = new RequestParams('loyaltyMember/{user}/{loyaltyLevel}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public VerifyContactUser(verifyUserRequest: any): Observable<any> {
                    const args = [new RequestParam('verifyUserRequest', RequestParamType.BODY, verifyUserRequest)];
                    const requestParams = new RequestParams('VerifyContactUser', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public ContactFulltext(request: any): Observable<Array<any>> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('ByContactFulltext', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public NewsletterUnsubscribe(userid: number, unsubscribeUID: string): Observable<any> {
                    const args = [new RequestParam('userid', RequestParamType.ROUTE, userid), new RequestParam('unsubscribeUID', RequestParamType.ROUTE, unsubscribeUID)];
                    const requestParams = new RequestParams('unsubscribe/{userId}/{unsubscribeUID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PAVouchersController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantPublicAPI/v1/paVouchers`;

                public GetVoucher(includeHistory: boolean, number: string): Observable<any> {
                    const args = [new RequestParam('includeHistory', RequestParamType.QUERY, includeHistory), new RequestParam('number', RequestParamType.ROUTE, number)];
                    const requestParams = new RequestParams('{number}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public TryUseVoucher(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('TryUseVoucher', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UseVoucher(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('UseVoucher', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UseVoucherWithoutCheck(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('UseVoucherWithoutCheck', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetUserVouchers(onlyUsable: boolean, locationNumber: string, userNumber: string): Observable<any> {
                    const args = [new RequestParam('onlyUsable', RequestParamType.QUERY, onlyUsable), new RequestParam('locationNumber', RequestParamType.QUERY, locationNumber), new RequestParam('userNumber', RequestParamType.ROUTE, userNumber)];
                    const requestParams = new RequestParams('GetUserVouchers/{userNumber}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RechargeVoucher(voucherRechargeRequet: any): Observable<any> {
                    const args = [new RequestParam('voucherRechargeRequet', RequestParamType.BODY, voucherRechargeRequet)];
                    const requestParams = new RequestParams('RechargeVoucher', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public SetVouchersStatus(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('SetVouchersStatus', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetVoucherMedia(voucherID: number): Observable<any> {
                    const args = [new RequestParam('voucherID', RequestParamType.ROUTE, voucherID)];
                    const requestParams = new RequestParams('voucherMedia/{voucherID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Generate(voucherName: string, count: number, setUsable: boolean, metadata: string, value: number, userNumber: string, voucherTemplateID: number): Observable<Array<any>> {
                    const args = [new RequestParam('voucherName', RequestParamType.QUERY, voucherName), new RequestParam('count', RequestParamType.QUERY, count), new RequestParam('setUsable', RequestParamType.QUERY, setUsable), new RequestParam('metadata', RequestParamType.QUERY, metadata), new RequestParam('value', RequestParamType.QUERY, value), new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherTemplateID', RequestParamType.QUERY, voucherTemplateID)];
                    const requestParams = new RequestParams('Generate', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public GenerateList(batchRequest: Array<any>): Observable<any> {
                    const args = [new RequestParam('batchRequest', RequestParamType.BODY, batchRequest)];
                    const requestParams = new RequestParams('GenerateList', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public CreateVoucherExistingNumber(request: any): Observable<any> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('CreateVoucherExistingNumber', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetVoucherTemplates(validOnly: boolean, loyaltyPointsExchangeOnly: boolean): Observable<Array<any>> {
                    const args = [new RequestParam('validOnly', RequestParamType.QUERY, validOnly), new RequestParam('loyaltyPointsExchangeOnly', RequestParamType.QUERY, loyaltyPointsExchangeOnly)];
                    const requestParams = new RequestParams('GetVoucherTemplates', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public ExchangePointsForVoucher(userNumber: string, voucherTemplateName: string, count: number, programName: string, negativePointsEnabled: boolean): Observable<Array<any>> {
                    const args = [new RequestParam('userNumber', RequestParamType.QUERY, userNumber), new RequestParam('voucherTemplateName', RequestParamType.QUERY, voucherTemplateName), new RequestParam('count', RequestParamType.QUERY, count), new RequestParam('programName', RequestParamType.QUERY, programName), new RequestParam('negativePointsEnabled', RequestParamType.QUERY, negativePointsEnabled)];
                    const requestParams = new RequestParams('ExchangePointsForVoucher', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public AssignVoucherToUser(requestAssignVouchers: Array<any>): Observable<any> {
                    const args = [new RequestParam('requestAssignVouchers', RequestParamType.BODY, requestAssignVouchers)];
                    const requestParams = new RequestParams('AssignVoucherToUser', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Controllers {


                    @Injectable()
                    export class ApiController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantPublicAPI/`;

                        public Ping(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CacheClear(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Version(): Observable<any> {
                            const args = [];
                            const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace TenantManagement {
        export namespace IService {


            @Injectable()
            export class APITypeScriptController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantManagement/APITypeScript`;

                public GetAPI(filter: Honeycomb.TenantManagement.IService.Models.APIFilter): Observable<Honeycomb.TenantManagement.IService.APIResult> {
                    const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                    const requestParams = new RequestParams('api', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.APIResult>;
                }

                public GetAPIFile(filter: Honeycomb.TenantManagement.IService.Models.APIFilter): Observable<Honeycomb.TenantManagement.IService.APIResult> {
                    const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                    const requestParams = new RequestParams('apiFile', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.APIResult>;
                }

                public Angular(): Observable<Honeycomb.TenantManagement.IService.APIResult> {
                    const args = [];
                    const requestParams = new RequestParams('angular', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.APIResult>;
                }

                public AngularPOS(): Observable<Honeycomb.TenantManagement.IService.APIResult> {
                    const args = [];
                    const requestParams = new RequestParams('angularPOS', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.APIResult>;
                }
            }

            @Injectable()
            export class ElasticController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantManagement/elastic`;

                public GetTenantUrl(id: number): Observable<string> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}/url', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                }

                public CreateNewTenant(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public RemapTenant(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('remap/{id}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public DeleteTenant(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public DeleteOrphans(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('orphans/{id?}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class TenantsController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantManagement/tenants`;

                public Register(viewModel: Honeycomb.TenantManagement.IService.RegisterViewModel): Observable<Honeycomb.TenantManagement.IService.TenantViewModel> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('Register', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.TenantViewModel>;
                }

                public ChangePassword(viewModel: Honeycomb.TenantManagement.IService.ChangePasswordRequestViewModel): Observable<any> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('ChangePassword', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Get(id: number): Observable<Honeycomb.TenantManagement.IService.TenantViewModel> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.TenantViewModel>;
                }

                public GetByName(name: string): Observable<Honeycomb.TenantManagement.IService.TenantViewModel> {
                    const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                    const requestParams = new RequestParams('GetByName/{name}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.TenantViewModel>;
                }

                public GetList(): Observable<Array<Honeycomb.TenantManagement.IService.TenantViewModel>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.TenantManagement.IService.TenantViewModel>>;
                }

                public GetShortList(): Observable<{ [key: number]: string }> {
                    const args = [];
                    const requestParams = new RequestParams('short', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: string }>;
                }

                public ShortHash(): Observable<Array<Honeycomb.TenantManagement.IService.TenantShortViewModel>> {
                    const args = [];
                    const requestParams = new RequestParams('shortHash', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.TenantManagement.IService.TenantShortViewModel>>;
                }

                public GetListByIds(viewModel: Honeycomb.TenantManagement.IService.TenantsByIdsRequestViewModel): Observable<Array<Honeycomb.TenantManagement.IService.TenantViewModel>> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('GetListByIds', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.TenantManagement.IService.TenantViewModel>>;
                }

                public GetLogo(viewModel: Honeycomb.TenantManagement.IService.TenantLogoRequestViewModel): Observable<Honeycomb.TenantManagement.IService.TenantLogoViewModel> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('GetLogo', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.TenantLogoViewModel>;
                }

                public Suspend(viewModel: Honeycomb.Common.Mvc.Middlewares.TenantRequestViewModel): Observable<any> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('Suspend', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Update(viewModel: Honeycomb.TenantManagement.IService.UpdateViewModel): Observable<any> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('Update', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateSimple(id: number, model: Honeycomb.TenantManagement.IService.TenantViewModel): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('UpdateSimple/{id}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Resume(viewModel: Honeycomb.Common.Mvc.Middlewares.TenantRequestViewModel): Observable<any> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('Resume', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(viewModel: Honeycomb.Common.Mvc.Middlewares.TenantRequestViewModel): Observable<any> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('Delete', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Authenticate(viewModel: Honeycomb.TenantManagement.IService.AuthenticateViewModel): Observable<Honeycomb.TenantManagement.IService.AuthenticateResultViewModel> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('Authenticate', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.TenantManagement.IService.AuthenticateResultViewModel>;
                }

                public GetConfig(viewModel: Honeycomb.Common.Mvc.Middlewares.TenantRequestViewModel): Observable<Honeycomb.Common.Mvc.Middlewares.GetConfigViewModel> {
                    const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                    const requestParams = new RequestParams('GetConfig', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.Mvc.Middlewares.GetConfigViewModel>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace TenantManagement {
        export namespace IService {


            @Injectable()
            export class ApiController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/TenantManagement/`;

                public Ping(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public CacheClear(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Version(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {


                @Injectable()
                export class CodeListController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/codelist`;

                    public ListAll(): Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeList>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeList>>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeList>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('list', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeList>>;
                    }

                    public SimpleList(ids: Array<number>): Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeListSimple>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('simplelist', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeListSimple>>;
                    }

                    public Lookup(langIsoCode: string, items: { [key: number]: any }): Observable<{ [key: number]: any }> {
                        const args = [new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode), new RequestParam('items', RequestParamType.BODY, items)];
                        const requestParams = new RequestParams('lookup/{langIsoCode}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: any }>;
                    }

                    public Filter(searchString: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeList>> {
                        const args = [new RequestParam('searchString', RequestParamType.ROUTE, searchString)];
                        const requestParams = new RequestParams('filter/{searchString}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.CodeList>>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.CodeList> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.CodeList>;
                    }

                    public DetailByName(name: string): Observable<Honeycomb.Tenant.LookupTables.IService.CodeList> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('byName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.CodeList>;
                    }

                    public Insert(codeList: Honeycomb.Tenant.LookupTables.IService.CodeList): Observable<any> {
                        const args = [new RequestParam('codeList', RequestParamType.BODY, codeList)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Update(id: number, codeList: Honeycomb.Tenant.LookupTables.IService.CodeList): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('codeList', RequestParamType.BODY, codeList)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Import(importedLists: Array<Honeycomb.Tenant.LookupTables.IService.CodeListImported>): Observable<any> {
                        const args = [new RequestParam('importedLists', RequestParamType.BODY, importedLists)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ListItem(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Code> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('code/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Code>;
                    }

                    public InsertItem(code: Honeycomb.Tenant.LookupTables.IService.Code): Observable<any> {
                        const args = [new RequestParam('code', RequestParamType.BODY, code)];
                        const requestParams = new RequestParams('code', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateItem(id: number, code: Honeycomb.Tenant.LookupTables.IService.Code): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('code', RequestParamType.BODY, code)];
                        const requestParams = new RequestParams('code/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteItem(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('code/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class CommonController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/common`;

                    public Get(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class CurrencyRatesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/currencyrates`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetList(): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.CurrencyRate>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.CurrencyRate>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.CurrencyRate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.CurrencyRate>;
                    }

                    public Post(currencyRate: Honeycomb.Tenant.LookupTables.IService.Model.CurrencyRate): Observable<any> {
                        const args = [new RequestParam('currencyRate', RequestParamType.BODY, currencyRate)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, currencyRate: Honeycomb.Tenant.LookupTables.IService.Model.CurrencyRate): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('currencyRate', RequestParamType.BODY, currencyRate)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class DiscountsController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/discounts`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetList(): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.Discount>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.Discount>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.Discount> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.Discount>;
                    }

                    public Post(discount: Honeycomb.Tenant.LookupTables.IService.Model.Discount): Observable<any> {
                        const args = [new RequestParam('discount', RequestParamType.BODY, discount)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, discount: Honeycomb.Tenant.LookupTables.IService.Model.Discount): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('discount', RequestParamType.BODY, discount)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class LocalizationDataController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/LocalizationData`;

                    public Upsert(model: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocalizationDataModel>): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(model: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocalizationDataModel>): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(lang: string, id: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocalizationDataModel>> {
                        const args = [new RequestParam('lang', RequestParamType.QUERY, lang), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('List/{*id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocalizationDataModel>>;
                    }
                }

                @Injectable()
                export class LocalizationsImportExportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/LocalizationsImportExport`;

                    public Import(request: Honeycomb.Common.ImportExport.Model.ImportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                    }

                    public Export(request: Honeycomb.Common.ImportExport.Model.ExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ExportResult>;
                    }

                    public DialogModel(interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                        const args = [new RequestParam('interfaceModel', RequestParamType.BODY, interfaceModel)];
                        const requestParams = new RequestParams('dialogModel', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                    }
                }

                @Injectable()
                export class LocationAttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/LocationAttributeTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>> {
                        const args = [];
                        const requestParams = new RequestParams('GetList', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>>;
                    }

                    public EnsureEditionAttributes(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('EnsureEditionAttributes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class LocationsController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/locations`;

                    public Post(model: Honeycomb.Tenant.LookupTables.IService.Model.Location): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Import(models: Array<Honeycomb.Tenant.LookupTables.IService.Model.Location>): Observable<any> {
                        const args = [new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.Location> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.Location>;
                    }

                    public Default(): Observable<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort> {
                        const args = [];
                        const requestParams = new RequestParams('default', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort>;
                    }

                    public DetailByNumber(number: string): Observable<Honeycomb.Tenant.LookupTables.IService.Model.Location> {
                        const args = [new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('detailByNumber/{number}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.Location>;
                    }

                    public List(filter: string, activeOnly: boolean): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.Location>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('activeOnly', RequestParamType.QUERY, activeOnly)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.Location>>;
                    }

                    public ListSimple(filter: string, offset: number, limit: number, activeOnly: boolean, specificLocations: string, includeAttributes: string, latitude: number, longitude: number, specificLocationsFilter: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('offset', RequestParamType.QUERY, offset), new RequestParam('limit', RequestParamType.QUERY, limit), new RequestParam('activeOnly', RequestParamType.QUERY, activeOnly), new RequestParam('specificLocations', RequestParamType.QUERY, specificLocations), new RequestParam('includeAttributes', RequestParamType.QUERY, includeAttributes), new RequestParam('latitude', RequestParamType.QUERY, latitude), new RequestParam('longitude', RequestParamType.QUERY, longitude), new RequestParam('specificLocationsFilter', RequestParamType.QUERY, specificLocationsFilter)];
                        const requestParams = new RequestParams('listsimple', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationShort>>;
                    }

                    public ListSimpleLong(filter: string, offset: number, limit: number, activeOnly: boolean, specificLocations: string, includeAttributes: string, latitude: number, longitude: number, locationsRequest: Honeycomb.Tenant.LookupTables.IService.Model.LocationRequest): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('offset', RequestParamType.QUERY, offset), new RequestParam('limit', RequestParamType.QUERY, limit), new RequestParam('activeOnly', RequestParamType.QUERY, activeOnly), new RequestParam('specificLocations', RequestParamType.QUERY, specificLocations), new RequestParam('includeAttributes', RequestParamType.QUERY, includeAttributes), new RequestParam('latitude', RequestParamType.QUERY, latitude), new RequestParam('longitude', RequestParamType.QUERY, longitude), new RequestParam('locationsRequest', RequestParamType.BODY, locationsRequest)];
                        const requestParams = new RequestParams('ListSimpleLong', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public TimeZones(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('timezones', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.LookupTables.IService.Model.Location): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetNames(ids: Array<number>): Observable<{ [key: number]: string }> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('names', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: string }>;
                    }

                    public SetAttribute(attribute: Honeycomb.Tenant.LookupTables.IService.Model.LocationAttribute): Observable<any> {
                        const args = [new RequestParam('attribute', RequestParamType.BODY, attribute)];
                        const requestParams = new RequestParams('attribute', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class PaymentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/payment`;

                    public Add(model: Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel>;
                    }

                    public ProviderByPaymentId(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('provider/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel>>;
                    }

                    public ListAll(): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel>> {
                        const args = [];
                        const requestParams = new RequestParams('listall', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.LookupTables.IService.Model.PaymentViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProviderController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/provider`;

                    public Add(model: Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ShippingController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/shipping`;

                    public Add(model: Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>>;
                    }

                    public CountryList(id: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('list/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>>;
                    }

                    public DeliveryOptions(aggregatorType: number, langIsoCode: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.DeliveryOption>> {
                        const args = [new RequestParam('aggregatorType', RequestParamType.ROUTE, aggregatorType), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('deliveryoptions/{aggregatorType}/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.DeliveryOption>>;
                    }

                    public ListWithLabels(aggregatorType: Honeycomb.Common.Enums.AggregatorType, langIsoCode: string): Observable<any> {
                        const args = [new RequestParam('aggregatorType', RequestParamType.ROUTE, aggregatorType), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('listwithlabels/{aggregatorType}/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeliveryLabels(aggregatorType: Honeycomb.Common.Enums.AggregatorType): Observable<{ [key: number]: string }> {
                        const args = [new RequestParam('aggregatorType', RequestParamType.ROUTE, aggregatorType)];
                        const requestParams = new RequestParams('deliverylabels/{aggregatorType}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: string }>;
                    }

                    public ShippingOptions(providerId: number, userInput: string): Observable<Array<Honeycomb.Tenant.LookupTables.IService.ShippingOption>> {
                        const args = [new RequestParam('providerId', RequestParamType.ROUTE, providerId), new RequestParam('userInput', RequestParamType.ROUTE, userInput)];
                        const requestParams = new RequestParams('shippingoptions/{providerId}/{userInput}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.ShippingOption>>;
                    }

                    public GetServiceItems(order: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer): Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.BasketServiceItem>> {
                        const args = [new RequestParam('order', RequestParamType.BODY, order)];
                        const requestParams = new RequestParams('resolve', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Transactions.IService.Model.BasketServiceItem>>;
                    }

                    public ListAll(): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>> {
                        const args = [];
                        const requestParams = new RequestParams('listall', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>>;
                    }

                    public AvailableParents(id: number): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>> {
                        const args = [new RequestParam('id', RequestParamType.QUERY, id)];
                        const requestParams = new RequestParams('availableParents', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.LookupTables.IService.Model.ShippingViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public AvailableCountries(): Observable<Array<Honeycomb.LookupTables.IService.Model.Country>> {
                        const args = [];
                        const requestParams = new RequestParams('countries', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Country>>;
                    }

                    public ShippingOptionsProviderList(): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingOptionsProvider>> {
                        const args = [];
                        const requestParams = new RequestParams('optionsprovider/list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingOptionsProvider>>;
                    }

                    public ShippingOptionsProviderDetail(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.ShippingOptionsProvider> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('optionsprovider/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.ShippingOptionsProvider>;
                    }

                    public UpdateShippingOptionsProvider(id: number, provider: Honeycomb.Tenant.LookupTables.IService.Model.ShippingOptionsProvider): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('provider', RequestParamType.BODY, provider)];
                        const requestParams = new RequestParams('optionsprovider/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ShippingPriceProviderList(): Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingPriceProvider>> {
                        const args = [];
                        const requestParams = new RequestParams('priceprovider/list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.LookupTables.IService.Model.ShippingPriceProvider>>;
                    }

                    public ShippingPriceProviderDetail(id: number): Observable<Honeycomb.Tenant.LookupTables.IService.Model.ShippingPriceProvider> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('priceprovider/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Model.ShippingPriceProvider>;
                    }

                    public UpdateShippingPriceProvider(id: number, provider: Honeycomb.Tenant.LookupTables.IService.Model.ShippingPriceProvider): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('provider', RequestParamType.BODY, provider)];
                        const requestParams = new RequestParams('priceprovider/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ScheduleController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/schedule`;

                    public List(filter: string): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DetailByName(name: string): Observable<Honeycomb.Tenant.LookupTables.IService.Schedule.PeriodSchedule> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('detailByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.LookupTables.IService.Schedule.PeriodSchedule>;
                    }

                    public Post(period: Honeycomb.Tenant.LookupTables.IService.Schedule.PeriodSchedule): Observable<any> {
                        const args = [new RequestParam('period', RequestParamType.BODY, period)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TenantLocalizationController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/TenantLocalization`;

                    public Localizations(id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                    }

                    public Filter(id: string, topCount: number): Observable<Array<any>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('topCount', RequestParamType.ROUTE, topCount)];
                        const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Get(langIsoCode: string, key: string): Observable<Honeycomb.LookupTables.IService.Model.Localization> {
                        const args = [new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode), new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('{langIsoCode}/{key}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Localization>;
                    }

                    public Detail(id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                    }

                    public Keys(prefixes: Array<string>): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                        const args = [new RequestParam('prefixes', RequestParamType.BODY, prefixes)];
                        const requestParams = new RequestParams('keys', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                    }

                    public Culture(queryPrefix: string, includeCommon: boolean, id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                        const args = [new RequestParam('queryPrefix', RequestParamType.QUERY, queryPrefix), new RequestParam('includeCommon', RequestParamType.QUERY, includeCommon), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('culture/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                    }

                    public CultureShort(queryPrefix: string, includeCommon: boolean, id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.LocalizationShort>> {
                        const args = [new RequestParam('queryPrefix', RequestParamType.QUERY, queryPrefix), new RequestParam('includeCommon', RequestParamType.QUERY, includeCommon), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('cultureShort/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.LocalizationShort>>;
                    }

                    public Dictionary(queryPrefix: string, includeCommon: boolean, id: string): Observable<{ [key: string]: string }> {
                        const args = [new RequestParam('queryPrefix', RequestParamType.QUERY, queryPrefix), new RequestParam('includeCommon', RequestParamType.QUERY, includeCommon), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('dictionary/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                    }

                    public LocalizeKeys(langIsoCode: string, keys: Array<string>): Observable<{ [key: string]: string }> {
                        const args = [new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode), new RequestParam('keys', RequestParamType.BODY, keys)];
                        const requestParams = new RequestParams('localizeKeys/{langIsoCode}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                    }

                    public Update(localization: Honeycomb.LookupTables.IService.Model.Localization): Observable<any> {
                        const args = [new RequestParam('localization', RequestParamType.ROUTE, localization)];
                        const requestParams = new RequestParams('update', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateList(list: Honeycomb.LookupTables.IService.Model.LocalizationList): Observable<any> {
                        const args = [new RequestParam('list', RequestParamType.BODY, list)];
                        const requestParams = new RequestParams('updateList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantLookupTables/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace DocumentStorage {
            export namespace IService {


                @Injectable()
                export class BackgroundController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/background`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class BQCodeController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/bqcode`;

                    public Get(codeType: string, value: string, width: number, height: number, pureBarcode: boolean): Observable<any> {
                        const args = [new RequestParam('codeType', RequestParamType.QUERY, codeType), new RequestParam('value', RequestParamType.QUERY, value), new RequestParam('width', RequestParamType.QUERY, width), new RequestParam('height', RequestParamType.QUERY, height), new RequestParam('pureBarcode', RequestParamType.QUERY, pureBarcode)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class EditorImageController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/editorImage`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class EmailController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/email`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class GalleryController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/gallery`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Thumb(size: number, filePath: string): Observable<any> {
                        const args = [new RequestParam('size', RequestParamType.ROUTE, size), new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('thumb/{size}/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ThumbByID(size: number, id: string): Observable<any> {
                        const args = [new RequestParam('size', RequestParamType.ROUTE, size), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('thumbByID/{size}/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(id: string): Observable<Honeycomb.Common.FileMetadata> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.FileMetadata>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Detail(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<any> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ListGallery(filesRequest: Honeycomb.Common.FilesRequest): Observable<any> {
                        const args = [new RequestParam('filesRequest', RequestParamType.BODY, filesRequest)];
                        const requestParams = new RequestParams('listGallery', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class NamedFileController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/namedfile`;

                    public GetByName(fileName: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('record/{fileName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public FileUpsert(model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('fileUpsert', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class OfflinePagesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/offlinePages`;

                    public Page(cache: boolean, url: string): Observable<any> {
                        const args = [new RequestParam('cache', RequestParamType.QUERY, cache), new RequestParam('url', RequestParamType.ROUTE, url)];
                        const requestParams = new RequestParams('page', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public PageAsDbFile(cache: boolean, url: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('cache', RequestParamType.QUERY, cache), new RequestParam('url', RequestParamType.ROUTE, url)];
                        const requestParams = new RequestParams('pageAsDbFile', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public CachePage(url: string): Observable<any> {
                        const args = [new RequestParam('url', RequestParamType.QUERY, url)];
                        const requestParams = new RequestParams('cachePage', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SavePage(model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('page/{name}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProductAttachmentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/productAttachment`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class RecordController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/record`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public DetailByName(name: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('detailByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public ListRecordUIDs(names: Array<string>): Observable<Array<string>> {
                        const args = [new RequestParam('names', RequestParamType.BODY, names)];
                        const requestParams = new RequestParams('listRecordUIDs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateThumbnail(width: number, existingRecordUID: string, imageUid: string): Observable<string> {
                        const args = [new RequestParam('width', RequestParamType.QUERY, width), new RequestParam('existingRecordUID', RequestParamType.QUERY, existingRecordUID), new RequestParam('imageUid', RequestParamType.ROUTE, imageUid)];
                        const requestParams = new RequestParams('createThumbnail/{imageUid}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public UpsertByName(maxWidth: number, maxHeight: number, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('upsertByName', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TaskAttachmentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/taskattachment`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TaskerFileController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/taskerFile`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TransactionAttachmentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/transactionAttachment`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class UserAttachmentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/userAttachment`;

                    public Add(model: Honeycomb.Common.DbFile): Observable<Honeycomb.Common.DbFileMetadata> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFileMetadata>;
                    }

                    public Upsert(maxWidth: number, maxHeight: number, models: Array<any>): Observable<Array<any>> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('models', RequestParamType.BODY, models)];
                        const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }

                    public Get(maxWidth: number, maxHeight: number, id: string, fileName: string): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('fileName', RequestParamType.ROUTE, fileName)];
                        const requestParams = new RequestParams('{id}/{fileName?}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByPath(filePath: string): Observable<any> {
                        const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                        const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetMetadata(recordUid: string): Observable<any> {
                        const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                        const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                    }

                    public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                    }

                    public Update(maxWidth: number, maxHeight: number, id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                        const args = [new RequestParam('maxWidth', RequestParamType.QUERY, maxWidth), new RequestParam('maxHeight', RequestParamType.QUERY, maxHeight), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace DocumentStorage {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/DocumentStorage/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {


                @Injectable()
                export class ContactAttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/ContactAttributeTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>> {
                        const args = [];
                        const requestParams = new RequestParams('GetList', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>>;
                    }

                    public EnsureEditionAttributes(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('EnsureEditionAttributes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetListMeanings(): Observable<{ [key: number]: string }> {
                        const args = [];
                        const requestParams = new RequestParams('Meanings', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: string }>;
                    }

                    public Filter(pageSize: number, page: number, id: string): Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>> {
                        const args = [new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Contact.IService.UserAttributeType> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserAttributeType>;
                    }

                    public Post(value: Honeycomb.Tenant.Contact.IService.UserAttributeType): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.BODY, value)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, attribute: Honeycomb.Tenant.Contact.IService.UserAttributeType): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attribute', RequestParamType.BODY, attribute)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ContactImportExportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/ContactImportExport`;

                    public Import(content: Honeycomb.Common.ImportExport.Model.ImportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                        const args = [new RequestParam('content', RequestParamType.BODY, content)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                    }

                    public Export(request: Honeycomb.Common.ImportExport.Model.ExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ExportResult>;
                    }

                    public DialogModel(interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                        const args = [new RequestParam('interfaceModel', RequestParamType.BODY, interfaceModel)];
                        const requestParams = new RequestParams('dialogModel', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                    }
                }

                @Injectable()
                export class InfoController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/info`;

                    public GetUserInfo(): Observable<Honeycomb.Tenant.Contact.IService.ViewModel.ClientInfo> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.ViewModel.ClientInfo>;
                    }
                }

                @Injectable()
                export class OptionTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/OptionTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Contact.IService.OptionType>> {
                        const args = [];
                        const requestParams = new RequestParams('GetList', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.OptionType>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Contact.IService.OptionType> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.OptionType>;
                    }

                    public Post(value: Honeycomb.Tenant.Contact.IService.OptionType): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.BODY, value)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class SurveyController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/survey`;

                    public SelectQuestions(filter: Honeycomb.Common.SearchBase): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('SelectQuestions', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SelectSurveys(filter: Honeycomb.Common.SearchBase): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('SelectSurveys', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteSurvey(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('DeleteSurvey/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteQuestion(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('DeleteQuestion/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ListQuestions(): Observable<Array<Honeycomb.Tenant.Contact.IService.SurveyQuestion>> {
                        const args = [];
                        const requestParams = new RequestParams('ListQuestions', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.SurveyQuestion>>;
                    }

                    public ListSurveys(): Observable<Array<Honeycomb.Tenant.Contact.IService.Survey>> {
                        const args = [];
                        const requestParams = new RequestParams('ListSurveys', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Survey>>;
                    }

                    public GetQuestion(id: number): Observable<Honeycomb.Tenant.Contact.IService.SurveyQuestion> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetQuestion/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.SurveyQuestion>;
                    }

                    public GetSurvey(id: number): Observable<Honeycomb.Tenant.Contact.IService.Survey> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('GetSurvey/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.Survey>;
                    }

                    public GetSurveyByName(name: string): Observable<Honeycomb.Tenant.Contact.IService.Survey> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('GetSurveyByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.Survey>;
                    }

                    public InsertQuestion(model: Honeycomb.Tenant.Contact.IService.SurveyQuestion): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('InsertQuestion', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public InsertSurvey(model: Honeycomb.Tenant.Contact.IService.Survey): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('InsertSurvey', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateQuestion(id: number, model: Honeycomb.Tenant.Contact.IService.SurveyQuestion): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('UpdateQuestion/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateSurvey(id: number, model: Honeycomb.Tenant.Contact.IService.Survey): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('UpdateSurvey/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SaveQuestion(surveyQuestion: Honeycomb.Tenant.Contact.IService.SurveyQuestion): Observable<any> {
                        const args = [new RequestParam('surveyQuestion', RequestParamType.BODY, surveyQuestion)];
                        const requestParams = new RequestParams('SaveQuestion', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SaveSurvey(survey: Honeycomb.Tenant.Contact.IService.Survey): Observable<any> {
                        const args = [new RequestParam('survey', RequestParamType.BODY, survey)];
                        const requestParams = new RequestParams('SaveSurvey', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SurveyResult(result: Honeycomb.Tenant.Contact.IService.SurveyResponse): Observable<any> {
                        const args = [new RequestParam('result', RequestParamType.BODY, result)];
                        const requestParams = new RequestParams('SurveyResult', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SurveyResultAuth(result: Honeycomb.Tenant.Contact.IService.SurveyResponse): Observable<any> {
                        const args = [new RequestParam('result', RequestParamType.BODY, result)];
                        const requestParams = new RequestParams('SurveyResultAuth', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckSurveyIsFilled(surveyId: number, userUid: string): Observable<any> {
                        const args = [new RequestParam('surveyId', RequestParamType.ROUTE, surveyId), new RequestParam('userUid', RequestParamType.ROUTE, userUid)];
                        const requestParams = new RequestParams('CheckSurveyIsFilled/{surveyId}/{userUid}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckSurveyIsFilledAuth(surveyId: number): Observable<any> {
                        const args = [new RequestParam('surveyId', RequestParamType.ROUTE, surveyId)];
                        const requestParams = new RequestParams('CheckSurveyIsFilledAuth/{surveyId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetSurveySnapshot(resultHeaderId: number): Observable<Honeycomb.Common.DbFile> {
                        const args = [new RequestParam('resultHeaderId', RequestParamType.ROUTE, resultHeaderId)];
                        const requestParams = new RequestParams('GetSurveySnapshot/{resultHeaderId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                    }
                }

                @Injectable()
                export class TagAttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/TagAttributeTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Contact.IService.TagAttributeType>> {
                        const args = [];
                        const requestParams = new RequestParams('GetList', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.TagAttributeType>>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Contact.IService.TagAttributeType>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.TagAttributeType>>;
                    }

                    public EnsureEditionAttributes(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('EnsureEditionAttributes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class UserActivityController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/userActivity`;

                    public Add(activity: Honeycomb.Tenant.Contact.IService.UserActivity): Observable<any> {
                        const args = [new RequestParam('activity', RequestParamType.BODY, activity)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class UserController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/user`;

                    public GetCurrentUserInfo(): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [];
                        const requestParams = new RequestParams('current', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetBasic(id: number): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}/basic', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetByNumber(includeRewardHistory: boolean, number: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('includeRewardHistory', RequestParamType.QUERY, includeRewardHistory), new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('number/{number}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetByTagNumber(number: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('tagNumber/{number}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public CheckByTagNumber(tagNumber: string, credentials: { [key: string]: string }): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('tagNumber', RequestParamType.ROUTE, tagNumber), new RequestParam('credentials', RequestParamType.BODY, credentials)];
                        const requestParams = new RequestParams('tagNumber/check/{tagNumber}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetByTagNumberAndEmailForRegistration(email: string, number: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('email', RequestParamType.QUERY, email), new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('tagNumberAndEmailForRegistration/{number}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetByPrimaryEmail(email: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('email', RequestParamType.ROUTE, email)];
                        const requestParams = new RequestParams('primaryEmail/{email}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetByLogin(login: string, loginRoute: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('login', RequestParamType.QUERY, login), new RequestParam('loginRoute', RequestParamType.ROUTE, loginRoute)];
                        const requestParams = new RequestParams('login/{*loginRoute}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetByLoginParam(login: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('login', RequestParamType.QUERY, login)];
                        const requestParams = new RequestParams('loginparam', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public LoginExists(login: string): Observable<boolean> {
                        const args = [new RequestParam('login', RequestParamType.ROUTE, login)];
                        const requestParams = new RequestParams('exists/{login}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<boolean>;
                    }

                    public GetUsersByPhoneNr(phoneNumber: string): Observable<Array<Honeycomb.Tenant.Contact.IService.UserInfo>> {
                        const args = [new RequestParam('phoneNumber', RequestParamType.QUERY, phoneNumber)];
                        const requestParams = new RequestParams('GetUsersByPhoneNr', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserInfo>>;
                    }

                    public SearchUsersForClubs(filter: Honeycomb.Tenant.Contact.IService.GetUsersForClubs): Observable<Array<Honeycomb.Tenant.Contact.IService.UserForClubsResponse>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('SearchUsersForClubs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserForClubsResponse>>;
                    }

                    public SearchUsersForEmails(model: Honeycomb.Tenant.Contact.IService.GetUsersForEmails): Observable<Array<Honeycomb.Tenant.Contact.IService.UserForClubsResponse>> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('SearchUsersForEmails', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserForClubsResponse>>;
                    }

                    public GetListOfOptionsByLogins(logins: Array<string>): Observable<Array<Honeycomb.Tenant.Contact.IService.UserInfo>> {
                        const args = [new RequestParam('logins', RequestParamType.BODY, logins)];
                        const requestParams = new RequestParams('loginsOptions', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserInfo>>;
                    }

                    public GetByUID(includeCollections: boolean, userUid: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('includeCollections', RequestParamType.QUERY, includeCollections), new RequestParam('userUid', RequestParamType.ROUTE, userUid)];
                        const requestParams = new RequestParams('uid/{userUid}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetForIds(shortModel: boolean, userIds: Array<number>): Observable<Array<Honeycomb.Tenant.Contact.IService.UserInfo>> {
                        const args = [new RequestParam('shortModel', RequestParamType.QUERY, shortModel), new RequestParam('userIds', RequestParamType.BODY, userIds)];
                        const requestParams = new RequestParams('getForIDs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserInfo>>;
                    }

                    public GetChanged(timestamp: number, topCount: number, onlyFilled: boolean, includeRewardHistory: boolean): Observable<Array<Honeycomb.Tenant.Contact.IService.User>> {
                        const args = [new RequestParam('timestamp', RequestParamType.QUERY, timestamp), new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('onlyFilled', RequestParamType.QUERY, onlyFilled), new RequestParam('includeRewardHistory', RequestParamType.QUERY, includeRewardHistory)];
                        const requestParams = new RequestParams('getChanged', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.User>>;
                    }

                    public GetUIDs(userIds: Array<number>): Observable<Array<Honeycomb.Tenant.Contact.IService.UserIdUidRelation>> {
                        const args = [new RequestParam('userIds', RequestParamType.BODY, userIds)];
                        const requestParams = new RequestParams('getUIDs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserIdUidRelation>>;
                    }

                    public GetNumbers(userIds: Array<number>): Observable<Array<Honeycomb.Tenant.Contact.IService.UserIdNumberRelation>> {
                        const args = [new RequestParam('userIds', RequestParamType.BODY, userIds)];
                        const requestParams = new RequestParams('getNumbers', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserIdNumberRelation>>;
                    }

                    public GetIds(userNumbers: Array<string>): Observable<Array<Honeycomb.Tenant.Contact.IService.UserIdNumberRelation>> {
                        const args = [new RequestParam('userNumbers', RequestParamType.BODY, userNumbers)];
                        const requestParams = new RequestParams('getIds', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserIdNumberRelation>>;
                    }

                    public ChangeUserNumber(oldNumber: string, newNumber: string): Observable<any> {
                        const args = [new RequestParam('oldNumber', RequestParamType.QUERY, oldNumber), new RequestParam('newNumber', RequestParamType.QUERY, newNumber)];
                        const requestParams = new RequestParams('changeUserNumber', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetByNumberBasic(number: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('number/{number}/basic', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public GetUsers(filter: Honeycomb.Common.SearchBase): Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('search', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>>;
                    }

                    public GetFilteredUsers(filter: Honeycomb.Common.Elastic.ElasticFilterRequest): Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('filter', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>>;
                    }

                    public CreateUserIDsTempTable(filter: Honeycomb.Common.Elastic.ElasticFilterRequest): Observable<Honeycomb.Common.ViewModels.BulkInsertTableResult> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('createUserIDsTempTable', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ViewModels.BulkInsertTableResult>;
                    }

                    public GetUsersInClub(filter: Honeycomb.Tenant.Contact.IService.SearchUsersInClub): Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('searchUsersInClub', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>>;
                    }

                    public RegisterExternal(accessToken: string, provider: string): Observable<Honeycomb.Auth.IService.AuthorizationResponse> {
                        const args = [new RequestParam('accessToken', RequestParamType.QUERY, accessToken), new RequestParam('provider', RequestParamType.ROUTE, provider)];
                        const requestParams = new RequestParams('registerExternal/{provider}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Auth.IService.AuthorizationResponse>;
                    }

                    public LoginExternal(accessToken: string, provider: Honeycomb.Common.Enums.ExternalAccountType): Observable<Honeycomb.Auth.IService.AuthorizationResponse> {
                        const args = [new RequestParam('accessToken', RequestParamType.QUERY, accessToken), new RequestParam('provider', RequestParamType.ROUTE, provider)];
                        const requestParams = new RequestParams('LoginExternal/{provider}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Auth.IService.AuthorizationResponse>;
                    }

                    public ProvideExternal(accessToken: string, provider: Honeycomb.Common.Enums.ExternalAccountType): Observable<Honeycomb.Common.Mvc.Services.SocialUserInfo> {
                        const args = [new RequestParam('accessToken', RequestParamType.QUERY, accessToken), new RequestParam('provider', RequestParamType.ROUTE, provider)];
                        const requestParams = new RequestParams('ProvideExternal/{provider}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.Mvc.Services.SocialUserInfo>;
                    }

                    public Register(model: Honeycomb.Tenant.Contact.IService.UserWithPasswordHash): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public UpsertUserOptions(id: number, model: Honeycomb.Tenant.Contact.IService.UserEditable): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('UpsertUserOptions/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SetUserRole(role: string, login: string): Observable<any> {
                        const args = [new RequestParam('role', RequestParamType.QUERY, role), new RequestParam('login', RequestParamType.ROUTE, login)];
                        const requestParams = new RequestParams('SetUserRole/{login}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RegisterEmail(model: Honeycomb.Tenant.Contact.IService.RegisterEmailModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('registerEmail', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RegisterCard(validYears: number, number: string): Observable<Honeycomb.Tenant.Contact.IService.UserInfo> {
                        const args = [new RequestParam('validYears', RequestParamType.QUERY, validYears), new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('registerCard/{number}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserInfo>;
                    }

                    public SendEmailConfirmation(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('sendEmailConfirmation', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SetTagStatus(status: Honeycomb.Common.Enums.TagStatus, number: string): Observable<any> {
                        const args = [new RequestParam('status', RequestParamType.QUERY, status), new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('setTagStatus/{number}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RequestNewVirtualCard(email: string): Observable<any> {
                        const args = [new RequestParam('email', RequestParamType.ROUTE, email)];
                        const requestParams = new RequestParams('requestNewVirtualCard/{email}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RequestNewPhysicalCard(contactID: number): Observable<any> {
                        const args = [new RequestParam('contactID', RequestParamType.ROUTE, contactID)];
                        const requestParams = new RequestParams('requestNewPhysicalCard/{contactID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public JoinAccountByTagNumberCustom(checkText: string, number: string): Observable<Array<Honeycomb.Tenant.Contact.IService.ContactJoinResult>> {
                        const args = [new RequestParam('checkText', RequestParamType.QUERY, checkText), new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('joinAccountByTagNumberCustom/{number}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.ContactJoinResult>>;
                    }

                    public JoinAccountByTagNumber(checkText: string, number: string): Observable<any> {
                        const args = [new RequestParam('checkText', RequestParamType.QUERY, checkText), new RequestParam('number', RequestParamType.ROUTE, number)];
                        const requestParams = new RequestParams('joinAccountByTagNumber/{number}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public AssignTagToUser(tagNumber: string, deleteTagUser: boolean, userId: number): Observable<any> {
                        const args = [new RequestParam('tagNumber', RequestParamType.QUERY, tagNumber), new RequestParam('deleteTagUser', RequestParamType.QUERY, deleteTagUser), new RequestParam('userId', RequestParamType.ROUTE, userId)];
                        const requestParams = new RequestParams('assignTagToUser/{userId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserReward(programName: string, userId: number): Observable<any> {
                        const args = [new RequestParam('programName', RequestParamType.QUERY, programName), new RequestParam('userId', RequestParamType.ROUTE, userId)];
                        const requestParams = new RequestParams('getUserReward/{userId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RewardPointsHistory(userId: number): Observable<Array<Honeycomb.Tenant.Contact.IService.Reward>> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId)];
                        const requestParams = new RequestParams('rewardPointsHistory/{userId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Reward>>;
                    }

                    public RewardGroups(): Observable<Array<string>> {
                        const args = [];
                        const requestParams = new RequestParams('rewardGroups', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                    }

                    public UpdateRewardsPoints(request: Honeycomb.Tenant.Contact.IService.UpdateRewardsRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('updateRewardsPoints', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Import(users: Array<Honeycomb.Tenant.Contact.IService.User>): Observable<any> {
                        const args = [new RequestParam('users', RequestParamType.BODY, users)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RequestPasswordReset(email: string): Observable<any> {
                        const args = [new RequestParam('email', RequestParamType.BODY, email)];
                        const requestParams = new RequestParams('reset', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckPasswordReset(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.QUERY, token)];
                        const requestParams = new RequestParams('reset', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ResetPassword(model: Honeycomb.Tenant.Contact.IService.PasswordResetRequest): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('reset', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ResetPasswordForClub(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('ResetPasswordForClub/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ChangePassword(model: Honeycomb.Tenant.Contact.IService.PasswordChangeRequest): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('current/password', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ChangeLoginEmail(request: Honeycomb.Tenant.Contact.IService.EmailChangeRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('current/email', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ChangeLoginAdmin(userId: number, newLogin: string): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.QUERY, userId), new RequestParam('newLogin', RequestParamType.QUERY, newLogin)];
                        const requestParams = new RequestParams('ChangeLoginAdmin', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ConfirmLoginEmail(email: string, token: string, redirectOKURL: string, redirectErrorURL: string): Observable<any> {
                        const args = [new RequestParam('email', RequestParamType.ROUTE, email), new RequestParam('token', RequestParamType.ROUTE, token), new RequestParam('redirectOKURL', RequestParamType.ROUTE, redirectOKURL), new RequestParam('redirectErrorURL', RequestParamType.ROUTE, redirectErrorURL)];
                        const requestParams = new RequestParams('confirm', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Contact.IService.UserWithOptions): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateAdmin(id: number, model: Honeycomb.Tenant.Contact.IService.UserEditable): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('admin/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SendUserMessage(message: string, id: number): Observable<any> {
                        const args = [new RequestParam('message', RequestParamType.QUERY, message), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('sendUserMessage/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateCurrent(model: Honeycomb.Tenant.Contact.IService.UserWithOptions): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('current', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdatePrimaryContact(contact: Honeycomb.Tenant.Contact.IService.Contact): Observable<any> {
                        const args = [new RequestParam('contact', RequestParamType.BODY, contact)];
                        const requestParams = new RequestParams('primaryContact', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public AddTag(numericSerieOrigin: string, type: Honeycomb.Common.Enums.UserTagType, validMonths: number, number: string, maskType: Honeycomb.Common.Enums.MaskType, id: number): Observable<number> {
                        const args = [new RequestParam('numericSerieOrigin', RequestParamType.QUERY, numericSerieOrigin), new RequestParam('type', RequestParamType.QUERY, type), new RequestParam('validMonths', RequestParamType.QUERY, validMonths), new RequestParam('number', RequestParamType.QUERY, number), new RequestParam('maskType', RequestParamType.QUERY, maskType), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('addTag/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public UpdateTagStatus(request: Honeycomb.Tenant.Contact.IService.UserTagStatusUpdateRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('updateTagStatus', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateTag(id: number, tag: Honeycomb.Tenant.Contact.IService.Tag): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('tag', RequestParamType.BODY, tag)];
                        const requestParams = new RequestParams('updateTag/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateReward(id: number, reward: Honeycomb.Tenant.Contact.IService.Reward): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('reward', RequestParamType.BODY, reward)];
                        const requestParams = new RequestParams('updateReward/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateObjectAttribute(source: Honeycomb.Common.Enums.ObjectAttributeSource, attributeName: string, attributeValue: string, id: number): Observable<any> {
                        const args = [new RequestParam('source', RequestParamType.QUERY, source), new RequestParam('attributeName', RequestParamType.QUERY, attributeName), new RequestParam('attributeValue', RequestParamType.QUERY, attributeValue), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('updateObjectAttribute/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteContact(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('DeleteContact/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SetUserLastContacts(id: number, contacts: Array<Honeycomb.Tenant.Contact.IService.Contact>): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('contacts', RequestParamType.ROUTE, contacts)];
                        const requestParams = new RequestParams('{id}/lastcontacts', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdatePhoto(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('updatePhoto', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SyncAll(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('syncAll', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SyncUser(userId: number): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId)];
                        const requestParams = new RequestParams('syncUser/{userId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ElasticSyncUsers(timestamp: number): Observable<any> {
                        const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                        const requestParams = new RequestParams('ElasticSyncUsers/{timestamp}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateUserFromContact(contact: Honeycomb.Tenant.Contact.IService.ContactPublic): Observable<any> {
                        const args = [new RequestParam('contact', RequestParamType.BODY, contact)];
                        const requestParams = new RequestParams('createUserFromContact', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateCurrentAttributes(attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttributeNameValue>): Observable<any> {
                        const args = [new RequestParam('attributes', RequestParamType.BODY, attributes)];
                        const requestParams = new RequestParams('current/attributes', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateAttributes(userId: number, attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttributeNameValue>): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId), new RequestParam('attributes', RequestParamType.BODY, attributes)];
                        const requestParams = new RequestParams('attributes/{userId}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SetOption(value: number, optionTypeName: string): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.QUERY, value), new RequestParam('optionTypeName', RequestParamType.ROUTE, optionTypeName)];
                        const requestParams = new RequestParams('setOption/{optionTypeName}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SetOptionForUser(value: number, validFrom: Date, validTo: Date, optionTypeName: string, userID: number): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.QUERY, value), new RequestParam('validFrom', RequestParamType.QUERY, validFrom), new RequestParam('validTo', RequestParamType.QUERY, validTo), new RequestParam('optionTypeName', RequestParamType.ROUTE, optionTypeName), new RequestParam('userID', RequestParamType.ROUTE, userID)];
                        const requestParams = new RequestParams('setOptionForUser/{optionTypeName}/{userID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CommitPendingValue(userUID: string, optionId: number, redirectOKURL: string, redirectErrorURL: string): Observable<any> {
                        const args = [new RequestParam('userUID', RequestParamType.QUERY, userUID), new RequestParam('optionId', RequestParamType.ROUTE, optionId), new RequestParam('redirectOKURL', RequestParamType.ROUTE, redirectOKURL), new RequestParam('redirectErrorURL', RequestParamType.ROUTE, redirectErrorURL)];
                        const requestParams = new RequestParams('commitPendingValue/{optionId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DisableOption(optionTypeID: number, userUID: string, redirectOKURL: string, redirectErrorURL: string): Observable<any> {
                        const args = [new RequestParam('optionTypeID', RequestParamType.ROUTE, optionTypeID), new RequestParam('userUID', RequestParamType.ROUTE, userUID), new RequestParam('redirectOKURL', RequestParamType.ROUTE, redirectOKURL), new RequestParam('redirectErrorURL', RequestParamType.ROUTE, redirectErrorURL)];
                        const requestParams = new RequestParams('disableOption/{optionTypeID}/{userUID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public NewsletterUnsubscribe(userId: number, unsubscribeUID: string): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId), new RequestParam('unsubscribeUID', RequestParamType.ROUTE, unsubscribeUID)];
                        const requestParams = new RequestParams('unsubscribeNewsletter/{userId}/{unsubscribeUID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUnsubsribeUID(userId: number, optionType: number): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId), new RequestParam('optionType', RequestParamType.ROUTE, optionType)];
                        const requestParams = new RequestParams('unsubscribeUID/{userId}/{optionType}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ForciblyForget(userID: number): Observable<any> {
                        const args = [new RequestParam('userID', RequestParamType.ROUTE, userID)];
                        const requestParams = new RequestParams('forciblyForget/{userID}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetGDPRInfo(userID: number): Observable<any> {
                        const args = [new RequestParam('userID', RequestParamType.ROUTE, userID)];
                        const requestParams = new RequestParams('GDPRInfo/{userID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateAllGendersAndSalutations(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('UpdateAllGendersAndSalutations', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Suggest(maxCount: number, filterName: string, userInput: string): Observable<Array<Honeycomb.Tenant.Contact.IService.UserListItem>> {
                        const args = [new RequestParam('maxCount', RequestParamType.QUERY, maxCount), new RequestParam('filterName', RequestParamType.QUERY, filterName), new RequestParam('userInput', RequestParamType.ROUTE, userInput)];
                        const requestParams = new RequestParams('suggest/{userInput}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserListItem>>;
                    }

                    public GetCurrentMembers(collectionName: string): Observable<any> {
                        const args = [new RequestParam('collectionName', RequestParamType.ROUTE, collectionName)];
                        const requestParams = new RequestParams('current/collection/{collectionName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserMembers(userId: number, collectionName: string): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId), new RequestParam('collectionName', RequestParamType.ROUTE, collectionName)];
                        const requestParams = new RequestParams('{userId}/collection/{collectionName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetCurrentMember(collectionName: string, id: string): Observable<any> {
                        const args = [new RequestParam('collectionName', RequestParamType.ROUTE, collectionName), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('current/collection/{collectionName}/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserMember(userId: number, collectionName: string, id: string): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId), new RequestParam('collectionName', RequestParamType.ROUTE, collectionName), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{userId}/collection/{collectionName}/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertCurrentMember(collectionName: string, model: any): Observable<any> {
                        const args = [new RequestParam('collectionName', RequestParamType.ROUTE, collectionName), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('current/collection/{collectionName}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertUserMember(userId: number, collectionName: string, model: any): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId), new RequestParam('collectionName', RequestParamType.ROUTE, collectionName), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{userId}/collection/{collectionName}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteCurrentMember(collectionName: string, id: string): Observable<any> {
                        const args = [new RequestParam('collectionName', RequestParamType.ROUTE, collectionName), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('current/collection/{collectionName}/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteUserMember(userId: number, collectionName: string, id: string): Observable<any> {
                        const args = [new RequestParam('userId', RequestParamType.ROUTE, userId), new RequestParam('collectionName', RequestParamType.ROUTE, collectionName), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{userId}/collection/{collectionName}/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateNew(user: Honeycomb.Tenant.Contact.IService.UserWithOptions): Observable<any> {
                        const args = [new RequestParam('user', RequestParamType.BODY, user)];
                        const requestParams = new RequestParams('createNew', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetLastTimestamp(): Observable<number> {
                        const args = [];
                        const requestParams = new RequestParams('lastTimeStamp', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public UpdateUserOptions(request: Honeycomb.Tenant.Contact.IService.UserOptionsUpdateRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('OptionUpdateModel', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateTagsEmission(count: number, pinLength: number, name: string): Observable<any> {
                        const args = [new RequestParam('count', RequestParamType.QUERY, count), new RequestParam('pinLength', RequestParamType.QUERY, pinLength), new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('CreateTagsEmission/{name}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ExportEmission(tagEmissionID: number): Observable<any> {
                        const args = [new RequestParam('tagEmissionID', RequestParamType.ROUTE, tagEmissionID)];
                        const requestParams = new RequestParams('ExportTagsEmission/{tagEmissionID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DownloadExportedEmission(token: string): Observable<any> {
                        const args = [new RequestParam('token', RequestParamType.ROUTE, token)];
                        const requestParams = new RequestParams('downloadExportedEmission/{token}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RegisterTagRequest(request: Honeycomb.Tenant.Contact.IService.TagRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('registerTagRequest', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UIRoles(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('uiroles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Roles(userID: number): Observable<Array<number>> {
                        const args = [new RequestParam('userID', RequestParamType.ROUTE, userID)];
                        const requestParams = new RequestParams('roles/{userID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                    }

                    public GenerateUserCard(numericMaskId: number, validMonths: number, email: string, id: number): Observable<any> {
                        const args = [new RequestParam('numericMaskId', RequestParamType.QUERY, numericMaskId), new RequestParam('validMonths', RequestParamType.QUERY, validMonths), new RequestParam('email', RequestParamType.QUERY, email), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('generateUserCard/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserCardMasks(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('getUserCardMasks', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GenerateUserCardEAN(numericMaskId: number, validMonths: number, email: string, id: number): Observable<any> {
                        const args = [new RequestParam('numericMaskId', RequestParamType.QUERY, numericMaskId), new RequestParam('validMonths', RequestParamType.QUERY, validMonths), new RequestParam('email', RequestParamType.QUERY, email), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('generateUserCardEAN/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetUserIdByTag(tagNumber: string): Observable<any> {
                        const args = [new RequestParam('tagNumber', RequestParamType.ROUTE, tagNumber)];
                        const requestParams = new RequestParams('getUserIdByTag', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {
                export namespace Controller {


                    @Injectable()
                    export class AttendanceController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantContact/attendance`;

                        public List(dateFrom: Date, dateTo: Date): Observable<Array<Honeycomb.Tenant.Contact.IService.Attendance>> {
                            const args = [new RequestParam('dateFrom', RequestParamType.QUERY, dateFrom), new RequestParam('dateTo', RequestParamType.QUERY, dateTo)];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Attendance>>;
                        }

                        public ForUser(dateFrom: Date, dateTo: Date, id: number): Observable<Array<Honeycomb.Tenant.Contact.IService.Attendance>> {
                            const args = [new RequestParam('dateFrom', RequestParamType.QUERY, dateFrom), new RequestParam('dateTo', RequestParamType.QUERY, dateTo), new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('ForUser/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Attendance>>;
                        }

                        public GetActive(): Observable<Honeycomb.Tenant.Contact.IService.Attendance> {
                            const args = [];
                            const requestParams = new RequestParams('GetActive', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.Attendance>;
                        }

                        public AddAttendance(attendance: Honeycomb.Tenant.Contact.IService.Attendance): Observable<number> {
                            const args = [new RequestParam('attendance', RequestParamType.BODY, attendance)];
                            const requestParams = new RequestParams('AddAttendance', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public AddAttendanceAdmin(attendance: Honeycomb.Tenant.Contact.IService.Attendance): Observable<number> {
                            const args = [new RequestParam('attendance', RequestParamType.BODY, attendance)];
                            const requestParams = new RequestParams('AddAttendanceAdmin', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public AddAttendanceRecord(id: number, attendanceRecord: Honeycomb.Tenant.Contact.IService.AttendanceRecord): Observable<number> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attendanceRecord', RequestParamType.BODY, attendanceRecord)];
                            const requestParams = new RequestParams('AddAttendanceRecord/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public AddAttendanceRecordAdmin(id: number, attendanceRecord: Honeycomb.Tenant.Contact.IService.AttendanceRecord): Observable<number> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attendanceRecord', RequestParamType.BODY, attendanceRecord)];
                            const requestParams = new RequestParams('AddAttendanceRecordAdmin/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public UpdateAttendanceRecord(id: number, attendanceRecord: Honeycomb.Tenant.Contact.IService.AttendanceRecordUpdate): Observable<number> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attendanceRecord', RequestParamType.BODY, attendanceRecord)];
                            const requestParams = new RequestParams('UpdateAttendanceRecord/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public UpdateAttendanceLocation(id: number, attendance: Honeycomb.Tenant.Contact.IService.Attendance): Observable<number> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attendance', RequestParamType.BODY, attendance)];
                            const requestParams = new RequestParams('UpdateAttendanceLocation/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class ClubController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantContact/club`;

                        public List(activeOnly: boolean): Observable<Array<Honeycomb.Tenant.Contact.IService.Club>> {
                            const args = [new RequestParam('activeOnly', RequestParamType.QUERY, activeOnly)];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Club>>;
                        }

                        public GetClubs(filter: Honeycomb.Common.SearchBase): Observable<Array<Honeycomb.Tenant.Contact.IService.ClubOverview>> {
                            const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                            const requestParams = new RequestParams('GetClubs', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.ClubOverview>>;
                        }

                        public GetClubsForUser(id: number): Observable<Array<Honeycomb.Tenant.Contact.IService.ClubOverview>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('GetClubsForUser/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.ClubOverview>>;
                        }

                        public GetUsersForClub(id: number): Observable<Array<Honeycomb.Tenant.Contact.IService.ViewModel.ClubUserContact>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('GetUsersForClub/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.ViewModel.ClubUserContact>>;
                        }

                        public Get(id: number): Observable<Honeycomb.Tenant.Contact.IService.Club> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.Club>;
                        }

                        public Post(club: Honeycomb.Tenant.Contact.IService.Club): Observable<any> {
                            const args = [new RequestParam('club', RequestParamType.BODY, club)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CreateClubOnTempTable(request: Honeycomb.Tenant.Contact.IService.ViewModel.CreateClubOnTempTableRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('CreateClubOnTempTable', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public AddToWhiteListOnTempTable(request: Honeycomb.Tenant.Contact.IService.ViewModel.AddToClubOnTempTableRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('AddToWhiteListOnTempTable', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CreateClub(request: Honeycomb.Tenant.Contact.IService.ViewModel.CreateClubRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('CreateClub', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public AddToWhiteList(request: Honeycomb.Tenant.Contact.IService.ViewModel.AddToClubRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('AddToWhiteList', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public RemoveWhiteListMember(clubID: number, userID: number): Observable<any> {
                            const args = [new RequestParam('clubID', RequestParamType.ROUTE, clubID), new RequestParam('userID', RequestParamType.ROUTE, userID)];
                            const requestParams = new RequestParams('RemoveWhiteListMember/{clubID}/{userID}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public RemoveWhiteListMembers(clubID: number, userIDs: Array<number>): Observable<any> {
                            const args = [new RequestParam('clubID', RequestParamType.ROUTE, clubID), new RequestParam('userIDs', RequestParamType.BODY, userIDs)];
                            const requestParams = new RequestParams('RemoveWhiteListMembers/{clubID}', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public ClearWhiteList(clubID: number): Observable<any> {
                            const args = [new RequestParam('clubID', RequestParamType.ROUTE, clubID)];
                            const requestParams = new RequestParams('ClearWhiteList', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Suggest(maxCount: number, userInput: string): Observable<any> {
                            const args = [new RequestParam('maxCount', RequestParamType.QUERY, maxCount), new RequestParam('userInput', RequestParamType.ROUTE, userInput)];
                            const requestParams = new RequestParams('suggest/{userInput}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class ContactController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantContact/contact`;

                        public ByEmail(email: string): Observable<Honeycomb.Tenant.Contact.IService.ContactUser> {
                            const args = [new RequestParam('email', RequestParamType.ROUTE, email)];
                            const requestParams = new RequestParams('ByEmail/{email}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.ContactUser>;
                        }

                        public ByPhoneNr(phoneNr: string): Observable<Honeycomb.Tenant.Contact.IService.ContactPublic> {
                            const args = [new RequestParam('phoneNr', RequestParamType.ROUTE, phoneNr)];
                            const requestParams = new RequestParams('ByPhoneNr/{phoneNr}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.ContactPublic>;
                        }

                        public ByLocation(locationID: number): Observable<Array<Honeycomb.Tenant.Contact.IService.ContactPublic>> {
                            const args = [new RequestParam('locationID', RequestParamType.ROUTE, locationID)];
                            const requestParams = new RequestParams('ByLocation/{locationID}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.ContactPublic>>;
                        }

                        public ByContactId(contactId: string): Observable<Honeycomb.Tenant.Contact.IService.ContactPublic> {
                            const args = [new RequestParam('contactId', RequestParamType.ROUTE, contactId)];
                            const requestParams = new RequestParams('ByContactId/{contactId}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.ContactPublic>;
                        }

                        public PublicByUserIdList(userIDsRequest: Honeycomb.Tenant.Contact.IService.ContactByUserIDsRequest): Observable<Array<Honeycomb.Tenant.Contact.IService.ContactPublic>> {
                            const args = [new RequestParam('userIDsRequest', RequestParamType.BODY, userIDsRequest)];
                            const requestParams = new RequestParams('PublicByUserIdList', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.ContactPublic>>;
                        }

                        public ByUserID(id: number): Observable<Honeycomb.Tenant.Contact.IService.ContactPublic> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('ByUserId/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.ContactPublic>;
                        }

                        public UpdateContact(id: number, model: Honeycomb.Tenant.Contact.IService.ContactPublic): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public CreateContact(model: Honeycomb.Tenant.Contact.IService.ContactPublic): Observable<any> {
                            const args = [new RequestParam('model', RequestParamType.BODY, model)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public ByUserIdList(userIDsRequest: Honeycomb.Tenant.Contact.IService.ContactByUserIDsRequest): Observable<Array<Honeycomb.Tenant.Contact.IService.Contact>> {
                            const args = [new RequestParam('userIDsRequest', RequestParamType.BODY, userIDsRequest)];
                            const requestParams = new RequestParams('ByUserIdList', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Contact>>;
                        }

                        public UserLocations(userIDs: Array<number>): Observable<Array<number>> {
                            const args = [new RequestParam('userIDs', RequestParamType.BODY, userIDs)];
                            const requestParams = new RequestParams('UserLocations', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                        }

                        public GetUsersByJob(jobIds: Array<number>): Observable<any> {
                            const args = [new RequestParam('jobIds', RequestParamType.BODY, jobIds)];
                            const requestParams = new RequestParams('GetUsersByJob', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public ContactFulltext(request: Honeycomb.Tenant.Contact.IService.ContactFulltextRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('ByContactFulltext', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class JobController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantContact/job`;

                        public List(): Observable<Array<Honeycomb.Tenant.Contact.IService.Job>> {
                            const args = [];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Job>>;
                        }

                        public JobChangeUsers(request: Honeycomb.Tenant.Contact.IService.JobChangeRequest): Observable<Honeycomb.Tenant.Contact.IService.JobChangeRequest> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('jobchange', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.JobChangeRequest>;
                        }

                        public JobUsersLocations(activeOnly: boolean, jobIds: Array<number>): Observable<Array<Honeycomb.Tenant.Contact.IService.Job>> {
                            const args = [new RequestParam('activeOnly', RequestParamType.QUERY, activeOnly), new RequestParam('jobIds', RequestParamType.BODY, jobIds)];
                            const requestParams = new RequestParams('filter', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Job>>;
                        }

                        public LocationsUsers(id: number): Observable<{ [key: number]: any }> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('LocationUsers/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: any }>;
                        }

                        public LocationJobUsers(id: number): Observable<Array<Honeycomb.Tenant.Contact.IService.Simple>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('LocationJobUsers/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Simple>>;
                        }

                        public PlanningUsers(filter: Honeycomb.Tenant.Contact.IService.UserFilter): Observable<Array<Honeycomb.Tenant.Contact.IService.SimpleUser>> {
                            const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                            const requestParams = new RequestParams('planning', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.SimpleUser>>;
                        }

                        public JobUsers(treshold: number, includeDeleted: boolean, search: string): Observable<Array<Honeycomb.Tenant.Contact.IService.SimpleUserJob>> {
                            const args = [new RequestParam('treshold', RequestParamType.QUERY, treshold), new RequestParam('includeDeleted', RequestParamType.QUERY, includeDeleted), new RequestParam('search', RequestParamType.ROUTE, search)];
                            const requestParams = new RequestParams('users/{search}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.SimpleUserJob>>;
                        }

                        public Get(includeLocations: boolean, id: number): Observable<Array<Honeycomb.Tenant.Contact.IService.Job>> {
                            const args = [new RequestParam('includeLocations', RequestParamType.QUERY, includeLocations), new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.Job>>;
                        }

                        public UsersForOperation(request: Honeycomb.Tenant.Contact.IService.UsersForOperationRequest): Observable<any> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('usersForOperation', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Resolve(request: Honeycomb.Tenant.Contact.IService.UsersResolveRequest): Observable<Array<number>> {
                            const args = [new RequestParam('request', RequestParamType.BODY, request)];
                            const requestParams = new RequestParams('resolve', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                        }

                        public JobGroups(): Observable<Array<Honeycomb.Tenant.Contact.IService.JobGroup>> {
                            const args = [];
                            const requestParams = new RequestParams('JobGroups', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.JobGroup>>;
                        }

                        public JobGroupForJob(id: number): Observable<Honeycomb.Tenant.Contact.IService.JobGroup> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('JobGroupForJob/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.JobGroup>;
                        }

                        public UIRoles(id: number): Observable<Array<string>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('uiroles/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                        }

                        public RolesForJob(userID: number, id: number): Observable<any> {
                            const args = [new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('rolesForJob/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public JobRoles(id: number): Observable<Array<number>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('jobRoles/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                        }
                    }

                    @Injectable()
                    export class UserNoteController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantContact/UserNote`;

                        public List(userID: number, createdBy: number): Observable<Array<Honeycomb.Tenant.Contact.IService.UserNote>> {
                            const args = [new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('createdBy', RequestParamType.QUERY, createdBy)];
                            const requestParams = new RequestParams('list', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserNote>>;
                        }

                        public Post(id: number, noteText: Honeycomb.Tenant.Contact.IService.NoteContent): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.QUERY, id), new RequestParam('noteText', RequestParamType.BODY, noteText)];
                            const requestParams = new RequestParams('', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }
                    }

                    @Injectable()
                    export class UserSubstitutionController {
                        constructor(private apiCaller: ApiCaller) { }
                        public controllerRoute: string = `api/TenantContact/UserSubstitution`;

                        public List(userID: number, replacedUserID: number, dateFromBegin: Date, dateFromEnd: Date, dateToBegin: Date, dateToEnd: Date, locationID: number, includeDeleted: boolean): Observable<Array<Honeycomb.Tenant.Contact.IService.UserSubstitution>> {
                            const args = [new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('replacedUserID', RequestParamType.QUERY, replacedUserID), new RequestParam('dateFromBegin', RequestParamType.QUERY, dateFromBegin), new RequestParam('dateFromEnd', RequestParamType.QUERY, dateFromEnd), new RequestParam('dateToBegin', RequestParamType.QUERY, dateToBegin), new RequestParam('dateToEnd', RequestParamType.QUERY, dateToEnd), new RequestParam('locationID', RequestParamType.QUERY, locationID), new RequestParam('includeDeleted', RequestParamType.QUERY, includeDeleted)];
                            const requestParams = new RequestParams('', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserSubstitution>>;
                        }

                        public Detail(id: number): Observable<Honeycomb.Tenant.Contact.IService.UserSubstitution> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserSubstitution>;
                        }

                        public Insert(userSubstitution: Honeycomb.Tenant.Contact.IService.UserSubstitution): Observable<number> {
                            const args = [new RequestParam('userSubstitution', RequestParamType.BODY, userSubstitution)];
                            const requestParams = new RequestParams('Insert', HttpMethod.POST, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                        }

                        public Update(id: number, userSubstitution: Honeycomb.Tenant.Contact.IService.UserSubstitution): Observable<Honeycomb.Tenant.Contact.IService.UserSubstitution> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('userSubstitution', RequestParamType.BODY, userSubstitution)];
                            const requestParams = new RequestParams('Update/{id}', HttpMethod.PUT, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Contact.IService.UserSubstitution>;
                        }

                        public Delete(id: number): Observable<any> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                        }

                        public History(id: number): Observable<Array<Honeycomb.Common.Db.HistoryData>> {
                            const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                            const requestParams = new RequestParams('history/{id}', HttpMethod.GET, args);
                            return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.Db.HistoryData>>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantContact/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Catalog {
            export namespace IService {


                @Injectable()
                export class AggregatorController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/aggregator`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>>;
                    }

                    public Filter(pageSize: number, page: number, id: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>> {
                        const args = [new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.Aggregator> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.Aggregator>;
                    }

                    public GetByType(type: Honeycomb.Common.Enums.AggregatorType): Observable<Honeycomb.Tenant.Catalog.IService.AggregatorSettings> {
                        const args = [new RequestParam('type', RequestParamType.ROUTE, type)];
                        const requestParams = new RequestParams('settings/{type}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.AggregatorSettings>;
                    }

                    public GetHeurekaSettings(): Observable<Honeycomb.Tenant.Catalog.IService.HeurekaSettingsFull> {
                        const args = [];
                        const requestParams = new RequestParams('settings/heureka', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.HeurekaSettingsFull>;
                    }

                    public GetAllPublicSettings(): Observable<{ [key: string]: Honeycomb.Tenant.Catalog.IService.AggregatorSettings }> {
                        const args = [];
                        const requestParams = new RequestParams('settings/public', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: Honeycomb.Tenant.Catalog.IService.AggregatorSettings }>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>>;
                    }

                    public Post(value: Honeycomb.Tenant.Catalog.IService.Aggregator): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.BODY, value)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, attribute: Honeycomb.Tenant.Catalog.IService.Aggregator): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attribute', RequestParamType.BODY, attribute)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class BackgroundController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/background`;

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.Background> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.Background>;
                    }

                    public Post(model: Honeycomb.Tenant.Catalog.IService.Background): Observable<number> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public Put(id: number, model: Honeycomb.Tenant.Catalog.IService.Background): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class BookingController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/booking`;

                    public Create(booking: Honeycomb.Tenant.Catalog.IService.Booking): Observable<any> {
                        const args = [new RequestParam('booking', RequestParamType.ROUTE, booking)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Cancel(uid: string): Observable<any> {
                        const args = [new RequestParam('uid', RequestParamType.ROUTE, uid)];
                        const requestParams = new RequestParams('{uid}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CancelMany(uids: Array<string>): Observable<any> {
                        const args = [new RequestParam('uids', RequestParamType.ROUTE, uids)];
                        const requestParams = new RequestParams('', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(occurenceId: number): Observable<any> {
                        const args = [new RequestParam('occurenceId', RequestParamType.ROUTE, occurenceId)];
                        const requestParams = new RequestParams('{occurenceId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Expire(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('expire', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Postpone(uids: Array<string>): Observable<any> {
                        const args = [new RequestParam('uids', RequestParamType.ROUTE, uids)];
                        const requestParams = new RequestParams('postpone', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Check(uids: Array<string>): Observable<any> {
                        const args = [new RequestParam('uids', RequestParamType.ROUTE, uids)];
                        const requestParams = new RequestParams('check', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Confirm(bookings: Array<Honeycomb.Tenant.Catalog.IService.Booking>): Observable<any> {
                        const args = [new RequestParam('bookings', RequestParamType.ROUTE, bookings)];
                        const requestParams = new RequestParams('confirm', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class CatalogImportExportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/CatalogImportExport`;

                    public Import(request: Honeycomb.Common.ImportExport.Model.ImportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                    }

                    public Export(request: Honeycomb.Common.ImportExport.Model.ExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ExportResult>;
                    }

                    public DialogModel(interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                        const args = [new RequestParam('interfaceModel', RequestParamType.BODY, interfaceModel)];
                        const requestParams = new RequestParams('dialogModel', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                    }
                }

                @Injectable()
                export class CategoryAttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/CategoryAttributeTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable>>;
                    }

                    public Filter(pageSize: number, page: number, id: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable>> {
                        const args = [new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable>>;
                    }

                    public Post(value: Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.BODY, value)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, attribute: Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attribute', RequestParamType.BODY, attribute)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class CategoryController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/category`;

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.CategorySerializable> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.CategorySerializable>;
                    }

                    public GetList(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('getList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>>;
                    }

                    public ByProductIDs(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductCategory>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('ByProductIDs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductCategory>>;
                    }

                    public GetByCode(rootCode: string, code: string): Observable<Honeycomb.Tenant.Catalog.IService.CategorySerializable> {
                        const args = [new RequestParam('rootCode', RequestParamType.ROUTE, rootCode), new RequestParam('code', RequestParamType.ROUTE, code)];
                        const requestParams = new RequestParams('code/{rootCode}/{code}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.CategorySerializable>;
                    }

                    public GetRoot(rootCode: string): Observable<Honeycomb.Tenant.Catalog.IService.CategorySerializable> {
                        const args = [new RequestParam('rootCode', RequestParamType.ROUTE, rootCode)];
                        const requestParams = new RequestParams('code/{rootCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.CategorySerializable>;
                    }

                    public GetCount(id: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryProductCount>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('count/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategoryProductCount>>;
                    }

                    public Select(filter: Honeycomb.Common.SearchBase): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('Select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ByLevel(lang: string, level: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>> {
                        const args = [new RequestParam('lang', RequestParamType.QUERY, lang), new RequestParam('level', RequestParamType.ROUTE, level)];
                        const requestParams = new RequestParams('ByLevel/{level}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>>;
                    }

                    public SelectForSiteMap(recVersion: number, categoryRoot: string, langIsoCode: string): Observable<Array<Honeycomb.Common.ViewModels.SiteMapDataModel>> {
                        const args = [new RequestParam('recVersion', RequestParamType.QUERY, recVersion), new RequestParam('categoryRoot', RequestParamType.QUERY, categoryRoot), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('forSiteMap/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.ViewModels.SiteMapDataModel>>;
                    }

                    public GetDescendants(id: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('from/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>>;
                    }

                    public GetAncestors(id: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('to/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>>;
                    }

                    public GetMenuJson(rootCode: string): Observable<Honeycomb.Tenant.Catalog.IService.MenuCategoriesList> {
                        const args = [new RequestParam('rootCode', RequestParamType.ROUTE, rootCode)];
                        const requestParams = new RequestParams('menu/{rootCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.MenuCategoriesList>;
                    }

                    public UpdateMenu(id: number, model: Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public InsertCategories(model: Honeycomb.Tenant.Catalog.IService.CategorySerializable): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('insertCategories', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public InsertCategoryTree(model: Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('InsertCategoryTree', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertCategoryTree(rootCategoryCode: string, model: Array<Honeycomb.Tenant.Catalog.IService.CategorySerializable>): Observable<any> {
                        const args = [new RequestParam('rootCategoryCode', RequestParamType.ROUTE, rootCategoryCode), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('UpsertCategoryTree/{rootCategoryCode}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Insert(model: Honeycomb.Tenant.Catalog.IService.CategorySerializable): Observable<number> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Catalog.IService.CategorySerializable): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SyncMenu(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('menu/sync', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SyncCategories(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('sync', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ElasticSyncCategories(timestamp: number): Observable<any> {
                        const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                        const requestParams = new RequestParams('ElasticSyncCategories/{timestamp}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class CategoryImportExportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/CategoryImportExport`;

                    public Import(request: Honeycomb.Common.ImportExport.Model.ImportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                    }

                    public Export(request: Honeycomb.Common.ImportExport.Model.ExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ExportResult>;
                    }

                    public DialogModel(interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                        const args = [new RequestParam('interfaceModel', RequestParamType.BODY, interfaceModel)];
                        const requestParams = new RequestParams('dialogModel', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                    }
                }

                @Injectable()
                export class EventSourceController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/eventsource`;

                    public Filter(searchString: string, pageSize: number, page: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.EventSource>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString), new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.EventSource>>;
                    }

                    public SyncAll(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('syncall', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.EventSource> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.EventSource>;
                    }

                    public Post(eventSource: Honeycomb.Tenant.Catalog.IService.EventSource): Observable<number> {
                        const args = [new RequestParam('eventSource', RequestParamType.BODY, eventSource)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public GenerateTerms(scheduler: Honeycomb.Tenant.Catalog.IService.Scheduler): Observable<Array<Honeycomb.Tenant.Catalog.IService.Occurence>> {
                        const args = [new RequestParam('scheduler', RequestParamType.BODY, scheduler)];
                        const requestParams = new RequestParams('terms', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Occurence>>;
                    }

                    public Put(id: number, eventSource: Honeycomb.Tenant.Catalog.IService.EventSource): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('eventSource', RequestParamType.BODY, eventSource)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class FulltextController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/fulltext`;

                    public Filter(filter: Honeycomb.Tenant.Catalog.IService.ProductServiceFilter): Observable<Honeycomb.Tenant.Catalog.IService.ProductListItemsResult> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('filter', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductListItemsResult>;
                    }

                    public Suggest(userInput: string, cfg: string, maxCount: number, from: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.Suggestion>> {
                        const args = [new RequestParam('userInput', RequestParamType.ROUTE, userInput), new RequestParam('cfg', RequestParamType.ROUTE, cfg), new RequestParam('maxCount', RequestParamType.ROUTE, maxCount), new RequestParam('from', RequestParamType.ROUTE, from)];
                        const requestParams = new RequestParams('suggest/{userInput}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Suggestion>>;
                    }

                    public SuggestTypes(request: Honeycomb.Tenant.Catalog.IService.SuggestData): Observable<Honeycomb.Tenant.Catalog.IService.SuggestData> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('suggestTypes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.SuggestData>;
                    }

                    public SuggestProducts(maxCount: number, userInput: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductListItem>> {
                        const args = [new RequestParam('maxCount', RequestParamType.QUERY, maxCount), new RequestParam('userInput', RequestParamType.ROUTE, userInput)];
                        const requestParams = new RequestParams('suggestProducts/{userInput}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductListItem>>;
                    }

                    public Select(productNumber: string): Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic> {
                        const args = [new RequestParam('productNumber', RequestParamType.ROUTE, productNumber)];
                        const requestParams = new RequestParams('{productNumber}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic>;
                    }

                    public Search(filter: Honeycomb.Common.SearchBase): Observable<Honeycomb.Tenant.Catalog.IService.ProductSearchResult> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('search', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductSearchResult>;
                    }

                    public CreateProductIDsTempTable(filter: Honeycomb.Common.Elastic.ElasticFilterRequest): Observable<Honeycomb.Common.ViewModels.BulkInsertTableResult> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('createProductIDsTempTable', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ViewModels.BulkInsertTableResult>;
                    }

                    public SearchForTemplateGrouped(model: Honeycomb.Tenant.Catalog.IService.ProductSearchForTemplateModel): Observable<Honeycomb.Tenant.Catalog.IService.CategoryWithProductsList> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('searchForTemplateGrouped', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.CategoryWithProductsList>;
                    }

                    public SearchForTemplate(model: Honeycomb.Tenant.Catalog.IService.ProductSearchForTemplateModel): Observable<Honeycomb.Tenant.Catalog.IService.ListOfProducts> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('searchForTemplate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ListOfProducts>;
                    }

                    public GetProductsInProductSet(filter: Honeycomb.Tenant.Catalog.IService.SearchProductsInProductSet): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('getProductsInProductSet', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>>;
                    }
                }

                @Injectable()
                export class OccurenceController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/occurence`;

                    public Filter(filter: Honeycomb.Tenant.Catalog.IService.OccurenceFilter): Observable<Array<Honeycomb.Tenant.Catalog.IService.OccurenceView>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.OccurenceView>>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Catalog.IService.OccurenceView> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.OccurenceView>;
                    }

                    public LocationList(): Observable<Array<any>> {
                        const args = [];
                        const requestParams = new RequestParams('locations', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                    }

                    public UpdateStatus(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('updatestatus', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProductAttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/ProductAttributeTypes`;

                    public GetList(): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeType>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeType>>;
                    }

                    public GetListMeanings(): Observable<{ [key: number]: string }> {
                        const args = [];
                        const requestParams = new RequestParams('Meanings', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: string }>;
                    }

                    public Filter(pageSize: number, page: number, id: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeType>> {
                        const args = [new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeType>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.ProductAttributeType> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductAttributeType>;
                    }

                    public List(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeType>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('List', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeType>>;
                    }

                    public Post(value: Honeycomb.Tenant.Catalog.IService.ProductAttributeType): Observable<any> {
                        const args = [new RequestParam('value', RequestParamType.BODY, value)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public EnsureEditionAttributes(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('EnsureEditionAttributes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, attribute: Honeycomb.Tenant.Catalog.IService.ProductAttributeType): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('attribute', RequestParamType.BODY, attribute)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProductController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/product`;

                    public Select(id: number): Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic>;
                    }

                    public SelectByNumber(category: string, includeHidden: boolean, productNumber: string): Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic> {
                        const args = [new RequestParam('category', RequestParamType.QUERY, category), new RequestParam('includeHidden', RequestParamType.QUERY, includeHidden), new RequestParam('productNumber', RequestParamType.ROUTE, productNumber)];
                        const requestParams = new RequestParams('byNumber/{productNumber}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic>;
                    }

                    public SelectByGTIN(gtin: string): Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic> {
                        const args = [new RequestParam('gtin', RequestParamType.ROUTE, gtin)];
                        const requestParams = new RequestParams('byGTIN/{gtin}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductElastic>;
                    }

                    public SecondaryProduct(id: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('secondaryProducts/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>>;
                    }

                    public DetailAdmin(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('admin/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RootRelevantSettings(id: number, keys: Array<string>): Observable<{ [key: number]: any }> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('keys', RequestParamType.BODY, keys)];
                        const requestParams = new RequestParams('rootrelevant/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: any }>;
                    }

                    public SelectMany(productIds: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.Product>> {
                        const args = [new RequestParam('productIds', RequestParamType.BODY, productIds)];
                        const requestParams = new RequestParams('list', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Product>>;
                    }

                    public SelectImages(productIds: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductImage>> {
                        const args = [new RequestParam('productIds', RequestParamType.BODY, productIds)];
                        const requestParams = new RequestParams('listImages', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductImage>>;
                    }

                    public SelectImagesByNumbers(productNumbers: Array<string>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('productNumbers', RequestParamType.BODY, productNumbers)];
                        const requestParams = new RequestParams('listImagesByNumbers', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public UpdateImagesFromExternalURIs(request: Honeycomb.Tenant.Catalog.IService.ProductImportImagesRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('UpdateImagesFromExternalURIs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SelectProductsByNumbers(productNumbers: Array<string>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('productNumbers', RequestParamType.BODY, productNumbers)];
                        const requestParams = new RequestParams('productsByNumbers', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public SelectFullProductsByNumbers(productNumbers: Array<string>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>> {
                        const args = [new RequestParam('productNumbers', RequestParamType.BODY, productNumbers)];
                        const requestParams = new RequestParams('productsFullByNumbers', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>>;
                    }

                    public SelectFullProductsByIDs(userUID: string, productIDs: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>> {
                        const args = [new RequestParam('userUID', RequestParamType.QUERY, userUID), new RequestParam('productIDs', RequestParamType.BODY, productIDs)];
                        const requestParams = new RequestParams('productsFullByIDs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>>;
                    }

                    public SelectProductsFullByTimestamp(topCount: number, timestamp: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('topCount', RequestParamType.QUERY, topCount), new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                        const requestParams = new RequestParams('productsFullByTimestamp/{timestamp}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public SelectProductsForPresents(productNumbers: Array<string>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('productNumbers', RequestParamType.BODY, productNumbers)];
                        const requestParams = new RequestParams('productsForPresents', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public SelectAttributesDistinctValues(useCodeLists: boolean, attributeTypeNames: Array<string>): Observable<Array<Honeycomb.Tenant.Catalog.IService.AttributeDistinctValues>> {
                        const args = [new RequestParam('useCodeLists', RequestParamType.QUERY, useCodeLists), new RequestParam('attributeTypeNames', RequestParamType.BODY, attributeTypeNames)];
                        const requestParams = new RequestParams('selectAttributesDistinctValues', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.AttributeDistinctValues>>;
                    }

                    public SelectProductFillterAttributesDistinctValues(useCodeLists: boolean): Observable<Array<Honeycomb.Tenant.Catalog.IService.AttributeDistinctValues>> {
                        const args = [new RequestParam('useCodeLists', RequestParamType.QUERY, useCodeLists)];
                        const requestParams = new RequestParams('selectProductFillterAttributesDistinctValues', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.AttributeDistinctValues>>;
                    }

                    public GetRelationParentProducts(id: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('getRelationParentProducts/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public SetRelationParentProducts(request: Honeycomb.Tenant.Catalog.IService.SetRelationParentProductsRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('setRelationParentProducts', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SelectAll(): Observable<Array<Honeycomb.Tenant.Catalog.IService.Product>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Product>>;
                    }

                    public SelectForSiteMap(recVersion: number, categoryRoot: string, langIsoCode: string): Observable<Array<Honeycomb.Common.ViewModels.SiteMapDataModel>> {
                        const args = [new RequestParam('recVersion', RequestParamType.QUERY, recVersion), new RequestParam('categoryRoot', RequestParamType.QUERY, categoryRoot), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('forSiteMap/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.ViewModels.SiteMapDataModel>>;
                    }

                    public SiteMapImages(recVersion: number, langIsoCode: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.SiteMapImagesModel>> {
                        const args = [new RequestParam('recVersion', RequestParamType.QUERY, recVersion), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('siteMapImages/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.SiteMapImagesModel>>;
                    }

                    public CodeListValues(): Observable<{ [key: number]: any }> {
                        const args = [];
                        const requestParams = new RequestParams('codelistvalues', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: any }>;
                    }

                    public Insert(model: Honeycomb.Tenant.Catalog.IService.ProductSerializable): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Update(model: Honeycomb.Tenant.Catalog.IService.ProductSerializable): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetVariables(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('variables', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ConvertNumberToID(list: Array<Honeycomb.Tenant.Catalog.IService.ConvertProductNumberBase>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ConvertProductVariantIDBase>> {
                        const args = [new RequestParam('list', RequestParamType.BODY, list)];
                        const requestParams = new RequestParams('ConvertNumberToID', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ConvertProductVariantIDBase>>;
                    }

                    public ConvertIDToNumber(list: Array<Honeycomb.Tenant.Catalog.IService.ConvertProductVariantIDBase>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ConvertProductNumberBase>> {
                        const args = [new RequestParam('list', RequestParamType.BODY, list)];
                        const requestParams = new RequestParams('ConvertIDToNumber', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ConvertProductNumberBase>>;
                    }

                    public Batch(products: Array<Honeycomb.Tenant.Catalog.IService.ProductImported>): Observable<any> {
                        const args = [new RequestParam('products', RequestParamType.BODY, products)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ImportStockByNumber(imported: Array<Honeycomb.Tenant.Catalog.IService.TransTypeByNumber>): Observable<Array<number>> {
                        const args = [new RequestParam('imported', RequestParamType.BODY, imported)];
                        const requestParams = new RequestParams('importStockByNumber', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                    }

                    public ImportStockByID(imported: Array<Honeycomb.Tenant.Catalog.IService.TransTypeByID>): Observable<Array<number>> {
                        const args = [new RequestParam('imported', RequestParamType.BODY, imported)];
                        const requestParams = new RequestParams('importStockByID', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<number>>;
                    }

                    public SyncAll(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('sync', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SyncProduct(productNumber: string): Observable<any> {
                        const args = [new RequestParam('productNumber', RequestParamType.ROUTE, productNumber)];
                        const requestParams = new RequestParams('sync/{productNumber}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ElasticSyncProductsByNumber(productNumbers: Array<string>): Observable<any> {
                        const args = [new RequestParam('productNumbers', RequestParamType.BODY, productNumbers)];
                        const requestParams = new RequestParams('ElasticSyncProductsByNumber', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public EnsureImageThumbnails(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('ensureImageThumbnails', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ElasticSyncProducts(timestamp: number): Observable<any> {
                        const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                        const requestParams = new RequestParams('ElasticSyncProducts/{timestamp}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ElasticSyncStock(timestamp: number): Observable<any> {
                        const args = [new RequestParam('timestamp', RequestParamType.ROUTE, timestamp)];
                        const requestParams = new RequestParams('ElasticSyncStock/{timestamp}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetDbStatus(): Observable<Honeycomb.Tenant.Catalog.IService.CatalogDBStatus> {
                        const args = [];
                        const requestParams = new RequestParams('dbStatus', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.CatalogDBStatus>;
                    }

                    public SearchForTemplateGrouped(model: Honeycomb.Tenant.Catalog.IService.ProductSearchForTemplateModel): Observable<Honeycomb.Tenant.Catalog.IService.CategoryWithProductsList> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('searchForTemplateGrouped', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.CategoryWithProductsList>;
                    }

                    public SearchForTemplate(model: Honeycomb.Tenant.Catalog.IService.ProductSearchForTemplateModel): Observable<Honeycomb.Tenant.Catalog.IService.ListOfProducts> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('searchForTemplate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ListOfProducts>;
                    }

                    public SelectProductsByIDsSimple(productIDs: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('productIDs', RequestParamType.BODY, productIDs)];
                        const requestParams = new RequestParams('productsByIDsSimple', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public SearchProductsWithAttributes(request: Honeycomb.Tenant.Catalog.IService.ProductAttributesSearchRequest): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('searchProductsWithAttributes', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public GetLastTimestamp(): Observable<number> {
                        const args = [];
                        const requestParams = new RequestParams('lastTimeStamp', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public GetProductRatings(productID: number, maxCount: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductRating>> {
                        const args = [new RequestParam('productID', RequestParamType.ROUTE, productID), new RequestParam('maxCount', RequestParamType.ROUTE, maxCount)];
                        const requestParams = new RequestParams('ratings/{productID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductRating>>;
                    }

                    public AddRating(rating: Honeycomb.Tenant.Catalog.IService.ProductRating): Observable<any> {
                        const args = [new RequestParam('rating', RequestParamType.BODY, rating)];
                        const requestParams = new RequestParams('addRating', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateRating(rating: Honeycomb.Tenant.Catalog.IService.ProductRating): Observable<any> {
                        const args = [new RequestParam('rating', RequestParamType.BODY, rating)];
                        const requestParams = new RequestParams('updateRating', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetPublicProductQuestions(productID: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductQuestion>> {
                        const args = [new RequestParam('productID', RequestParamType.ROUTE, productID)];
                        const requestParams = new RequestParams('{productID}/qa', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductQuestion>>;
                    }

                    public GetPublicQuestions(search: string, skip: number, take: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductQuestion>> {
                        const args = [new RequestParam('search', RequestParamType.ROUTE, search), new RequestParam('skip', RequestParamType.ROUTE, skip), new RequestParam('take', RequestParamType.ROUTE, take)];
                        const requestParams = new RequestParams('qa/{skip}/{take}/{search}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductQuestion>>;
                    }

                    public GetProductQuestion(productQuestionID: number): Observable<Honeycomb.Tenant.Catalog.IService.ProductQuestion> {
                        const args = [new RequestParam('productQuestionID', RequestParamType.ROUTE, productQuestionID)];
                        const requestParams = new RequestParams('qa/{productQuestionID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductQuestion>;
                    }

                    public GetAllQuestions(skip: number, take: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductQuestion>> {
                        const args = [new RequestParam('skip', RequestParamType.ROUTE, skip), new RequestParam('take', RequestParamType.ROUTE, take)];
                        const requestParams = new RequestParams('qa/all/{skip}/{take}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductQuestion>>;
                    }

                    public AddQuestion(question: Honeycomb.Tenant.Catalog.IService.ProductQuestion): Observable<any> {
                        const args = [new RequestParam('question', RequestParamType.BODY, question)];
                        const requestParams = new RequestParams('qa', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateQuestion(question: Honeycomb.Tenant.Catalog.IService.ProductQuestion): Observable<any> {
                        const args = [new RequestParam('question', RequestParamType.BODY, question)];
                        const requestParams = new RequestParams('qa', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProductDiscountController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/productDiscount`;

                    public GetDiscountTypes(): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductDiscountType>> {
                        const args = [];
                        const requestParams = new RequestParams('discountTypes', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductDiscountType>>;
                    }

                    public GetDiscountType(id: number): Observable<Honeycomb.Tenant.Catalog.IService.ProductDiscountType> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('discountType/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductDiscountType>;
                    }

                    public SaveDiscountType(discountType: Honeycomb.Tenant.Catalog.IService.ProductDiscountType): Observable<number> {
                        const args = [new RequestParam('discountType', RequestParamType.BODY, discountType)];
                        const requestParams = new RequestParams('discountType', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public GetDiscounts(): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductDiscount>> {
                        const args = [];
                        const requestParams = new RequestParams('discounts', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductDiscount>>;
                    }

                    public GetDiscount(id: number): Observable<Honeycomb.Tenant.Catalog.IService.ProductDiscount> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('productDiscount/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductDiscount>;
                    }

                    public SaveDiscount(discountType: Honeycomb.Tenant.Catalog.IService.ProductDiscount): Observable<number> {
                        const args = [new RequestParam('discountType', RequestParamType.BODY, discountType)];
                        const requestParams = new RequestParams('discount', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public ImportDiscounts(discount: Honeycomb.Tenant.Catalog.IService.ProductDiscount): Observable<any> {
                        const args = [new RequestParam('discount', RequestParamType.BODY, discount)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteProductDiscount(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('productDiscount/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteProductDiscountType(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('productDiscountType/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SuggestType(maxCount: number, userInput: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetOverview>> {
                        const args = [new RequestParam('maxCount', RequestParamType.QUERY, maxCount), new RequestParam('userInput', RequestParamType.ROUTE, userInput)];
                        const requestParams = new RequestParams('suggestType/{userInput}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetOverview>>;
                    }
                }

                @Injectable()
                export class ProductFeedController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/productfeed`;

                    public GetTenantXmlFeed(tenantHash: string, aggregator: Honeycomb.Common.Enums.AggregatorType): Observable<string> {
                        const args = [new RequestParam('tenantHash', RequestParamType.ROUTE, tenantHash), new RequestParam('aggregator', RequestParamType.ROUTE, aggregator)];
                        const requestParams = new RequestParams('{tenantHash}/{aggregator}/feed.xml', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public GetXmlFeed(tenantHash: string, companyId: number, aggregator: Honeycomb.Common.Enums.AggregatorType): Observable<string> {
                        const args = [new RequestParam('tenantHash', RequestParamType.ROUTE, tenantHash), new RequestParam('companyId', RequestParamType.ROUTE, companyId), new RequestParam('aggregator', RequestParamType.ROUTE, aggregator)];
                        const requestParams = new RequestParams('{tenantHash}/{companyId}/{aggregator}/feed.xml', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public UpsertXmlFeed(companyId: number, aggregator: Honeycomb.Common.Enums.AggregatorType): Observable<any> {
                        const args = [new RequestParam('companyId', RequestParamType.QUERY, companyId), new RequestParam('aggregator', RequestParamType.ROUTE, aggregator)];
                        const requestParams = new RequestParams('{aggregator}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProductSetController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/productSet`;

                    public List(activeOnly: boolean): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSet>> {
                        const args = [new RequestParam('activeOnly', RequestParamType.QUERY, activeOnly)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSet>>;
                    }

                    public GetProductSets(filter: Honeycomb.Common.SearchBase): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetOverview>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('GetProductSets', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetOverview>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.ProductSet> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductSet>;
                    }

                    public ByProductIDs(productIDs: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetWhiteList>> {
                        const args = [new RequestParam('productIDs', RequestParamType.BODY, productIDs)];
                        const requestParams = new RequestParams('ByProductIDs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetWhiteList>>;
                    }

                    public GetList(ids: Array<number>): Observable<any> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('GetList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Post(productSet: Honeycomb.Tenant.Catalog.IService.ProductSet): Observable<any> {
                        const args = [new RequestParam('productSet', RequestParamType.BODY, productSet)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateProductSetOnFulltext(filter: Honeycomb.Tenant.Catalog.IService.CreateProductSetFulltextRequest): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('CreateProductSetOnFulltext', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public AddToWhiteListFulltext(request: Honeycomb.Tenant.Catalog.IService.AddToProductSetFulltextRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('AddToWhiteListFulltext', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CreateProductSet(request: Honeycomb.Tenant.Catalog.IService.CreateProductSetRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('CreateProductSet', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public AddToWhiteList(request: Honeycomb.Tenant.Catalog.IService.AddToProductSetRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('AddToWhiteList', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ImportWhiteList(request: Honeycomb.Tenant.Catalog.IService.AddToProductSetByNumberRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('ImportWhiteList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RemoveWhiteListMember(productSetId: number, productId: number): Observable<any> {
                        const args = [new RequestParam('productSetId', RequestParamType.ROUTE, productSetId), new RequestParam('productId', RequestParamType.ROUTE, productId)];
                        const requestParams = new RequestParams('RemoveWhiteListMember/{productSetId}/{productId}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public RemoveWhiteListMembers(productSetID: number, productIDs: Array<number>): Observable<any> {
                        const args = [new RequestParam('productSetID', RequestParamType.ROUTE, productSetID), new RequestParam('productIDs', RequestParamType.BODY, productIDs)];
                        const requestParams = new RequestParams('RemoveWhiteListMembers/{productSetID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ClearWhiteList(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('ClearWhiteList/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Suggest(maxCount: number, userInput: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetOverview>> {
                        const args = [new RequestParam('maxCount', RequestParamType.QUERY, maxCount), new RequestParam('userInput', RequestParamType.ROUTE, userInput)];
                        const requestParams = new RequestParams('suggest/{userInput}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSetOverview>>;
                    }
                }

                @Injectable()
                export class ProductStockController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/productStock`;

                    public ProductStock(formulaId: number, locationId: number, id: number): Observable<Array<Honeycomb.Common.ViewModels.ProductStock>> {
                        const args = [new RequestParam('formulaId', RequestParamType.QUERY, formulaId), new RequestParam('locationId', RequestParamType.QUERY, locationId), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('product/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.ViewModels.ProductStock>>;
                    }

                    public ProductVariantStock(formulaId: number, locationId: number, id: number): Observable<Array<Honeycomb.Common.ViewModels.ProductVariantStock>> {
                        const args = [new RequestParam('formulaId', RequestParamType.QUERY, formulaId), new RequestParam('locationId', RequestParamType.QUERY, locationId), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('productVariant/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.ViewModels.ProductVariantStock>>;
                    }

                    public ProductListStock(request: Honeycomb.Tenant.Catalog.IService.ProductStockRequest): Observable<Array<Honeycomb.Common.ViewModels.ProductStock>> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('productList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.ViewModels.ProductStock>>;
                    }

                    public ProductVariantListStock(request: Honeycomb.Tenant.Catalog.IService.ProductStockRequest): Observable<Array<Honeycomb.Common.ViewModels.ProductVariantStock>> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('productVariantList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.ViewModels.ProductVariantStock>>;
                    }

                    public UpdateStockInElastic(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('syncAll', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProductVariableController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/productvariable`;

                    public Filter(searchString: string, pageSize: number, page: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString), new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable>;
                    }

                    public Post(productVariable: Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable): Observable<any> {
                        const args = [new RequestParam('productVariable', RequestParamType.BODY, productVariable)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Put(id: number, productVariable: Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('productVariable', RequestParamType.BODY, productVariable)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ProductVariantController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/ProductVariant`;

                    public FindItems(variantIDs: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('variantIDs', RequestParamType.BODY, variantIDs)];
                        const requestParams = new RequestParams('FindItems', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public FindItemsForTransaction(userID: number, variantIDs: Array<number>): Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>> {
                        const args = [new RequestParam('userID', RequestParamType.QUERY, userID), new RequestParam('variantIDs', RequestParamType.BODY, variantIDs)];
                        const requestParams = new RequestParams('FindItemsForTransaction', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>>;
                    }

                    public FindCategories(variantIDs: Array<number>): Observable<{ [key: number]: any }> {
                        const args = [new RequestParam('variantIDs', RequestParamType.BODY, variantIDs)];
                        const requestParams = new RequestParams('FindCategories', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: number]: any }>;
                    }
                }

                @Injectable()
                export class SlotPlanController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/slotplan`;

                    public Filter(searchString: string, pageSize: number, page: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.SlotPlan>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString), new RequestParam('pageSize', RequestParamType.QUERY, pageSize), new RequestParam('page', RequestParamType.QUERY, page)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.SlotPlan>>;
                    }

                    public Get(id: number): Observable<Honeycomb.Tenant.Catalog.IService.SlotPlan> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Catalog.IService.SlotPlan>;
                    }

                    public Post(slotplan: Honeycomb.Tenant.Catalog.IService.SlotPlan): Observable<number> {
                        const args = [new RequestParam('slotplan', RequestParamType.BODY, slotplan)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public Put(id: number, slotplan: Honeycomb.Tenant.Catalog.IService.SlotPlan): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('slotplan', RequestParamType.BODY, slotplan)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TaxonomyController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/taxonomy`;

                    public SelectAll(): Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>>;
                    }

                    public ForCategory(id: number): Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('forCategory/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>>;
                    }

                    public SelectAllSections(): Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Aggregator>>;
                    }

                    public Select(aggregator: string): Observable<Array<Honeycomb.Tenant.Catalog.IService.Taxonomy>> {
                        const args = [new RequestParam('aggregator', RequestParamType.ROUTE, aggregator)];
                        const requestParams = new RequestParams('{aggregator}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Catalog.IService.Taxonomy>>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Catalog {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantCatalog/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {


                @Injectable()
                export class AdminImportExportController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/AdminImportExport`;

                    public Import(request: Honeycomb.Tenant.Admin.IService.AdminImportExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                    }

                    public Export(request: Honeycomb.Tenant.Admin.IService.AdminImportExportRequest): Observable<Honeycomb.Tenant.Admin.IService.AdminExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.AdminExportResult>;
                    }

                    public DialogModel(interfaceModelID: number): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                        const args = [new RequestParam('interfaceModelID', RequestParamType.ROUTE, interfaceModelID)];
                        const requestParams = new RequestParams('dialogModel/{interfaceModelID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                    }

                    public Download(FileType: string, FileName: string, id: string): Observable<any> {
                        const args = [new RequestParam('FileType', RequestParamType.QUERY, FileType), new RequestParam('FileName', RequestParamType.QUERY, FileName), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('download/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class AdminLocalizationController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/localization`;

                    public Update(model: Honeycomb.LookupTables.IService.Model.Localization): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateList(model: Honeycomb.LookupTables.IService.Model.LocalizationList): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('updateList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string, pageSize: number): Observable<any> {
                        const args = [new RequestParam('searchString', RequestParamType.ROUTE, searchString), new RequestParam('pageSize', RequestParamType.ROUTE, pageSize)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Culture(langIsoCode: string): Observable<any> {
                        const args = [new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('Culture/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CultureShort(noCache: boolean, langIsoCode: string): Observable<any> {
                        const args = [new RequestParam('noCache', RequestParamType.QUERY, noCache), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('CultureShort/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SupportedLanguages(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('SupportedLanguages', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public I18n(lang: string): Observable<any> {
                        const args = [new RequestParam('lang', RequestParamType.ROUTE, lang)];
                        const requestParams = new RequestParams('tasker/i18n/{lang}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class AttributeTypesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/AttributeTypes`;

                    public List(attribute: Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.AttributeDataTable> {
                        const args = [new RequestParam('attribute', RequestParamType.ROUTE, attribute)];
                        const requestParams = new RequestParams('list/{attribute}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.AttributeDataTable>;
                    }

                    public Detail(attribute: Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum, id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.AttributeDataTable> {
                        const args = [new RequestParam('attribute', RequestParamType.ROUTE, attribute), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{attribute}/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.AttributeDataTable>;
                    }

                    public Insert(attribute: Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum, data: any): Observable<any> {
                        const args = [new RequestParam('attribute', RequestParamType.ROUTE, attribute), new RequestParam('data', RequestParamType.BODY, data)];
                        const requestParams = new RequestParams('{attribute}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Update(attribute: Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum, id: number, data: any): Observable<any> {
                        const args = [new RequestParam('attribute', RequestParamType.ROUTE, attribute), new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('data', RequestParamType.BODY, data)];
                        const requestParams = new RequestParams('{attribute}/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(attribute: Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum, id: number): Observable<any> {
                        const args = [new RequestParam('attribute', RequestParamType.ROUTE, attribute), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{attribute}/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class CompanyController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/company`;

                    public Save(model: Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel>;
                    }

                    public GetActive(): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel> {
                        const args = [];
                        const requestParams = new RequestParams('getActive', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel>;
                    }

                    public GetActiveObject(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('getActiveObject', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetCompanies(filter: Honeycomb.Common.SearchBase): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('getCompanies', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel>>;
                    }

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel>> {
                        const args = [];
                        const requestParams = new RequestParams('listAll', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.CompanyViewModel>>;
                    }
                }

                @Injectable()
                export class ComponentController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/component`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.ComponentDetailViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.ROUTE, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentDetailViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentDetailViewModel>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentDetailViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentDetailViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Admin.IService.ViewModels.ComponentDetailViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ComponentPageController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/ComponentPage`;

                    public GetComponentPage(configurationName: string): Observable<any> {
                        const args = [new RequestParam('configurationName', RequestParamType.QUERY, configurationName)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class ConfigurationController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/configuration`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.ConfigurationAdminViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentConfigurationViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentConfigurationViewModel>;
                    }

                    public DetailAdmin(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ConfigurationAdminViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detailAdmin/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ConfigurationAdminViewModel>;
                    }

                    public DetailByName(name: string, includeSkin: boolean): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentConfigurationDetailViewModel> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name), new RequestParam('includeSkin', RequestParamType.ROUTE, includeSkin)];
                        const requestParams = new RequestParams('detailByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentConfigurationDetailViewModel>;
                    }

                    public VueJSDetailByName(name: string, includeSkin: boolean): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.VuejsComponentConfigurationModel> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name), new RequestParam('includeSkin', RequestParamType.ROUTE, includeSkin)];
                        const requestParams = new RequestParams('vuejsDetailByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.VuejsComponentConfigurationModel>;
                    }

                    public UpdateDynamicComponent(request: Honeycomb.Tenant.Admin.IService.ViewModels.VueComponentUpdateRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('updateDynamicComponent', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetDynamicComponent(name: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDynamicEdit> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('getDynamicComponent/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDynamicEdit>;
                    }

                    public UpdateDynamicComponentFromGit(apiKey: string, branch: string, name: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDynamicEdit> {
                        const args = [new RequestParam('apiKey', RequestParamType.QUERY, apiKey), new RequestParam('branch', RequestParamType.QUERY, branch), new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('updateDynamicComponentFromGit/{name}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDynamicEdit>;
                    }

                    public GetComponentGitStatus(apiKey: string, branch: string, name: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubComponentStatusResponse> {
                        const args = [new RequestParam('apiKey', RequestParamType.QUERY, apiKey), new RequestParam('branch', RequestParamType.QUERY, branch), new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('getComponentGitStatus/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubComponentStatusResponse>;
                    }

                    public GetVueComponentSource(apiKey: string, branch: string, name: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantSourceEdit> {
                        const args = [new RequestParam('apiKey', RequestParamType.QUERY, apiKey), new RequestParam('branch', RequestParamType.QUERY, branch), new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('getVueComponentSource/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantSourceEdit>;
                    }

                    public UpdateVueComponent(request: Honeycomb.Tenant.Admin.IService.ViewModels.VueComponentUpdateRequest): Observable<any> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('updateVueComponent', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateVueComponentFromGit(apiKey: string, branch: string, name: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDynamicEdit> {
                        const args = [new RequestParam('apiKey', RequestParamType.QUERY, apiKey), new RequestParam('branch', RequestParamType.QUERY, branch), new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('updateVueComponentFromGit/{name}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDynamicEdit>;
                    }

                    public ServerParams(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('serverparams/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentConfigurationViewModel>> {
                        const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentConfigurationViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Admin.IService.ViewModels.ConfigurationAdminViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CheckName(name: string): Observable<any> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('CheckName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertByName(model: Honeycomb.Tenant.Admin.IService.ViewModels.ConfigurationUpsertModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('UpsertByName', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertBootFile(files: Array<any>): Observable<any> {
                        const args = [new RequestParam('files', RequestParamType.FORM, files)];
                        const requestParams = new RequestParams('BootFile', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class FilterController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/filter`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>;
                    }

                    public List(filter: string, componentType: Honeycomb.Tenant.Admin.IService.Enums.ComponentType): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('componentType', RequestParamType.QUERY, componentType)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>>;
                    }

                    public ListForPurpose(purpose: Honeycomb.Common.Enums.FilterPurpose): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>> {
                        const args = [new RequestParam('purpose', RequestParamType.ROUTE, purpose)];
                        const requestParams = new RequestParams('listForPurpose/{purpose}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>>;
                    }

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>> {
                        const args = [];
                        const requestParams = new RequestParams('listall', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>>;
                    }

                    public ListForIDs(ids: Array<number>): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('listForIDs', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class FilterDialogController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/filterDialog`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel>>;
                    }

                    public Select(filter: Honeycomb.Common.SearchBase): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class GalleryController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/gallery`;
                }

                @Injectable()
                export class GITHubController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/GITHub`;

                    public GetFilesHistory(request: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileHistoryRequest): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileHistoryResponse> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('filesHistory', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileHistoryResponse>;
                    }

                    public GetFilesContent(request: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileContentRequest): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileContentResponse> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('filesContent', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileContentResponse>;
                    }

                    public UpdateFiles(request: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileUpdateRequest): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileUpdateResponse> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('updateFiles', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileUpdateResponse>;
                    }

                    public GetUser(apiKey: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubUser> {
                        const args = [new RequestParam('apiKey', RequestParamType.ROUTE, apiKey)];
                        const requestParams = new RequestParams('getUser/{apiKey}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubUser>;
                    }
                }

                @Injectable()
                export class GoogleAccountController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/googleAccount`;

                    public CheckAccount(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('check', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetAuthorizationUrl(finalUrl: string): Observable<any> {
                        const args = [new RequestParam('finalUrl', RequestParamType.QUERY, finalUrl)];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetProfiles(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('profiles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SelectedProfile(): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GoogleAccount> {
                        const args = [];
                        const requestParams = new RequestParams('profile', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.GoogleAccount>;
                    }

                    public SelectProfile(selectedProfile: string): Observable<any> {
                        const args = [new RequestParam('selectedProfile', RequestParamType.ROUTE, selectedProfile)];
                        const requestParams = new RequestParams('profile/{selectedProfile}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CancelConnection(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class InterfaceController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/interface`;

                    public Update(model: Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel>>;
                    }

                    public ListForAgenda(agenda: Honeycomb.Common.Enums.PortalAgenda): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel>> {
                        const args = [new RequestParam('agenda', RequestParamType.QUERY, agenda)];
                        const requestParams = new RequestParams('listForAgenda', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel>>;
                    }

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel>> {
                        const args = [];
                        const requestParams = new RequestParams('listall', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.InterfaceModel>>;
                    }

                    public GetLastExportInfo(name: string): Observable<Honeycomb.Common.ImportExport.Model.InterfaceLastExportInfo> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('lastExportInfo/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.InterfaceLastExportInfo>;
                    }

                    public SetLastExportInfo(name: string, lastExportInfo: Honeycomb.Common.ImportExport.Model.InterfaceLastExportInfo): Observable<any> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name), new RequestParam('lastExportInfo', RequestParamType.BODY, lastExportInfo)];
                        const requestParams = new RequestParams('lastExportInfo/{name}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class MediaController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/media`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Groups(): Observable<Array<string>> {
                        const args = [];
                        const requestParams = new RequestParams('Groups', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>;
                    }

                    public GetVoucherTemplateAsMedia(voucherTemplateId: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media> {
                        const args = [new RequestParam('voucherTemplateId', RequestParamType.ROUTE, voucherTemplateId)];
                        const requestParams = new RequestParams('getVoucherTemplate/{voucherTemplateId}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>;
                    }

                    public GetVoucherTemplateAsMediaByName(name: string): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('getVoucherTemplateByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.Media>;
                    }

                    public UpdateVoucherTemplateFromMedia(media: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('media', RequestParamType.BODY, media)];
                        const requestParams = new RequestParams('updateVoucherTemplateFromMedia', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>>;
                    }

                    public Clone(model: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('clone', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Post(model: Honeycomb.Tenant.TemplateEngine.IService.Model.Media): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Export(request: Honeycomb.Tenant.Admin.IService.ViewModels.MediaExportRequest): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.MediaExportResult> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('Export', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.MediaExportResult>;
                    }

                    public Import(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Import', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Select(filter: Honeycomb.Tenant.TemplateEngine.IService.ViewModel.MediaListFilter): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetDistributedMediaDetail(distributionBatchItemID: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItem> {
                        const args = [new RequestParam('distributionBatchItemID', RequestParamType.ROUTE, distributionBatchItemID)];
                        const requestParams = new RequestParams('distributedMediaDetail/{distributionBatchItemID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItem>;
                    }

                    public GetDistributedMediaContent(encryptedBatchItemID: string): Observable<any> {
                        const args = [new RequestParam('encryptedBatchItemID', RequestParamType.ROUTE, encryptedBatchItemID)];
                        const requestParams = new RequestParams('distributedMediaContent/{encryptedBatchItemID}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ImportVoucherCodes(templateId: number): Observable<any> {
                        const args = [new RequestParam('templateId', RequestParamType.ROUTE, templateId)];
                        const requestParams = new RequestParams('importVoucherCodes/{templateId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateCreatedVouchersValidity(templateId: number): Observable<any> {
                        const args = [new RequestParam('templateId', RequestParamType.ROUTE, templateId)];
                        const requestParams = new RequestParams('updateCreatedVouchersValidity/{templateId}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class NamedConfigurationsController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/namedConfigurations`;

                    public Save(cfg: Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration): Observable<any> {
                        const args = [new RequestParam('cfg', RequestParamType.BODY, cfg)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>;
                    }

                    public GetAsObject(name: string): Observable<any> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('GetAsObject/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetByName(name: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('GetByName/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>;
                    }

                    public GetByPrefix(prefix: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>> {
                        const args = [new RequestParam('prefix', RequestParamType.ROUTE, prefix)];
                        const requestParams = new RequestParams('GetByPrefix/{prefix}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>>;
                    }

                    public GetByNames(names: Array<string>): Observable<{ [key: string]: any }> {
                        const args = [new RequestParam('names', RequestParamType.BODY, names)];
                        const requestParams = new RequestParams('GetByNames', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: any }>;
                    }

                    public List(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>> {
                        const args = [];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>>;
                    }

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>> {
                        const args = [];
                        const requestParams = new RequestParams('listall', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.NamedConfiguration>>;
                    }
                }

                @Injectable()
                export class NumericSerieController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/numericSerie`;

                    public SaveMask(model: Honeycomb.Tenant.Admin.IService.ViewModels.MaskViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('mask', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('mask/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteMaskAndNumbers(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('maskAndNumbers/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.MaskViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('mask/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.MaskViewModel>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.MaskViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.MaskViewModel>>;
                    }

                    public GetFutureNumber(model: Honeycomb.Tenant.Admin.IService.ViewModels.NumberQueryModel): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NumberResultModel> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('futureNumber', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NumberResultModel>;
                    }

                    public GetFutureNumberEAN(model: Honeycomb.Tenant.Admin.IService.ViewModels.NumberQueryModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('futureNumberEAN', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetFutureNumbers(model: Honeycomb.Tenant.Admin.IService.ViewModels.NumberBatchQueryModel): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NumberBatchResultModel> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('futureNumbers', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.NumberBatchResultModel>;
                    }

                    public GetUserCardMasks(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.MaskViewModel>> {
                        const args = [];
                        const requestParams = new RequestParams('getUserCardMasks', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.MaskViewModel>>;
                    }
                }

                @Injectable()
                export class PortalUserController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/portal-user`;

                    public CreateUser(viewModel: Honeycomb.Tenant.Admin.IService.ViewModels.UserDetailViewModel): Observable<any> {
                        const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateUser(id: number, viewModel: Honeycomb.Tenant.Admin.IService.ViewModels.UserDetailViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Roles(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('roles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Filter(filter: Honeycomb.Tenant.Admin.IService.ViewModels.PortalUserFilterRequest): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.PortalUserShort>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('filter', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.PortalUserShort>>;
                    }
                }

                @Injectable()
                export class RoleController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/role`;

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.Role> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.Role>;
                    }

                    public List(request: Honeycomb.Tenant.Admin.IService.RoleRequest): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.Role>> {
                        const args = [new RequestParam('request', RequestParamType.BODY, request)];
                        const requestParams = new RequestParams('list', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.Role>>;
                    }

                    public AddRole(name: string, roles: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UiRole>): Observable<number> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name), new RequestParam('roles', RequestParamType.BODY, roles)];
                        const requestParams = new RequestParams('{name}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<number>;
                    }

                    public UpdateUiRoleMap(id: number, roles: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UiRole>): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('roles', RequestParamType.BODY, roles)];
                        const requestParams = new RequestParams('UpdateUiRoleMap/{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UiRoles(roleID: number): Observable<any> {
                        const args = [new RequestParam('roleID', RequestParamType.QUERY, roleID)];
                        const requestParams = new RequestParams('uiRoles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UiRolesForRoles(scope: string, roleIDs: Array<number>): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UiRole>> {
                        const args = [new RequestParam('scope', RequestParamType.QUERY, scope), new RequestParam('roleIDs', RequestParamType.BODY, roleIDs)];
                        const requestParams = new RequestParams('uiRolesForRoles', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UiRole>>;
                    }
                }

                @Injectable()
                export class SeriesController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/setting`;
                }

                @Injectable()
                export class SettingController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/setting`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(key: string): Observable<any> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('{key}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(companyId: number, key: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel> {
                        const args = [new RequestParam('companyId', RequestParamType.QUERY, companyId), new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('detail/{key}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel>;
                    }

                    public Details(keys: Array<string>): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel>> {
                        const args = [new RequestParam('keys', RequestParamType.BODY, keys)];
                        const requestParams = new RequestParams('details', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel>>;
                    }

                    public Value(key: string): Observable<string> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('value/{key}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public List(filter: string, companyId: number): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('companyId', RequestParamType.QUERY, companyId)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel>>;
                    }

                    public GetDictionary(): Observable<{ [key: string]: string }> {
                        const args = [];
                        const requestParams = new RequestParams('getDictionary', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                    }

                    public Update(key: string, model: Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel): Observable<any> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public GetCentralServiceDictionary(): Observable<{ [key: string]: string }> {
                        const args = [];
                        const requestParams = new RequestParams('centralServiceDictionary', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                    }

                    public GetCentralServiceDetail(key: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('centralServiceDetail/{key}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SettingViewModel>;
                    }

                    public GetCentralServiceCertificates(type: Honeycomb.Common.Enums.CertificateType): Observable<Array<Honeycomb.CentralService.IService.ViewModel.CertificateV2>> {
                        const args = [new RequestParam('type', RequestParamType.ROUTE, type)];
                        const requestParams = new RequestParams('centralServiceCertificates/{type}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.CentralService.IService.ViewModel.CertificateV2>>;
                    }

                    public GetRetailCurrencyRounding(): Observable<Array<Honeycomb.Common.RetailCurrencyRounding>> {
                        const args = [];
                        const requestParams = new RequestParams('RetailCurrencyRounding', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.RetailCurrencyRounding>>;
                    }

                    public ClearAllCaches(callers: Array<Honeycomb.Common.Mvc.IApiCaller>): Observable<{}> {
                        const args = [new RequestParam('callers', RequestParamType.ROUTE, callers)];
                        const requestParams = new RequestParams('ClearAllCaches', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{}>;
                    }
                }

                @Injectable()
                export class SiteMapController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/siteMap`;

                    public GetSiteMap(companyName: string, categoryRoot: string, langIsoCode: string): Observable<string> {
                        const args = [new RequestParam('companyName', RequestParamType.QUERY, companyName), new RequestParam('categoryRoot', RequestParamType.QUERY, categoryRoot), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public GetSiteMapForChanges(categoryRecVersion: number, productRecVersion: number, companyName: string, categoryRoot: string, langIsoCode: string): Observable<string> {
                        const args = [new RequestParam('categoryRecVersion', RequestParamType.QUERY, categoryRecVersion), new RequestParam('productRecVersion', RequestParamType.QUERY, productRecVersion), new RequestParam('companyName', RequestParamType.QUERY, companyName), new RequestParam('categoryRoot', RequestParamType.QUERY, categoryRoot), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('forChanges/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public GetSiteMapForChangesData(categoryRecVersion: number, productRecVersion: number, companyName: string, categoryRoot: string, langIsoCode: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.Urlset> {
                        const args = [new RequestParam('categoryRecVersion', RequestParamType.QUERY, categoryRecVersion), new RequestParam('productRecVersion', RequestParamType.QUERY, productRecVersion), new RequestParam('companyName', RequestParamType.QUERY, companyName), new RequestParam('categoryRoot', RequestParamType.QUERY, categoryRoot), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('forChangesData/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.Urlset>;
                    }

                    public ImagesSiteMap(companyName: string, categoryRoot: string, langIsoCode: string): Observable<string> {
                        const args = [new RequestParam('companyName', RequestParamType.QUERY, companyName), new RequestParam('categoryRoot', RequestParamType.QUERY, categoryRoot), new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode)];
                        const requestParams = new RequestParams('ImagesSiteMap/{langIsoCode}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }
                }

                @Injectable()
                export class SkinController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/skin`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.SkinDetailViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.ROUTE, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SkinDetailViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SkinDetailViewModel>;
                    }

                    public Css(id: number): Observable<string> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('css/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SkinDetailViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SkinDetailViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Admin.IService.ViewModels.SkinDetailViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class SkinPaletteController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/skinPalette`;

                    public Save(palette: Honeycomb.Tenant.Admin.IService.ViewModels.SkinPalette): Observable<any> {
                        const args = [new RequestParam('palette', RequestParamType.BODY, palette)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateSelectedPalette(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('updateSelectedPalette', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ClearActiveSkin(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('clearActiveSkin', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SkinPalette> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SkinPalette>;
                    }

                    public List(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SkinPalette>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SkinPalette>>;
                    }
                }

                @Injectable()
                export class SkinTemplateController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/skinTemplate`;

                    public Save(palette: Honeycomb.Tenant.Admin.IService.ViewModels.SkinTemplate): Observable<any> {
                        const args = [new RequestParam('palette', RequestParamType.BODY, palette)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public SelectTemplate(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('selectTemplate/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SkinTemplate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SkinTemplate>;
                    }

                    public List(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SkinTemplate>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SkinTemplate>>;
                    }
                }

                @Injectable()
                export class SystemStorageController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/systemstorage`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.SystemStorageViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(key: string): Observable<any> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('{key}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(key: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SystemStorageViewModel> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('detail/{key}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.SystemStorageViewModel>;
                    }

                    public Value(key: string): Observable<string> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                        const requestParams = new RequestParams('value/{key}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SystemStorageViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.SystemStorageViewModel>>;
                    }

                    public GetDictionary(): Observable<{ [key: string]: string }> {
                        const args = [];
                        const requestParams = new RequestParams('getDictionary', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                    }

                    public Update(key: string, model: Honeycomb.Tenant.Admin.IService.ViewModels.SystemStorageViewModel): Observable<any> {
                        const args = [new RequestParam('key', RequestParamType.ROUTE, key), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TemplateController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/template`;

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>;
                    }

                    public List(filter: string): Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Post(model: Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Select(filter: Honeycomb.Tenant.TemplateEngine.IService.ViewModel.TemplateSelect): Observable<any> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('select', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public BatchesHistory(mediaID: number, searchBase: Honeycomb.Common.SearchBase): Observable<any> {
                        const args = [new RequestParam('mediaID', RequestParamType.ROUTE, mediaID), new RequestParam('searchBase', RequestParamType.BODY, searchBase)];
                        const requestParams = new RequestParams('BatchesHistory/{mediaID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public BatchHistoryItems(batchID: number, searchBase: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionItemsRequest): Observable<any> {
                        const args = [new RequestParam('batchID', RequestParamType.ROUTE, batchID), new RequestParam('searchBase', RequestParamType.BODY, searchBase)];
                        const requestParams = new RequestParams('BatchHistoryItems/{batchID}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TenantController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/Tenant`;

                    public UpdateCertificate(certUuid: string, removeInvalid: boolean, model: Honeycomb.CentralService.IService.ViewModel.CertificateV2): Observable<any> {
                        const args = [new RequestParam('certUuid', RequestParamType.QUERY, certUuid), new RequestParam('removeInvalid', RequestParamType.QUERY, removeInvalid), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('certificate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Certificates(): Observable<Array<Honeycomb.CentralService.IService.ViewModel.CertificateV2>> {
                        const args = [];
                        const requestParams = new RequestParams('certificates', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.CentralService.IService.ViewModel.CertificateV2>>;
                    }

                    public DeleteCertificate(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('deleteCertificate/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TenantLocalizationController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/localization`;

                    public Update(model: Honeycomb.LookupTables.IService.Model.Localization): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpdateList(model: Honeycomb.LookupTables.IService.Model.LocalizationList): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('updateList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: string): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public List(searchString: string, pageSize: number): Observable<any> {
                        const args = [new RequestParam('searchString', RequestParamType.ROUTE, searchString), new RequestParam('pageSize', RequestParamType.ROUTE, pageSize)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class TransactionTypeController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/transactionType`;

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.TransactionTypeModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.TransactionTypeModel>;
                    }

                    public GetForType(id: Honeycomb.Common.Enums.TransactionType): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.TransactionTypeModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('forType/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.TransactionTypeModel>;
                    }

                    public Save(model: Honeycomb.Tenant.Admin.IService.ViewModels.TransactionTypeModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public ListAll(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.TransactionTypeModel>> {
                        const args = [];
                        const requestParams = new RequestParams('listAll', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.TransactionTypeModel>>;
                    }
                }

                @Injectable()
                export class UserController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/user`;

                    public Authenticate(viewModel: Honeycomb.Tenant.Admin.IService.TenantUserAuthenticateViewModel): Observable<Honeycomb.Tenant.Admin.IService.TenantUserAuthenticateViewResult> {
                        const args = [new RequestParam('viewModel', RequestParamType.BODY, viewModel)];
                        const requestParams = new RequestParams('Authenticate', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.TenantUserAuthenticateViewResult>;
                    }

                    public UIRoles(): Observable<Array<string>> {
                        const args = [];
                        const requestParams = new RequestParams('/uiroles', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<string>>;
                    }

                    public DeleteContact(userID: string, id: string): Observable<any> {
                        const args = [new RequestParam('userID', RequestParamType.ROUTE, userID), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('contact/{userID}/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Filter(filter: Honeycomb.Common.Elastic.ElasticFilterRequest): Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>> {
                        const args = [new RequestParam('filter', RequestParamType.BODY, filter)];
                        const requestParams = new RequestParams('filter', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Contact.IService.UserElastic>>;
                    }

                    public GetUsersProperties(ids: Array<number>): Observable<any> {
                        const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                        const requestParams = new RequestParams('getUsersProperties', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }

                @Injectable()
                export class UserTriggerController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/userTrigger`;

                    public UpsertTrigger(trigger: Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger): Observable<any> {
                        const args = [new RequestParam('trigger', RequestParamType.BODY, trigger)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UpsertTriggerList(triggers: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger>): Observable<any> {
                        const args = [new RequestParam('triggers', RequestParamType.BODY, triggers)];
                        const requestParams = new RequestParams('list', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteTrigger(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteTriggerList(id: Array<number>): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.BODY, id)];
                        const requestParams = new RequestParams('deleteList', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public TriggerDetail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger>;
                    }

                    public TriggersList(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger>> {
                        const args = [];
                        const requestParams = new RequestParams('', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger>>;
                    }

                    public TriggersListForType(typeName: string): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger>> {
                        const args = [new RequestParam('typeName', RequestParamType.ROUTE, typeName)];
                        const requestParams = new RequestParams('forType/{typeName}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger>>;
                    }

                    public UpsertTriggerType(triggerType: Honeycomb.Tenant.Admin.IService.ViewModels.UserTriggerType): Observable<any> {
                        const args = [new RequestParam('triggerType', RequestParamType.BODY, triggerType)];
                        const requestParams = new RequestParams('triggerType', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public DeleteTriggerType(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('triggerType/{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public TriggerTypeDetail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.UserTriggerType> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('triggerType/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.UserTriggerType>;
                    }

                    public TriggerTypesList(): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTriggerType>> {
                        const args = [];
                        const requestParams = new RequestParams('triggerType', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTriggerType>>;
                    }
                }

                @Injectable()
                export class VariantController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/variant`;

                    public Add(model: Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel): Observable<any> {
                        const args = [new RequestParam('model', RequestParamType.ROUTE, model)];
                        const requestParams = new RequestParams('', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Delete(id: number): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Detail(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel>;
                    }

                    public DetailAdmin(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('detailAdmin/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel>;
                    }

                    public Config(id: number): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('config/{id}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel>;
                    }

                    public Name(name: string): Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel> {
                        const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                        const requestParams = new RequestParams('name/{name}', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel>;
                    }

                    public List(filter: string, componentId: number, componentType: Honeycomb.Tenant.Admin.IService.Enums.ComponentType): Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel>> {
                        const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('componentId', RequestParamType.QUERY, componentId), new RequestParam('componentType', RequestParamType.QUERY, componentType)];
                        const requestParams = new RequestParams('list', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel>>;
                    }

                    public Update(id: number, model: Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel): Observable<any> {
                        const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                        const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public UploadFile(type: string, id: number): Observable<any> {
                        const args = [new RequestParam('type', RequestParamType.QUERY, type), new RequestParam('id', RequestParamType.ROUTE, id)];
                        const requestParams = new RequestParams('uploadfile/{id}', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {


                @Injectable()
                export class ApiController {
                    constructor(private apiCaller: ApiCaller) { }
                    public controllerRoute: string = `api/TenantAdmin/`;

                    public Ping(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public CacheClear(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }

                    public Version(): Observable<any> {
                        const args = [];
                        const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                        return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace LookupTables {
        export namespace IService {


            @Injectable()
            export class CountriesController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/Countries`;

                public GetList(): Observable<Array<Honeycomb.LookupTables.IService.Model.Country>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Country>>;
                }

                public Filter(id: string, topCount: number): Observable<Honeycomb.LookupTables.IService.Model.Country> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('topCount', RequestParamType.ROUTE, topCount)];
                    const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Country>;
                }

                public Get(iso2: string): Observable<Honeycomb.LookupTables.IService.Model.Country> {
                    const args = [new RequestParam('iso2', RequestParamType.ROUTE, iso2)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Country>;
                }

                public Post(value: Honeycomb.LookupTables.IService.Model.Country): Observable<any> {
                    const args = [new RequestParam('value', RequestParamType.BODY, value)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Put(iso2: string, country: Honeycomb.LookupTables.IService.Model.Country): Observable<any> {
                    const args = [new RequestParam('iso2', RequestParamType.ROUTE, iso2), new RequestParam('country', RequestParamType.BODY, country)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(iso2: string): Observable<any> {
                    const args = [new RequestParam('iso2', RequestParamType.ROUTE, iso2)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class CurrenciesController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/Currencies`;

                public GetList(): Observable<Array<Honeycomb.LookupTables.IService.Model.Currency>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Currency>>;
                }

                public Filter(id: string, topCount: number): Observable<Honeycomb.LookupTables.IService.Model.Currency> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('topCount', RequestParamType.ROUTE, topCount)];
                    const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Currency>;
                }

                public Get(id: string): Observable<Honeycomb.LookupTables.IService.Model.Currency> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Currency>;
                }

                public List(ids: Array<string>): Observable<Array<Honeycomb.LookupTables.IService.Model.Currency>> {
                    const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                    const requestParams = new RequestParams('List', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Currency>>;
                }

                public Post(value: Honeycomb.LookupTables.IService.Model.Currency): Observable<any> {
                    const args = [new RequestParam('value', RequestParamType.BODY, value)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Put(id: string, currency: Honeycomb.LookupTables.IService.Model.Currency): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('currency', RequestParamType.BODY, currency)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: string): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class FailedRulesController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/FailedRules`;

                public Add(fr: Honeycomb.LookupTables.IService.Model.BusinessLogic.FailedRule): Observable<any> {
                    const args = [new RequestParam('fr', RequestParamType.BODY, fr)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetForNextAttempt(maxCount: number): Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.FailedRule>> {
                    const args = [new RequestParam('maxCount', RequestParamType.ROUTE, maxCount)];
                    const requestParams = new RequestParams('{maxCount}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.FailedRule>>;
                }
            }

            @Injectable()
            export class LanguageController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/Languages`;

                public GetList(): Observable<Array<Honeycomb.LookupTables.IService.Model.Language>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Language>>;
                }

                public Filter(id: string, topCount: number): Observable<Array<Honeycomb.LookupTables.IService.Model.Language>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('topCount', RequestParamType.ROUTE, topCount)];
                    const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Language>>;
                }

                public Get(id: string): Observable<Honeycomb.LookupTables.IService.Model.Language> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Language>;
                }

                public Post(value: Honeycomb.LookupTables.IService.Model.Language): Observable<any> {
                    const args = [new RequestParam('value', RequestParamType.BODY, value)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Put(id: string, Language: Honeycomb.LookupTables.IService.Model.Language): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('Language', RequestParamType.BODY, Language)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: string): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class LocalizationController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/Localization`;

                public Localizations(id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                }

                public Filter(id: string, topCount: number): Observable<Array<any>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('topCount', RequestParamType.ROUTE, topCount)];
                    const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public SupportedLanguages(): Observable<Array<Honeycomb.LookupTables.IService.Model.Language>> {
                    const args = [];
                    const requestParams = new RequestParams('SupportedLanguages', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Language>>;
                }

                public Get(langIsoCode: string, key: string): Observable<Honeycomb.LookupTables.IService.Model.Localization> {
                    const args = [new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode), new RequestParam('key', RequestParamType.ROUTE, key)];
                    const requestParams = new RequestParams('{langIsoCode}/{key}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Localization>;
                }

                public Detail(id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                }

                public Keys(prefixes: Array<string>): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                    const args = [new RequestParam('prefixes', RequestParamType.BODY, prefixes)];
                    const requestParams = new RequestParams('keys', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                }

                public Culture(queryPrefix: string, includeCommon: boolean, id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>> {
                    const args = [new RequestParam('queryPrefix', RequestParamType.QUERY, queryPrefix), new RequestParam('includeCommon', RequestParamType.QUERY, includeCommon), new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('culture/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Localization>>;
                }

                public CultureShort(queryPrefix: string, includeCommon: boolean, id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.LocalizationShort>> {
                    const args = [new RequestParam('queryPrefix', RequestParamType.QUERY, queryPrefix), new RequestParam('includeCommon', RequestParamType.QUERY, includeCommon), new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('cultureShort/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.LocalizationShort>>;
                }

                public Dictionary(queryPrefix: string, includeCommon: boolean, id: string): Observable<{ [key: string]: string }> {
                    const args = [new RequestParam('queryPrefix', RequestParamType.QUERY, queryPrefix), new RequestParam('includeCommon', RequestParamType.QUERY, includeCommon), new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('dictionary/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                }

                public LocalizeKeys(langIsoCode: string, keys: Array<string>): Observable<{ [key: string]: string }> {
                    const args = [new RequestParam('langIsoCode', RequestParamType.ROUTE, langIsoCode), new RequestParam('keys', RequestParamType.BODY, keys)];
                    const requestParams = new RequestParams('localizeKeys/{langIsoCode}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                }

                public Update(localization: Honeycomb.LookupTables.IService.Model.Localization): Observable<any> {
                    const args = [new RequestParam('localization', RequestParamType.ROUTE, localization)];
                    const requestParams = new RequestParams('update', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpdateList(list: Honeycomb.LookupTables.IService.Model.LocalizationList): Observable<any> {
                    const args = [new RequestParam('list', RequestParamType.BODY, list)];
                    const requestParams = new RequestParams('updateList', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: string): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public I18n(includeCommon: boolean, prefix: string, lang: string): Observable<any> {
                    const args = [new RequestParam('includeCommon', RequestParamType.QUERY, includeCommon), new RequestParam('prefix', RequestParamType.ROUTE, prefix), new RequestParam('lang', RequestParamType.ROUTE, lang)];
                    const requestParams = new RequestParams('i18n/{prefix}/{lang}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class LocalizationsImportExportController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/LocalizationsImportExport`;

                public Import(request: Honeycomb.Common.ImportExport.Model.ImportRequest): Observable<Honeycomb.Common.ImportExport.Model.ImportResult> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('import', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ImportResult>;
                }

                public Export(request: Honeycomb.Common.ImportExport.Model.ExportRequest): Observable<Honeycomb.Common.ImportExport.Model.ExportResult> {
                    const args = [new RequestParam('request', RequestParamType.BODY, request)];
                    const requestParams = new RequestParams('export', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Model.ExportResult>;
                }

                public DialogModel(interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase): Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel> {
                    const args = [new RequestParam('interfaceModel', RequestParamType.BODY, interfaceModel)];
                    const requestParams = new RequestParams('dialogModel', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.ImportExport.Interface.DialogViewModel>;
                }
            }

            @Injectable()
            export class MeasurementUnitsController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/MeasurementUnits`;

                public GetList(): Observable<Array<Honeycomb.LookupTables.IService.Model.MeasurementUnit>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.MeasurementUnit>>;
                }

                public Filter(id: string, topCount: number): Observable<Array<Honeycomb.LookupTables.IService.Model.MeasurementUnit>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('topCount', RequestParamType.ROUTE, topCount)];
                    const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.MeasurementUnit>>;
                }

                public Get(id: number): Observable<Honeycomb.LookupTables.IService.Model.MeasurementUnit> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.MeasurementUnit>;
                }

                public Post(value: Honeycomb.LookupTables.IService.Model.MeasurementUnit): Observable<any> {
                    const args = [new RequestParam('value', RequestParamType.BODY, value)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Put(id: number, MeasurementUnit: Honeycomb.LookupTables.IService.Model.MeasurementUnit): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('MeasurementUnit', RequestParamType.BODY, MeasurementUnit)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class RegionController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/region`;

                public GetList(): Observable<Array<Honeycomb.LookupTables.IService.Model.Region>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Region>>;
                }

                public Filter(id: string, pageSize: number, page: number): Observable<Array<Honeycomb.LookupTables.IService.Model.Region>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('pageSize', RequestParamType.ROUTE, pageSize), new RequestParam('page', RequestParamType.ROUTE, page)];
                    const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Region>>;
                }

                public GetByNuts(filter: string, count: number, id: number): Observable<Array<Honeycomb.LookupTables.IService.Model.Region>> {
                    const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('count', RequestParamType.QUERY, count), new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('nuts/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Region>>;
                }

                public GetNutsByParent(filter: string, level: number, parent: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Region>> {
                    const args = [new RequestParam('filter', RequestParamType.QUERY, filter), new RequestParam('level', RequestParamType.ROUTE, level), new RequestParam('parent', RequestParamType.ROUTE, parent)];
                    const requestParams = new RequestParams('nuts2/{level}/{parent}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Region>>;
                }

                public GetSubRegions(id: string): Observable<Array<Honeycomb.LookupTables.IService.Model.Region>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('subregions/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.Region>>;
                }

                public Get(id: string): Observable<Honeycomb.LookupTables.IService.Model.Region> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('Detail/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.Region>;
                }

                public Post(region: Honeycomb.LookupTables.IService.Model.Region): Observable<any> {
                    const args = [new RequestParam('region', RequestParamType.BODY, region)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Put(id: string, region: Honeycomb.LookupTables.IService.Model.Region): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('region', RequestParamType.BODY, region)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class RuleController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/rule`;

                public GetForControllers(controllers: Array<string>): Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>> {
                    const args = [new RequestParam('controllers', RequestParamType.BODY, controllers)];
                    const requestParams = new RequestParams('GetForControllers', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>>;
                }

                public Post(model: Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Detail(id: number): Observable<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>;
                }

                public List(): Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>>;
                }

                public GetByName(name: string): Observable<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule> {
                    const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                    const requestParams = new RequestParams('GetByName/{name}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>;
                }

                public SetRuleTrace(trace: number): Observable<any> {
                    const args = [new RequestParam('trace', RequestParamType.ROUTE, trace)];
                    const requestParams = new RequestParams('SetRuleTrace/{trace}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetForActionContext(context: Honeycomb.Common.Enums.RuleRunContext): Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>> {
                    const args = [new RequestParam('context', RequestParamType.ROUTE, context)];
                    const requestParams = new RequestParams('GetForActionContext/{context}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.Rule>>;
                }
            }

            @Injectable()
            export class VATsController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/VATs`;

                public GetList(): Observable<Array<Honeycomb.LookupTables.IService.Model.VAT>> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.VAT>>;
                }

                public GetValidList(): Observable<Array<Honeycomb.LookupTables.IService.Model.VAT>> {
                    const args = [];
                    const requestParams = new RequestParams('valid', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.VAT>>;
                }

                public Filter(id: string, topCount: number): Observable<Array<Honeycomb.LookupTables.IService.Model.VAT>> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('topCount', RequestParamType.ROUTE, topCount)];
                    const requestParams = new RequestParams('Filter/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.LookupTables.IService.Model.VAT>>;
                }

                public Get(id: number): Observable<Honeycomb.LookupTables.IService.Model.VAT> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.VAT>;
                }

                public Id(VATID: number): Observable<Honeycomb.LookupTables.IService.Model.VAT> {
                    const args = [new RequestParam('VATID', RequestParamType.ROUTE, VATID)];
                    const requestParams = new RequestParams('id/{VATID}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.LookupTables.IService.Model.VAT>;
                }

                public Post(value: Honeycomb.LookupTables.IService.Model.VAT): Observable<any> {
                    const args = [new RequestParam('value', RequestParamType.BODY, value)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Put(id: number, VAT: Honeycomb.LookupTables.IService.Model.VAT): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('VAT', RequestParamType.BODY, VAT)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace LookupTables {
        export namespace IService {


            @Injectable()
            export class ApiController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `api/GlobalLookupTables/`;

                public Ping(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public CacheClear(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Version(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace CentralService {
        export namespace IService {


            @Injectable()
            export class CentralSettingsController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/centralSettings`;

                public GetDictionary(): Observable<{ [key: string]: string }> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<{ [key: string]: string }>;
                }

                public Get(key: string): Observable<Honeycomb.CentralService.IService.ViewModel.CentralSetting> {
                    const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                    const requestParams = new RequestParams('{key}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.CentralService.IService.ViewModel.CentralSetting>;
                }

                public Post(model: Honeycomb.CentralService.IService.ViewModel.CentralSetting): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(key: string): Observable<any> {
                    const args = [new RequestParam('key', RequestParamType.ROUTE, key)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class CertificatesController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/certificates`;

                public Get(name: string): Observable<any> {
                    const args = [new RequestParam('name', RequestParamType.ROUTE, name)];
                    const requestParams = new RequestParams('{name}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetByType(type: Honeycomb.Common.Enums.CertificateType): Observable<any> {
                    const args = [new RequestParam('type', RequestParamType.ROUTE, type)];
                    const requestParams = new RequestParams('type/{type}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Post(model: Honeycomb.CentralService.IService.ViewModel.Certificate): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class CertificatesControllerV2 {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/v2/certificates`;

                public Get(tenantUID: string, name: string): Observable<Honeycomb.CentralService.IService.ViewModel.CertificateV2> {
                    const args = [new RequestParam('tenantUID', RequestParamType.QUERY, tenantUID), new RequestParam('name', RequestParamType.ROUTE, name)];
                    const requestParams = new RequestParams('{name}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.CentralService.IService.ViewModel.CertificateV2>;
                }

                public list(tenantUID: string, type: Honeycomb.Common.Enums.CertificateType): Observable<any> {
                    const args = [new RequestParam('tenantUID', RequestParamType.QUERY, tenantUID), new RequestParam('type', RequestParamType.QUERY, type)];
                    const requestParams = new RequestParams('list', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetByType(type: Honeycomb.Common.Enums.CertificateType): Observable<any> {
                    const args = [new RequestParam('type', RequestParamType.ROUTE, type)];
                    const requestParams = new RequestParams('type/{type}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Post(model: Honeycomb.CentralService.IService.ViewModel.CertificateV2): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class GoogleWalletControllerV2 {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/v2/GoogleWallet`;

                public Action(tenantUID: string, callbackModel: Honeycomb.CentralService.IService.ViewModel.GoogleSignedMessage): Observable<any> {
                    const args = [new RequestParam('tenantUID', RequestParamType.QUERY, tenantUID), new RequestParam('callbackModel', RequestParamType.BODY, callbackModel)];
                    const requestParams = new RequestParams('Action', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpsertObject(walletInstance: Honeycomb.CentralService.IService.ViewModel.GoogleWalletObject): Observable<any> {
                    const args = [new RequestParam('walletInstance', RequestParamType.BODY, walletInstance)];
                    const requestParams = new RequestParams('Upsert', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UpsertClass(walletClass: Honeycomb.CentralService.IService.ViewModel.GoogleWalletClass): Observable<any> {
                    const args = [new RequestParam('walletClass', RequestParamType.BODY, walletClass)];
                    const requestParams = new RequestParams('UpsertClass', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class MediaLibraryController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/mediaLibrary`;

                public List(isSysAdmin: boolean, tenantHash: string): Observable<Array<Honeycomb.CentralService.IService.ViewModel.Template>> {
                    const args = [new RequestParam('isSysAdmin', RequestParamType.QUERY, isSysAdmin), new RequestParam('tenantHash', RequestParamType.ROUTE, tenantHash)];
                    const requestParams = new RequestParams('{tenantHash}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.CentralService.IService.ViewModel.Template>>;
                }

                public Detail(id: number): Observable<Honeycomb.CentralService.IService.ViewModel.Template> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.CentralService.IService.ViewModel.Template>;
                }

                public Post(model: Honeycomb.CentralService.IService.ViewModel.Template): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Filter(model: Honeycomb.CentralService.IService.ViewModel.Template): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('Filter', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetLatestVersion(id: string): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('GetLatestVersion/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Update(id: number, vmTemplate: Honeycomb.CentralService.IService.ViewModel.Template): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('vmTemplate', RequestParamType.BODY, vmTemplate)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Delete(id: number): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PassbookControllerV2 {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/v1`;

                public RegisterDevicePass(deviceLibraryIdentifier: string, passTypeIdentifier: string, serialNumber: string): Observable<any> {
                    const args = [new RequestParam('deviceLibraryIdentifier', RequestParamType.ROUTE, deviceLibraryIdentifier), new RequestParam('passTypeIdentifier', RequestParamType.ROUTE, passTypeIdentifier), new RequestParam('serialNumber', RequestParamType.ROUTE, serialNumber)];
                    const requestParams = new RequestParams('devices/{deviceLibraryIdentifier}/registrations/{passTypeIdentifier}/{serialNumber}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetDevicePasses(deviceLibraryIdentifier: string, passTypeIdentifier: string, passesUpdatedSince: string): Observable<any> {
                    const args = [new RequestParam('deviceLibraryIdentifier', RequestParamType.ROUTE, deviceLibraryIdentifier), new RequestParam('passTypeIdentifier', RequestParamType.ROUTE, passTypeIdentifier), new RequestParam('passesUpdatedSince', RequestParamType.ROUTE, passesUpdatedSince)];
                    const requestParams = new RequestParams('devices/{deviceLibraryIdentifier}/registrations/{passTypeIdentifier}/{passesUpdatedSince?}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetPass(passTypeIdentifier: string, serialNumber: string): Observable<any> {
                    const args = [new RequestParam('passTypeIdentifier', RequestParamType.ROUTE, passTypeIdentifier), new RequestParam('serialNumber', RequestParamType.ROUTE, serialNumber)];
                    const requestParams = new RequestParams('passes/{passTypeIdentifier}/{serialNumber}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public UnregisterPass(deviceLibraryIdentifier: string, passTypeIdentifier: string, serialNumber: string): Observable<any> {
                    const args = [new RequestParam('deviceLibraryIdentifier', RequestParamType.ROUTE, deviceLibraryIdentifier), new RequestParam('passTypeIdentifier', RequestParamType.ROUTE, passTypeIdentifier), new RequestParam('serialNumber', RequestParamType.ROUTE, serialNumber)];
                    const requestParams = new RequestParams('devices/{deviceLibraryIdentifier}/registrations/{passTypeIdentifier}/{serialNumber}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PassbookUpdateController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/passbookUpdate`;

                public Post(model: Honeycomb.CentralService.IService.ViewModel.ApplePassbookInstance): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PassbookUpdateControllerV2 {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/v2/PassbookUpdate`;

                public Post(model: Honeycomb.CentralService.IService.ViewModel.ApplePassbookInstance): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class PolyfillController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/polyfill`;

                public Get(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }

            @Injectable()
            export class StorageController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/storage`;

                public Add(model: Honeycomb.Common.DbFile): Observable<any> {
                    const args = [new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Upsert(models: Array<any>): Observable<Array<any>> {
                    const args = [new RequestParam('models', RequestParamType.BODY, models)];
                    const requestParams = new RequestParams('upsert', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<any>>;
                }

                public Delete(id: string): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.DELETE, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Detail(id: string): Observable<Honeycomb.Common.DbFile> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('detail/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Honeycomb.Common.DbFile>;
                }

                public Get(id: string): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public ByPath(filePath: string): Observable<any> {
                    const args = [new RequestParam('filePath', RequestParamType.ROUTE, filePath)];
                    const requestParams = new RequestParams('byPath/{*filePath}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public GetMetadata(recordUid: string): Observable<any> {
                    const args = [new RequestParam('recordUid', RequestParamType.ROUTE, recordUid)];
                    const requestParams = new RequestParams('metadata/{id}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public List(searchString: string): Observable<Array<Honeycomb.Common.DbFileMetadata>> {
                    const args = [new RequestParam('searchString', RequestParamType.QUERY, searchString)];
                    const requestParams = new RequestParams('list', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFileMetadata>>;
                }

                public MetadataForList(ids: Array<string>): Observable<Array<Honeycomb.Common.DbFile>> {
                    const args = [new RequestParam('ids', RequestParamType.BODY, ids)];
                    const requestParams = new RequestParams('metadataForList', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<Array<Honeycomb.Common.DbFile>>;
                }

                public Update(id: string, model: Honeycomb.Common.DbFile): Observable<any> {
                    const args = [new RequestParam('id', RequestParamType.ROUTE, id), new RequestParam('model', RequestParamType.BODY, model)];
                    const requestParams = new RequestParams('{id}', HttpMethod.PUT, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public CreateThumbnail(width: number, imageUid: string): Observable<string> {
                    const args = [new RequestParam('width', RequestParamType.QUERY, width), new RequestParam('imageUid', RequestParamType.ROUTE, imageUid)];
                    const requestParams = new RequestParams('createThumbnail/{imageUid}', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<string>;
                }

                public ThumbByID(size: number, id: string): Observable<any> {
                    const args = [new RequestParam('size', RequestParamType.ROUTE, size), new RequestParam('id', RequestParamType.ROUTE, id)];
                    const requestParams = new RequestParams('thumbByID/{size}/{id}/{*filePath}', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace CentralService {
        export namespace IService {


            @Injectable()
            export class ApiController {
                constructor(private apiCaller: ApiCaller) { }
                public controllerRoute: string = `UnkonwnService/`;

                public Ping(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Ping', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public CacheClear(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('CacheClear', HttpMethod.POST, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }

                public Version(): Observable<any> {
                    const args = [];
                    const requestParams = new RequestParams('Version', HttpMethod.GET, args);
                    return this.apiCaller.request(this.controllerRoute, requestParams) as Observable<any>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Auth {
        export namespace IService {

            export class AuthorizationResponse {
                token_type: string;
                access_token: string;
                expires_in: number;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace CentralService {
        export namespace IService {
            export namespace ViewModel {

                export class ApplePassbookInstance {
                    applePassbookInstanceId: number;
                    passTypeIdentifier: string;
                    serialNumber: string;
                    passbookContent: Array<number>;
                    tenantUID: string;
                    updateTime: Date;
                }
                export class CentralSetting {
                    settingKey: string;
                    settingValue: string;
                    updateTime: Date;
                }
                export class Certificate {
                    certificateId: number;
                    type: Honeycomb.Common.Enums.CertificateType;
                    name: string;
                    content: Array<number>;
                    contentAsString: string;
                    password: string;
                    validSince: Date;
                    validTill: Date;
                    additionalSettings: string;
                }
                export class CertificateV2 {
                    certificateId: number;
                    type: Honeycomb.Common.Enums.CertificateType;
                    name: string;
                    content: Array<number>;
                    contentAsString: string;
                    password: string;
                    validSince: Date;
                    validTill: Date;
                    additionalSettings: string;
                    tenantUID?: string;
                }
                export class GoogleSignedMessage {
                    signature: string;
                    protocolVersion: string;
                    signedMessage: string;
                }
                export class GoogleWalletClass {
                    classID: string;
                    classJson: string;
                    tenantUID: string;
                    googleWalletObjects: Array<Honeycomb.CentralService.IService.ViewModel.GoogleWalletObject>;
                }
                export class GoogleWalletObject {
                    classID: string;
                    objectID: string;
                    instanceStatus: Honeycomb.Common.Enums.GoogleWalletInstanceStatus;
                    tenantUID: string;
                    objectJson: string;
                    expiration: Date;
                    expirationEpoch: number;
                }
                export class Storage {
                }
                export class Template {
                    templateID: number;
                    jsTemplateTypeID: string;
                    jsTemplateTypeShortID: string;
                    entityDataType: Honeycomb.Common.Enums.EntityDataType;
                    name: string;
                    description: string;
                    tenantRestricted: boolean;
                    enabled: boolean;
                    majorVersion: number;
                    minorVersion: number;
                    minApiVersion: string;
                    price?: number;
                    vatID?: number;
                    currencyISOCode: string;
                    thumbnail?: string;
                    thumbnailUpdated: boolean;
                    templateData: string;
                    mediaData: string;
                    jsReportData: string;
                    templateTenants: Array<Honeycomb.TenantManagement.IService.TenantShortViewModel>;
                    thumbnailImage: Honeycomb.CentralService.IService.ViewModel.Storage;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace DataProviders {

            export class AggregatedDecimalStats {
                min?: number;
                max?: number;
                avg?: number;
                sum?: number;
                count: number;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {

        export class DateRange {
            since: Date;
            till: Date;
        }
        export class DbFile {
            content: Array<number>;
            resize: Honeycomb.Common.FileResizeModel;
            fileNameForResize: string;
            recordUID: string;
            fileName: string;
            fileType: string;
            mimeType: string;
            dateUpdated: Date;
            dateCreated: Date;
        }
        export class DbFileMetadata {
            recordUID: string;
            fileName: string;
            fileType: string;
            mimeType: string;
            dateUpdated: Date;
            dateCreated: Date;
        }
        export class FileMetadata {
            recordUID: string;
            fileName: string;
            isDirectory: boolean;
            fileType: string;
            fileSize: number;
            dateCreated: Date;
            level: number;
            path: string;
            mimeType: string;
        }
        export class FileResizeModel {
            uploads: Array<any>;
            mpx: number;
            width: number;
            height: number;
            orientation: Honeycomb.Common.ImageOrientation;
            isOriginal: boolean;
            shortCut: string;
        }
        export class FilesRequest {
            parent?: string;
            includeContent: boolean;
            searchString: string;
            sortColumn: string;
            sortDirection: Honeycomb.Common.SortDirection;
            pageSize?: number;
            page: number;
        }
        export class RetailCurrencyRounding {
            currencyISOCode: string;
            limitation: string;
            decimalPlaces: number;
        }
        export class SearchBase {
            sortColumn: string;
            sortDirection: Honeycomb.Common.SortDirection;
            pageSize?: number;
            page: number;
            searchString: string;
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Db {

            export class HistoryData {
                updated: Date;
                updatedBy: Honeycomb.Common.Db.HistoryUpdatedBy;
                fields: Array<Honeycomb.Common.Db.HistoryChangeField>;
            }
            export class HistoryChange {
                tableName: string;
                columnName: string;
                value: string;
                nextValue: string;
                validFromUTC: Date;
                validToUTC: Date;
            }
            export class HistoryChangeField {
                columnName: string;
                value: string;
                nextValue: string;
            }
            export class HistoryUpdatedBy {
                userID: number;
                name: string;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Db {
            export namespace ProductDiscounts {

                export class ProductDiscountResult {
                    name: string;
                    type: Honeycomb.Common.Enums.DiscountValueType;
                    resultType: Honeycomb.Common.Enums.DiscountResultType;
                    value: number;
                    priority: number;
                    productID: number;
                    userID: number;
                    roundingDecimalPlaces: number;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Elastic {

            export class ElasticFilterElement {
                filterID?: number;
                filterName: string;
                filterParams: { [key: string]: any };
            }
            export class ElasticFilterRequest {
                filterPurpose: Honeycomb.Common.Enums.FilterPurpose;
                filters: Array<Honeycomb.Common.Elastic.ElasticFilterElement>;
                searchString: string;
                pagination: Honeycomb.Common.Elastic.ElasticPagination;
                sorting: Array<Honeycomb.Common.Elastic.ElasticSorting>;
                properties: Array<string>;
                filterPanel: Honeycomb.Common.Elastic.FilterPanel;
                usePersonalization: boolean;
            }
            export class ElasticPagination {
                pageSize: number;
                pageIndex: number;
            }
            export class ElasticSorting {
                sortField: string;
                sortDir: number;
            }
            export class FilterPanel {
                configName: string;
                groups: Array<Honeycomb.Common.Elastic.PanelGroup>;
            }
            export class PanelGroup {
                key: string;
                title: string;
                type: Honeycomb.Common.Elastic.PanelGroupType;
                items: Array<Honeycomb.Common.Elastic.PanelItem>;
            }
            export class PanelItem {
                key: string;
                title: string;
                type: Honeycomb.Common.Elastic.PanelItemType;
                value: string;
                isSelected: boolean;
                isCountRelative: boolean;
                count: number;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace ImportExport {
            export namespace Interface {

                export class DialogFieldEnumItem {
                    dataValue: string;
                    displayValue: string;
                    displayOrder: number;
                }
                export class DialogFieldViewModel {
                    dialogFieldID: number;
                    dialogID: number;
                    name: string;
                    caption: string;
                    dataType: Honeycomb.Common.Enums.DataType;
                    codeListID?: number;
                    regExValidator: string;
                    minValue?: number;
                    maxValue?: number;
                    required: boolean;
                    displayOrder: number;
                    enumItems: Array<Honeycomb.Common.ImportExport.Interface.DialogFieldEnumItem>;
                }
                export class DialogViewModel {
                    dialogID: number;
                    name: string;
                    caption: string;
                    fields: Array<Honeycomb.Common.ImportExport.Interface.DialogFieldViewModel>;
                }
                export class InterfaceFile {
                    interfaceFileID: number;
                    interfaceID: number;
                    type: Honeycomb.Common.ImportExport.Model.ImportExportFileFormat;
                    name: string;
                    extension: string;
                    mimeType: string;
                }
                export class InterfaceModelBase {
                    interfaceID: number;
                    name: string;
                    description: string;
                    agenda: Honeycomb.Common.Enums.PortalAgenda;
                    interfaceType: Honeycomb.Common.Enums.InterfaceType;
                    customQuery: string;
                    dialogID?: number;
                    dialog: Honeycomb.Common.ImportExport.Interface.DialogViewModel;
                    exportSelector: Honeycomb.Common.Enums.ExportContentSelector;
                    lastExportedDate?: Date;
                    lastExportedRowVersion?: number;
                    interfaceFiles: Array<Honeycomb.Common.ImportExport.Interface.InterfaceFile>;
                    controllerType: string;
                    serviceType: string;
                    assemblyName: string;
                    instanceType: string;
                    controllerQualifiedName: string;
                    serviceQualifiedName: string;
                    methodName: string;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace ImportExport {
            export namespace Model {

                export class ExportContent {
                    uiLanguageIsoCode: string;
                    selector: Honeycomb.Common.Enums.ExportContentSelector;
                    iDs: Array<number>;
                    sinceDate?: Date;
                    tillDate?: Date;
                    selectedFileFormat: Honeycomb.Common.ImportExport.Interface.InterfaceFile;
                    lastExportInfo: Honeycomb.Common.ImportExport.Model.InterfaceLastExportInfo;
                    dialogData: { [key: string]: string };
                }
                export class ExportRequest {
                    level: Honeycomb.Common.Enums.FileExportLevel;
                    isSample: boolean;
                    exportSelected: Honeycomb.Common.Enums.ExportContentSelector;
                    directDownload: boolean;
                    selectedExporFileID: number;
                    localizations: { [key: string]: string };
                    interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase;
                    content: Honeycomb.Common.ImportExport.Model.ExportContent;
                    limit?: number;
                }
                export class ExportResult {
                    errors: Array<string>;
                    totalExported: number;
                    content: Array<number>;
                    tempFile: string;
                    lastExportInfo: Honeycomb.Common.ImportExport.Model.InterfaceLastExportInfo;
                    entityDataID: string;
                    mimeType: string;
                    fileName: string;
                    backgroudTask: boolean;
                    serviceUrl: string;
                }
                export class ImportContent {
                    uiLanguageIsoCode: string;
                    selectedFileFormat: Honeycomb.Common.ImportExport.Interface.InterfaceFile;
                    dialogData: { [key: string]: string };
                    content: Array<number>;
                }
                export class ImportRequest {
                    localizations: { [key: string]: string };
                    interfaceModel: Honeycomb.Common.ImportExport.Interface.InterfaceModelBase;
                    content: Honeycomb.Common.ImportExport.Model.ImportContent;
                }
                export class ImportResult {
                    failed: boolean;
                    read: number;
                    skipped: number;
                    inserted: number;
                    updated: number;
                    errors: Array<string>;
                }
                export class InterfaceLastExportInfo {
                    lastExportedDate?: Date;
                    lastExportedRowVersion?: number;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {

            export class IApiCaller {
                port: Honeycomb.Common.ServicePorts;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {
            export namespace Middlewares {

                export class GetConfigViewModel {
                    id: number;
                    tenantDatabaseConnectionString: string;
                    tenantElasticSearchUrl: string;
                    tenantLanguageIsoCode: string;
                    tenantCountryIsoCode: string;
                    tenantCurrencyIsoCode: string;
                    tenantHash: string;
                    tenantEdition: Honeycomb.Common.Enums.Edition;
                }
                export class TenantRequestViewModel {
                    tenantId?: number;
                    tenantHash: string;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {
            export namespace Services {

                export class SocialUserInfo {
                    firstName: string;
                    lastName: string;
                    email: string;
                    imageUrl: string;
                    profileID: string;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Shipping {

            export class DeliveryTime {
                days: number;
                decisiveHour: number;
                saturdays: boolean;
                sundays: boolean;
                holidays: boolean;
                term: number;
            }
            export class Limit {
                max: number;
                min: number;
                disabled: boolean;
                strict: boolean;
                failedState?: Honeycomb.Common.Enums.ServiceItemState;
                countries: { [key: string]: Honeycomb.Common.Shipping.Limit };
            }
            export class ShippingRestriction {
                shippingRestrictionID: number;
                shippingID: number;
                countryCode: string;
                allow: boolean;
            }
            export class ShippingSettings {
                freeDeliveryEnabled: boolean;
                freeDeliveryLimits: { [key: string]: number };
                freeDeliveryProductAttribute: string;
                priceReplacementKey: string;
                shippingInfoKey: string;
                groupKey: string;
                displayOrder?: number;
                weightProductAttribute: string;
                basketWeightLimits: { [key: string]: number };
                parAvionForbiddenAttribute: string;
                productLimits: {};
                dimensionsProductAttribute: string;
                digitalProductAttribute: string;
                features: Array<Honeycomb.Common.Enums.ShippingFeature>;
                isStandardDelivery: boolean;
                deliveryTime: Honeycomb.Common.Shipping.DeliveryTime;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace ViewModels {

            export class BulkInsertTableResult {
                tableName: string;
                idColumnName: string;
                connectedColumnName: string;
                recordCount: number;
            }
            export class Dimensions {
                depthCM: number;
                heightCM: number;
                widthCM: number;
            }
            export class LocalizedTexts {
                defaultText: string;
                texts: { [key: string]: string };
            }
            export class ProductStock {
                productID: number;
                productNumber: string;
                locationID: number;
                quantity: number;
                avaiableAt?: Date;
                variants: Array<Honeycomb.Common.ViewModels.ProductVariantStock>;
            }
            export class ProductVariantStock {
                productID: number;
                productNumber: string;
                productVariantID: number;
                locationID: number;
                quantity: number;
                avaiableAt?: Date;
            }
            export class RegisterUserInterestRequest {
                userNumber: string;
                userID?: number;
                sessionID: number;
                interestType: Honeycomb.Common.Enums.UserInterestType;
                productNumber: string;
                productText: string;
                attributes: Array<Honeycomb.Common.ViewModels.UserInterestProductAttribute>;
            }
            export class RelatedProduct {
                productNumber: string;
                productID?: number;
                score: number;
            }
            export class SiteMapDataModel {
                id: string;
                text: string;
                rowVersion: number;
                urlType: Honeycomb.Common.Enums.SiteMapURLType;
            }
            export class TableData {
                cols: Array<string>;
                data: Array<any>;
            }
            export class UserInterestProductAttribute {
                meaning: Honeycomb.Common.Enums.RawItemDataMeaning;
                name: string;
                value: string;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace LookupTables {
        export namespace IService {
            export namespace Model {
                export namespace BusinessLogic {

                    export class FailedRule {
                        failedRuleID: number;
                        ruleID: number;
                        tenantID: number;
                        companyID: number;
                        messageContent: string;
                        exception: string;
                        firstAttempt: Date;
                        nextAttempt: Date;
                        delaySeconds: number;
                    }
                    export class Rule {
                        ruleId: number;
                        tenantIDs: Array<number>;
                        name: string;
                        exchange: string;
                        messages: string;
                        description: string;
                        category: string;
                        inputObjectType: string;
                        sourceCode: string;
                        controller: string;
                        method: string;
                        disabled: boolean;
                        methodRunOrder: Honeycomb.LookupTables.IService.Model.BusinessLogic.MethodRunOrder;
                        created: Date;
                        modified: Date;
                        runOrder: number;
                        runContext: Honeycomb.Common.Enums.RuleRunContext;
                        messageRetryDelay: number;
                        ruleAction: Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.RuleAction>;
                        ruleConfiguration: Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.RuleConfiguration>;
                    }
                    export class RuleAction {
                        ruleActionId: number;
                        ruleId: number;
                        parentRuleActionId?: number;
                        sequence: number;
                        type: Honeycomb.LookupTables.IService.Model.BusinessLogic.RuleActionType;
                        parentConditionResult: boolean;
                        expression: string;
                        result: string;
                        catchRule: Honeycomb.LookupTables.IService.Model.BusinessLogic.RuleAction;
                        children: Array<Honeycomb.LookupTables.IService.Model.BusinessLogic.RuleAction>;
                        created: Date;
                        modified: Date;
                        expressionAsForeach: Honeycomb.LookupTables.IService.Model.BusinessLogic.RuleActionForeach;
                    }
                    export class RuleActionForeach {
                        variableName: string;
                        collection: string;
                    }
                    export class RuleConfiguration {
                        ruleConfigurationId: number;
                        ruleId: number;
                        tenantId?: number;
                        configuration: string;
                        created: Date;
                        modified: Date;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace LookupTables {
        export namespace IService {
            export namespace Model {

                export class Country {
                    tsT: Array<number>;
                    isoCode2: string;
                    isoCode3: string;
                    name: string;
                    localizationKey: string;
                }
                export class Currency {
                    isoCode: string;
                    symbol: string;
                    name: string;
                }
                export class Discount {
                    valueType: Honeycomb.Common.Enums.DiscountValueType;
                    discountOrigin: Honeycomb.Common.Enums.DiscountOrigin;
                    description: string;
                    name: string;
                    voucherNumber: string;
                    discountUID?: string;
                    value: number;
                    evaluatedPrice: number;
                    priority: number;
                    priceWithVAT: boolean;
                    vatPercent: number;
                    totalItemDiscountWithVAT: number;
                    totalItemDiscountWithoutVAT: number;
                    totalItemDiscountVAT: number;
                }
                export class Language {
                    languageISOCode: string;
                }
                export class Localization {
                    languageISOCode: string;
                    key: string;
                    value: string;
                }
                export class LocalizationList {
                    list: Array<Honeycomb.LookupTables.IService.Model.Localization>;
                }
                export class LocalizationShort {
                    K: string;
                    V: string;
                }
                export class MeasurementUnit {
                    measurementUnitID: number;
                    description: string;
                    symbol: string;
                    decimalPlaces: number;
                }
                export class Region {
                    nuts: string;
                    name: string;
                    nutsLevel: number;
                    parents: Array<Honeycomb.LookupTables.IService.Model.Region>;
                }
                export class VAT {
                    vatKeyID: number;
                    countryISO2Code: string;
                    vatiD: number;
                    vatValue: number;
                    vatType: Honeycomb.Common.Enums.VATType;
                    validSince: Date;
                    validTill: Date;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {

                export class AdminExportResult {
                    errors: Array<string>;
                    totalExported: number;
                    entityDataID: string;
                }
                export class AdminImportExportRequest {
                    interfaceModelID: number;
                    interfaceFileID: number;
                    dialogData: { [key: string]: string };
                    selectedIDs: Array<number>;
                }
                export class RoleRequest {
                    forAdmin: boolean;
                    forContact: boolean;
                    includeAttributes: boolean;
                    includeUiRoles: boolean;
                    includeApiRoles: boolean;
                }
                export class TenantUserAuthenticateViewModel {
                    email: string;
                    password: string;
                    tenantID: number;
                }
                export class TenantUserAuthenticateViewResult {
                    iD: number;
                    tenantID: number;
                    primaryEmail: string;
                    firstName: string;
                    lastName: string;
                    roles: Array<string>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {
                export namespace ViewModels {

                    export class ApiRole {
                        apiRoleID: number;
                        name: string;
                        description: string;
                        updateTime: Date;
                        uiApiMaps: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UiApiMap>;
                    }
                    export class AppUiMap {
                        appUiMapID: number;
                        roleID: number;
                        uiRoleID: number;
                        created: Date;
                        role: Honeycomb.Tenant.Admin.IService.ViewModels.Role;
                        uiRole: Honeycomb.Tenant.Admin.IService.ViewModels.UiRole;
                    }
                    export class AttributeDataTable {
                        rows: Array<any>;
                        columns: Array<Honeycomb.Tenant.Admin.IService.ViewModels.DataTableColumn>;
                    }
                    export class CompanyViewModel {
                        companyId: number;
                        name: string;
                        initials: string;
                        salutation: string;
                        firstName: string;
                        middleName: string;
                        lastName: string;
                        jobTitle: string;
                        idn: string;
                        tin: string;
                        registrationInCourt: string;
                        poBox: string;
                        addressLine1: string;
                        addressLine2: string;
                        street: string;
                        houseNumber: string;
                        city: string;
                        zip: string;
                        countryIsoCode: string;
                        currencyIsoCode: string;
                        currencySymbol: string;
                        languageIsoCode: string;
                        email: string;
                        phoneNr: string;
                        weburL: string;
                        facebook: string;
                        twitter: string;
                        latitude?: number;
                        longtitude?: number;
                        vatPayer: boolean;
                        pricesWithVAT: boolean;
                        logoUID: string;
                        selectedSkinTemplateId?: number;
                        selectedPaletteId?: number;
                        takeoverComponetsStyleFromWEB: boolean;
                        created: Date;
                        updated: Date;
                    }
                    export class ComponentConfigurationDetailViewModel {
                        mainFilterID?: number;
                        skinSource: string;
                        componentVariant: Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel;
                        userFilters: Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel>;
                        configurationID: number;
                        configurationName: string;
                        description: string;
                        componentVariantID: number;
                        componentVariantName: string;
                        mainFilterName: string;
                        skinID?: number;
                        skinName: string;
                        fileName: string;
                    }
                    export class ComponentConfigurationViewModel {
                        configurationID: number;
                        configurationName: string;
                        description: string;
                        componentVariantID: number;
                        componentVariantName: string;
                        mainFilterID?: number;
                        mainFilterName: string;
                        skinID?: number;
                        skinName: string;
                        fileName: string;
                    }
                    export class ComponentDetailViewModel {
                        componentID: number;
                        componentName: string;
                        componentType: Honeycomb.Tenant.Admin.IService.Enums.ComponentType;
                        framework: Honeycomb.Tenant.Admin.IService.Enums.ComponentFramework;
                        sortOrder: number;
                        componentFeatures: Honeycomb.Tenant.Admin.IService.Enums.ComponentFeature;
                    }
                    export class ComponentVariantDetailViewModel {
                        componentVariantID: number;
                        variantName: string;
                        componentID: number;
                        componentName: string;
                        componentParamsObject: any;
                        componentType: Honeycomb.Tenant.Admin.IService.Enums.ComponentType;
                        componentFeature: Honeycomb.Tenant.Admin.IService.Enums.ComponentFeature;
                        html: string;
                        hasNewHtml: boolean;
                        hasRemovedHtml: boolean;
                        hasHtml: boolean;
                        js: string;
                        hasNewJs: boolean;
                        hasRemovedJs: boolean;
                        hasJs: boolean;
                        jsEditorChange: boolean;
                        htmlEditorChange: boolean;
                        cssEditorChange: boolean;
                        css: string;
                        updateCss: boolean;
                        componentParamsJson: string;
                        serverParamsJson: string;
                    }
                    export class ComponentVariantDynamicEdit {
                        tenantScriptInit: string;
                        template: Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel;
                        componentVariantSourceID: number;
                        componentVariantID: number;
                        html: string;
                        js: string;
                        css: string;
                        componentParamsJson: string;
                        updateTime: Date;
                    }
                    export class ComponentVariantSourceEdit {
                        tenantScriptInit: string;
                        componentVariantSourceID: number;
                        componentVariantID: number;
                        html: string;
                        js: string;
                        css: string;
                        componentParamsJson: string;
                        updateTime: Date;
                    }
                    export class ConfigurationAdminViewModel {
                        configurationID: number;
                        configurationName: string;
                        description: string;
                        componentVariantID: number;
                        skinID?: number;
                        mainFilterID?: number;
                        componentParamsJson: string;
                        mergedComponentParamsJson: string;
                        serverParamsJson: string;
                        mainFilter: Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel;
                        componentVariant: Honeycomb.Tenant.Admin.IService.ViewModels.ComponentVariantDetailViewModel;
                        skin: Honeycomb.Tenant.Admin.IService.ViewModels.SkinDetailViewModel;
                        updateTime: Date;
                    }
                    export class ConfigurationUpsertModel {
                        configurationName: string;
                        description: string;
                        componentType: Honeycomb.Tenant.Admin.IService.Enums.ComponentType;
                        componentVariantID?: number;
                        skinID?: number;
                        mainFilterID?: number;
                        componentParamsJson: string;
                        serverParamsJson: string;
                    }
                    export class DataTableColumn {
                        isPrimary: boolean;
                        dataType: Honeycomb.Common.Enums.DataType;
                        required: boolean;
                        columnName: string;
                    }
                    export class FilterDialogItemValueViewModel {
                        filterDialogItemValueID: number;
                        filterDialogItemID: number;
                        placeholderName: string;
                        filterInputTypeID: number;
                        value: string;
                        displayOrder: number;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        filterInputType: Honeycomb.Tenant.Admin.IService.ViewModels.FilterInputTypeViewModel;
                        filterDialogItem: Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogItemViewModel;
                    }
                    export class FilterDialogItemViewModel {
                        filterDialogItemID: number;
                        filterDialogID: number;
                        name: string;
                        filterDialogItemType: Honeycomb.Common.Enums.FilterDialogItemType;
                        displayOrder: number;
                        filterID?: number;
                        filter: Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        filterDialog: Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogViewModel;
                        filterDialogItemValues: Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogItemValueViewModel>;
                    }
                    export class FilterDialogViewModel {
                        filterDialogID: number;
                        dialogType: Honeycomb.Common.Enums.DialogType;
                        name: string;
                        displayOrder: number;
                        updatedBy?: number;
                        filterDialogItems: Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogItemViewModel>;
                    }
                    export class FilterInputTypeViewModel {
                        filterInputTypeID: number;
                        placeholderName: string;
                        filterID: number;
                        dataType: Honeycomb.Common.Enums.DataType;
                        codeListID?: number;
                        regExValidator: string;
                        minValue?: number;
                        maxValue?: number;
                        required: boolean;
                        displayOrder: number;
                        filterDialogItemValues: Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterDialogItemValueViewModel>;
                        filter: Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel;
                    }
                    export class FilterQueryViewModel {
                        filterQueryID?: number;
                        filterID: number;
                        filterQueryName: string;
                        query: string;
                        active: boolean;
                        useOrder: number;
                        filterQueryType: Honeycomb.Common.Enums.FilterQueryType;
                        filter: Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel;
                    }
                    export class FilterViewModel {
                        filterID?: number;
                        filterName: string;
                        defaultQuery: string;
                        aggregation: string;
                        active: boolean;
                        filterType: Honeycomb.Tenant.Admin.IService.Enums.FilterType;
                        filterPurpose: Honeycomb.Common.Enums.FilterPurpose;
                        elasticSource: Honeycomb.Common.Enums.ElasticSource;
                        filterQueries: Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterQueryViewModel>;
                        filterInputTypes: Array<Honeycomb.Tenant.Admin.IService.ViewModels.FilterInputTypeViewModel>;
                        locKey: string;
                    }
                    export class GoogleAccount {
                        profile: string;
                        accountId: string;
                        webPropertyId: string;
                        profileId: string;
                        timeZone: string;
                        timeZoneInfo: string;
                        currency: string;
                    }
                    export class InterfaceModel {
                        interfaceID: number;
                        name: string;
                        description: string;
                        agenda: Honeycomb.Common.Enums.PortalAgenda;
                        interfaceType: Honeycomb.Common.Enums.InterfaceType;
                        customQuery: string;
                        dialogID?: number;
                        dialog: Honeycomb.Common.ImportExport.Interface.DialogViewModel;
                        exportSelector: Honeycomb.Common.Enums.ExportContentSelector;
                        lastExportedDate?: Date;
                        lastExportedRowVersion?: number;
                        interfaceFiles: Array<Honeycomb.Common.ImportExport.Interface.InterfaceFile>;
                        controllerType: string;
                        serviceType: string;
                        assemblyName: string;
                        instanceType: string;
                        controllerQualifiedName: string;
                        serviceQualifiedName: string;
                        methodName: string;
                    }
                    export class MaskViewModel {
                        numericMaskID: number;
                        type: Honeycomb.Common.Enums.MaskType;
                        mask: string;
                        created: Date;
                        updated: Date;
                    }
                    export class MediaExportRequest {
                        mediaIDs: Array<number>;
                    }
                    export class MediaExportResult {
                        token: string;
                        fileName: string;
                    }
                    export class NamedConfiguration {
                        namedConfigurationID: number;
                        name: string;
                        value: string;
                        updateTime: Date;
                        companyID?: number;
                    }
                    export class NumberBatchQueryModel {
                        count: number;
                        takeNumber: boolean;
                        numericMaskID?: number;
                        maskType?: Honeycomb.Common.Enums.MaskType;
                        origin: string;
                        locationNumber: number;
                        userNumber: string;
                    }
                    export class NumberBatchResultModel {
                        results: Array<Honeycomb.Tenant.Admin.IService.ViewModels.NumberResultModel>;
                    }
                    export class NumberQueryModel {
                        takeNumber: boolean;
                        numericMaskID?: number;
                        maskType?: Honeycomb.Common.Enums.MaskType;
                        origin: string;
                        locationNumber: number;
                        userNumber: string;
                    }
                    export class NumberResultModel {
                        number: string;
                    }
                    export class PortalUserFilterRequest {
                        userIDs: Array<number>;
                    }
                    export class PortalUserShort {
                        userID: number;
                        email: string;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                    }
                    export class Role {
                        roleID: number;
                        name: string;
                        description: string;
                        updateTime: Date;
                        forAdmin: boolean;
                        forContact: boolean;
                        attributes: Array<Honeycomb.Tenant.Admin.IService.ViewModels.RoleAttribute>;
                        appUiMaps: Array<Honeycomb.Tenant.Admin.IService.ViewModels.AppUiMap>;
                        uiRoles: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UiRole>;
                        apiRoles: Array<Honeycomb.Tenant.Admin.IService.ViewModels.ApiRole>;
                    }
                    export class RoleAttribute {
                        roleAttributeID: number;
                        roleID: number;
                        roleAttributeTypeID: number;
                        value: string;
                        role: Honeycomb.Tenant.Admin.IService.ViewModels.Role;
                        roleAttributeType: Honeycomb.Tenant.Admin.IService.ViewModels.RoleAttributeType;
                    }
                    export class RoleAttributeType {
                        roleAttributeTypeID: number;
                        meaning: Honeycomb.Common.Enums.AttributeMeaning.RoleAttributeMeaning;
                        roleAttributes: Array<Honeycomb.Tenant.Admin.IService.ViewModels.RoleAttribute>;
                        name: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                        codeListId?: number;
                    }
                    export class SettingViewModel {
                        settingKey: string;
                        settingValue: string;
                        valueType: Honeycomb.Common.Enums.SettingValueType;
                        companyID: number;
                        private: boolean;
                    }
                    export class SkinDetailViewModel {
                        hasNewSkin: boolean;
                        hasRemovedSkin: boolean;
                        updateCss: boolean;
                        skinID: number;
                        skinTemplateId?: number;
                        active: boolean;
                        skinSource: string;
                        skinName: string;
                        hasSkin: boolean;
                    }
                    export class SkinPalette {
                        skinPaletteId: number;
                        name: string;
                        description: string;
                        created: Date;
                        updated: Date;
                        skinPaletteEntry: Array<Honeycomb.Tenant.Admin.IService.ViewModels.SkinPaletteEntry>;
                    }
                    export class SkinPaletteEntry {
                        skinPaletteEntryID: number;
                        skinPaletteId: number;
                        name: string;
                        type: Honeycomb.Common.Enums.PaletteEntryType;
                        value: string;
                        created: Date;
                        updated: Date;
                    }
                    export class SkinTemplate {
                        skinTemplateId: number;
                        name: string;
                        template: string;
                        created: Date;
                        updated: Date;
                    }
                    export class SystemStorageViewModel {
                        settingKey: string;
                        settingValue: string;
                        valueType: Honeycomb.Common.Enums.SettingValueType;
                    }
                    export class TransactionTypeModel {
                        transactionTypeID: number;
                        transactionName: string;
                        type: Honeycomb.Common.Enums.TransactionType;
                        numericMaskID: number;
                    }
                    export class UiApiMap {
                        uiApiMapID: number;
                        uiRoleID: number;
                        apiRoleID: number;
                        created: Date;
                        uiRole: Honeycomb.Tenant.Admin.IService.ViewModels.UiRole;
                        apiRole: Honeycomb.Tenant.Admin.IService.ViewModels.ApiRole;
                    }
                    export class UiRole {
                        uiRoleID: number;
                        scope: string;
                        name: string;
                        description: string;
                        updateTime: Date;
                        scopedName: string;
                        uiApiMaps: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UiApiMap>;
                        appUiMaps: Array<Honeycomb.Tenant.Admin.IService.ViewModels.AppUiMap>;
                        apiRoles: Array<Honeycomb.Tenant.Admin.IService.ViewModels.ApiRole>;
                    }
                    export class UrlElement {
                        loc: string;
                        changefreq: string;
                        priority: string;
                        rowVersion: number;
                        urlType: Honeycomb.Common.Enums.SiteMapURLType;
                    }
                    export class Urlset {
                        urls: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UrlElement>;
                        xmlns: string;
                    }
                    export class UserDetailViewModel {
                        userID: number;
                        email: string;
                        firstName: string;
                        lastName: string;
                        password: string;
                        emailConfirmationRequired: boolean;
                        emailConfirmed: boolean;
                        passwordResetTokenValidTill?: Date;
                        updateTime: Date;
                        contactRoles: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserRoleViewModel>;
                    }
                    export class UserRoleViewModel {
                        roleID: number;
                        name: string;
                        assigned: boolean;
                    }
                    export class UserTrigger {
                        userTriggerID: number;
                        userTriggerTypeID: number;
                        triggerTypeName: string;
                        triggerParams: string;
                        lastData: string;
                        languageISOCode: string;
                        validTill: Date;
                        created: Date;
                        lastRun: Date;
                        userTriggerType: Honeycomb.Tenant.Admin.IService.ViewModels.UserTriggerType;
                    }
                    export class UserTriggerType {
                        userTriggerTypeID: number;
                        name: string;
                        description: string;
                        instanceType: string;
                        triggerUsage: Honeycomb.Common.Enums.UserTriggerUsage;
                        parameters: string;
                        created: Date;
                        userTriggers: Array<Honeycomb.Tenant.Admin.IService.ViewModels.UserTrigger>;
                    }
                    export class VueComponentUpdateRequest {
                        variantName: string;
                        html: string;
                        js: string;
                        css: string;
                        componentParamsJson: string;
                        sourceHtml: string;
                        sourceJs: string;
                        sourceCss: string;
                        componentFramework?: Honeycomb.Tenant.Admin.IService.Enums.ComponentFramework;
                        commitToGIT: boolean;
                        commitMessage: string;
                        apiKey: string;
                        forRefreh: boolean;
                        branch: string;
                    }
                    export class VuejsComponentConfigurationModel {
                        componentVariantID: number;
                        configurationName: string;
                        configurationNameAsIdentifier: string;
                        html: string;
                        js: string;
                        componentParamsJson: string;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {
                export namespace ViewModels {
                    export namespace GITHub {

                        export class GITHubComponentStatusResponse {
                            currentUpdateTime?: Date;
                            gitUpdateTime?: Date;
                            state: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileState;
                            gitHistory: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileHistoryResponse;
                        }
                        export class GITHubFile {
                            location: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileLocation;
                            name: string;
                            content: string;
                            fileExists: boolean;
                            attributes: { [key: string]: any };
                            filePath: string;
                            directoryPath: string;
                        }
                        export class GITHubFileContentRequest {
                            files: Array<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFile>;
                            apiKey: string;
                            branch: string;
                        }
                        export class GITHubFileContentResponse {
                            files: Array<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFile>;
                        }
                        export class GITHubFileHistory {
                            file: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFile;
                            state: Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileState;
                            history: Array<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileHistoryElement>;
                        }
                        export class GITHubFileHistoryElement {
                            created: Date;
                            userName: string;
                            commitMessage: string;
                        }
                        export class GITHubFileHistoryRequest {
                            files: Array<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFile>;
                            apiKey: string;
                            branch: string;
                        }
                        export class GITHubFileHistoryResponse {
                            filesHistory: Array<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFileHistory>;
                        }
                        export class GITHubFileUpdateRequest {
                            files: Array<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFile>;
                            message: string;
                            apiKey: string;
                            branch: string;
                        }
                        export class GITHubFileUpdateResponse {
                            files: Array<Honeycomb.Tenant.Admin.IService.ViewModels.GITHub.GITHubFile>;
                        }
                        export class GITHubUser {
                            login: string;
                            name: string;
                            avatarURL: string;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Catalog {
            export namespace IService {

                export class AddToProductSetByNumberRequest {
                    productSetId: number;
                    productNumbers: Array<string>;
                }
                export class AddToProductSetFulltextRequest {
                    productSetId: number;
                    search: string;
                }
                export class AddToProductSetRequest {
                    productSetId: number;
                    productIDs: Array<number>;
                }
                export class Aggregator {
                    aggregatorId: number;
                    aggregatorType: Honeycomb.Common.Enums.AggregatorType;
                    aggregatorName: string;
                    taxonomyUrl: string;
                    settings: string;
                    langIsoCode: string;
                    productFeedMapper: string;
                    productFeedUrl: string;
                    taxonomies: Array<Honeycomb.Tenant.Catalog.IService.Taxonomy>;
                    enabled: boolean;
                }
                export class AggregatorSettings {
                }
                export class AttributeDistinctValues {
                    attributeType: Honeycomb.Tenant.Catalog.IService.ProductAttributeType;
                    values: Array<Honeycomb.Tenant.Catalog.IService.DistinctValue>;
                }
                export class Background {
                    backgroundID: number;
                    default: boolean;
                    backgroundUid: string;
                    label: string;
                    sortOrder?: number;
                    backgroundType: Honeycomb.Common.Enums.BackgroundType;
                    productBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.ProductBackground>;
                    categoryBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.CategoryBackground>;
                    validFrom?: Date;
                    validTo?: Date;
                    refUrl: string;
                }
                export class Booking {
                    bookingId: number;
                    bookingUid: string;
                    occurenceId: number;
                    productVariantId: number;
                    slotId?: number;
                    transactionItemId?: number;
                    seat: string;
                    row: string;
                    sector: string;
                    updateTime: Date;
                    expire?: Date;
                    occurence: Honeycomb.Tenant.Catalog.IService.Occurence;
                    productVariant: Honeycomb.Tenant.Catalog.IService.ProductVariantSerializable;
                    slot: Honeycomb.Tenant.Catalog.IService.Slot;
                }
                export class CatalogDBStatus {
                    productCount: number;
                    categoriesCount: number;
                }
                export class Category {
                    deleted: boolean;
                    recVersion: number;
                    productCategory: Array<Honeycomb.Tenant.Catalog.IService.ProductCategory>;
                    categoryId: number;
                    imageGuid?: string;
                    backgroundImageGuid?: string;
                    published: boolean;
                    categoryCode: string;
                    redirectURL: string;
                    edition: Honeycomb.Common.Enums.Edition;
                    categoryNode: Honeycomb.Tenant.Catalog.IService.CategoryNodeSerializable;
                    categoryTexts: Array<Honeycomb.Tenant.Catalog.IService.CategoryTextSerializable>;
                    categoryIdentifiers: Array<Honeycomb.Tenant.Catalog.IService.CategoryIdentifierSerializable>;
                    categoryExtensions: Array<Honeycomb.Tenant.Catalog.IService.CategoryExtension>;
                    categoryProducts: Array<Honeycomb.Tenant.Catalog.IService.CategoryProduct>;
                    categoryAttributes: Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeSerializable>;
                    categoryBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.CategoryBackgroundSerializable>;
                }
                export class CategoryAttributeSerializable {
                    categoryAttributeID?: number;
                    categoryID: number;
                    categoryAttributeTypeID: number;
                    attrValue: string;
                    categoryAttributeType: Honeycomb.Tenant.Catalog.IService.CategoryAttributeTypeSerializable;
                }
                export class CategoryAttributeTypeSerializable {
                    categoryAttributeTypeID: number;
                    name: string;
                    dataType: Honeycomb.Common.Enums.DataType;
                    categoryAttributeMeaning: Honeycomb.Common.Enums.CategoryAttributeMeaning;
                }
                export class CategoryBackground {
                    categoryID: number;
                    backgroundID: number;
                    category: Honeycomb.Tenant.Catalog.IService.Category;
                    background: Honeycomb.Tenant.Catalog.IService.Background;
                }
                export class CategoryBackgroundSerializable {
                    backgroundID: number;
                    backgroundUid: string;
                    default: boolean;
                    label: string;
                    sortOrder?: number;
                    backgroundType: Honeycomb.Common.Enums.BackgroundType;
                    validFrom?: Date;
                    validTo?: Date;
                    refUrl: string;
                    isNew: boolean;
                }
                export class CategoryElastic {
                    texts: { [key: string]: Honeycomb.Tenant.Catalog.IService.CategoryTextSerializable };
                    inCategoryId: Array<number>;
                    isFeaturedProduct: boolean;
                    displayOrder: number;
                    categoryId: number;
                    imageGuid?: string;
                    backgroundImageGuid?: string;
                    published: boolean;
                    categoryCode: string;
                    redirectURL: string;
                    edition: Honeycomb.Common.Enums.Edition;
                    categoryNode: Honeycomb.Tenant.Catalog.IService.CategoryNodeSerializable;
                    categoryTexts: Array<Honeycomb.Tenant.Catalog.IService.CategoryTextSerializable>;
                    categoryIdentifiers: Array<Honeycomb.Tenant.Catalog.IService.CategoryIdentifierSerializable>;
                    categoryExtensions: Array<Honeycomb.Tenant.Catalog.IService.CategoryExtension>;
                    categoryProducts: Array<Honeycomb.Tenant.Catalog.IService.CategoryProduct>;
                    categoryAttributes: Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeSerializable>;
                    categoryBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.CategoryBackgroundSerializable>;
                }
                export class CategoryExtension {
                    categoryExtensionID: number;
                    categoryId: number;
                    languageISOCode: string;
                    categoryExtensionType: Honeycomb.Common.Enums.CategoryExtensionTypeEnum;
                    extensionValue: string;
                }
                export class CategoryIdentifierSerializable {
                    categoryId: number;
                    provider: Honeycomb.Common.Enums.ProductCategoryProvider;
                    identifier: string;
                }
                export class CategoryImported {
                    importStrategy: Honeycomb.Tenant.Catalog.IService.ImportStrategy;
                    categoryId: number;
                    imageGuid?: string;
                    backgroundImageGuid?: string;
                    published: boolean;
                    categoryCode: string;
                    redirectURL: string;
                    edition: Honeycomb.Common.Enums.Edition;
                    categoryNode: Honeycomb.Tenant.Catalog.IService.CategoryNodeSerializable;
                    categoryTexts: Array<Honeycomb.Tenant.Catalog.IService.CategoryTextSerializable>;
                    categoryIdentifiers: Array<Honeycomb.Tenant.Catalog.IService.CategoryIdentifierSerializable>;
                    categoryExtensions: Array<Honeycomb.Tenant.Catalog.IService.CategoryExtension>;
                    categoryProducts: Array<Honeycomb.Tenant.Catalog.IService.CategoryProduct>;
                    categoryAttributes: Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeSerializable>;
                    categoryBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.CategoryBackgroundSerializable>;
                }
                export class CategoryNodeSerializable {
                    categoryId: number;
                    categoryCode: string;
                    stringPath: string;
                    binaryPath: Array<number>;
                    pathLevel?: number;
                    parentCategoryId?: number;
                    parentCategoryCode: string;
                    parentStringPath: string;
                    parentBinaryPath: Array<number>;
                    parentPathLevel?: number;
                    rootCategoryId?: number;
                    rootCategoryCode: string;
                    rootStringPath: string;
                    rootBinaryPath: Array<number>;
                    rootPathLevel?: number;
                }
                export class CategoryProduct {
                    productId: number;
                    isFeaturedProduct: boolean;
                    displayOrder: number;
                    productName: string;
                    gtin: string;
                }
                export class CategoryProductCount {
                    categoryID: number;
                    productsActive: number;
                    productsAll: number;
                    level: number;
                }
                export class CategorySerializable {
                    categoryId: number;
                    imageGuid?: string;
                    backgroundImageGuid?: string;
                    published: boolean;
                    categoryCode: string;
                    redirectURL: string;
                    edition: Honeycomb.Common.Enums.Edition;
                    categoryNode: Honeycomb.Tenant.Catalog.IService.CategoryNodeSerializable;
                    categoryTexts: Array<Honeycomb.Tenant.Catalog.IService.CategoryTextSerializable>;
                    categoryIdentifiers: Array<Honeycomb.Tenant.Catalog.IService.CategoryIdentifierSerializable>;
                    categoryExtensions: Array<Honeycomb.Tenant.Catalog.IService.CategoryExtension>;
                    categoryProducts: Array<Honeycomb.Tenant.Catalog.IService.CategoryProduct>;
                    categoryAttributes: Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeSerializable>;
                    categoryBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.CategoryBackgroundSerializable>;
                }
                export class CategoryTextSerializable {
                    categoryId: number;
                    languageIsocode: string;
                    shortName: string;
                    fullName: string;
                    description: string;
                    shortDescription: string;
                }
                export class CategoryWithProducts {
                    items: Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>;
                    categoryId: number;
                    imageGuid?: string;
                    backgroundImageGuid?: string;
                    published: boolean;
                    categoryCode: string;
                    redirectURL: string;
                    edition: Honeycomb.Common.Enums.Edition;
                    categoryNode: Honeycomb.Tenant.Catalog.IService.CategoryNodeSerializable;
                    categoryTexts: Array<Honeycomb.Tenant.Catalog.IService.CategoryTextSerializable>;
                    categoryIdentifiers: Array<Honeycomb.Tenant.Catalog.IService.CategoryIdentifierSerializable>;
                    categoryExtensions: Array<Honeycomb.Tenant.Catalog.IService.CategoryExtension>;
                    categoryProducts: Array<Honeycomb.Tenant.Catalog.IService.CategoryProduct>;
                    categoryAttributes: Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeSerializable>;
                    categoryBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.CategoryBackgroundSerializable>;
                }
                export class CategoryWithProductsList {
                    productCategories: Array<Honeycomb.Tenant.Catalog.IService.CategoryWithProducts>;
                }
                export class ConvertProductNumberBase {
                    productNumber: string;
                    variantIndexes: Array<number>;
                    tag: any;
                }
                export class ConvertProductVariantIDBase {
                    productVariantID: number;
                    tag: any;
                }
                export class CreateProductSetFulltextRequest {
                    name: string;
                    search: string;
                }
                export class CreateProductSetRequest {
                    name: string;
                    productIDs: Array<number>;
                }
                export class DistinctValue {
                    value: string;
                    caption: string;
                }
                export class DynamicProductSetSelector {
                    dynamicProductSetSelectorId: number;
                    productSetId: number;
                    selectorId: number;
                    queryParameters: string;
                }
                export class EventSource {
                    eventSourceId: number;
                    name: string;
                    slotPlanId: number;
                    mediaId: number;
                    updateTime: Date;
                    occurences: Array<Honeycomb.Tenant.Catalog.IService.Occurence>;
                    schedulers: Array<Honeycomb.Tenant.Catalog.IService.Scheduler>;
                    slotPlan: Honeycomb.Tenant.Catalog.IService.SlotPlan;
                    product: Honeycomb.Tenant.Catalog.IService.ProductElastic;
                }
                export class ExternalImage {
                    isPrimary: boolean;
                    externalURI: string;
                }
                export class FilterPanel {
                    configName: string;
                    groups: Array<Honeycomb.Tenant.Catalog.IService.PanelGroup>;
                }
                export class FilterSorting {
                    sortField: string;
                    sortDir: number;
                }
                export class HeurekaSettingsFull {
                    ozApiKey: string;
                    ozApiUrl: string;
                    conversionApiKey: string;
                    conversionApiUrl: string;
                }
                export class ImportStrategy {
                    rootCode: string;
                    createIfNotFound: boolean;
                    updateIfFound: boolean;
                }
                export class ListOfProducts {
                    productList: Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>;
                }
                export class MenuCategoriesList {
                    items: Array<Honeycomb.Tenant.Catalog.IService.MenuCategory>;
                }
                export class MenuCategory {
                    categoryId: number;
                    imageGuid?: string;
                    backgroundImageGuid?: string;
                    categoryCode: string;
                    shortName: string;
                    fullName: string;
                    description: string;
                    shortDescription: string;
                    urlKey: string;
                    redirectURL: string;
                    published: boolean;
                    title: string;
                    metaDescription: string;
                    categoryAttributes: Array<Honeycomb.Tenant.Catalog.IService.CategoryAttributeSerializable>;
                    categoryBackgrounds: Array<Honeycomb.Tenant.Catalog.IService.CategoryBackgroundSerializable>;
                    categoryExtensions: Array<Honeycomb.Tenant.Catalog.IService.CategoryExtension>;
                    items: Array<Honeycomb.Tenant.Catalog.IService.MenuCategory>;
                }
                export class Occurence {
                    occurenceId: number;
                    eventSourceId: number;
                    dateFrom: Date;
                    dateTo: Date;
                    state: Honeycomb.Common.Enums.OccurenceState;
                    note: string;
                    updateTime: Date;
                    minutes: number;
                    bookings: Array<Honeycomb.Tenant.Catalog.IService.Booking>;
                    eventSource: Honeycomb.Tenant.Catalog.IService.EventSource;
                }
                export class OccurenceFilter {
                    from?: Date;
                    to?: Date;
                    locationId?: number;
                    eventSourceId?: number;
                    state?: Honeycomb.Common.Enums.OccurenceState;
                    occurenceId?: number;
                }
                export class OccurenceView {
                    occurenceId: number;
                    eventSourceId: number;
                    dateFrom: Date;
                    dateTo: Date;
                    minutes: number;
                    state: Honeycomb.Common.Enums.OccurenceState;
                    name: string;
                    shortDescription: string;
                    fullDescription: string;
                    imageURL: string;
                    priceFrom: number;
                    priceTo: number;
                    capacity: number;
                    booked: number;
                    locationName: string;
                }
                export class PanelGroup {
                    key: string;
                    title: string;
                    type: Honeycomb.Tenant.Catalog.IService.PanelGroupType;
                    items: Array<Honeycomb.Tenant.Catalog.IService.PanelItem>;
                }
                export class PanelItem {
                    key: string;
                    title: string;
                    type: Honeycomb.Tenant.Catalog.IService.PanelItemType;
                    value: string;
                    isSelected: boolean;
                    isCountRelative: boolean;
                    count: number;
                }
                export class Product {
                    adminComment: string;
                    productVariants: Array<Honeycomb.Tenant.Catalog.IService.ProductVariantSerializable>;
                    productAttributes: Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeSerializable>;
                    productCategories: Array<Honeycomb.Tenant.Catalog.IService.CategoryElastic>;
                    productTexts: Array<Honeycomb.Tenant.Catalog.IService.ProductTextSerializable>;
                    recVersion: number;
                    productId: number;
                    name: string;
                    productNumber: string;
                    manufacturerPartNumber: string;
                    timestamp: number;
                    createdOnUtc: Date;
                    updatedOnUtc: Date;
                    measurementUnitID: number;
                    edition: Honeycomb.Common.Enums.Edition;
                    productType: Honeycomb.Common.Enums.ProductType;
                    backgroundImageUid?: string;
                    noDiscount: boolean;
                    mappedAttributes: Honeycomb.Tenant.Catalog.IService.Models.Editions.ProductMappedAttributes;
                    productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                    productAttachments: Array<Honeycomb.Tenant.Catalog.IService.ProductAttachment>;
                    productDiscounts: Array<Honeycomb.Tenant.Catalog.IService.ProductDiscount>;
                    relatedProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    relatedToProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    productSetWhiteList: Array<Honeycomb.Tenant.Catalog.IService.ProductSetWhiteList>;
                    productCompositions: Array<Honeycomb.Tenant.Catalog.IService.ProductComposition>;
                    primaryProductId?: number;
                    primaryProduct: Honeycomb.Tenant.Catalog.IService.ProductSerializable;
                    secondaryProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>;
                }
                export class ProductAnswer {
                    productAnswerID: number;
                    productQuestionID: number;
                    userID: number;
                    userName: string;
                    answer: string;
                    created: Date;
                    productAnswerUID: string;
                }
                export class ProductAttachment {
                    productAttachmentID?: number;
                    productID: number;
                    productAttachmentUID: string;
                    attachmentName: string;
                    attachmentDescription: string;
                    displayOrder: number;
                    created: Date;
                    updateTime: Date;
                }
                export class ProductAttribute {
                    product: Honeycomb.Tenant.Catalog.IService.Product;
                    productId: number;
                    productAttributeTypeId: number;
                    value: string;
                    productAttributeBlobID?: number;
                    productAttributeType: Honeycomb.Tenant.Catalog.IService.ProductAttributeTypeSerializable;
                }
                export class ProductAttributeSerializable {
                    productId: number;
                    productAttributeTypeId: number;
                    value: string;
                    productAttributeBlobID?: number;
                    productAttributeType: Honeycomb.Tenant.Catalog.IService.ProductAttributeTypeSerializable;
                }
                export class ProductAttributesSearchRequest {
                    elements: Array<Honeycomb.Tenant.Catalog.IService.ProductAttributesSearchRequestElement>;
                }
                export class ProductAttributesSearchRequestElement {
                    attributeName: string;
                    values: Array<string>;
                }
                export class ProductAttributeType {
                    blobAttribute: boolean;
                    productAttributeTypeId: number;
                    meaning: Honeycomb.Common.Enums.AttributeMeaning.ProductAttributeMeaning;
                    productAttributes: Array<Honeycomb.Tenant.Catalog.IService.ProductAttribute>;
                    name: string;
                    description: string;
                    dataType: Honeycomb.Common.Enums.DataType;
                    codeListId?: number;
                }
                export class ProductAttributeTypeSerializable {
                    productAttributeTypeId: number;
                    meaning: Honeycomb.Common.Enums.AttributeMeaning.ProductAttributeMeaning;
                    blobAttribute: boolean;
                    name: string;
                    description: string;
                    dataType: Honeycomb.Common.Enums.DataType;
                    codeListId?: number;
                }
                export class ProductBackground {
                    productID: number;
                    backgroundID: number;
                    product: Honeycomb.Tenant.Catalog.IService.Product;
                    background: Honeycomb.Tenant.Catalog.IService.Background;
                }
                export class ProductCategory {
                    productId: number;
                    categoryId: number;
                    isFeaturedProduct: boolean;
                    displayOrder: number;
                }
                export class ProductComposition {
                    productId: number;
                    productVariantId: number;
                    amount: number;
                    productImage: Honeycomb.Tenant.Catalog.IService.ProductImage;
                    productNumber: string;
                    name: string;
                    product: Honeycomb.Tenant.Catalog.IService.ProductSerializable;
                    productVariant: Honeycomb.Tenant.Catalog.IService.ProductVariantSerializable;
                }
                export class ProductDiscount {
                    productDiscountID: number;
                    productDiscountTypeID: number;
                    userID?: number;
                    clubID?: number;
                    productSetID?: number;
                    productID?: number;
                    productDiscountType: Honeycomb.Tenant.Catalog.IService.ProductDiscountType;
                    product: Honeycomb.Tenant.Catalog.IService.ProductSerializable;
                    productSet: Honeycomb.Tenant.Catalog.IService.ProductSet;
                }
                export class ProductDiscountType {
                    productDiscountTypeID: number;
                    name: string;
                    description: string;
                    priority: number;
                    active: boolean;
                    type: Honeycomb.Common.Enums.DiscountValueType;
                    resultType: Honeycomb.Common.Enums.DiscountResultType;
                    value: number;
                    validSince?: Date;
                    validTill?: Date;
                }
                export class ProductElastic {
                    shortDescription: string;
                    fullDescription: string;
                    lookupValues: { [key: string]: any };
                    attributes: { [key: string]: any };
                    texts: { [key: string]: Honeycomb.Tenant.Catalog.IService.ProductTextSerializable };
                    inCategoryId: Array<number>;
                    ratings: Honeycomb.Common.DataProviders.AggregatedDecimalStats;
                    primaryVariant: Honeycomb.Tenant.Catalog.IService.ProductVariantSerializable;
                    categoryDisplayOrder: { [key: number]: number };
                    categoryFeaturedProduct: { [key: number]: boolean };
                    productId: number;
                    name: string;
                    recVersion: number;
                    productNumber: string;
                    manufacturerPartNumber: string;
                    timestamp: number;
                    createdOnUtc: Date;
                    updatedOnUtc: Date;
                    measurementUnitID: number;
                    edition: Honeycomb.Common.Enums.Edition;
                    productType: Honeycomb.Common.Enums.ProductType;
                    backgroundImageUid?: string;
                    noDiscount: boolean;
                    mappedAttributes: Honeycomb.Tenant.Catalog.IService.Models.Editions.ProductMappedAttributes;
                    productAttributes: Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeSerializable>;
                    productVariants: Array<Honeycomb.Tenant.Catalog.IService.ProductVariantSerializable>;
                    productCategories: Array<Honeycomb.Tenant.Catalog.IService.CategoryElastic>;
                    productTexts: Array<Honeycomb.Tenant.Catalog.IService.ProductTextSerializable>;
                    productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                    productAttachments: Array<Honeycomb.Tenant.Catalog.IService.ProductAttachment>;
                    productDiscounts: Array<Honeycomb.Tenant.Catalog.IService.ProductDiscount>;
                    relatedProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    relatedToProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    productSetWhiteList: Array<Honeycomb.Tenant.Catalog.IService.ProductSetWhiteList>;
                    productCompositions: Array<Honeycomb.Tenant.Catalog.IService.ProductComposition>;
                    primaryProductId?: number;
                    primaryProduct: Honeycomb.Tenant.Catalog.IService.ProductSerializable;
                    secondaryProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>;
                }
                export class ProductFilterElement {
                    filterName: string;
                    filterParams: { [key: string]: any };
                }
                export class ProductFilterPagination {
                    pageSize: number;
                    pageIndex: number;
                    pagesBefore: number;
                }
                export class ProductImage {
                    productImageId: number;
                    productId: number;
                    productVariantId?: number;
                    imageType: Honeycomb.Common.Enums.ImageType;
                    recordUID?: string;
                    externalURI: string;
                    localPath: string;
                    purpose: string;
                    parentProductImageId?: number;
                    isPrimary: boolean;
                }
                export class ProductImageSerializable {
                    imageRecordUID: string;
                    imageURL: string;
                    thumbnailRecordUID: string;
                    thumbnailURL: string;
                    purpose: string;
                    productVariantID?: number;
                    parentProductImageId?: number;
                    productId: number;
                    productImageId: number;
                    isPrimary: boolean;
                }
                export class ProductImported {
                    importStrategy: Honeycomb.Tenant.Catalog.IService.ProductImportStrategy;
                    productCategories: Array<Honeycomb.Tenant.Catalog.IService.CategoryElastic>;
                    productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                    productId: number;
                    name: string;
                    recVersion: number;
                    productNumber: string;
                    manufacturerPartNumber: string;
                    timestamp: number;
                    createdOnUtc: Date;
                    updatedOnUtc: Date;
                    measurementUnitID: number;
                    edition: Honeycomb.Common.Enums.Edition;
                    productType: Honeycomb.Common.Enums.ProductType;
                    backgroundImageUid?: string;
                    noDiscount: boolean;
                    mappedAttributes: Honeycomb.Tenant.Catalog.IService.Models.Editions.ProductMappedAttributes;
                    productAttributes: Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeSerializable>;
                    productVariants: Array<Honeycomb.Tenant.Catalog.IService.ProductVariantSerializable>;
                    productTexts: Array<Honeycomb.Tenant.Catalog.IService.ProductTextSerializable>;
                    productAttachments: Array<Honeycomb.Tenant.Catalog.IService.ProductAttachment>;
                    productDiscounts: Array<Honeycomb.Tenant.Catalog.IService.ProductDiscount>;
                    relatedProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    relatedToProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    productSetWhiteList: Array<Honeycomb.Tenant.Catalog.IService.ProductSetWhiteList>;
                    productCompositions: Array<Honeycomb.Tenant.Catalog.IService.ProductComposition>;
                    primaryProductId?: number;
                    primaryProduct: Honeycomb.Tenant.Catalog.IService.ProductSerializable;
                    secondaryProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>;
                }
                export class ProductImportImage {
                    productNumber: string;
                    externalImages: Array<Honeycomb.Tenant.Catalog.IService.ExternalImage>;
                }
                export class ProductImportImagesRequest {
                    images: Array<Honeycomb.Tenant.Catalog.IService.ProductImportImage>;
                }
                export class ProductImportStrategy {
                    deleteStockOnProductVariantDelete: boolean;
                    insertToCategories: boolean;
                    deleteFromCategories: boolean;
                    updateOnlyTextsWithContent: boolean;
                    markAllDbVariantsDeletedIfImportHasNoActiveVariant: boolean;
                }
                export class ProductListItem {
                    productId: number;
                    productNumber: string;
                    manufacturerPartNumber: string;
                    name: string;
                    nameURLSafe: string;
                    productVariants: Array<Honeycomb.Tenant.Catalog.IService.ProductListVariant>;
                    productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                }
                export class ProductListItemsResult {
                    total: number;
                    items: Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>;
                    filterPanel: Honeycomb.Tenant.Catalog.IService.FilterPanel;
                    fallbackLevel: number;
                }
                export class ProductListVariant {
                    productVariantId: number;
                    gtin: string;
                    retailPrice?: number;
                    originalPrice?: number;
                    stock?: number;
                    vatId?: number;
                    isPrimary: boolean;
                }
                export class ProductPriceSerializable {
                    productPriceID: number;
                    productVariantID: number;
                    priceType: Honeycomb.Common.Enums.PriceType;
                    priceIndex: number;
                    pricePriority?: number;
                    retailPrice: number;
                    validFrom?: Date;
                    validTill?: Date;
                    currencyISOCode: string;
                }
                export class ProductQuestion {
                    productQuestionID: number;
                    productID?: number;
                    userID?: number;
                    userName: string;
                    phone: string;
                    email: string;
                    question: string;
                    isPrivate: boolean;
                    status: Honeycomb.Common.Enums.ProductRatingStatus;
                    modifiedByAdmin: boolean;
                    created: Date;
                    modified: Date;
                    productAnswers: Array<Honeycomb.Tenant.Catalog.IService.ProductAnswer>;
                    product: Honeycomb.Tenant.Catalog.IService.ProductElastic;
                    productQuestionUID: string;
                }
                export class ProductRating {
                    productRatingID: number;
                    productID: number;
                    userID?: number;
                    rating: number;
                    nickName: string;
                    comment: string;
                    status: Honeycomb.Common.Enums.ProductRatingStatus;
                    modifiedByAdmin: boolean;
                    created: Date;
                    modified: Date;
                    productRatingUID: string;
                }
                export class ProductRelation {
                    productRelationID: number;
                    productID: number;
                    relatedProductID: number;
                    sortOrder: number;
                    productRelationType: Honeycomb.Common.Enums.ProductRelationType;
                    relatedProductNumber: string;
                    relatedProductText: string;
                    primaryImage: Honeycomb.Tenant.Catalog.IService.ProductImageSerializable;
                }
                export class ProductSearchForTemplateModel {
                    inputDataType: Honeycomb.Common.Enums.TemplateInputDataType;
                    inputData: Array<string>;
                    sortAccordingInputData: boolean;
                }
                export class ProductSearchResult {
                    totalCount: number;
                    items: Array<Honeycomb.Tenant.Catalog.IService.ProductElastic>;
                }
                export class ProductSerializable {
                    productId: number;
                    name: string;
                    recVersion: number;
                    productNumber: string;
                    manufacturerPartNumber: string;
                    timestamp: number;
                    createdOnUtc: Date;
                    updatedOnUtc: Date;
                    measurementUnitID: number;
                    edition: Honeycomb.Common.Enums.Edition;
                    productType: Honeycomb.Common.Enums.ProductType;
                    backgroundImageUid?: string;
                    noDiscount: boolean;
                    mappedAttributes: Honeycomb.Tenant.Catalog.IService.Models.Editions.ProductMappedAttributes;
                    productAttributes: Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeSerializable>;
                    productVariants: Array<Honeycomb.Tenant.Catalog.IService.ProductVariantSerializable>;
                    productCategories: Array<Honeycomb.Tenant.Catalog.IService.CategoryElastic>;
                    productTexts: Array<Honeycomb.Tenant.Catalog.IService.ProductTextSerializable>;
                    productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                    productAttachments: Array<Honeycomb.Tenant.Catalog.IService.ProductAttachment>;
                    productDiscounts: Array<Honeycomb.Tenant.Catalog.IService.ProductDiscount>;
                    relatedProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    relatedToProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductRelation>;
                    productSetWhiteList: Array<Honeycomb.Tenant.Catalog.IService.ProductSetWhiteList>;
                    productCompositions: Array<Honeycomb.Tenant.Catalog.IService.ProductComposition>;
                    primaryProductId?: number;
                    primaryProduct: Honeycomb.Tenant.Catalog.IService.ProductSerializable;
                    secondaryProducts: Array<Honeycomb.Tenant.Catalog.IService.ProductSerializable>;
                }
                export class ProductServiceFilter {
                    filters: Array<Honeycomb.Tenant.Catalog.IService.ProductFilterElement>;
                    pagination: Honeycomb.Tenant.Catalog.IService.ProductFilterPagination;
                    sorting: Array<Honeycomb.Tenant.Catalog.IService.FilterSorting>;
                    filterPanel: Honeycomb.Tenant.Catalog.IService.FilterPanel;
                    usePersonalization: boolean;
                    locationID?: number;
                }
                export class ProductSet {
                    productSetId: number;
                    name: string;
                    type: Honeycomb.Common.Enums.ListType;
                    isActive: boolean;
                    parameters: string;
                    created: Date;
                    updated: Date;
                    dynamicProductSetSelector: Array<Honeycomb.Tenant.Catalog.IService.DynamicProductSetSelector>;
                    productSetBlackList: Array<Honeycomb.Tenant.Catalog.IService.ProductSetBlackList>;
                    productSetWhiteList: Array<Honeycomb.Tenant.Catalog.IService.ProductSetWhiteList>;
                    productDiscounts: Array<Honeycomb.Tenant.Catalog.IService.ProductDiscount>;
                }
                export class ProductSetBlackList {
                    productProductSetBlackListId: number;
                    productId: number;
                    productSetId: number;
                }
                export class ProductSetOverview {
                    productSetId: number;
                    name: string;
                    type: number;
                    isActive: boolean;
                    parameters: string;
                    created: Date;
                    updated: Date;
                    productCount: number;
                }
                export class ProductSetWhiteList {
                    productProductSetId: number;
                    productId: number;
                    productSetId: number;
                }
                export class ProductStockRequest {
                    formulaId: number;
                    locationId: number;
                    ids: Array<number>;
                }
                export class ProductText {
                    product: Honeycomb.Tenant.Catalog.IService.Product;
                    productId: number;
                    languageIsocode: string;
                    name: string;
                    shortDescription: string;
                    fullDescription: string;
                }
                export class ProductTextSerializable {
                    productId: number;
                    languageIsocode: string;
                    name: string;
                    shortDescription: string;
                    fullDescription: string;
                }
                export class ProductTransTypeByID {
                    productVariantID: number;
                    timeStamp: number;
                    quantity: number;
                }
                export class ProductTransTypeByNumber {
                    productNumber: string;
                    variantIndexes: Array<number>;
                    timeStamp: number;
                    quantity: number;
                }
                export class ProductVariable {
                    productVariableValues: Array<Honeycomb.Tenant.Catalog.IService.ProductVariableValue>;
                    productVariableId: number;
                    name: string;
                    description: string;
                }
                export class ProductVariableMapping {
                    productVariant: Honeycomb.Tenant.Catalog.IService.ProductVariant;
                    productVariableValue: Honeycomb.Tenant.Catalog.IService.ProductVariableValueSerializable;
                    productVariantId: number;
                    productVariableValueId: number;
                    imageGuid?: string;
                }
                export class ProductVariableMappingSerializable {
                    productVariantId: number;
                    productVariableValueId: number;
                    imageGuid?: string;
                    productVariableValue: Honeycomb.Tenant.Catalog.IService.ProductVariableValueSerializable;
                }
                export class ProductVariableSerializable {
                    productVariableId: number;
                    name: string;
                    description: string;
                    productVariableValues: Array<Honeycomb.Tenant.Catalog.IService.ProductVariableValue>;
                }
                export class ProductVariableValue {
                    productVariable: Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable;
                    productVariableMappings: Array<Honeycomb.Tenant.Catalog.IService.ProductVariableMapping>;
                    productVariableValueId: number;
                    productVariableId: number;
                    variableIndex: number;
                    variableValue: string;
                    isDefault: boolean;
                    displayOrder: number;
                }
                export class ProductVariableValueSerializable {
                    productVariableValueId: number;
                    productVariableId: number;
                    variableIndex: number;
                    variableValue: string;
                    isDefault: boolean;
                    displayOrder: number;
                    productVariable: Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable;
                }
                export class ProductVariant {
                    hidden: boolean;
                    product: Honeycomb.Tenant.Catalog.IService.Product;
                    productVariableMappings: Array<Honeycomb.Tenant.Catalog.IService.ProductVariableMappingSerializable>;
                    productVariantId: number;
                    productId: number;
                    gtin: string;
                    quantity: number;
                    isPrimary: boolean;
                    deleted: boolean;
                    manufacturerPartNumber: string;
                    vatId?: number;
                    vatPercent: number;
                    priceWithVAT: boolean;
                    productVariantUid?: string;
                    minOrder: number;
                    maxOrder: number;
                    multiple: number;
                    productPrices: Array<Honeycomb.Tenant.Catalog.IService.ProductPriceSerializable>;
                    stockList: Array<Honeycomb.Tenant.Catalog.IService.Stock>;
                    stock?: number;
                    cummulatedStock?: number;
                    externalStock?: number;
                    locationStock?: number;
                    preferredCurrencyISOCode: string;
                    givenDiscount: Honeycomb.Common.Db.ProductDiscounts.ProductDiscountResult;
                    retailPriceBeforeDiscount: number;
                    retailPriceWithVATBeforeDiscount: number;
                    retailPriceWithoutVATBeforeDiscount: number;
                    vatValueBeforeDiscount: number;
                    retailPrice: number;
                    retailPriceWithVAT: number;
                    retailPriceWithoutVAT: number;
                    vatValue: number;
                    currencyISOCode: string;
                    pricesMap: { [key: string]: any };
                    originalPrice?: number;
                    purchasePrice?: number;
                }
                export class ProductVariantSerializable {
                    productVariantId: number;
                    productId: number;
                    gtin: string;
                    quantity: number;
                    isPrimary: boolean;
                    deleted: boolean;
                    hidden: boolean;
                    manufacturerPartNumber: string;
                    vatId?: number;
                    vatPercent: number;
                    priceWithVAT: boolean;
                    productVariantUid?: string;
                    minOrder: number;
                    maxOrder: number;
                    multiple: number;
                    productPrices: Array<Honeycomb.Tenant.Catalog.IService.ProductPriceSerializable>;
                    productVariableMappings: Array<Honeycomb.Tenant.Catalog.IService.ProductVariableMappingSerializable>;
                    stockList: Array<Honeycomb.Tenant.Catalog.IService.Stock>;
                    stock?: number;
                    cummulatedStock?: number;
                    externalStock?: number;
                    locationStock?: number;
                    preferredCurrencyISOCode: string;
                    givenDiscount: Honeycomb.Common.Db.ProductDiscounts.ProductDiscountResult;
                    retailPriceBeforeDiscount: number;
                    retailPriceWithVATBeforeDiscount: number;
                    retailPriceWithoutVATBeforeDiscount: number;
                    vatValueBeforeDiscount: number;
                    retailPrice: number;
                    retailPriceWithVAT: number;
                    retailPriceWithoutVAT: number;
                    vatValue: number;
                    currencyISOCode: string;
                    pricesMap: { [key: string]: any };
                    originalPrice?: number;
                    purchasePrice?: number;
                }
                export class SearchProductsInProductSet {
                    productSetId: number;
                    sortColumn: string;
                    sortDirection: Honeycomb.Common.SortDirection;
                    pageSize?: number;
                    page: number;
                    searchString: string;
                }
                export class SetRelationParentProductsRequest {
                    relatedProductID: number;
                    relatedToProductIds: Array<number>;
                    productRelationType: Honeycomb.Common.Enums.ProductRelationType;
                }
                export class Scheduler {
                    schedulerId: number;
                    eventSourceId: number;
                    name: string;
                    dateTimeStart: Date;
                    timeSpan: number;
                    periodType: Honeycomb.Common.Enums.PeriodType;
                    occursTill: Date;
                    periodLength: number;
                    nthOccurence: number;
                    monday: boolean;
                    tuesday: boolean;
                    wednesday: boolean;
                    thursday: boolean;
                    friday: boolean;
                    saturday: boolean;
                    sunday: boolean;
                    timeZoneInfoId: string;
                    alldays: boolean;
                    eventSource: Honeycomb.Tenant.Catalog.IService.EventSource;
                }
                export class SiteMapImagesLinksModel {
                    isPrimary: boolean;
                    recordUID?: string;
                    localPath: string;
                    externalURI: string;
                    imageType: Honeycomb.Common.Enums.ImageType;
                }
                export class SiteMapImagesModel {
                    productId: number;
                    productNumber: string;
                    name: string;
                    rowVersion: number;
                    images: Array<Honeycomb.Tenant.Catalog.IService.SiteMapImagesLinksModel>;
                }
                export class Slot {
                    slotId: number;
                    slotPlanId: number;
                    productVariableValueId: number;
                    capacity: number;
                    seat: string;
                    row: string;
                    sector: string;
                    coordinates: string;
                    productVariableValue: Honeycomb.Tenant.Catalog.IService.ProductVariableValueSerializable;
                }
                export class SlotPlan {
                    slotPlanId: number;
                    locationId: number;
                    productVariableId: number;
                    name: string;
                    canvas: string;
                    slots: Array<Honeycomb.Tenant.Catalog.IService.Slot>;
                    timeZoneInfoId: string;
                    productVariable: Honeycomb.Tenant.Catalog.IService.ProductVariableSerializable;
                }
                export class Stock {
                    stockID: number;
                    productVariantID: number;
                    locationID: number;
                    transactionTypeID: number;
                    quantity: number;
                    modified: Date;
                    recVer: number;
                }
                export class SuggestData {
                    userInput: string;
                    configurationName: string;
                    typedData: {};
                }
                export class Suggestion {
                    type: Honeycomb.Common.Enums.SuggestionType;
                    imageUrl: string;
                    text: string;
                    identifier: string;
                    linkText: string;
                    optionals: { [key: string]: string };
                }
                export class SuggestionTypeData {
                    from: number;
                    size: number;
                    total: number;
                    suggestions: Array<Honeycomb.Tenant.Catalog.IService.Suggestion>;
                }
                export class Taxonomy {
                    taxonomyId: number;
                    aggregatorId: number;
                    externalId?: number;
                    level: number;
                    sortOrder: number;
                    shortName: string;
                    descriptor: string;
                    selected: boolean;
                }
                export class TransTypeByID {
                    locationID: number;
                    transactionType: number;
                    products: Array<Honeycomb.Tenant.Catalog.IService.ProductTransTypeByID>;
                }
                export class TransTypeByNumber {
                    locationNumber: string;
                    transactionType: number;
                    products: Array<Honeycomb.Tenant.Catalog.IService.ProductTransTypeByNumber>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Catalog {
            export namespace IService {
                export namespace Models {
                    export namespace Editions {

                        export class ProductMappedAttributes {
                            margin: number;
                            productDimension: Honeycomb.Common.ViewModels.Dimensions;
                            packageDimension: Honeycomb.Common.ViewModels.Dimensions;
                            dateOfAvailability: Date;
                            freeDelivery: boolean;
                            specialProduct: Honeycomb.Common.DateRange;
                            recommended: Honeycomb.Common.DateRange;
                            lastChance: Honeycomb.Common.DateRange;
                            new: Honeycomb.Common.DateRange;
                            customerChoice: Honeycomb.Common.DateRange;
                            inAction: Honeycomb.Common.DateRange;
                            publiclyKnown: Honeycomb.Common.DateRange;
                            videoScript: string;
                            videoURL: string;
                            ordering: string;
                            sale: Honeycomb.Common.DateRange;
                            brand: string;
                            packageKind: string;
                            producer: string;
                            usage: Honeycomb.Common.ViewModels.LocalizedTexts;
                            warning: Honeycomb.Common.ViewModels.LocalizedTexts;
                            usageRecomendation: Honeycomb.Common.ViewModels.LocalizedTexts;
                            originCountry: string;
                            appliedDiscount: number;
                            useBeforeDate: Date;
                            packageWeight: string;
                            complexity: number;
                            backgroundImage: string;
                            otherAttributes: { [key: string]: string };
                            attributeTypes: Array<Honeycomb.Tenant.Catalog.IService.ProductAttributeTypeSerializable>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {

                export class Attachment {
                    attachmentId: number;
                    userId: number;
                    memo: string;
                    name: string;
                    documentUid?: string;
                    created: Date;
                    modified: Date;
                    deleted: boolean;
                }
                export class Attendance {
                    attendanceID: number;
                    locationID: number;
                    contactUserID: number;
                    updatedByContactUserID?: number;
                    updatedBy?: number;
                    attendanceRecords: Array<Honeycomb.Tenant.Contact.IService.AttendanceRecord>;
                }
                export class AttendanceRecord {
                    attendanceRecordID: number;
                    attendanceID: number;
                    recordTime: Date;
                    latitude: number;
                    longitude: number;
                    accurancy: number;
                    positionTimestamp: number;
                    attendanceRecordTypeID: number;
                    updatedBy?: number;
                    validFromUTC: Date;
                    validToUTC: Date;
                    updatedByContactUserID?: number;
                    updatedByContactUser: Honeycomb.Tenant.Contact.IService.User;
                    attendance: Honeycomb.Tenant.Contact.IService.Attendance;
                    attendanceRecordType: Honeycomb.Tenant.Contact.IService.AttendanceRecordType;
                }
                export class AttendanceRecordType {
                    attendanceRecordTypeID: number;
                    attendanceType: Honeycomb.Common.Enums.AttendanceType;
                    attendanceSubType: Honeycomb.Common.Enums.AttendanceSubType;
                    name: string;
                    attendanceRecords: Array<Honeycomb.Tenant.Contact.IService.AttendanceRecord>;
                }
                export class AttendanceRecordUpdate {
                    recordTime: Date;
                    updatedBy?: number;
                    updatedByContactUserID?: number;
                    locationID: number;
                }
                export class Club {
                    clubID: number;
                    name: string;
                    type: Honeycomb.Common.Enums.ListType;
                    isActive: boolean;
                    parameters: string;
                    created: Date;
                    updated: Date;
                    numberOfUsers?: number;
                    dynamicClubInClubClub: Array<Honeycomb.Tenant.Contact.IService.DynamicClubInClub>;
                    dynamicClubInClubIncludedClub: Array<Honeycomb.Tenant.Contact.IService.DynamicClubInClub>;
                    dynamicClubSelector: Array<Honeycomb.Tenant.Contact.IService.DynamicClubSelector>;
                    userClubBlackList: Array<Honeycomb.Tenant.Contact.IService.UserClubBlackList>;
                    userClubWhiteList: Array<Honeycomb.Tenant.Contact.IService.UserClubWhiteList>;
                }
                export class ClubOverview {
                    clubID: number;
                    name: string;
                    type: number;
                    isActive: boolean;
                    parameters: string;
                    created: Date;
                    updated: Date;
                }
                export class Contact {
                    contactId: number;
                    userId: number;
                    contactType: number;
                    isPrimary: boolean;
                    companyName: string;
                    companyIdn: string;
                    companyTin: string;
                    vatPayer: boolean;
                    initials: string;
                    firstName: string;
                    middleName: string;
                    lastName: string;
                    jobTitle: string;
                    degree: string;
                    salutation: string;
                    salutationFormal: string;
                    salutationInformal: string;
                    gender?: Honeycomb.Common.Enums.Gender;
                    birthDay?: Date;
                    addressLine1: string;
                    addressLine2: string;
                    addressType?: number;
                    city: string;
                    zip: string;
                    countryIsocode: string;
                    currencyIsocode: string;
                    email: string;
                    phoneNr: string;
                    contactUid: string;
                    picture?: string;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    lastUsedAs?: number;
                    latitude?: number;
                    longitude?: number;
                    formatedName: string;
                }
                export class ContactByUserIDsRequest {
                    userIDs: Array<number>;
                }
                export class ContactFulltextRequest {
                    search: string;
                    topCount?: number;
                }
                export class ContactJoinResult {
                    result: boolean;
                    resultMessage: string;
                    loggedUserID: number;
                    joinedCardNumber: string;
                    joinedUserID: number;
                }
                export class ContactPublic {
                    contactId: number;
                    userId: number;
                    contactType: number;
                    isPrimary: boolean;
                    companyName: string;
                    companyIdn: string;
                    companyTin: string;
                    vatPayer: boolean;
                    initials: string;
                    firstName: string;
                    middleName: string;
                    lastName: string;
                    jobTitle: string;
                    degree: string;
                    salutation: string;
                    salutationFormal: string;
                    salutationInformal: string;
                    gender?: Honeycomb.Common.Enums.Gender;
                    birthDay?: Date;
                    addressLine1: string;
                    addressLine2: string;
                    addressType?: number;
                    city: string;
                    zip: string;
                    countryIsocode: string;
                    currencyIsocode: string;
                    email: string;
                    phoneNr: string;
                    contactUid: string;
                    picture?: string;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    lastUsedAs?: number;
                    latitude?: number;
                    longitude?: number;
                    formatedName: string;
                }
                export class ContactRoleViewModel {
                    roleID: number;
                    name: string;
                    assigned: boolean;
                    attributes: { [key: string]: string };
                }
                export class ContactUser {
                    contactID: number;
                    userID: number;
                    userUID: string;
                    email: string;
                    phoneNr: string;
                    userNumber: string;
                }
                export class DynamicClubInClub {
                    dynamicClubInClubId: number;
                    clubId: number;
                    includedClubId: number;
                    club: Honeycomb.Tenant.Contact.IService.Club;
                    includedClub: Honeycomb.Tenant.Contact.IService.Club;
                }
                export class DynamicClubSelector {
                    clubSelectorId: number;
                    clubId: number;
                    selectorId: number;
                    queryParameters: string;
                    club: Honeycomb.Tenant.Contact.IService.Club;
                }
                export class EmailChangeRequest {
                    password: string;
                    email: string;
                }
                export class Event {
                    eventId: number;
                    userId: number;
                    type: number;
                    description: string;
                    referenceId: number;
                    happened: Date;
                    quantity: number;
                    price: number;
                    created: Date;
                }
                export class GetUsersForClubs {
                    distributionChannelType: number;
                    clubIDs: Array<number>;
                    requiredOptionTypeID?: number;
                }
                export class GetUsersForEmails {
                    distributionChannelType: Honeycomb.Common.Enums.DistributionChannelType;
                    emails: Array<string>;
                    requiredOptionTypeID?: number;
                }
                export class Handle {
                    handleId: number;
                    userId: number;
                    type: Honeycomb.Common.Enums.UserHandleType;
                    value: string;
                }
                export class Job {
                    jobID: number;
                    name: string;
                    roleID?: number;
                    jobGroupID?: number;
                    created: Date;
                    jobLocations: Array<Honeycomb.Tenant.Contact.IService.JobLocation>;
                    jobGroup: Honeycomb.Tenant.Contact.IService.JobGroup;
                    userJobs: Array<Honeycomb.Tenant.Contact.IService.UserJob>;
                    userIDs: Array<number>;
                }
                export class JobGroup {
                    jobGroupID: number;
                    name: string;
                    sortOrder: number;
                    type?: Honeycomb.Common.Enums.JobGroupType;
                    jobs: Array<Honeycomb.Tenant.Contact.IService.Job>;
                }
                export class JobChangeRequest {
                    oldJobID?: number;
                    newJobID?: number;
                    oldUsers: Array<number>;
                    newUsers: Array<number>;
                }
                export class JobLocation {
                    jobID: number;
                    locationID: number;
                    created: Date;
                    job: Honeycomb.Tenant.Contact.IService.Job;
                }
                export class NoteContent {
                    content: string;
                }
                export class Option {
                    optionID: number;
                    userID: number;
                    optionTypeID: number;
                    pendingValue: number;
                    value: number;
                    optionValidFrom: Date;
                    optionValidTo: Date;
                    validFromUTC: Date;
                    validToUTC: Date;
                    unsubscribeUID: string;
                    updatedBy?: number;
                    optionType: Honeycomb.Tenant.Contact.IService.OptionType;
                }
                export class OptionSimple {
                    option: string;
                    value: number;
                }
                export class OptionType {
                    optionTypeID: number;
                    name: string;
                    description: string;
                    meaning: Honeycomb.Common.Enums.UserOptionMeaning;
                    useDoubleOptIn: boolean;
                    mediaName: string;
                    emailText: string;
                    useDoubleOptInOnDisable: boolean;
                    mediaNameOnDisable: string;
                    emailTextOnDisable: string;
                }
                export class OptionUpdateModel {
                    email: string;
                    optionName: string;
                    value: number;
                }
                export class PasswordChangeRequest {
                    oldPassword: string;
                    password: string;
                    passwordConfirmation: string;
                }
                export class PasswordResetRequest {
                    passwordResetToken: string;
                    password: string;
                    passwordConfirmation: string;
                }
                export class QuestionAnswer {
                    questionAnswerId: number;
                    surveyQuestionId: number;
                    answerNumber: number;
                    answer: string;
                    isActive: boolean;
                    hasAnswer: boolean;
                    name: string;
                    surveyQuestion: Honeycomb.Tenant.Contact.IService.SurveyQuestion;
                    surveyResult: Array<Honeycomb.Tenant.Contact.IService.SurveyResult>;
                }
                export class QuestionsInSurvey {
                    questionInSurveyId: number;
                    surveyQuestionId: number;
                    surveyId: number;
                    ordinal: number;
                    survey: Honeycomb.Tenant.Contact.IService.Survey;
                    surveyQuestion: Honeycomb.Tenant.Contact.IService.SurveyQuestion;
                }
                export class RegisterEmailModel {
                    options: Array<Honeycomb.Tenant.Contact.IService.OptionSimple>;
                    email: string;
                    firstName: string;
                    middleName: string;
                    lastName: string;
                    jobTitle: string;
                    salutation: string;
                    addressLine1: string;
                    addressLine2: string;
                    pictureUrl: string;
                    profileID: string;
                    token: string;
                    externalAccountType: Honeycomb.Common.Enums.ExternalAccountType;
                }
                export class Reward {
                    rewardId: number;
                    userId: number;
                    name: string;
                    type: number;
                    value: number;
                    loyaltyLevel: number;
                    history: Array<Honeycomb.Tenant.Contact.IService.RewardHistory>;
                    created: Date;
                }
                export class RewardHistory {
                    rewardHistoryID: number;
                    rewardID: number;
                    transactionID?: number;
                    statSaleTransactionID?: number;
                    note: string;
                    value: number;
                    created: Date;
                    recVer: number;
                    rewardReason: Honeycomb.Common.Enums.RewardReason;
                    reasonNote: string;
                }
                export class SearchUsersInClub {
                    clubID: number;
                    sortColumn: string;
                    sortDirection: Honeycomb.Common.SortDirection;
                    pageSize?: number;
                    page: number;
                    searchString: string;
                }
                export class Simple {
                    id: number;
                    name: string;
                }
                export class SimpleUser {
                    jobs: Array<Honeycomb.Tenant.Contact.IService.Simple>;
                    locations: Array<Honeycomb.Tenant.Contact.IService.Simple>;
                    id: number;
                    name: string;
                }
                export class SimpleUserJob {
                    user: Honeycomb.Tenant.Contact.IService.Simple;
                    job: Honeycomb.Tenant.Contact.IService.Simple;
                    ratio: number;
                    deleted: boolean;
                }
                export class Survey {
                    surveyId: number;
                    isActive: boolean;
                    name: string;
                    group: string;
                    description: string;
                    refName: string;
                    type: Honeycomb.Common.Enums.SurveyType;
                    status?: number;
                    motivationType: Honeycomb.Common.Enums.SurveyMotivationType;
                    numberOfBonusPoints?: number;
                    voucherTemplateId?: number;
                    layoutTemplateId: number;
                    layoutTemplateParameters: string;
                    created: Date;
                    updated: Date;
                    redirectUrl: string;
                    redirectDelay?: number;
                    redirectScreen: string;
                    showEmailRegistration: boolean;
                    saveSnapshot: boolean;
                    surveyQuestions: Array<Honeycomb.Tenant.Contact.IService.SurveyQuestion>;
                    questionsInSurvey: Array<Honeycomb.Tenant.Contact.IService.QuestionsInSurvey>;
                    surveyResult: Array<Honeycomb.Tenant.Contact.IService.SurveyResult>;
                    surveyDistributions: Array<Honeycomb.Tenant.Contact.IService.SurveyDistribution>;
                }
                export class SurveyDistribution {
                    surveyDistributionId: number;
                    surveyId: number;
                    distributionChannelId: number;
                    survey: Honeycomb.Tenant.Contact.IService.Survey;
                }
                export class SurveyQuestion {
                    surveyQuestionId: number;
                    name: string;
                    group: string;
                    question: string;
                    description: string;
                    answerType: Honeycomb.Common.Enums.SurveyAnswerType;
                    answerRequired: boolean;
                    created: Date;
                    updated: Date;
                    hasAnyResult: boolean;
                    questionAnswers: Array<Honeycomb.Tenant.Contact.IService.QuestionAnswer>;
                    surveyResult: Array<Honeycomb.Tenant.Contact.IService.SurveyResult>;
                    ordinal: number;
                }
                export class SurveyResponse {
                    name: string;
                    surveyId: number;
                    type: Honeycomb.Common.Enums.SurveyType;
                    userGuid?: string;
                    email: string;
                    snapshot: string;
                    metadata: { [key: string]: string };
                    surveyResults: Array<Honeycomb.Tenant.Contact.IService.SurveyResponseResult>;
                }
                export class SurveyResponseResult {
                    surveyQuestionId: number;
                    questionAnswers: Array<number>;
                    answerTextValue: string;
                    answerType: Honeycomb.Common.Enums.SurveyAnswerType;
                }
                export class SurveyResult {
                    surveyResultId: number;
                    surveyResultHeaderId: number;
                    surveyQuestionId: number;
                    questionAnswerId?: number;
                    answerValue?: number;
                    answerTextValue: string;
                    resultHeader: Honeycomb.Tenant.Contact.IService.SurveyResultHeader;
                    questionAnswer: Honeycomb.Tenant.Contact.IService.QuestionAnswer;
                    surveyQuestion: Honeycomb.Tenant.Contact.IService.SurveyQuestion;
                }
                export class SurveyResultHeader {
                    surveyResultHeaderId: number;
                    surveyId: number;
                    userId?: number;
                    distributionId: string;
                    snapshotId?: string;
                    created: Date;
                    survey: Honeycomb.Tenant.Contact.IService.Survey;
                    user: Honeycomb.Tenant.Contact.IService.User;
                    results: Array<Honeycomb.Tenant.Contact.IService.SurveyResult>;
                }
                export class Tag {
                    tagId: number;
                    userId: number;
                    type: Honeycomb.Common.Enums.UserTagType;
                    number: string;
                    validSince?: Date;
                    validTill?: Date;
                    status: Honeycomb.Common.Enums.TagStatus;
                    statusKey: string;
                    attributes: Array<Honeycomb.Tenant.Contact.IService.TagAttribute>;
                    mappedAttributes: Honeycomb.Tenant.Contact.IService.ViewModel.Editions.TagMappedAttributes;
                    tagEmission: Honeycomb.Tenant.Contact.IService.TagEmission;
                    attributeTypes: Array<Honeycomb.Tenant.Contact.IService.TagAttributeType>;
                }
                export class TagAttribute {
                    tagAttributeId: number;
                    tagId: number;
                    tagAttributeTypeId: number;
                    value: string;
                    tag: Honeycomb.Tenant.Contact.IService.Tag;
                    tagAttributeType: Honeycomb.Tenant.Contact.IService.TagAttributeType;
                }
                export class TagAttributeType {
                    tagAttributeTypeId: number;
                    meaning: Honeycomb.Common.Enums.AttributeMeaning.TagAttributeMeaning;
                    tagAttributes: Array<Honeycomb.Tenant.Contact.IService.TagAttribute>;
                    name: string;
                    description: string;
                    dataType: Honeycomb.Common.Enums.DataType;
                    codeListId?: number;
                }
                export class TagEmission {
                    tagEmissionID: number;
                    name: string;
                    updatedBy?: number;
                    pinLength?: number;
                    created: Date;
                    modified: Date;
                }
                export class TagRequest {
                    tagRequestID: number;
                    userID: number;
                    contactID: number;
                    type: Honeycomb.Common.Enums.UserTagType;
                    status: Honeycomb.Common.Enums.TagRequestStatus;
                    number: string;
                    created: Date;
                    modified: Date;
                }
                export class UpdateRewardsRequest {
                    userID: number;
                    programName: string;
                    value?: number;
                    loyaltyLevel?: number;
                    transactionID?: number;
                    statSaleTransactionID?: number;
                    created?: Date;
                    updatedBy?: number;
                    note: string;
                    rewardReason: Honeycomb.Common.Enums.RewardReason;
                    reasonNote: string;
                }
                export class User {
                    password: string;
                    passwordConfirmation: string;
                    passwordResetToken: string;
                    passwordResetTokenValidTill?: Date;
                    passwordSalt: string;
                    passwordHashType: Honeycomb.Common.Enums.PasswordHashType;
                    identificationType: number;
                    emailConfirmationToken: string;
                    emailRequested: string;
                    timeStamp: number;
                    hasPassword: boolean;
                    userId: number;
                    userNumber: string;
                    emailConfirmed?: boolean;
                    emailConfirmationRequired?: Honeycomb.Common.Enums.RequiredEmailConfirmationType;
                    userUid: string;
                    login: string;
                    primaryEmailView: string;
                    communicationType: number;
                    attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttribute>;
                    contacts: Array<Honeycomb.Tenant.Contact.IService.Contact>;
                    tags: Array<Honeycomb.Tenant.Contact.IService.Tag>;
                    handles: Array<Honeycomb.Tenant.Contact.IService.Handle>;
                    rewards: Array<Honeycomb.Tenant.Contact.IService.Reward>;
                    options: Array<Honeycomb.Tenant.Contact.IService.Option>;
                    attachments: Array<Honeycomb.Tenant.Contact.IService.Attachment>;
                    relatedUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    relatedToUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    userJobs: Array<Honeycomb.Tenant.Contact.IService.UserJob>;
                    userLocations: Array<Honeycomb.Tenant.Contact.IService.UserLocation>;
                    mappedAttributes: Honeycomb.Tenant.Contact.IService.ViewModel.Editions.UserMappedAttributes;
                    forciblyForgotten?: Date;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    picture?: string;
                    updatedBy?: number;
                    updatedByContactUserID?: number;
                    approved: boolean;
                    deleted: boolean;
                    pictureURL: string;
                    languageISOCode: string;
                }
                export class UserActivity {
                    userActivityID: number;
                    ipAddress: string;
                    sessionID: number;
                    userID?: number;
                    componentType: number;
                    action: string;
                    urL: string;
                    created: Date;
                    userActivityData: Array<Honeycomb.Tenant.Contact.IService.UserActivityData>;
                }
                export class UserActivityData {
                    userActivityDataID: number;
                    userActivityID: number;
                    entityID: string;
                    quantity?: number;
                    value?: number;
                }
                export class UserAttribute {
                    userId: number;
                    userAttributeTypeId: number;
                    value: string;
                    userAttributeType: Honeycomb.Tenant.Contact.IService.UserAttributeType;
                }
                export class UserAttributeNameValue {
                    userId: number;
                    attrName: string;
                    attrValue: string;
                }
                export class UserAttributeType {
                    userAttributeTypeId: number;
                    meaning: Honeycomb.Common.Enums.AttributeMeaning.UserAttributeMeaning;
                    name: string;
                    description: string;
                    dataType: Honeycomb.Common.Enums.DataType;
                    codeListId?: number;
                }
                export class UserClubBlackList {
                    userClubBlackListId: number;
                    userId: number;
                    clubId: number;
                    club: Honeycomb.Tenant.Contact.IService.Club;
                    user: Honeycomb.Tenant.Contact.IService.User;
                }
                export class UserClubWhiteList {
                    userClubWhiteListId: number;
                    userId: number;
                    clubId: number;
                    club: Honeycomb.Tenant.Contact.IService.Club;
                    user: Honeycomb.Tenant.Contact.IService.User;
                }
                export class UserEditable {
                    login: string;
                    primaryEmailView: string;
                    communicationType: number;
                    attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttribute>;
                    contacts: Array<Honeycomb.Tenant.Contact.IService.Contact>;
                    tags: Array<Honeycomb.Tenant.Contact.IService.Tag>;
                    handles: Array<Honeycomb.Tenant.Contact.IService.Handle>;
                    rewards: Array<Honeycomb.Tenant.Contact.IService.Reward>;
                    options: Array<Honeycomb.Tenant.Contact.IService.Option>;
                    attachments: Array<Honeycomb.Tenant.Contact.IService.Attachment>;
                    relatedUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    relatedToUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    userJobs: Array<Honeycomb.Tenant.Contact.IService.UserJob>;
                    userLocations: Array<Honeycomb.Tenant.Contact.IService.UserLocation>;
                    mappedAttributes: Honeycomb.Tenant.Contact.IService.ViewModel.Editions.UserMappedAttributes;
                    forciblyForgotten?: Date;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    picture?: string;
                    updatedBy?: number;
                    updatedByContactUserID?: number;
                    approved: boolean;
                    deleted: boolean;
                    pictureURL: string;
                    languageISOCode: string;
                }
                export class UserElastic {
                    rewardSum: number;
                    answers: { [key: number]: any };
                    events: Array<Honeycomb.Tenant.Contact.IService.Event>;
                    hasPassword: boolean;
                    userId: number;
                    userNumber: string;
                    emailConfirmed?: boolean;
                    emailConfirmationRequired?: Honeycomb.Common.Enums.RequiredEmailConfirmationType;
                    userUid: string;
                    login: string;
                    primaryEmailView: string;
                    communicationType: number;
                    attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttribute>;
                    contacts: Array<Honeycomb.Tenant.Contact.IService.Contact>;
                    tags: Array<Honeycomb.Tenant.Contact.IService.Tag>;
                    handles: Array<Honeycomb.Tenant.Contact.IService.Handle>;
                    rewards: Array<Honeycomb.Tenant.Contact.IService.Reward>;
                    options: Array<Honeycomb.Tenant.Contact.IService.Option>;
                    attachments: Array<Honeycomb.Tenant.Contact.IService.Attachment>;
                    relatedUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    relatedToUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    userJobs: Array<Honeycomb.Tenant.Contact.IService.UserJob>;
                    userLocations: Array<Honeycomb.Tenant.Contact.IService.UserLocation>;
                    mappedAttributes: Honeycomb.Tenant.Contact.IService.ViewModel.Editions.UserMappedAttributes;
                    forciblyForgotten?: Date;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    picture?: string;
                    updatedBy?: number;
                    updatedByContactUserID?: number;
                    approved: boolean;
                    deleted: boolean;
                    pictureURL: string;
                    languageISOCode: string;
                }
                export class UserFilter {
                    roles: Array<number>;
                    jobs: Array<number>;
                    users: Array<number>;
                }
                export class UserForClubsResponse {
                    userID: number;
                    userNumber: string;
                    recipient: string;
                }
                export class UserIdNumberRelation {
                    userID: number;
                    userNumber: string;
                }
                export class UserIdUidRelation {
                    userID: number;
                    userUID: string;
                }
                export class UserInfo {
                    hasPassword: boolean;
                    userId: number;
                    userNumber: string;
                    emailConfirmed?: boolean;
                    emailConfirmationRequired?: Honeycomb.Common.Enums.RequiredEmailConfirmationType;
                    userUid: string;
                    login: string;
                    primaryEmailView: string;
                    communicationType: number;
                    attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttribute>;
                    contacts: Array<Honeycomb.Tenant.Contact.IService.Contact>;
                    tags: Array<Honeycomb.Tenant.Contact.IService.Tag>;
                    handles: Array<Honeycomb.Tenant.Contact.IService.Handle>;
                    rewards: Array<Honeycomb.Tenant.Contact.IService.Reward>;
                    options: Array<Honeycomb.Tenant.Contact.IService.Option>;
                    attachments: Array<Honeycomb.Tenant.Contact.IService.Attachment>;
                    relatedUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    relatedToUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    userJobs: Array<Honeycomb.Tenant.Contact.IService.UserJob>;
                    userLocations: Array<Honeycomb.Tenant.Contact.IService.UserLocation>;
                    mappedAttributes: Honeycomb.Tenant.Contact.IService.ViewModel.Editions.UserMappedAttributes;
                    forciblyForgotten?: Date;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    picture?: string;
                    updatedBy?: number;
                    updatedByContactUserID?: number;
                    approved: boolean;
                    deleted: boolean;
                    pictureURL: string;
                    languageISOCode: string;
                }
                export class UserJob {
                    userID: number;
                    jobID: number;
                    created: Date;
                    user: Honeycomb.Tenant.Contact.IService.User;
                    job: Honeycomb.Tenant.Contact.IService.Job;
                }
                export class UserListItem {
                    userId: number;
                    userUID: string;
                    userNumber: string;
                    name: string;
                    company: string;
                    address: string;
                }
                export class UserLocation {
                    userID: number;
                    locationID: number;
                    created: Date;
                    user: Honeycomb.Tenant.Contact.IService.User;
                }
                export class UserNote {
                    userNoteID?: number;
                    userID: number;
                    createdBy: number;
                    created: Date;
                    note: string;
                    formattedName: string;
                    creatorEmail: string;
                    user: Honeycomb.Tenant.Contact.IService.User;
                }
                export class UserOptionsUpdateRequest {
                    options: Array<Honeycomb.Tenant.Contact.IService.OptionUpdateModel>;
                }
                export class UserRelation {
                    userRelationID: number;
                    userID: number;
                    relatedUserID: number;
                    relatedUserType: Honeycomb.Common.Enums.RelatedUserType;
                    created: Date;
                    user: Honeycomb.Tenant.Contact.IService.User;
                    relatedUser: Honeycomb.Tenant.Contact.IService.User;
                }
                export class UsersForOperationRequest {
                    userID: number;
                    jobID: number;
                    operationID: number;
                    treshold: number;
                    search: string;
                    includeDeleted: boolean;
                }
                export class UsersResolveRequest {
                    userID: number;
                    selectedJobID: number;
                    userSelectors: Array<string>;
                }
                export class UserSubstitution {
                    userSubstitutionID: number;
                    userID: number;
                    replacedUserID: number;
                    replacedJobID: number;
                    dateFrom: Date;
                    dateTo: Date;
                    note: string;
                    updatedBy?: number;
                    validFromUTC: Date;
                    validToUTC: Date;
                    user: Honeycomb.Tenant.Contact.IService.User;
                    replacedUser: Honeycomb.Tenant.Contact.IService.User;
                    replacedJob: Honeycomb.Tenant.Contact.IService.Job;
                    userSubstitutionLocations: Array<Honeycomb.Tenant.Contact.IService.UserSubstitutionLocation>;
                }
                export class UserSubstitutionLocation {
                    userSubstitutionID: number;
                    locationID: number;
                    updatedBy?: number;
                    userSubstitution: Honeycomb.Tenant.Contact.IService.UserSubstitution;
                    validFromUTC: Date;
                    validToUTC: Date;
                }
                export class UserTagStatusUpdateRequest {
                    cardNumber: string;
                    tagStatus: Honeycomb.Common.Enums.TagStatus;
                }
                export class UserWithOptions {
                    options: Array<Honeycomb.Tenant.Contact.IService.Option>;
                    password: string;
                    passwordConfirmation: string;
                    passwordResetToken: string;
                    passwordResetTokenValidTill?: Date;
                    passwordSalt: string;
                    passwordHashType: Honeycomb.Common.Enums.PasswordHashType;
                    identificationType: number;
                    emailConfirmationToken: string;
                    emailRequested: string;
                    timeStamp: number;
                    hasPassword: boolean;
                    userId: number;
                    userNumber: string;
                    emailConfirmed?: boolean;
                    emailConfirmationRequired?: Honeycomb.Common.Enums.RequiredEmailConfirmationType;
                    userUid: string;
                    login: string;
                    primaryEmailView: string;
                    communicationType: number;
                    attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttribute>;
                    contacts: Array<Honeycomb.Tenant.Contact.IService.Contact>;
                    tags: Array<Honeycomb.Tenant.Contact.IService.Tag>;
                    handles: Array<Honeycomb.Tenant.Contact.IService.Handle>;
                    rewards: Array<Honeycomb.Tenant.Contact.IService.Reward>;
                    attachments: Array<Honeycomb.Tenant.Contact.IService.Attachment>;
                    relatedUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    relatedToUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    userJobs: Array<Honeycomb.Tenant.Contact.IService.UserJob>;
                    userLocations: Array<Honeycomb.Tenant.Contact.IService.UserLocation>;
                    mappedAttributes: Honeycomb.Tenant.Contact.IService.ViewModel.Editions.UserMappedAttributes;
                    forciblyForgotten?: Date;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    picture?: string;
                    updatedBy?: number;
                    updatedByContactUserID?: number;
                    approved: boolean;
                    deleted: boolean;
                    pictureURL: string;
                    languageISOCode: string;
                }
                export class UserWithPasswordHash {
                    passwordHash: string;
                    options: Array<Honeycomb.Tenant.Contact.IService.Option>;
                    password: string;
                    passwordConfirmation: string;
                    passwordResetToken: string;
                    passwordResetTokenValidTill?: Date;
                    passwordSalt: string;
                    passwordHashType: Honeycomb.Common.Enums.PasswordHashType;
                    identificationType: number;
                    emailConfirmationToken: string;
                    emailRequested: string;
                    timeStamp: number;
                    hasPassword: boolean;
                    userId: number;
                    userNumber: string;
                    emailConfirmed?: boolean;
                    emailConfirmationRequired?: Honeycomb.Common.Enums.RequiredEmailConfirmationType;
                    userUid: string;
                    login: string;
                    primaryEmailView: string;
                    communicationType: number;
                    attributes: Array<Honeycomb.Tenant.Contact.IService.UserAttribute>;
                    contacts: Array<Honeycomb.Tenant.Contact.IService.Contact>;
                    tags: Array<Honeycomb.Tenant.Contact.IService.Tag>;
                    handles: Array<Honeycomb.Tenant.Contact.IService.Handle>;
                    rewards: Array<Honeycomb.Tenant.Contact.IService.Reward>;
                    attachments: Array<Honeycomb.Tenant.Contact.IService.Attachment>;
                    relatedUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    relatedToUsers: Array<Honeycomb.Tenant.Contact.IService.UserRelation>;
                    userJobs: Array<Honeycomb.Tenant.Contact.IService.UserJob>;
                    userLocations: Array<Honeycomb.Tenant.Contact.IService.UserLocation>;
                    mappedAttributes: Honeycomb.Tenant.Contact.IService.ViewModel.Editions.UserMappedAttributes;
                    forciblyForgotten?: Date;
                    contactRoles: Array<Honeycomb.Tenant.Contact.IService.ContactRoleViewModel>;
                    picture?: string;
                    updatedBy?: number;
                    updatedByContactUserID?: number;
                    approved: boolean;
                    deleted: boolean;
                    pictureURL: string;
                    languageISOCode: string;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {
                export namespace ViewModel {

                    export class AddToClubOnTempTableRequest {
                        clubID: number;
                        tempTableName: string;
                        idColumnName: string;
                        deleteTable: boolean;
                    }
                    export class AddToClubRequest {
                        clubID: number;
                        userIDs: Array<number>;
                    }
                    export class ClientInfo {
                        businessName: string;
                        businessWebsite: string;
                        city: string;
                        continent: string;
                        country: string;
                        countryCode: string;
                        ipName: string;
                        ipType: string;
                        isp: string;
                        lat: string;
                        lon: string;
                        org: string;
                        query: string;
                        region: string;
                        status: string;
                    }
                    export class ClubUserContact {
                        userID: number;
                        contactID: number;
                        email: string;
                        isPrimary: boolean;
                        firstName: string;
                        lastName: string;
                        contactType: Honeycomb.Common.Enums.ContactType;
                    }
                    export class CreateClubOnTempTableRequest {
                        name: string;
                        tempTableName: string;
                        idColumnName: string;
                        deleteTable: boolean;
                    }
                    export class CreateClubRequest {
                        name: string;
                        userIDs: Array<number>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {
                export namespace ViewModel {
                    export namespace Editions {

                        export class TagMappedAttributes {
                            points: number;
                            stripImage: string;
                            walletBackgroundColor: string;
                            attributeTypes: Array<Honeycomb.Tenant.Contact.IService.TagAttributeType>;
                        }
                        export class UserMappedAttributes {
                            attributeTypes: Array<Honeycomb.Tenant.Contact.IService.UserAttributeType>;
                            discount: number;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {

                export class Code {
                    codeId: number;
                    codeListId: number;
                    codeKey: string;
                    orderIndex: number;
                    updateTime: Date;
                    codeValues: { [key: string]: Honeycomb.Tenant.LookupTables.IService.CodeValue };
                }
                export class CodeImported {
                    codeKey: string;
                    codeValues: { [key: string]: string };
                }
                export class CodeList {
                    codeListId: number;
                    codeListName: string;
                    description: string;
                    type: Honeycomb.Common.Enums.CodeListType;
                    queryableID?: number;
                    updateTime: Date;
                    codes: Array<Honeycomb.Tenant.LookupTables.IService.Code>;
                }
                export class CodeListImported {
                    codeListName: string;
                    description: string;
                    codes: Array<Honeycomb.Tenant.LookupTables.IService.CodeImported>;
                    timestamp: number;
                }
                export class CodeListSimple {
                    codeListId: number;
                    codeListName: string;
                    description: string;
                    codes: Array<Honeycomb.Tenant.LookupTables.IService.CodeSimple>;
                }
                export class CodeSimple {
                    codeKey: string;
                    codeValue: string;
                }
                export class CodeValue {
                    codeValueId: number;
                    codeId: number;
                    langIsoCode: string;
                    displayValue: string;
                    updateTime: Date;
                }
                export class DeliveryLabel {
                    deliveryLabelId: number;
                    shippingID: number;
                    aggregatorType: Honeycomb.Common.Enums.AggregatorType;
                    label: string;
                }
                export class DeliveryOption {
                    name: string;
                    price?: number;
                    price_COD?: number;
                    hasLabel: boolean;
                    countries: Array<string>;
                    shippingID: number;
                    shippingCode: string;
                    nameKey: string;
                    descriptionKey: string;
                    shippingCost: number;
                    parentShippingID?: number;
                    vatID: number;
                    nutsRegion: string;
                    shippingOptionsProviderID?: number;
                    shippingPriceProviderID?: number;
                    shippingOptionsProvider: Honeycomb.Tenant.LookupTables.IService.Model.ShippingOptionsProvider;
                    shippingPriceProvider: Honeycomb.Tenant.LookupTables.IService.Model.ShippingPriceProvider;
                    paymentIDs: Array<number>;
                    deliveryLabels: Array<Honeycomb.Tenant.LookupTables.IService.DeliveryLabel>;
                    enabled: boolean;
                    settings: string;
                    shippingSettings: Honeycomb.Common.Shipping.ShippingSettings;
                    shippingRestrictions: Array<Honeycomb.Common.Shipping.ShippingRestriction>;
                }
                export class ShippingOption {
                    address: Honeycomb.Tenant.Contact.IService.Contact;
                    providerType: Honeycomb.Common.Enums.ShippingOptionsProviderType;
                    identifier: string;
                    website: string;
                    attributes: { [key: string]: string };
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Model {

                    export class CurrencyRate {
                        currencyRateID: number;
                        tsT: Array<number>;
                        nutsRegion: string;
                        currencyISOCode: number;
                        rate: number;
                        validSince: Date;
                        validTill: Date;
                    }
                    export class Discount {
                        discountID: number;
                        updateTime: Date;
                        tsT: Array<number>;
                        nutsRegion: string;
                        type: Honeycomb.Common.Enums.DiscountValueType;
                        value: number;
                        description: string;
                    }
                    export class LocalizationDataModel {
                        key: string;
                        languageISOCode: string;
                        value: string;
                    }
                    export class Location {
                        topPrintLine1: string;
                        topPrintLine2: string;
                        topPrintLine3: string;
                        topPrintLine4: string;
                        topPrintLine5: string;
                        bottomPrintLine1: string;
                        bottomPrintLine2: string;
                        bottomPrintLine3: string;
                        bottomPrintLine4: string;
                        bottomPrintLine5: string;
                        periodSchedules: Array<Honeycomb.Tenant.LookupTables.IService.Schedule.PeriodSchedule>;
                        locationImages: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationImage>;
                        mappedAttributes: Honeycomb.Tenant.LookupTables.IService.Model.Editions.LocationMappedAttributes;
                        openingHours: Array<Honeycomb.Tenant.LookupTables.IService.Model.Schedule.DayOfWeekSchedule>;
                        locationID: number;
                        companyID?: number;
                        locationNumber: string;
                        nutsRegion: string;
                        name: string;
                        city: string;
                        cityPart: string;
                        street: string;
                        streetNumber: string;
                        zipCode: string;
                        phone: string;
                        email: string;
                        latitude?: number;
                        longitude?: number;
                        timeZoneInfoId: string;
                        timestamp: number;
                        hidden: boolean;
                        users: Array<Honeycomb.Tenant.Contact.IService.SimpleUser>;
                        attributes: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationAttribute>;
                        textAddress: string;
                    }
                    export class LocationAttribute {
                        locationAttributeId: number;
                        locationId: number;
                        locationAttributeTypeId: number;
                        value: string;
                        locationAttributeType: Honeycomb.Tenant.LookupTables.IService.Model.LocationAttributeType;
                    }
                    export class LocationAttributeType {
                        locationAttributeTypeId: number;
                        meaning: Honeycomb.Common.Enums.AttributeMeaning.LocationAttributeMeaning;
                        name: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                        codeListId?: number;
                    }
                    export class LocationImage {
                        locationImageId: number;
                        locationId: number;
                        name: string;
                        recordUID: string;
                        isPrimary: boolean;
                        fileGroup: string;
                    }
                    export class LocationRequest {
                        specificLocations: Array<number>;
                        specificLocationsFilter: Array<number>;
                    }
                    export class LocationShort {
                        locationID: number;
                        companyID?: number;
                        locationNumber: string;
                        nutsRegion: string;
                        name: string;
                        city: string;
                        cityPart: string;
                        street: string;
                        streetNumber: string;
                        zipCode: string;
                        phone: string;
                        email: string;
                        latitude?: number;
                        longitude?: number;
                        timeZoneInfoId: string;
                        timestamp: number;
                        hidden: boolean;
                        users: Array<Honeycomb.Tenant.Contact.IService.SimpleUser>;
                        attributes: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationAttribute>;
                        textAddress: string;
                    }
                    export class PaymentProvider {
                        paymentProviderID: number;
                        providerName: string;
                        providerType: Honeycomb.Common.Enums.PaymentProviderType;
                        providerSettings: string;
                        updateTime: Date;
                    }
                    export class PaymentViewModel {
                        paymentID: number;
                        paymentCode: string;
                        nameKey: string;
                        paymentCost: number;
                        enabled: boolean;
                        desctiptionKey: string;
                        nutsRegion: string;
                        paymentProviderID?: number;
                        vatID: number;
                        providerSettings: string;
                        canReturn: boolean;
                        shippingIDs: Array<number>;
                        paymentProvider: Honeycomb.Tenant.LookupTables.IService.Model.PaymentProvider;
                    }
                    export class ShippingOptionsProvider {
                        shippingOptionsProviderID: number;
                        providerName: string;
                        providerType: Honeycomb.Common.Enums.ShippingOptionsProviderType;
                        providerSettings: string;
                        updateTime: Date;
                    }
                    export class ShippingPriceProvider {
                        shippingPriceProviderID: number;
                        providerName: string;
                        providerType: Honeycomb.Common.Enums.ShippingPriceProviderType;
                        providerSettings: string;
                        updateTime: Date;
                    }
                    export class ShippingViewModel {
                        shippingID: number;
                        shippingCode: string;
                        nameKey: string;
                        descriptionKey: string;
                        shippingCost: number;
                        parentShippingID?: number;
                        vatID: number;
                        nutsRegion: string;
                        shippingOptionsProviderID?: number;
                        shippingPriceProviderID?: number;
                        shippingOptionsProvider: Honeycomb.Tenant.LookupTables.IService.Model.ShippingOptionsProvider;
                        shippingPriceProvider: Honeycomb.Tenant.LookupTables.IService.Model.ShippingPriceProvider;
                        paymentIDs: Array<number>;
                        deliveryLabels: Array<Honeycomb.Tenant.LookupTables.IService.DeliveryLabel>;
                        enabled: boolean;
                        settings: string;
                        shippingSettings: Honeycomb.Common.Shipping.ShippingSettings;
                        shippingRestrictions: Array<Honeycomb.Common.Shipping.ShippingRestriction>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Model {
                    export namespace Editions {

                        export class LocationMappedAttributes {
                            parkingAvailable: boolean;
                            centralBranch: number;
                            assignedToEcommerce: boolean;
                            wheelchairAccess: boolean;
                            localizedName: Honeycomb.Common.ViewModels.LocalizedTexts;
                            risk: string;
                            riskReason: string;
                            lastStockTaking: Date;
                            lastManagerCheck: Date;
                            storeEvents: Honeycomb.Common.ViewModels.TableData;
                            contractValidity: string;
                            attributeTypes: Array<Honeycomb.Tenant.LookupTables.IService.Model.LocationAttributeType>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Model {
                    export namespace Schedule {

                        export class ActionTime {
                            startTime: any;
                            startTimeFormated: string;
                            endTime: any;
                            endTimeFormated: string;
                        }
                        export class DayOfWeekSchedule {
                            dayOfWeek: number;
                            dayOfWeekName: string;
                            dayOfWeekLocKey: string;
                            actionTimes: Array<Honeycomb.Tenant.LookupTables.IService.Model.Schedule.ActionTime>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Schedule {

                    export class DaySchedule {
                        dayScheduleID: number;
                        periodScheduleID: number;
                        startTime: any;
                        endTime: any;
                        action: Honeycomb.Common.Enums.ScheduleAction;
                        updateTime: Date;
                    }
                    export class PeriodSchedule {
                        periodScheduleID: number;
                        name: string;
                        dateSince?: Date;
                        dateTill?: Date;
                        monday: boolean;
                        tuesday: boolean;
                        wednesday: boolean;
                        thursday: boolean;
                        friday: boolean;
                        saturday: boolean;
                        sunday: boolean;
                        updateTime: Date;
                        daySchedules: Array<Honeycomb.Tenant.LookupTables.IService.Schedule.DaySchedule>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Controllers {
                    export namespace v1 {

                        export class MailkitUpdateOptionRequest {
                            clientID: string;
                            mD5: string;
                            listName: string;
                            optionTypeName: string;
                        }
                        export class MailkitUpdateUnsuscribedList {
                            clientID: string;
                            mD5: string;
                            unsuscribedSince: Date;
                            optionTypeName: string;
                        }
                        export class SambaUnsubscribeApi {
                            trackpoint?: number;
                            domain: string;
                            recipient: string;
                            mailCode: string;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Model {
                    export namespace Container {

                        export class ImportCategoriesRequest {
                            items: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrCategory>;
                        }
                        export class ImportCustomersRequest {
                            items: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrUser>;
                        }
                        export class ImportLocationsRequest {
                            items: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrLocation>;
                        }
                        export class ImportProductsRequest {
                            items: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProduct>;
                        }
                        export class ImportResult {
                            rows: Array<Honeycomb.Tenant.PublicAPI.IService.Model.Container.ImportResultRow>;
                        }
                        export class ImportResultRow {
                            iD: string;
                            oP: Honeycomb.Common.Enums.ImportResultOperation;
                        }
                        export class ImportStockRequest {
                            items: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrStock>;
                        }
                        export class ImportUsersRequest {
                            items: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrUser>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Model {

                    export class CrAttachment {
                        attachmentId: number;
                        userId: number;
                        memo: string;
                        name: string;
                        documentUid?: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                    }
                    export class CrCategory {
                        categoryId: number;
                        imageGuid?: string;
                        backgroundImageGuid?: string;
                        published: boolean;
                        deleted: boolean;
                        recVer: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        categoryCode: string;
                        parentCategoryCode: string;
                        redirectURL: string;
                        edition: Honeycomb.Common.Enums.Edition;
                        productCategory: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductCategory>;
                        categoryTexts: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrCategoryText>;
                    }
                    export class CrCategoryText {
                        categoryId: number;
                        languageIsocode: string;
                        shortName: string;
                        fullName: string;
                        description: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        category: Honeycomb.Tenant.PublicAPI.IService.Model.CrCategory;
                    }
                    export class CrContact {
                        contactId: number;
                        userId: number;
                        contactType: Honeycomb.Common.Enums.ContactType;
                        isPrimary: boolean;
                        companyName: string;
                        companyIdn: string;
                        companyTin: string;
                        vatPayer: boolean;
                        initials: string;
                        firstName: string;
                        middleName: string;
                        lastName: string;
                        jobTitle: string;
                        salutation: string;
                        salutationFormal: string;
                        salutationInformal: string;
                        gender?: Honeycomb.Common.Enums.Gender;
                        birthDay?: Date;
                        addressLine1: string;
                        addressLine2: string;
                        addressType?: number;
                        city: string;
                        zip: string;
                        countryIsocode: string;
                        currencyIsocode: string;
                        nutsRegion: string;
                        email: string;
                        phoneNr: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        contactUid: string;
                        updatedBy?: number;
                        recVer: number;
                        lastUsedAs?: number;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                    }
                    export class CrHandle {
                        handleId: number;
                        userId: number;
                        type: Honeycomb.Common.Enums.UserHandleType;
                        value: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                    }
                    export class CrLocation {
                        locationID: number;
                        locationNumber: string;
                        updateTime: Date;
                        nutsRegion: string;
                        name: string;
                        city: string;
                        cityPart: string;
                        street: string;
                        streetNumber: string;
                        zipCode: string;
                        phone: string;
                        email: string;
                        countryIsocode: string;
                        currencyIsocode: string;
                        topPrintLine1: string;
                        topPrintLine2: string;
                        topPrintLine3: string;
                        topPrintLine4: string;
                        topPrintLine5: string;
                        bottomPrintLine1: string;
                        bottomPrintLine2: string;
                        bottomPrintLine3: string;
                        bottomPrintLine4: string;
                        bottomPrintLine5: string;
                        latitude?: number;
                        longitude?: number;
                        timeZoneInfoId: string;
                        attributes: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrLocationAttribute>;
                    }
                    export class CrLocationAttribute {
                        locationAttributeID: number;
                        locationID: number;
                        name: string;
                        value: string;
                    }
                    export class CrOption {
                        optionID: number;
                        userID: number;
                        optionTypeName: string;
                        validFromUTC?: Date;
                        validToUTC?: Date;
                        pendingValue: number;
                        value: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        updatedBy?: number;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                    }
                    export class CrProduct {
                        productId: number;
                        name: string;
                        adminComment: string;
                        productNumber: string;
                        manufacturerPartNumber: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        measurementUnitID: number;
                        edition: Honeycomb.Common.Enums.Edition;
                        recVer: number;
                        primaryProductNumber: string;
                        productAttributes: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductAttribute>;
                        productCategories: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductCategory>;
                        productTexts: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductText>;
                        productVariants: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductVariant>;
                        productImages: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductImage>;
                        productDiscounts: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductDiscount>;
                        productAttachments: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductAttachment>;
                        productRelations: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductRelation>;
                    }
                    export class CrProductAttachment {
                        productAttachmentID: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        productID: number;
                        productAttachmentUID: string;
                        attachmentName: string;
                        attachmentDescription: string;
                        displayOrder: number;
                        product: Honeycomb.Tenant.PublicAPI.IService.Model.CrProduct;
                    }
                    export class CrProductAttribute {
                        productId: number;
                        name: string;
                        value: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        product: Honeycomb.Tenant.PublicAPI.IService.Model.CrProduct;
                    }
                    export class CrProductCategory {
                        productCategoryID: number;
                        isFeaturedProduct: boolean;
                        displayOrder: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        categoryCode: string;
                        categoryRootCode: string;
                        product: Honeycomb.Tenant.PublicAPI.IService.Model.CrProduct;
                    }
                    export class CrProductDiscount {
                        productDiscountID: number;
                        type: Honeycomb.Common.Enums.DiscountValueType;
                        value: number;
                        userID?: number;
                        clubID?: number;
                        userNumber: string;
                        clubName: string;
                        productSetName: string;
                        productNumber: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                    }
                    export class CrProductImage {
                        productImageId: number;
                        productId: number;
                        productVariantId?: number;
                        imageType: Honeycomb.Common.Enums.ImageType;
                        recordUID?: string;
                        externalURI: string;
                        localPath: string;
                        purpose: string;
                        parentProductImageId?: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        isPrimary: boolean;
                        childImages: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductImage>;
                    }
                    export class CrProductPrice {
                        productPriceID: number;
                        productVariantID: number;
                        priceType: Honeycomb.Common.Enums.PriceType;
                        priceIndex: number;
                        pricePriority?: number;
                        retailPrice: number;
                        validFrom?: Date;
                        validTill?: Date;
                        currencyISOCode: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        productVariant: Honeycomb.Tenant.PublicAPI.IService.Model.CrProductVariant;
                    }
                    export class CrProductRelation {
                        productRelationID: number;
                        productId: number;
                        productNumber: string;
                        productRelationType: Honeycomb.Common.Enums.ProductRelationType;
                        product: Honeycomb.Tenant.PublicAPI.IService.Model.CrProduct;
                    }
                    export class CrProductText {
                        productId: number;
                        languageIsocode: string;
                        name: string;
                        shortDescription: string;
                        fullDescription: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        product: Honeycomb.Tenant.PublicAPI.IService.Model.CrProduct;
                    }
                    export class CrProductVariant {
                        productVariantId: number;
                        gtin: string;
                        productId: number;
                        vatId?: number;
                        productVariantUid?: string;
                        hidden: boolean;
                        deleted: boolean;
                        isPrimary: boolean;
                        v1: string;
                        v2: string;
                        v3: string;
                        minOrder: number;
                        maxOrder?: number;
                        multiple: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        manufacturerPartNumber: string;
                        measurementUnitID?: number;
                        product: Honeycomb.Tenant.PublicAPI.IService.Model.CrProduct;
                        productPrices: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrProductPrice>;
                    }
                    export class CrReward {
                        rewardId: number;
                        userId: number;
                        name: string;
                        type: number;
                        value: number;
                        loyaltyLevel: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                    }
                    export class CrRole {
                        roleId: number;
                        name: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        userRoles: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrUserRole>;
                    }
                    export class CrStock {
                        stockID: number;
                        productNumber: string;
                        gtin: string;
                        locationID: number;
                        locationNumber: string;
                        transactionTypeID: number;
                        transactionTypeName: string;
                        quantity: number;
                        operation: Honeycomb.Common.Enums.DbOperation;
                    }
                    export class CrTag {
                        tagId: number;
                        userId: number;
                        type: Honeycomb.Common.Enums.UserTagType;
                        number: string;
                        validSince?: Date;
                        validTill?: Date;
                        status: Honeycomb.Common.Enums.TagStatus;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                        attributes: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrTagAttribute>;
                    }
                    export class CrTagAttribute {
                        tagAttributeId: number;
                        tagId: number;
                        tagAttributeName: string;
                        value: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        tag: Honeycomb.Tenant.PublicAPI.IService.Model.CrTag;
                    }
                    export class CrUser {
                        userId: number;
                        userNumber: string;
                        login: string;
                        password: string;
                        passwordHash: string;
                        passwordResetToken: string;
                        passwordResetTokenValidTill?: Date;
                        passwordSalt: string;
                        passwordHashType: Honeycomb.Common.Enums.PasswordHashType;
                        communicationType: number;
                        identificationType: number;
                        emailRequested: string;
                        emailConfirmationRequired?: Honeycomb.Common.Enums.RequiredEmailConfirmationType;
                        emailConfirmationToken: string;
                        emailConfirmed?: boolean;
                        approved: boolean;
                        deleted: boolean;
                        picture?: string;
                        created: Date;
                        modified: Date;
                        userUid: string;
                        recVer: number;
                        attributes: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrUserAttribute>;
                        contacts: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrContact>;
                        userRoles: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrUserRole>;
                        tags: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrTag>;
                        handles: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrHandle>;
                        rewards: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrReward>;
                        attachments: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrAttachment>;
                        options: Array<Honeycomb.Tenant.PublicAPI.IService.Model.CrOption>;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        forciblyForgotten?: Date;
                    }
                    export class CrUserAttribute {
                        userAttributeId: number;
                        userId: number;
                        userAttributeName: string;
                        value: string;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                    }
                    export class CrUserRole {
                        userId: number;
                        roleId: number;
                        created: Date;
                        operation: Honeycomb.Common.Enums.DbOperation;
                        role: Honeycomb.Tenant.PublicAPI.IService.Model.CrRole;
                        user: Honeycomb.Tenant.PublicAPI.IService.Model.CrUser;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {

                    export class ColumnFilter {
                        columnName: string;
                        comparision: Honeycomb.Common.Enums.ColumnFilterComparison;
                        queryableColumnID?: number;
                        queryableParamID?: number;
                        value1: any;
                        value2: any;
                    }
                    export class CustomFunctionRunRequest {
                        timeoutSeconds: number;
                        functionName: string;
                        message: string;
                        parameters: { [key: string]: any };
                    }
                    export class CustomFunctionRunResult {
                        scalarResult: any;
                        tableResult: Array<any>;
                    }
                    export class DataImportResult {
                        errors: Array<string>;
                        exception: string;
                    }
                    export class Queryable {
                        queryableID: number;
                        dataGroup: Honeycomb.Common.Enums.DataGroup;
                        type: Honeycomb.Common.Enums.QueryableType;
                        purpose: Honeycomb.Common.Enums.QueryablePurpose;
                        name: string;
                        description: string;
                        spName: string;
                        groupName: string;
                        sqlName: string;
                        sqlAlias: string;
                        sqlspTableName: string;
                        disabled?: boolean;
                        created: Date;
                        updateTime: Date;
                        queryableColumns: Array<Honeycomb.Tenant.Reports.IService.Model.QueryableColumn>;
                        queryableParams: Array<Honeycomb.Tenant.Reports.IService.Model.QueryableParam>;
                        queryableConnectors: Array<Honeycomb.Tenant.Reports.IService.Model.QueryableConnector>;
                    }
                    export class QueryableColumn {
                        queryableID: number;
                        queryableColumnID: number;
                        name: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                        sqlDataType: string;
                        codeListID?: number;
                        ordinal: number;
                        sqlName: string;
                        sqlNameNoParenthesis: string;
                        sqlAlias: string;
                        created: Date;
                        updateTime: Date;
                        reportDetailFilters: Array<Honeycomb.Tenant.Reports.IService.Model.ReportDetailFilter>;
                    }
                    export class QueryableConnector {
                        queryableID: number;
                        queryableConnectorID: number;
                        name: string;
                        created: Date;
                        updateTime: Date;
                    }
                    export class QueryableParam {
                        queryableID: number;
                        queryableParamID: number;
                        name: string;
                        nameLocKey: string;
                        devaultValue: string;
                        ordinal: number;
                        dataType?: Honeycomb.Common.Enums.DataType;
                        codeListID?: number;
                        codeListName: string;
                        created: Date;
                        updateTime: Date;
                    }
                    export class RelatedProductResult {
                        products: Array<Honeycomb.Common.ViewModels.RelatedProduct>;
                    }
                    export class Report {
                        reportID: number;
                        name: string;
                        description: string;
                        reportGroup: string;
                        reportType: Honeycomb.Common.Enums.ReportType;
                        reportDataModel: Honeycomb.Common.Enums.ReportDataModel;
                        defaultPageSize: number;
                        showSelectionCheckbox: boolean;
                        detailReportID?: number;
                        showTotals: boolean;
                        rowHeight: number;
                        advancedFilterPurpose: Honeycomb.Common.Enums.FilterPurpose;
                        showFulltextFilter: boolean;
                        maxRecordCount: number;
                        commandTimeout?: number;
                        enableUpdate: boolean;
                        enableDelete: boolean;
                        enableInsert: boolean;
                        enableImport: boolean;
                        updateQuery: string;
                        deleteQuery: string;
                        updateExchange: string;
                        updateMessage: string;
                        updateMessageTemplate: string;
                        updateMessageObjectType: string;
                        created: Date;
                        updateTime: Date;
                        reportQueryables: Array<Honeycomb.Tenant.Reports.IService.Model.ReportQueryable>;
                        reportFilters: Array<Honeycomb.Tenant.Reports.IService.Model.ReportFilter>;
                        reportColumns: Array<Honeycomb.Tenant.Reports.IService.Model.ReportColumn>;
                        reportDetailFilters: Array<Honeycomb.Tenant.Reports.IService.Model.ReportDetailFilter>;
                        savedReports: Array<Honeycomb.Tenant.Reports.IService.Model.SavedReport>;
                        reportActions: Array<Honeycomb.Tenant.Reports.IService.Model.ReportAction>;
                        reportRoles: Array<Honeycomb.Tenant.Reports.IService.Model.ReportRole>;
                        detailReport: Honeycomb.Tenant.Reports.IService.Model.Report;
                    }
                    export class ReportAction {
                        reportActionID: number;
                        reportID: number;
                        action: Honeycomb.Common.Enums.ReportActionType;
                        params: string;
                    }
                    export class ReportColumn {
                        reportColumnID: number;
                        reportID: number;
                        queryableColumnID?: number;
                        customName: string;
                        customDescription: string;
                        customDataType?: Honeycomb.Common.Enums.DataType;
                        displayRules: string;
                        uniqueColumnName: string;
                        expression: string;
                        defaultValue: string;
                        jsonAttributes: string;
                        aggregateFunction: Honeycomb.Common.Enums.AggregateFunction;
                        ordinal: number;
                        hidden: boolean;
                        rowIDColumn: boolean;
                        dynamicHeight: boolean;
                        reportDetailType: Honeycomb.Common.Enums.ReportDetailType;
                        detailContentColumnID?: number;
                        currencyQueryableColumnID?: number;
                        codeListID?: number;
                        codeListName: string;
                        defaultSortDirection: Honeycomb.Common.Enums.ReportColumnSortDirection;
                        pinType: Honeycomb.Common.Enums.ReportColumnPinType;
                        defaultSortOrder: number;
                        defaultGroupBy: boolean;
                        editable: boolean;
                        created: Date;
                        updateTime: Date;
                        queryableColumn: Honeycomb.Tenant.Reports.IService.Model.QueryableColumn;
                        currencyQueryableColumn: Honeycomb.Tenant.Reports.IService.Model.QueryableColumn;
                        columnName: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                    }
                    export class ReportContext {
                        reportID: number;
                        reportName: string;
                        needTotalCount: boolean;
                        pagination: Honeycomb.Tenant.Reports.IService.Model.ReportPagination;
                        sorting: Array<Honeycomb.Tenant.Reports.IService.Model.ReportSorting>;
                        groupByColumns: Array<string>;
                        sqlQueryGroupByColumns: Array<string>;
                        groupByValuesFilter: Array<string>;
                        columnFilters: Array<Honeycomb.Tenant.Reports.IService.Model.ColumnFilter>;
                        filterTempTables: Array<Honeycomb.Common.ViewModels.BulkInsertTableResult>;
                        variables: { [key: string]: any };
                        fulltextFilter: string;
                        resultToTempTable: boolean;
                        resultColumnIDName: string;
                        maxRecordCount: number;
                        commandTimeout?: number;
                        printSettings: Honeycomb.Tenant.Reports.IService.Model.ReportPrintSettings;
                        isGrouping: boolean;
                        hasAnyGrouping: boolean;
                    }
                    export class ReportDetailFilter {
                        reportDetailFilterID: number;
                        reportID: number;
                        masterQueryableColumnID: number;
                        detailQueryableColumnID: number;
                        masterQueryableColumn: Honeycomb.Tenant.Reports.IService.Model.QueryableColumn;
                        detailQueryableColumn: Honeycomb.Tenant.Reports.IService.Model.QueryableColumn;
                    }
                    export class ReportExportRequest {
                        reportIDs: Array<number>;
                    }
                    export class ReportExportResult {
                        token: string;
                        fileName: string;
                    }
                    export class ReportFilter {
                        reportFilterID: number;
                        reportID: number;
                        filterID?: number;
                        queryableColumnID?: number;
                        queryableParameterID?: number;
                        comparision: Honeycomb.Common.Enums.ColumnFilterComparison;
                        defaultFilterValue1: string;
                        defaultFilterValue2: string;
                        description: string;
                        ordinal: number;
                        editorType: Honeycomb.Common.Enums.FilterEditorType;
                        codeListID?: number;
                        codeListName: string;
                        hidden: boolean;
                        required: boolean;
                        queryableColumn: Honeycomb.Tenant.Reports.IService.Model.QueryableColumn;
                        queryableParam: Honeycomb.Tenant.Reports.IService.Model.QueryableParam;
                        filter: Honeycomb.Tenant.Admin.IService.ViewModels.FilterViewModel;
                        caption: string;
                    }
                    export class ReportObjectResult {
                        totalCount: number;
                        tempTableName: string;
                        idColumnName: string;
                        lookup: { [key: number]: string };
                    }
                    export class ReportPagination {
                        offset: number;
                        rowCount: number;
                    }
                    export class ReportPrintSettings {
                        pageFormat: Honeycomb.Common.Enums.ReportPrintFormat;
                        pageOrientation: Honeycomb.Common.Enums.ReportPrintOrientation;
                        isPreview: boolean;
                    }
                    export class ReportQueryable {
                        reportQueryableID: number;
                        reportID: number;
                        queryableID: number;
                        joinType: Honeycomb.Common.Enums.SQLJoinType;
                        joinCondition: string;
                        preferredJoinColumn: string;
                        ordinal: number;
                        queryable: Honeycomb.Tenant.Reports.IService.Model.Queryable;
                    }
                    export class ReportResult {
                        totalCount: number;
                        tempTableName: string;
                        idColumnName: string;
                        lookup: { [key: number]: string };
                    }
                    export class ReportRole {
                        reportRoleID: number;
                        roleID: number;
                        reportID: number;
                    }
                    export class ReportSorting {
                        sortField: string;
                        sortDir: Honeycomb.Common.SortDirection;
                    }
                    export class ReportTableRunRequest {
                        reportName: string;
                        reportID: number;
                        userUID?: string;
                        fulltextFilter: string;
                        filters: Array<Honeycomb.Tenant.Reports.IService.Model.ColumnFilter>;
                        pagination: Honeycomb.Tenant.Reports.IService.Model.ReportPagination;
                    }
                    export class RunTableReportResult {
                        columns: Array<Honeycomb.Tenant.Reports.IService.Model.ReportColumn>;
                        totalCount: number;
                        tempTableName: string;
                        idColumnName: string;
                        lookup: { [key: number]: string };
                    }
                    export class RunUpdateQueryRequest {
                        reportID: number;
                        updatedRows: Array<any>;
                        deleteRows: Array<any>;
                    }
                    export class SavedReport {
                        savedReportID: number;
                        reportID: number;
                        userName: string;
                        name: string;
                        content: string;
                        created: Date;
                        updateTime: Date;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace PBI {

                        export class AAD {
                            token_type: string;
                            scope: string;
                            experies_in: number;
                            ext_experies_in: number;
                            experies_on: number;
                            not_before: number;
                            resource: any;
                            access_token: string;
                            refresh_token: string;
                            internalExpiresAt: Date;
                        }
                        export class PBIConfig {
                            user: string;
                            password: string;
                            encryptedPassword: string;
                            authServiceURL: string;
                            authResourceURL: string;
                            apiServiceURL: string;
                            applicationId: string;
                            gatewayName: string;
                        }
                        export class PBIExportResult {
                            token: string;
                            fileName: string;
                        }
                        export class PBIReport {
                            pbiReportID: number;
                            reportName: string;
                            description: string;
                            reportID?: string;
                            targetUrl: string;
                            folder: string;
                            layoutType: Honeycomb.Common.Enums.ReportLayoutType;
                            showInCRS: boolean;
                            report: any;
                            reportSource: Honeycomb.Common.Enums.ReportSourceType;
                            created: Date;
                            roles: Array<Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReportRole>;
                        }
                        export class PBIReportRole {
                            pbiReportRoleID: number;
                            roleID: number;
                            pbiReportID: number;
                        }
                        export class PBIReportTemplate {
                            reportTemplateID: number;
                            name: string;
                            description: string;
                            pbixFileContent: Array<number>;
                            created: Date;
                        }
                        export class PBITemplateExportRequest {
                            templateIDs: Array<number>;
                        }
                        export class ReportCloneRequest {
                            reportName: string;
                            reportId: string;
                            description: string;
                            folder: string;
                            reportSource: Honeycomb.Common.Enums.ReportSourceType;
                            targetUrl: string;
                        }
                        export class ReportCreateRequest {
                            reportName: string;
                            templateName: string;
                            description: string;
                            folder: string;
                            uploadToken: string;
                            reportSource: Honeycomb.Common.Enums.ReportSourceType;
                            targetUrl: string;
                            sourceOverride: boolean;
                        }
                        export class ReportUpdateDatasetRequest {
                            reportId: string;
                            templateName: string;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace Segments {

                        export class ResultTable {
                            tableName: string;
                            resultCount: number;
                        }
                        export class RunSegmentRequest {
                            sessionUID?: string;
                            segment: Honeycomb.Tenant.Reports.IService.Model.Segments.Segment;
                            filterUID?: string;
                            resultTableUID?: string;
                        }
                        export class RunSegmentResult {
                            sessionUID: string;
                            filtersResult: { [key: string]: Honeycomb.Tenant.Reports.IService.Model.Segments.ResultTable };
                            columnsResult: { [key: number]: Honeycomb.Tenant.Reports.IService.Model.Segments.ResultTable };
                            finalResultCount: number;
                            resultSessionTable: Honeycomb.Tenant.Reports.IService.Model.Segments.SegmentSession;
                        }
                        export class Segment {
                            segmentID: number;
                            name: string;
                            description: string;
                            dataGroup: Honeycomb.Common.Enums.DataGroup;
                            created: Date;
                            updateTime: Date;
                            segmentFilters: Array<Honeycomb.Tenant.Reports.IService.Model.Segments.SegmentFilter>;
                            segmentHistory: Array<Honeycomb.Tenant.Reports.IService.Model.Segments.SegmentHistory>;
                        }
                        export class SegmentFilter {
                            segmentFilterID: number;
                            segmentID: number;
                            filterQueryableID?: number;
                            filterSegmentID?: number;
                            filterUID: string;
                            description: string;
                            column: number;
                            row: number;
                            created: Date;
                            updateTime: Date;
                            filterQueryable: Honeycomb.Tenant.Reports.IService.Model.Queryable;
                            filterSegment: Honeycomb.Tenant.Reports.IService.Model.Segments.Segment;
                            segmentFilterParams: Array<Honeycomb.Tenant.Reports.IService.Model.Segments.SegmentFilterParam>;
                        }
                        export class SegmentFilterParam {
                            segmentFilterParamID: number;
                            segmentFilterID: number;
                            queryableParamID: number;
                            value: string;
                            created: Date;
                            updateTime: Date;
                            queryableParam: Honeycomb.Tenant.Reports.IService.Model.QueryableParam;
                            nameLocKey: string;
                        }
                        export class SegmentHistory {
                            segmentHistoryID: number;
                            segmentID: number;
                            count: number;
                            created: Date;
                        }
                        export class SegmentSession {
                            segmentSessionID: number;
                            sessionUID: string;
                            filterUID: string;
                            tableName: string;
                            contentHashCode: string;
                            created: Date;
                            updateTime: Date;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace Stats {
                        export namespace Container {

                            export class RawContact {
                                rawContactId: number;
                                rawHeaderID: number;
                                contactType: number;
                                companyName: string;
                                companyIdn: string;
                                companyTin: string;
                                initials: string;
                                firstName: string;
                                middleName: string;
                                lastName: string;
                                jobTitle: string;
                                salutation: string;
                                salutationFormal: string;
                                salutationInformal: string;
                                addressLine1: string;
                                addressLine2: string;
                                addressType?: number;
                                city: string;
                                zip: string;
                                countryIsocode: string;
                                email: string;
                                phoneNr: string;
                                updatedBy?: number;
                                headerID: number;
                                rawHeader: Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawHeader;
                            }
                            export class RawHeader {
                                rawHeaderID: number;
                                number: string;
                                transactionTypeName: string;
                                transactionType: number;
                                locationNumber: string;
                                sellerNumber: string;
                                cachierNumber: string;
                                posNumber?: number;
                                userID?: number;
                                userNumber: string;
                                sessionID?: number;
                                userCardNumber: string;
                                currencyIsocode: string;
                                currencyRate: number;
                                created: Date;
                                locationName: string;
                                countryIsocode: string;
                                cancel: boolean;
                                transactionUniqueIdentifier: string;
                                status?: number;
                                rawRewards: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawReward>;
                                rawItems: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawItem>;
                                rawPayments: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawPayment>;
                                rawPromotions: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawPromotion>;
                                rawHeaderData: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawHeaderData>;
                                rawVouchers: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawVoucher>;
                                rawContacts: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawContact>;
                            }
                            export class RawHeaderData {
                                rawHeaderDataID: number;
                                rawHeaderID: number;
                                meaning: Honeycomb.Common.Enums.RawHeaderDataMeaning;
                                name: string;
                                value: string;
                            }
                            export class RawItem {
                                rawItemID: number;
                                rawHeaderID: number;
                                itemNumber: number;
                                productNumber: string;
                                productText: string;
                                productImageURL: string;
                                vatPercent: number;
                                quantity: number;
                                totalPrice: number;
                                variant1: string;
                                variant2: string;
                                variant3: string;
                                stockItem: boolean;
                                movementType: number;
                                movementTypeName: string;
                                originalRetailPriceWithoutVAT: number;
                                originalRetailPriceWithVAT: number;
                                retailPriceWithoutVAT: number;
                                retailPriceWithVAT: number;
                                originalPurchasePriceWithoutVAT: number;
                                originalPurchasePriceWithVAT: number;
                                purchasePriceWithoutVAT: number;
                                purchasePriceWithVAT: number;
                                rawHeader: Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawHeader;
                                rawItemCategories: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawItemCategory>;
                                rawItemData: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawItemData>;
                                rawPromotions: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.Container.RawItemPromotion>;
                            }
                            export class RawItemCategory {
                                rawItemCategoryID: number;
                                rawItemID: number;
                                level: number;
                                code: string;
                                text: string;
                            }
                            export class RawItemData {
                                rawItemDataID: number;
                                rawItemID: number;
                                meaning: Honeycomb.Common.Enums.RawItemDataMeaning;
                                name: string;
                                value: string;
                            }
                            export class RawItemPromotion {
                                rawItemPromotionID: number;
                                rawItemID: number;
                                meaning: number;
                                code: string;
                                name: string;
                                discountValue: number;
                                promotionValue: number;
                                voucherNumber: string;
                            }
                            export class RawPayment {
                                rawPaymentID: number;
                                rawHeaderID: number;
                                medium: Honeycomb.Common.Enums.PaymentMedium;
                                currencyIsocode: string;
                                currencyRate: number;
                                value: number;
                            }
                            export class RawPromotion {
                                rawPromotionID: number;
                                rawHeaderID?: number;
                                rawItemID?: number;
                                meaning: number;
                                code: string;
                                name: string;
                                discountValue: number;
                                voucherNumber: string;
                            }
                            export class RawReward {
                                rawRewardID: number;
                                rawHeaderID: number;
                                name: string;
                                value: number;
                            }
                            export class RawVoucher {
                                rawVoucherID: number;
                                rawHeaderID: number;
                                longNumber: string;
                                value: number;
                                voucherValueType: Honeycomb.Common.Enums.DiscountValueType;
                                voucherID: number;
                                operation: Honeycomb.Common.Enums.VoucherOperation;
                            }
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace Stats {

                        export class StatCodeListItem {
                            codeListItemID: number;
                            codeListID: number;
                            value: string;
                            productAttributes: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatProductAttribute>;
                        }
                        export class StatCurrency {
                            currencyID: number;
                            currencyIsocode: string;
                            currencyRate?: number;
                        }
                        export class StatLocation {
                            locationID: number;
                            lookupLocationID?: number;
                            locationNumber: string;
                            name: string;
                            countryIsocode: string;
                            currencyIsocode: string;
                        }
                        export class StatMovementType {
                            movementTypeID: number;
                            type: number;
                            name: string;
                            description: string;
                            groupType: Honeycomb.Common.Enums.CumulatedMovementGroupType;
                            groupEffect: number;
                            movementEffect: number;
                        }
                        export class StatPaymentType {
                            paymentTypeID: number;
                            medium: number;
                            currencyIsocode: string;
                            currencyRate: number;
                        }
                        export class StatProduct {
                            statProductID: number;
                            number: string;
                            name: string;
                            imageURL: string;
                            productID?: number;
                            supplierUserID?: number;
                            stockItem: boolean;
                            categories: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatProductInCategory>;
                            productVariants: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatProductVariant>;
                            productAttributes: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatProductAttribute>;
                        }
                        export class StatProductAttribute {
                            attributeID: number;
                            statProductID: number;
                            codeListItemID: number;
                            codeListItem: Honeycomb.Tenant.Reports.IService.Model.Stats.StatCodeListItem;
                            product: Honeycomb.Tenant.Reports.IService.Model.Stats.StatProduct;
                        }
                        export class StatProductCategory {
                            statProductCategoryID: number;
                            level: number;
                            code: string;
                            text: string;
                        }
                        export class StatProductInCategory {
                            statProductInCategoryID: number;
                            productID: number;
                            productCategoryID: number;
                            product: Honeycomb.Tenant.Reports.IService.Model.Stats.StatProduct;
                            category: Honeycomb.Tenant.Reports.IService.Model.Stats.StatProductCategory;
                        }
                        export class StatProductVariant {
                            statProductVariantID: number;
                            statProductVariantTypeID: number;
                            statProductID: number;
                            displayOrder: number;
                            value: string;
                            variantType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatProductVariantType;
                        }
                        export class StatProductVariantType {
                            statProductVariantTypeID: number;
                            name: string;
                            description: string;
                        }
                        export class StatPromotionType {
                            promotionTypeID: number;
                            meaning: number;
                            code: string;
                            name: string;
                            value?: number;
                        }
                        export class StatRewardType {
                            rewardTypeID: number;
                            name: string;
                        }
                        export class StatTransaction {
                            transactionID: number;
                            transactionTypeID: number;
                            created: Date;
                            modified: Date;
                            locationID: number;
                            number: string;
                            userID?: number;
                            sellerID?: number;
                            cachierID?: number;
                            sessionID?: number;
                            posNumber?: number;
                            currencyID: number;
                            transactionUniqueIdentifier: string;
                            statusID?: number;
                            status?: number;
                            recVer: number;
                            vatPercentage: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatVatValuePair>;
                            totalRetailPriceWithVAT: number;
                            totalRetailPriceWithoutVAT: number;
                            totalVAT: number;
                            transactionType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionType;
                            currency: Honeycomb.Tenant.Reports.IService.Model.Stats.StatCurrency;
                            location: Honeycomb.Tenant.Reports.IService.Model.Stats.StatLocation;
                            items: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionItem>;
                            rewards: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionRewards>;
                            payments: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionPayment>;
                            promotions: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionPromotion>;
                            attributes: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionAttribute>;
                            vouchers: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionVoucher>;
                            contacts: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionContact>;
                        }
                        export class StatTransactionAttribute {
                            transactionAttributeID: number;
                            transactionAttributeTypeID: number;
                            transactionID: number;
                            value: string;
                            attributeType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionAttributeType;
                        }
                        export class StatTransactionAttributeType {
                            transactionAttributeTypeID: number;
                            meaning: Honeycomb.Common.Enums.RawHeaderDataMeaning;
                            name: string;
                        }
                        export class StatTransactionContact {
                            contactId: number;
                            transactionID: number;
                            contactType: number;
                            companyName: string;
                            companyIdn: string;
                            companyTin: string;
                            initials: string;
                            firstName: string;
                            middleName: string;
                            lastName: string;
                            jobTitle: string;
                            salutation: string;
                            salutationFormal: string;
                            salutationInformal: string;
                            addressLine1: string;
                            addressLine2: string;
                            addressType?: number;
                            city: string;
                            zip: string;
                            countryIsocode: string;
                            email: string;
                            phoneNr: string;
                            updatedBy?: number;
                            headerID: number;
                            transaction: Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransaction;
                        }
                        export class StatTransactionItem {
                            transactionItemID: number;
                            statProductID: number;
                            itemNumber: number;
                            movementTypeID: number;
                            transactionID: number;
                            quantity: number;
                            vatPercent: number;
                            totalPrice: number;
                            vatValue: number;
                            totalRetailPriceWithVAT: number;
                            totalRetailPriceWithoutVAT: number;
                            movementType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatMovementType;
                            product: Honeycomb.Tenant.Reports.IService.Model.Stats.StatProduct;
                            promotions: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionItemPromotion>;
                            variants: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionItemVariant>;
                            prices: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionItemPrices>;
                            attributes: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionItemAttribute>;
                        }
                        export class StatTransactionItemAttribute {
                            transactionItemAttributeID: number;
                            transactionItemAttributeTypeID: number;
                            itemID: number;
                            value: string;
                            attributeType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransactionItemAttributeType;
                        }
                        export class StatTransactionItemAttributeType {
                            transactionItemAttributeTypeID: number;
                            meaning: Honeycomb.Common.Enums.RawItemDataMeaning;
                            name: string;
                        }
                        export class StatTransactionItemPrices {
                            transactionItemPricesID: number;
                            transactionItemID: number;
                            originalRetailPriceWithoutVAT: number;
                            originalRetailPriceWithVAT: number;
                            retailPriceWithoutVAT: number;
                            retailPriceWithVAT: number;
                            purchasePriceWithoutVAT: number;
                            purchasePriceWithVAT: number;
                        }
                        export class StatTransactionItemPromotion {
                            transactionItemPromotionID: number;
                            transactionItemID?: number;
                            promotionTypeID: number;
                            discountValue: number;
                            promotionType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatPromotionType;
                        }
                        export class StatTransactionItemVariant {
                            transactionItemVariantID: number;
                            transactionItemID: number;
                            statProductVariantID: number;
                            variant: Honeycomb.Tenant.Reports.IService.Model.Stats.StatProductVariant;
                        }
                        export class StatTransactionPayment {
                            transactionPaymentID: number;
                            transactionID: number;
                            value: number;
                            paymentTypeID: number;
                            paymentType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatPaymentType;
                        }
                        export class StatTransactionPromotion {
                            transactionPromotionID: number;
                            transactionID?: number;
                            promotionTypeID: number;
                            discountValue: number;
                            promotionType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatPromotionType;
                        }
                        export class StatTransactionRewards {
                            transactionRewardsID: number;
                            transactionID: number;
                            rewardTypeID: number;
                            value: number;
                            rewardType: Honeycomb.Tenant.Reports.IService.Model.Stats.StatRewardType;
                        }
                        export class StatTransactionType {
                            transactionTypeID: number;
                            type: number;
                            name: string;
                            description: string;
                        }
                        export class StatTransactionVoucher {
                            statTransactionVoucherID: number;
                            transactionID: number;
                            longNumber: string;
                            value: number;
                            voucherValueType: Honeycomb.Common.Enums.DiscountValueType;
                            voucherID: number;
                            operation: Honeycomb.Common.Enums.VoucherOperation;
                        }
                        export class StatVatValuePair {
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {

                export class StatTransactionsListResponse {
                    totalCount: number;
                    headers: Array<Honeycomb.Tenant.Reports.IService.Model.Stats.StatTransaction>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {
                export namespace Model {

                    export class Activity {
                        activityID: number;
                        taskType: Honeycomb.Common.Enums.TaskType;
                        name: string;
                        nameLoc: string;
                        description: string;
                        duration: number;
                        confirmRequired: boolean;
                        created: Date;
                        updateTime: Date;
                        deactivated?: Date;
                        activityInputs: Array<Honeycomb.Tenant.Tasker.IService.Model.ActivityInput>;
                        operationActivities: Array<Honeycomb.Tenant.Tasker.IService.Model.OperationActivity>;
                        taskActivities: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivity>;
                    }
                    export class ActivityInput {
                        activityInputID: number;
                        activityID: number;
                        inputID: number;
                        sortingOrder: number;
                        activity: Honeycomb.Tenant.Tasker.IService.Model.Activity;
                        input: Honeycomb.Tenant.Tasker.IService.Model.Input;
                    }
                    export class ActivityInputUpsert {
                        activityInputID: number;
                        activityID: number;
                        inputID: number;
                        sortingOrder: number;
                        codeListID?: number;
                        meaningID: number;
                        inputValue: any;
                        inputNote: string;
                        uiType: Honeycomb.Common.Enums.UiType;
                        name: string;
                        regex: string;
                        recordUid?: string;
                        taskValuePhotos: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhotoUpsert>;
                    }
                    export class AdditionalLocationInfo {
                        items: Array<Honeycomb.Tenant.Tasker.IService.Model.AdditionalLocationInfoItem>;
                    }
                    export class AdditionalLocationInfoItem {
                        locationID: number;
                        infoValues: Array<Honeycomb.Tenant.Tasker.IService.Model.AdditionalLocationInfoValue>;
                    }
                    export class AdditionalLocationInfoValue {
                        name: string;
                        value: string;
                    }
                    export class Batch {
                        batchID: number;
                        userID: number;
                        name: string;
                        description: string;
                        created: Date;
                        tasks: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>;
                    }
                    export class CustomSpModel {
                        spName: string;
                        params: { [key: string]: any };
                    }
                    export class DashboardRequest {
                        jobID?: number;
                        tileKeys: Array<string>;
                    }
                    export class FollowUpTaskTemplate {
                        followUpTaskTemplateID: number;
                        taskActivityID: number;
                        activityID: number;
                        taskValueID?: number;
                        followUpKey: string;
                        operationID: number;
                        name: string;
                        priority: Honeycomb.Common.Enums.Priority;
                        startDate: Date;
                        deadline?: Date;
                        taskActivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivity;
                        taskValue: Honeycomb.Tenant.Tasker.IService.Model.TaskValue;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                        userIDs: Array<number>;
                        locationIDs: Array<number>;
                    }
                    export class Input {
                        inputID: number;
                        name: string;
                        nameLoc: string;
                        description: string;
                        descriptionLoc: string;
                        infoText: string;
                        infoTextLoc: string;
                        hasInfoText: boolean;
                        uitype: Honeycomb.Common.Enums.UiType;
                        inputParams: string;
                        codeListID?: number;
                        required: boolean;
                        regex: string;
                        meaningID: number;
                        created: Date;
                        updateTime: Date;
                        value: any;
                        valueUpdated?: Date;
                        note: string;
                        meaning: Honeycomb.Tenant.Tasker.IService.Model.Meaning;
                        taskValuePhotos: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhoto>;
                        activityInputs: Array<Honeycomb.Tenant.Tasker.IService.Model.ActivityInput>;
                    }
                    export class InputSnapshot {
                        inputID: number;
                        name: string;
                        description: string;
                        infoText: string;
                        uitype: Honeycomb.Common.Enums.UiType;
                        inputParams: string;
                        codeListID?: number;
                        required: boolean;
                        regex: string;
                        meaningID: number;
                        created: Date;
                        updateTime: Date;
                        meaningName: string;
                        meaningDescription: string;
                        meaningDataType: Honeycomb.Common.Enums.DataType;
                        valueUserID: number;
                        value: any;
                        note: string;
                    }
                    export class InputValuePhotoSnapshot {
                        inputValuePhotoSnapshotID: number;
                        inputID: number;
                        taskerFileUid: string;
                        inputSnapshot: Honeycomb.Tenant.Tasker.IService.Model.InputSnapshot;
                        validFromUTC: Date;
                    }
                    export class JobPermissionType {
                        jobPermissionTypeID: number;
                        name: string;
                        jobPermissionMeaning: Honeycomb.Common.Enums.JobPermissionMeaning;
                        value: any;
                        jobToJobPermissions: Array<Honeycomb.Tenant.Tasker.IService.Model.JobToJobPermission>;
                    }
                    export class JobToJobPermission {
                        jobGroupIDSrc: number;
                        taskRelationSrc: Honeycomb.Common.Enums.TaskRelation;
                        jobGroupIDDest: number;
                        taskRelationDest: Honeycomb.Common.Enums.TaskRelation;
                        operationID: number;
                        jobPermissionTypeIDs: Array<number>;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                        jobPermissionType: Honeycomb.Tenant.Tasker.IService.Model.JobPermissionType;
                        permissionType: Honeycomb.Common.Enums.PermissionType;
                    }
                    export class LocationContact {
                    }
                    export class LocationRecord {
                        locationRecordID: number;
                        latitude: number;
                        longitude: number;
                        altitude?: number;
                        accuracy: number;
                        altitudeAccuracy?: number;
                        heading?: number;
                        speed?: number;
                        positionTimestamp: number;
                        userID: number;
                        dateCreated: Date;
                        data: string;
                    }
                    export class LocationSearchResultItem {
                        locationID: number;
                        companyID?: number;
                        locationNumber: string;
                        nutsRegion: string;
                        name: string;
                        city: string;
                        cityPart: string;
                        street: string;
                        streetNumber: string;
                        zipCode: string;
                        phone: string;
                        email: string;
                        latitude?: number;
                        longitude?: number;
                        timeZoneInfoId: string;
                        timestamp: number;
                        locationContacts: Array<Honeycomb.Tenant.Tasker.IService.Model.LocationContact>;
                    }
                    export class Meaning {
                        meaningID: number;
                        name: string;
                        nameLoc: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                        created: Date;
                        updateTime: Date;
                        inputs: Array<Honeycomb.Tenant.Tasker.IService.Model.Input>;
                        taskValues: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskValue>;
                    }
                    export class Operation {
                        operationID: number;
                        taskType: Honeycomb.Common.Enums.TaskType;
                        name: string;
                        nameLoc: string;
                        code: string;
                        description: string;
                        created: Date;
                        updateTime: Date;
                        deactivated?: Date;
                        sortIndex: number;
                        canBeChild: boolean;
                        statusTransitionSettings: Honeycomb.Tenant.Tasker.IService.Model.StatusTransitionSettings;
                        operationActivities: Array<Honeycomb.Tenant.Tasker.IService.Model.OperationActivity>;
                        operationStatuses: Array<Honeycomb.Tenant.Tasker.IService.Model.OperationStatus>;
                        tasks: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>;
                        operationJobs: Array<Honeycomb.Tenant.Tasker.IService.Model.OperationJob>;
                        jobToJobPermissions: Array<Honeycomb.Tenant.Tasker.IService.Model.JobToJobPermission>;
                    }
                    export class OperationActivity {
                        operationActivityID: number;
                        operationID: number;
                        activityID: number;
                        sortingOrder: number;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                        activity: Honeycomb.Tenant.Tasker.IService.Model.Activity;
                    }
                    export class OperationJob {
                        operationID: number;
                        jobID: number;
                        taskRelation: Honeycomb.Common.Enums.TaskRelation;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                    }
                    export class OperationStatus {
                        operationID: number;
                        taskStatusID: number;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                        taskStatus: Honeycomb.Tenant.Tasker.IService.Model.TaskStatus;
                    }
                    export class Status {
                        statusID: number;
                        name: string;
                        deactivated?: Date;
                        activityClosed: boolean;
                        activityCreated: boolean;
                        taskActivities: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivity>;
                        statusChanges: Array<Honeycomb.Tenant.Tasker.IService.Model.StatusChange>;
                    }
                    export class StatusChange {
                        statusChangeID: number;
                        taskActivityID: number;
                        statusID: number;
                        userID: number;
                        updateTime: Date;
                        note: string;
                        taskActivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivity;
                        status: Honeycomb.Tenant.Tasker.IService.Model.Status;
                    }
                    export class StatusTransitionSettings {
                        sourceStatusIDs: Array<number>;
                        periodType: Honeycomb.Common.Enums.PeriodType;
                        periodValue: number;
                        olderThanDays?: number;
                        executionTime: Date;
                        targetStatusID: number;
                        customSPName: string;
                    }
                    export class Task {
                        taskID: number;
                        batchID?: number;
                        operationID: number;
                        operationSnapshotID?: number;
                        parentTaskID?: number;
                        parentTaskValueID?: number;
                        parentSnapshotInputID?: number;
                        locationID?: number;
                        jobID: number;
                        name: string;
                        description: string;
                        address: string;
                        locationName: string;
                        startDate: Date;
                        deadline?: Date;
                        priority: Honeycomb.Common.Enums.Priority;
                        resultValue: string;
                        created: Date;
                        updateTime: Date;
                        taskStatusID: number;
                        taskStatusBase: Honeycomb.Common.Enums.TaskState;
                        taskStatusCode: string;
                        taskStatusUpdate: Date;
                        isDeleted: boolean;
                        messagesTotal: number;
                        messagesRead: number;
                        childTasksCount: number;
                        childTaskIDs: Array<number>;
                        dateRead?: Date;
                        taskSequenceID?: number;
                        taskInfo: Honeycomb.Tenant.Tasker.IService.Model.TaskInfo;
                        batch: Honeycomb.Tenant.Tasker.IService.Model.Batch;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                        parent: Honeycomb.Tenant.Tasker.IService.Model.Task;
                        taskSequence: Honeycomb.Tenant.Tasker.IService.Model.TaskSequence;
                        children: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>;
                        taskActivities: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivity>;
                        taskActivitySnapshots: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivity>;
                        taskUsers: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskUser>;
                        taskAttachments: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskAttachment>;
                        taskResults: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskResult>;
                        followUpTaskTemplates: Array<Honeycomb.Tenant.Tasker.IService.Model.FollowUpTaskTemplate>;
                        taskPhotos: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPhoto>;
                        taskReads: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskRead>;
                        taskAttributes: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskAttribute>;
                        updatedBy?: number;
                        validFromUTC?: Date;
                        validToUTC?: Date;
                        updatedByContactUserID?: number;
                    }
                    export class TaskActivity {
                        taskActivityID: number;
                        taskID: number;
                        activityID: number;
                        duration?: number;
                        statusID: number;
                        userID: number;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        updatedByContactUserID?: number;
                        task: Honeycomb.Tenant.Tasker.IService.Model.Task;
                        activity: Honeycomb.Tenant.Tasker.IService.Model.Activity;
                        status: Honeycomb.Tenant.Tasker.IService.Model.Status;
                        statusChanges: Array<Honeycomb.Tenant.Tasker.IService.Model.StatusChange>;
                        taskValues: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskValue>;
                        taskActivityResults: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivityResult>;
                    }
                    export class TaskActivityResult {
                        taskActivityResultID: number;
                        taskActivityID: number;
                        taskResultTypeID: number;
                        value: any;
                        created: Date;
                        updateTime: Date;
                        resultType: Honeycomb.Tenant.Tasker.IService.Model.TaskResultType;
                    }
                    export class TaskActivitySnapshotStatusUpsert {
                        activityID: number;
                        activityDone: boolean;
                        updatedByContactUserID?: number;
                        updatedBy?: number;
                    }
                    export class TaskActivityStatusUpsert {
                        taskActivityID: number;
                        activityDone: boolean;
                        updatedByContactUserID?: number;
                        updatedBy?: number;
                    }
                    export class TaskActivityUpsert {
                        taskActivityID: number;
                        activityID: number;
                        statusID: number;
                        taskType: number;
                        name: string;
                        duration?: number;
                        activityDone: boolean;
                        activityInputs: Array<Honeycomb.Tenant.Tasker.IService.Model.ActivityInputUpsert>;
                    }
                    export class TaskAttachment {
                        taskAttachmentID: number;
                        taskID: number;
                        documentUid?: string;
                        name: string;
                        description: string;
                        created: Date;
                        updateTime: Date;
                        task: Honeycomb.Tenant.Tasker.IService.Model.Task;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        updatedByContactUserID?: number;
                    }
                    export class TaskAttribute {
                        taskAttributeID: number;
                        taskID: number;
                        taskAttributeTypeID: number;
                        value: string;
                        taskAttributeType: Honeycomb.Tenant.Tasker.IService.Model.TaskAttributeType;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        updatedByContactUserID?: number;
                    }
                    export class TaskAttributeType {
                        taskAttributeTypeID: number;
                        meaning: Honeycomb.Common.Enums.AttributeMeaning.TaskAttributeMeaning;
                        name: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                        codeListId?: number;
                    }
                    export class TaskAttributeUpsert {
                        taskAttributeTypeName: string;
                        value: string;
                    }
                    export class TaskBatchRequest {
                        task: Honeycomb.Tenant.Tasker.IService.Model.TaskUpsert;
                        selectedJobID: number;
                    }
                    export class TaskFileInfo {
                        name: string;
                        relativePath: string;
                        path: Array<string>;
                        parentPath: string;
                        isFile: boolean;
                        isExpanded: boolean;
                        created: Date;
                        children: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskFileInfo>;
                        hasChildren: boolean;
                        fileAuthToken: string;
                    }
                    export class TaskInfo {
                        taskID: number;
                        duration?: number;
                        taskStatusID: number;
                        taskStatusCode: string;
                        taskStatusBase: Honeycomb.Common.Enums.TaskState;
                        activityCount: number;
                        ratio?: number;
                        locationName: string;
                    }
                    export class TaskLocationFilter {
                        userTaskRelations: Array<Honeycomb.Common.Enums.TaskRelation>;
                        selectedJobID: number;
                    }
                    export class TaskMessage {
                        taskMessageID: number;
                        taskID?: number;
                        locationID?: number;
                        userID: number;
                        content: string;
                        imageUID?: string;
                        documentUID?: string;
                        contactName: string;
                        created: Date;
                        updateTime: Date;
                        read?: Date;
                        task: Honeycomb.Tenant.Tasker.IService.Model.Task;
                    }
                    export class TaskPage {
                        userID: number;
                        jobID?: number;
                        pageIndex?: number;
                        pageSize?: number;
                        filter: Honeycomb.Tenant.Tasker.IService.Model.TaskPageFilter;
                        sort: Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort;
                    }
                    export class TaskPageFilter {
                        name: string;
                        description: string;
                        taskStatusIDs: Array<number>;
                        taskRead?: boolean;
                        operationIDs: Array<number>;
                        locationIDs: Array<number>;
                        priorities: Array<number>;
                        dateFrom?: Date;
                        dateTo?: Date;
                        assignedUsers: Array<number>;
                        assignerUsers: Array<number>;
                        creatorUsers: Array<number>;
                        fulltext: string;
                        mainFilter: Honeycomb.Common.Enums.TaskMainFilter;
                    }
                    export class TaskPageSort {
                        column: string;
                        direction: string;
                        isAttribute: boolean;
                    }
                    export class TaskPhoto {
                        taskPhotoID: number;
                        taskID: number;
                        taskerFileUid: string;
                        recordUid?: string;
                        task: Honeycomb.Tenant.Tasker.IService.Model.Task;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        updatedByContactUserID?: number;
                    }
                    export class TaskPlan {
                        taskPlanID: number;
                        operationID: number;
                        locationID: number;
                        assigneeID: number;
                        cancelled: boolean;
                        stored: boolean;
                        taskID?: number;
                        startDate: Date;
                        deadline: Date;
                        updatedBy?: number;
                        created: Date;
                        updateTime: Date;
                        task: Honeycomb.Tenant.Tasker.IService.Model.Task;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                        taskPlanUsers: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlanUser>;
                    }
                    export class TaskPlanResult {
                        taskPlan: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlan>;
                        sourceTable: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPlanSourceTable>;
                    }
                    export class TaskPlanSourceTable {
                        locationID: number;
                        locationName: string;
                        lastStockTaking: Date;
                        lastStockTakingDiff: number;
                        lastManagerCheck: Date;
                        locLat: number;
                        locLong: number;
                        contLat: number;
                        contLong: number;
                        distance: number;
                        userID: number;
                        userName: string;
                        checkDate: Date;
                        shopEventSince?: Date;
                        shopEventTill?: Date;
                        shopEventName: string;
                        peoplePerStockChecking: number;
                        daysForStockChecking: number;
                        risk: string;
                        color: string;
                        coef: number;
                        stockTakingAssigned: boolean;
                        stockTakingSkipped: boolean;
                        note: string;
                    }
                    export class TaskPlanUser {
                        taskPlanUserID: number;
                        taskPlanID: number;
                        userID: number;
                        taskRelation: Honeycomb.Common.Enums.TaskRelation;
                        taskPlan: Honeycomb.Tenant.Tasker.IService.Model.TaskPlan;
                        updatedBy?: number;
                    }
                    export class TaskPrescription {
                        taskStart: Date;
                        taskEnd: Date;
                    }
                    export class TaskRead {
                        taskID: number;
                        userID: number;
                        created: Date;
                        task: Honeycomb.Tenant.Tasker.IService.Model.Task;
                    }
                    export class TaskResult {
                        taskResultID: number;
                        taskID: number;
                        taskResultTypeID: number;
                        value: any;
                        created: Date;
                        updateTime: Date;
                        resultType: Honeycomb.Tenant.Tasker.IService.Model.TaskResultType;
                    }
                    export class TaskResultType {
                        taskResultTypeID: number;
                        displayOrder: number;
                        name: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                        created: Date;
                        updateTime: Date;
                    }
                    export class TaskSequence {
                        taskSequenceID: number;
                        periodType: Honeycomb.Common.Enums.PeriodType;
                        periodRepeat: number;
                        weekDays: Honeycomb.Common.Enums.DayOfWeek;
                        yearRepeatDay: Date;
                        monthRepeatDay: number;
                        sequenceStart: Date;
                        sequenceEnd?: Date;
                        numberOfRepeats?: number;
                        taskTimeStart: any;
                        taskTimeEnd: any;
                    }
                    export class TaskSequenceRequest {
                        task: Honeycomb.Tenant.Tasker.IService.Model.TaskUpsert;
                        sequence: Honeycomb.Tenant.Tasker.IService.Model.TaskSequence;
                    }
                    export class TaskStatus {
                        taskStatusID: number;
                        taskStatusBase: Honeycomb.Common.Enums.TaskState;
                        code: string;
                        name: string;
                        nameLoc: string;
                        defaultFilter: boolean;
                        enabled: boolean;
                        operationStatuses: Array<Honeycomb.Tenant.Tasker.IService.Model.OperationStatus>;
                        taskStatusRoles: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskStatusRole>;
                    }
                    export class TaskStatusResult {
                        taskID: number;
                        taskStatusID: number;
                        taskStatusCode: string;
                        taskStatusBase: Honeycomb.Common.Enums.TaskState;
                        operationSnapshotID?: number;
                    }
                    export class TaskStatusRole {
                        taskStatusRoleID: number;
                        taskStatusID: number;
                        roleID: number;
                        taskStatus: Honeycomb.Tenant.Tasker.IService.Model.TaskStatus;
                        updateTime: Date;
                        updatedBy?: number;
                    }
                    export class TaskStatusUpdateRequest {
                        taskIDs: Array<number>;
                        taskStatusID: number;
                    }
                    export class TaskUpsert {
                        contactInput: any;
                        assignee: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskUser>;
                        observer: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskUser>;
                        responsible: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskUser>;
                        taskActivitiesUpsert: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivityUpsert>;
                        taskAttributesUpsert: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskAttributeUpsert>;
                        taskID: number;
                        batchID?: number;
                        operationID: number;
                        operationSnapshotID?: number;
                        parentTaskID?: number;
                        parentTaskValueID?: number;
                        parentSnapshotInputID?: number;
                        locationID?: number;
                        jobID: number;
                        name: string;
                        description: string;
                        address: string;
                        locationName: string;
                        startDate: Date;
                        deadline?: Date;
                        priority: Honeycomb.Common.Enums.Priority;
                        resultValue: string;
                        created: Date;
                        updateTime: Date;
                        taskStatusID: number;
                        taskStatusBase: Honeycomb.Common.Enums.TaskState;
                        taskStatusCode: string;
                        taskStatusUpdate: Date;
                        isDeleted: boolean;
                        messagesTotal: number;
                        messagesRead: number;
                        childTasksCount: number;
                        childTaskIDs: Array<number>;
                        dateRead?: Date;
                        taskSequenceID?: number;
                        taskInfo: Honeycomb.Tenant.Tasker.IService.Model.TaskInfo;
                        batch: Honeycomb.Tenant.Tasker.IService.Model.Batch;
                        operation: Honeycomb.Tenant.Tasker.IService.Model.Operation;
                        parent: Honeycomb.Tenant.Tasker.IService.Model.Task;
                        taskSequence: Honeycomb.Tenant.Tasker.IService.Model.TaskSequence;
                        children: Array<Honeycomb.Tenant.Tasker.IService.Model.Task>;
                        taskActivities: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivity>;
                        taskActivitySnapshots: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskActivity>;
                        taskUsers: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskUser>;
                        taskAttachments: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskAttachment>;
                        taskResults: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskResult>;
                        followUpTaskTemplates: Array<Honeycomb.Tenant.Tasker.IService.Model.FollowUpTaskTemplate>;
                        taskPhotos: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskPhoto>;
                        taskReads: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskRead>;
                        taskAttributes: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskAttribute>;
                        updatedBy?: number;
                        validFromUTC?: Date;
                        validToUTC?: Date;
                        updatedByContactUserID?: number;
                    }
                    export class TaskUser {
                        taskUserID: number;
                        taskID: number;
                        userID: number;
                        taskRelation: Honeycomb.Common.Enums.TaskRelation;
                        name: string;
                        updatedBy?: number;
                        validFromUTC?: Date;
                        validToUTC?: Date;
                        updatedByContactUserID?: number;
                        task: Honeycomb.Tenant.Tasker.IService.Model.Task;
                    }
                    export class TaskValue {
                        taskValueID: number;
                        taskActivityID: number;
                        meaningID: number;
                        userID: number;
                        value: any;
                        note: string;
                        taskValuePhotos: Array<Honeycomb.Tenant.Tasker.IService.Model.TaskValuePhoto>;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        updatedByContactUserID?: number;
                        taskActivity: Honeycomb.Tenant.Tasker.IService.Model.TaskActivity;
                        meaning: Honeycomb.Tenant.Tasker.IService.Model.Meaning;
                    }
                    export class TaskValueNoteSnapshotUpsert {
                        inputID: number;
                        note: string;
                        updatedBy?: number;
                        updatedByContactUserID?: number;
                    }
                    export class TaskValueNoteUpsert {
                        meaningID: number;
                        taskActivityID: number;
                        note: string;
                        updatedBy?: number;
                        updatedByContactUserID?: number;
                    }
                    export class TaskValuePhoto {
                        taskValuePhotoID: number;
                        taskValueID: number;
                        taskerFileUid: string;
                        created: Date;
                        updateTime: Date;
                        updatedBy?: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        updatedByContactUserID?: number;
                        taskValue: Honeycomb.Tenant.Tasker.IService.Model.TaskValue;
                    }
                    export class TaskValuePhotoInsert {
                        meaningID: number;
                        taskActivityID: number;
                        recordUid: string;
                        updatedBy?: number;
                        updatedByContactUserID?: number;
                    }
                    export class TaskValuePhotoSnapshotInsert {
                        inputID: number;
                        recordUid: string;
                        updatedBy?: number;
                        updatedByContactUserID?: number;
                    }
                    export class TaskValuePhotoUpsert {
                        taskValuePhotoID?: number;
                        taskerFileUid?: string;
                        recordUid?: string;
                    }
                    export class TaskValueSnapshotUpsert {
                        inputID: number;
                        value: any;
                        updatedBy?: number;
                        updatedByContactUserID?: number;
                    }
                    export class TaskValueUpsert {
                        meaningID: number;
                        taskActivityID: number;
                        value: any;
                        updatedBy?: number;
                        updatedByContactUserID?: number;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {
                export namespace Model {
                    export namespace Notification {

                        export class BellSubscriptionModel {
                            connectionID: string;
                            eventName: string;
                        }
                        export class Notification {
                            notificationID: number;
                            name: string;
                            description: string;
                            weekDays: Honeycomb.Common.Enums.DayOfWeek;
                            periodType: Honeycomb.Common.Enums.PeriodType;
                            yearRepeatDay?: Date;
                            monthRepeatDay?: number;
                            notifyTime: any;
                            processingSpName: string;
                            customSettingsJson: string;
                            emailAvailable: boolean;
                            emailDefault: boolean;
                            emailData: string;
                            pushAvailable: boolean;
                            pushDefault: boolean;
                            pushData: string;
                            bellAvailable: boolean;
                            bellDefault: boolean;
                            bellData: string;
                        }
                        export class NotificationQueueLength {
                            bellQueueLength: number;
                            pushQueueLength: number;
                        }
                        export class NotificationUserSetting {
                            notificationID: number;
                            name: string;
                            description: string;
                            emailAvailable: boolean;
                            email: boolean;
                            pushAvailable: boolean;
                            push: boolean;
                            bellAvailable: boolean;
                            bell: boolean;
                        }
                        export class PushPubKey {
                            pubKey: string;
                        }
                        export class PushSubscriptionModel {
                            endpoint: string;
                            expirationTime: number;
                            keys: Honeycomb.Tenant.Tasker.IService.Model.Notification.PushSubscriptionModelKeys;
                        }
                        export class PushSubscriptionModelKeys {
                            auth: string;
                            p256dh: string;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {

                export class SortParam {
                    column: string;
                    direction: Honeycomb.Common.SortDirection;
                }
                export class TaskFilter {
                    taskRelations: Array<Honeycomb.Tenant.Tasker.IService.TaskRelationFilter>;
                    jobID?: number;
                    locations: Array<number>;
                    locationID?: number;
                    taskSequenceID?: number;
                    baseTaskStatuses: Array<Honeycomb.Common.Enums.TaskState>;
                    taskRead?: Honeycomb.Common.Enums.TaskRead;
                    taskStatusIDs: Array<number>;
                    taskTypes: Array<Honeycomb.Common.Enums.TaskType>;
                    dateFrom?: Date;
                    dateTo?: Date;
                    priority?: Honeycomb.Common.Enums.Priority;
                    priorities: Array<Honeycomb.Common.Enums.Priority>;
                    limit?: number;
                    offset?: number;
                    pageIndex?: number;
                    pageSize?: number;
                    fulltext: string;
                    operations: Array<number>;
                    sortParams: Array<Honeycomb.Tenant.Tasker.IService.SortParam>;
                }
                export class TaskListFilter {
                    taskRelationAdditional: Array<Honeycomb.Tenant.Tasker.IService.TaskRelationFilter>;
                    userTaskRelations: Array<Honeycomb.Common.Enums.TaskRelation>;
                    includeAttributes: Array<string>;
                    mainFilter: Honeycomb.Common.Enums.TaskMainFilter;
                    jobID?: number;
                    locations: Array<number>;
                    locationID?: number;
                    taskSequenceID?: number;
                    baseTaskStatuses: Array<Honeycomb.Common.Enums.TaskState>;
                    taskRead?: Honeycomb.Common.Enums.TaskRead;
                    taskStatusIDs: Array<number>;
                    taskTypes: Array<Honeycomb.Common.Enums.TaskType>;
                    dateFrom?: Date;
                    dateTo?: Date;
                    priority?: Honeycomb.Common.Enums.Priority;
                    priorities: Array<Honeycomb.Common.Enums.Priority>;
                    limit?: number;
                    offset?: number;
                    pageIndex?: number;
                    pageSize?: number;
                    fulltext: string;
                    operations: Array<number>;
                    sortParams: Array<Honeycomb.Tenant.Tasker.IService.SortParam>;
                }
                export class TaskPlanConfirmation {
                    updatedBy?: number;
                    created: Date;
                    updateTime: Date;
                    taskPlanIDs: Array<number>;
                }
                export class TaskRelationFilter {
                    userID: number;
                    taskRelation: Honeycomb.Common.Enums.TaskRelation;
                }
                export class UserJobChangeRequest {
                    userID: number;
                    removedJobIDs: Array<number>;
                    addedJobIDs: Array<number>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace TemplateEngine {
            export namespace IService {
                export namespace Model {

                    export class ContextData {
                        entityID: string;
                        entityData: string;
                        distributeItemSubject: string;
                        userNumber: string;
                        userID: number;
                        userUID?: string;
                        voucherID: number;
                        tagID?: number;
                        mediaWebUrlParams: { [key: string]: string };
                    }
                    export class ContextDataAnonymous {
                        encryptedEntityID: string;
                        userUID?: string;
                    }
                    export class Data {
                        shortid: string;
                    }
                    export class DataSourceInput {
                        inputDataType: Honeycomb.Common.Enums.TemplateInputDataType;
                        description: string;
                        inputEditorType: Honeycomb.Tenant.TemplateEngine.IService.Model.InputEditorTypeType;
                    }
                    export class DistributeModel {
                        mediaName: string;
                        userId?: number;
                        userNumber: string;
                        userUID?: string;
                        preferredChannel: Honeycomb.Common.Enums.DistributionChannelType;
                        recipient: string;
                        entityDataID: string;
                        entityData: string;
                        distributeItemSubject: string;
                        tagID?: number;
                        voucherID?: number;
                        languageISOCode: string;
                        templateParameters: { [key: string]: string };
                        mediaWebUrlParams: { [key: string]: string };
                    }
                    export class DistributeTest {
                        distributionChannel: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelEmail;
                        distributeModel: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributeModel;
                    }
                    export class DistributionBatch {
                        distributionBatchID: number;
                        distributionChannelID: number;
                        dontSendEmail: boolean;
                        saveEmailToFile: boolean;
                        state: Honeycomb.Common.Enums.DistributionBatchState;
                        distributeAt?: Date;
                        created: Date;
                        distributionChannel: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannel;
                        distributionBatchItems: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItem>;
                    }
                    export class DistributionBatchCreateRequest {
                        mediaID: number;
                        distributeAt?: Date;
                        dontSendEmail: boolean;
                        saveEmailToFile: boolean;
                        sendToEmails: boolean;
                        recipient: string;
                    }
                    export class DistributionBatchItem {
                        distributionBatchItemID: number;
                        distributionBatchID: number;
                        userID?: number;
                        contact: Honeycomb.Tenant.Contact.IService.Contact;
                        distributionBatchItemDetails: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItemDetail>;
                        distributionBatchItemAttributes: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionBatchItemAttribute>;
                        contactValue: string;
                        subject: string;
                        sender: string;
                        entityDataID: string;
                        entityData: string;
                        languageISOCode: string;
                        result: Honeycomb.Common.Enums.DistributeResult;
                        created: Date;
                        shown?: Date;
                        smtpResultCode?: number;
                        smtpResultMessage: string;
                        emailContentUID?: string;
                        emailContent: string;
                        encryptedDistributionItemID: string;
                    }
                    export class DistributionBatchItemAttribute {
                        distributionBatchItemAttributeID: number;
                        distributionBacthItemID: number;
                        distributionBatchItemAttributeTypeID: number;
                        value: string;
                    }
                    export class DistributionBatchItemDetail {
                        distributionBatchItemDetailID: number;
                        distributionBatchItemID: number;
                        osFamily: string;
                        osMajor: string;
                        osMinor: string;
                        osPatch: string;
                        osPatchMinor: string;
                        deviceIsSpider: boolean;
                        deviceBrand: string;
                        deviceFamily: string;
                        deviceModel: string;
                        uaFamily: string;
                        uaMajor: string;
                        uaMinor: string;
                        uaPatch: string;
                        ipAddress: string;
                        uaHash: number;
                        created: Date;
                    }
                    export class DistributionHistory {
                        distributionBatchID: number;
                        created: Date;
                        distributeAt?: Date;
                        distributionChannelID: number;
                        note: string;
                        state: Honeycomb.Common.Enums.DistributionBatchState;
                        batchType: Honeycomb.Common.Enums.DistributeBatchType;
                        channelType: Honeycomb.Common.Enums.DistributionChannelType;
                        itemsStatusNotDistributedCount: number;
                        itemsStatusOkCount: number;
                        itemsStatusIncorrectNumberCount: number;
                        itemsStatusEmptySMSCount: number;
                        itemsStatusTooLongSMSCount: number;
                        itemsStatusIncorrectEmailCount: number;
                        itemsStatusOtherErrorCount: number;
                        shown: number;
                    }
                    export class DistributionChannel {
                        distributionChannelResults: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelResult>;
                        distributionChannelAttachments: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelAttachment>;
                        distributionChannelID: number;
                        mediaID: number;
                        type: Honeycomb.Common.Enums.DistributionChannelType;
                        active: boolean;
                        validFromUTC: Date;
                        validToUTC: Date;
                    }
                    export class DistributionChannelAttachment {
                        distributionChannelAttachmentId: number;
                        distributionChannelId: number;
                        mediaID?: number;
                        recordUid?: string;
                        attachmentType: Honeycomb.Common.Enums.AttachmentType;
                        name: string;
                        created: Date;
                        updated: Date;
                    }
                    export class DistributionChannelEmail {
                        addAsAttachment: boolean;
                        saveToStorage: boolean;
                        subject: string;
                        header: string;
                        footer: string;
                        senderEmail: string;
                        senderName: string;
                        distributionChannelResults: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelResult>;
                        distributionChannelAttachments: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelAttachment>;
                        distributionChannelID: number;
                        mediaID: number;
                        type: Honeycomb.Common.Enums.DistributionChannelType;
                        active: boolean;
                        validFromUTC: Date;
                        validToUTC: Date;
                    }
                    export class DistributionChannelResult {
                        distributionChannelResultId: number;
                        distributionChannelId: number;
                        userNumber: string;
                        contactValue: string;
                        result: Honeycomb.Common.Enums.DistributeResult;
                        created: Date;
                        shown?: Date;
                    }
                    export class DistributionChannelSms {
                        smsText: string;
                        distributionChannelResults: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelResult>;
                        distributionChannelAttachments: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelAttachment>;
                        distributionChannelID: number;
                        mediaID: number;
                        type: Honeycomb.Common.Enums.DistributionChannelType;
                        active: boolean;
                        validFromUTC: Date;
                        validToUTC: Date;
                    }
                    export class DistributionItemsRequest {
                        delivered?: boolean;
                        read?: boolean;
                        sortColumn: string;
                        sortDirection: Honeycomb.Common.SortDirection;
                        pageSize?: number;
                        page: number;
                        searchString: string;
                    }
                    export class HtmlTableConfigRequest {
                        tableName: string;
                        reportName: string;
                        filters: { [key: string]: string };
                    }
                    export class HtmlView {
                        htmlViewID: number;
                        name: string;
                        description: string;
                        group: string;
                        html: string;
                        css: string;
                        jsBackend: string;
                        jsFrontend: string;
                        parametersJson: string;
                        stylesJson: string;
                        contentType: Honeycomb.Common.Enums.HTMLViewContentType;
                        created: Date;
                        dataSources: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlViewDataSource>;
                    }
                    export class HtmlViewDataSource {
                        htmlViewDataSourceID: number;
                        name: string;
                        reportName: string;
                        filters: string;
                    }
                    export class HtmlViewRenderRequest {
                        viewName: string;
                        userUID?: string;
                        filters: { [key: string]: string };
                        reportFilters: { [key: string]: any };
                    }
                    export class JSReport {
                        name: string;
                        recipe: string;
                        engine: string;
                        data: Honeycomb.Tenant.TemplateEngine.IService.Model.Data;
                        shortid: string;
                        modificationDate: Date;
                        _id: string;
                        content: string;
                        helpers: string;
                    }
                    export class Media {
                        mediaID: number;
                        clone: boolean;
                        name: string;
                        companyID?: number;
                        group: string;
                        description: string;
                        comment: string;
                        tenantTemplateDescription: string;
                        tenantTemplateCaption: string;
                        jsTemplateName: string;
                        mediaType: Honeycomb.Common.Enums.MediaType;
                        mediaOrigin: Honeycomb.Common.Enums.MediaOrigin;
                        mediaPurpose: Honeycomb.Common.Enums.MediaPurpose;
                        requiredOptionTypeID?: number;
                        tenantTemplateID?: number;
                        recordUID?: string;
                        systemReserved: boolean;
                        generateNewCode: boolean;
                        dataInputType: Honeycomb.Common.Enums.TemplateInputDataType;
                        entityDataType: Honeycomb.Common.Enums.EntityDataType;
                        tenantTemplateExists: boolean;
                        useTemplateData: boolean;
                        updateWallets: boolean;
                        dataInputData: string;
                        recipient: string;
                        validFromUTC: Date;
                        distributionChannelEmail: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelEmail;
                        mediaClubs: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.MediaClub>;
                        mediaParameters: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.MediaParameter>;
                        mediaStyles: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.MediaStyle>;
                        walletInfos: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletInfo>;
                        mediaHtmlViews: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.MediaHtmlView>;
                        distributionChannelSms: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelSms;
                        smsPlaceholders: Array<string>;
                        utmSource: string;
                        utmMedium: string;
                        utmCampaign: string;
                        utmContent: string;
                        useWallet: boolean;
                        walletAppleTemplateID?: number;
                        walletGoogleTemplateID?: number;
                        walletBackgroundColor: string;
                        walletTextColor: string;
                        walletLabelColor: string;
                        walletLogoUID?: string;
                        walletImageUID?: string;
                        walletGoogleLogoUID?: string;
                        walletGoogleImageUID?: string;
                        walletLogoText: string;
                        walletTitle: string;
                        walletPrimaryDescription: string;
                        walletSecondaryDescription: string;
                        walletAddCompanyInfo: boolean;
                        walletBarcodeType: Honeycomb.Common.Enums.WalletBarcodeType;
                        walletImageType: Honeycomb.Common.Enums.WalletImageType;
                        walletAppleTemplate: Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate;
                        walletGoogleTemplate: Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplate;
                        voucherTemplateID?: number;
                        voucherDiscountType: Honeycomb.Common.Enums.VoucherValueType;
                        discountApplicationType: Honeycomb.Common.Enums.DiscountApplicationType;
                        discountMaskLong: string;
                        valueCurrencyISOCode: string;
                        numberMaskId?: number;
                        discountMaskShort: string;
                        shortNumberMaskId?: number;
                        discountValidityRelative?: number;
                        discountValue: number;
                        numberOfRepetitions?: number;
                        useNumberStack: boolean;
                        walletChangeMessage: string;
                        discountRelativeValueType: Honeycomb.Common.Enums.DiscountRelativeValueType;
                        discountValidSince?: Date;
                        discountValidityFixed?: Date;
                        discountEndNoteDays?: number;
                        discountEndNote: string;
                        voucherTemplateLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateLimitation>;
                        voucherTemplateAttributes: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateAttribute>;
                        voucherTemplateLocations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateLocation>;
                        voucherTemplateProductLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateProductLimitation>;
                        voucherTemplateCodeListLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateCodeListLimitation>;
                        voucherFixedCode: string;
                        voucherLoyaltyPointsExchange: number;
                        voucherMinTransactionPrice: number;
                        voucherMinTransactionCurrencyISOCode: string;
                        mediaWebUrl: string;
                        distributionItemsCount: number;
                        maxUsedByUser: number;
                    }
                    export class MediaClub {
                        mediaID: number;
                        clubID: number;
                        updatedBy?: number;
                    }
                    export class MediaDistributionResult {
                        media: Honeycomb.Tenant.TemplateEngine.IService.Model.Media;
                        result: Honeycomb.Tenant.TemplateEngine.IService.Model.DistributionChannelResult;
                    }
                    export class MediaHtmlView {
                        mediaHtmlViewID: number;
                        mediaID: number;
                        htmlViewID: number;
                        displayOrder: number;
                        htmlView: Honeycomb.Tenant.TemplateEngine.IService.Model.HtmlView;
                        parameters: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.MediaHtmlViewParameter>;
                    }
                    export class MediaHtmlViewParameter {
                        mediaHtmlViewsParameterID: number;
                        mediaHtmlViewID: number;
                        name: string;
                        value: string;
                        type: Honeycomb.Common.Enums.HtmlViewParameterType;
                    }
                    export class MediaParameter {
                        mediaID: number;
                        name: string;
                        description: string;
                        value: string;
                        inDB: boolean;
                        inTemplate: boolean;
                        valueType: Honeycomb.Common.Enums.DataType;
                        sortOrder: number;
                        updatedBy?: number;
                    }
                    export class MediaRenderRequest {
                        media: Honeycomb.Tenant.TemplateEngine.IService.Model.Media;
                        renderRequest: Honeycomb.Tenant.TemplateEngine.IService.Model.TemplateRenderRequest;
                    }
                    export class MediaStyle {
                        mediaID: number;
                        name: string;
                        description: string;
                        value: string;
                        updatedBy?: number;
                        inDB: boolean;
                        inTemplate: boolean;
                        sortOrder: number;
                    }
                    export class PDFRenderRequest {
                        content: string;
                        returnURL: boolean;
                        saveToStorage: boolean;
                        resultFileName: string;
                    }
                    export class PDFRenderResponse {
                        pdfData: Array<number>;
                        pdfDataURL: string;
                        streamID: string;
                    }
                    export class PDFValidationResult {
                        isValid: boolean;
                        invalidFields: Array<string>;
                    }
                    export class ProcessDistributionBatch {
                        batchID: number;
                        batchItemIDs: Array<number>;
                    }
                    export class QueryOptions {
                        dateSince: Date;
                        dateTill: Date;
                        transactionType: Honeycomb.Common.Enums.TransactionType;
                        sorting: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.SortOptions>;
                    }
                    export class ResultOptions {
                        renderResult: Honeycomb.Common.Enums.RenderResult;
                        returnToken: boolean;
                        returnFileName: string;
                        returnRich: boolean;
                        mediaCarrier: Honeycomb.Common.Enums.MediaCarrier;
                        recipientEmail: string;
                    }
                    export class ScriptTagResponse {
                        scriptTag: string;
                    }
                    export class SortOptions {
                        columnName: string;
                        sortDirection: Honeycomb.Common.SortDirection;
                    }
                    export class TemplateRenderRequest {
                        contextData: Honeycomb.Tenant.TemplateEngine.IService.Model.ContextData;
                        mediaName: string;
                        languageISOCode: string;
                        mediaId: number;
                        queryOptions: Honeycomb.Tenant.TemplateEngine.IService.Model.QueryOptions;
                        resultOptions: Honeycomb.Tenant.TemplateEngine.IService.Model.ResultOptions;
                        templateParameters: { [key: string]: string };
                    }
                    export class TemplateRenderRequestAnonymous {
                        contextData: Honeycomb.Tenant.TemplateEngine.IService.Model.ContextDataAnonymous;
                        mediaName: string;
                        languageISOCode: string;
                        mediaId: number;
                        queryOptions: Honeycomb.Tenant.TemplateEngine.IService.Model.QueryOptions;
                        resultOptions: Honeycomb.Tenant.TemplateEngine.IService.Model.ResultOptions;
                        templateParameters: { [key: string]: string };
                    }
                    export class TemplateReport {
                        content: string;
                        recipe: string;
                        helpers: string;
                        shortid: string;
                        name: string;
                    }
                    export class TemplateType {
                        _id: string;
                        shortid: string;
                        name: string;
                        content: string;
                        recipe: string;
                        helpers: string;
                        data_shortid: string;
                    }
                    export class TenantTemplate {
                        tenantHash: string;
                        tenantTemplateTypeID: number;
                        entityDataType: Honeycomb.Common.Enums.EntityDataType;
                        entityDataTypeCode: string;
                        name: string;
                        caption: string;
                        description: string;
                        updateCss: boolean;
                        edition: Honeycomb.Common.Enums.Edition;
                        folder: string;
                        jsTemplateTypeID: string;
                        jsTemplateTypeShortID: string;
                        jsTemplateName: string;
                        validFromUTC: Date;
                        validToUTC: Date;
                        tenantTemplateParameters: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplateParameter>;
                        tenantTemplateStyles: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplateStyle>;
                        jsReportServerUrl: string;
                        jsReportServerPort?: number;
                        templateParameters: Array<any>;
                        templateStyles: Array<any>;
                        updatedBy?: number;
                    }
                    export class TenantTemplateParameter {
                        tenantTemplateParameterID: number;
                        tenantTemplateTypeID: number;
                        name: string;
                        description: string;
                        defaultValue: string;
                        valueType: Honeycomb.Common.Enums.DataType;
                        updatedBy?: number;
                        sortOrder: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        tenantTemplate: Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate;
                    }
                    export class TenantTemplateStyle {
                        tenantTemplateStyleID: number;
                        tenantTemplateTypeID: number;
                        name: string;
                        description: string;
                        defaultValue: string;
                        updatedBy?: number;
                        sortOrder: number;
                        validFromUTC: Date;
                        validToUTC: Date;
                        tenantTemplate: Honeycomb.Tenant.TemplateEngine.IService.Model.TenantTemplate;
                    }
                    export class UpdateWalletRequest {
                        mediaName: string;
                        userUID: string;
                        tenantId?: number;
                        voucherId?: number;
                        tagId?: number;
                    }
                    export class WalletInfo {
                        walletInfoID: number;
                        mediaID: number;
                        infoName: string;
                        infoText: string;
                        infoTextGoogle: string;
                        appleInfoTextPlacement: Honeycomb.Common.Enums.AppleInfoTextPlacement;
                        sortOrder: number;
                    }
                    export class WalletTemplate {
                        walletTemplateID: number;
                        name: string;
                        description: string;
                        walletStyle: number;
                        created: Date;
                        target: Honeycomb.Common.Enums.WalletTemplateTarget;
                        isDeleted: boolean;
                        fields: Array<Honeycomb.Tenant.TemplateEngine.IService.Model.WalletTemplateField>;
                    }
                    export class WalletTemplateField {
                        walletTemplateFieldID: number;
                        walletTemplateID: number;
                        fieldOrder: number;
                        dataType: Honeycomb.Common.Enums.DataType;
                        dataSource: Honeycomb.Common.Enums.WalletTemplateDataSource;
                        sourceField: string;
                        defaultValueDataSource: Honeycomb.Common.Enums.WalletTemplateDataSource;
                        defaultValueSourceField: string;
                        labelDataSource: Honeycomb.Common.Enums.WalletTemplateDataSource;
                        label: string;
                        defaultValueLabelDataSource: Honeycomb.Common.Enums.WalletTemplateDataSource;
                        defaultValueLabel: string;
                        appleField: Honeycomb.Common.Enums.WalletAppleField;
                        googleField: Honeycomb.Common.Enums.WalletGoogleField;
                        changeMessage: string;
                        created: Date;
                        googleClassVariantField: boolean;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace TemplateEngine {
            export namespace IService {
                export namespace ViewModel {

                    export class MediaListFilter {
                        group: string;
                        sortColumn: string;
                        sortDirection: Honeycomb.Common.SortDirection;
                        pageSize?: number;
                        page: number;
                        searchString: string;
                    }
                    export class RenameGroupModel {
                        oldName: string;
                        newName: string;
                    }
                    export class TemplateSelect {
                        sortColumn: string;
                        searchString: string;
                        tenantHash: string;
                        jsReportExists: boolean;
                        sortDirection: Honeycomb.Common.SortDirection;
                        pageSize?: number;
                        page: number;
                        attributes: { [key: string]: string };
                    }
                    export class TemplateTypeSelect {
                        jsName: string;
                        jsTemplateTypeID: string;
                        jsTemplateTypeShortID: string;
                        inDb: boolean;
                        dbName: string;
                        caption: string;
                        description: string;
                        templateID?: number;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {

                    export class BasketContainer {
                        creationTime: Date;
                        items: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketItem>;
                        vouchers: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketVoucher>;
                        possiblePresents: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketPresent>;
                        clientGivenDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.ClientGivenDiscount>;
                        promotionDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketDiscount>;
                        totalUsedRewardPoints: number;
                        discounts: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketDiscount>;
                        languageIsoCode: string;
                        decimalPlacesOrder: number;
                        _decimalPlacesItem: number;
                        decimalPlacesItem: number;
                        debugInfo: string;
                        totalItemPrice: number;
                        totalVoucherAppliedPrice: number;
                        totalDiscountWithVAT: number;
                        totalDiscountWithoutVAT: number;
                        totalWithoutServices: number;
                        withoutServicesInclVAT: number;
                        withoutServicesExclVAT: number;
                    }
                    export class BasketDiscount {
                        valueType: Honeycomb.Common.Enums.DiscountValueType;
                        description: string;
                        name: string;
                        voucherNumber: string;
                        discountUID?: string;
                        value: number;
                        number: number;
                        evaluatedPrice: number;
                        evaluatedPriceWithVAT: number;
                        evaluatedPriceWithoutVAT: number;
                        priority: number;
                        isValid: boolean;
                        message: string;
                        promotionCode: string;
                        processed: boolean;
                    }
                    export class BasketForVoucherResult {
                        items: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketForVoucherResultItem>;
                    }
                    export class BasketForVoucherResultItem {
                        transactionID: number;
                        voucherID: number;
                        voucherNumber: string;
                        voucherShortNumber: string;
                    }
                    export class BasketItem {
                        name: string;
                        productNumber: string;
                        productVariantId: number;
                        originalPrice?: number;
                        basePrice?: number;
                        purchasePrice?: number;
                        userEnteredPrice?: number;
                        userEnteredName: string;
                        gtin: string;
                        usedRewardsPoints: number;
                        productId: number;
                        producVariantUid?: string;
                        itemUID?: string;
                        parentItemUID?: string;
                        itemId?: number;
                        note: string;
                        giftPromoCode: string;
                        minOrder: number;
                        maxOrder?: number;
                        multiple: number;
                        itemImages: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketItemImage>;
                        headerDiscounts: Array<Honeycomb.LookupTables.IService.Model.Discount>;
                        itemVariables: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketItemVariable>;
                        itemAttachments: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketItemAttachment>;
                        itemAttributes: { [key: string]: any };
                        productSets: Array<number>;
                        finalPriceBeforeHeaderDiscount: number;
                        finalPriceAfterItemDiscount: number;
                        finalPriceAfterPromotion: number;
                        totalHeaderDiscount: number;
                        totalDiscount: number;
                        retailPrice: number;
                        quantity: number;
                        vatId?: number;
                        vatPercent: number;
                        priceWithVAT: boolean;
                        currencyDecimalPoints: number;
                        itemDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemDiscount>;
                        finalPriceBeforeDiscount: number;
                        totalItemDiscountDetail: Array<any>;
                        totalItemDiscount: number;
                        totalItemDiscountVAT: number;
                        totalItemDiscountWithVAT: number;
                        totalItemDiscountWithoutVAT: number;
                        totalDiscountVAT: number;
                        totalDiscountWithVAT: number;
                        totalDiscountWithoutVAT: number;
                        finalPrice: number;
                        vatValueBeforeDiscount: number;
                        vatValue: number;
                        finalPriceWithoutVATBeforeDiscount: number;
                        finalPriceWithVATBeforeDiscount: number;
                        finalPriceWithoutVAT: number;
                        finalPriceWithVAT: number;
                        itemPrice: number;
                    }
                    export class BasketItemAttachment {
                        productAttachmentUID: string;
                        attachmentName: string;
                        attachmentDescription: string;
                        displayOrder: number;
                    }
                    export class BasketItemImage {
                        imageRecordUID: string;
                        imageURL: string;
                        thumbnailRecordUID: string;
                        thumbnailURL: string;
                        purpose: string;
                        isPrimary: boolean;
                    }
                    export class BasketItemVariable {
                        itemVariableId: number;
                        itemId: number;
                        variableName: string;
                        variableIndex: number;
                        variableValue: string;
                    }
                    export class BasketPresent {
                        productVariantIDs: Array<number>;
                        productNumbers: Array<string>;
                        promotionCode: string;
                        promotionDescription: string;
                        presentCount: number;
                    }
                    export class BasketServiceContainer {
                        items: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketServiceItem>;
                        _decimalPlacesItem: number;
                        decimalPlacesItem: number;
                        totalServiceCost: number;
                        totalShippingCost: number;
                        totalPaymentCost: number;
                        forceFreeShipping: boolean;
                        forceFreePayment: boolean;
                        debugInfo: string;
                    }
                    export class BasketServiceItem {
                        type: Honeycomb.Common.Enums.ServiceItemType;
                        referredItemId: number;
                        serviceCode: string;
                        description: string;
                        serviceItemUID?: string;
                        itemState: Honeycomb.Common.Enums.ServiceItemState;
                        message: string;
                        retailPrice: number;
                        quantity: number;
                        vatId?: number;
                        vatPercent: number;
                        priceWithVAT: boolean;
                        currencyDecimalPoints: number;
                        itemDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemDiscount>;
                        finalPriceBeforeDiscount: number;
                        totalItemDiscountDetail: Array<any>;
                        totalItemDiscount: number;
                        totalItemDiscountVAT: number;
                        totalItemDiscountWithVAT: number;
                        totalItemDiscountWithoutVAT: number;
                        totalDiscount: number;
                        totalDiscountVAT: number;
                        totalDiscountWithVAT: number;
                        totalDiscountWithoutVAT: number;
                        finalPrice: number;
                        vatValueBeforeDiscount: number;
                        vatValue: number;
                        finalPriceWithoutVATBeforeDiscount: number;
                        finalPriceWithVATBeforeDiscount: number;
                        finalPriceWithoutVAT: number;
                        finalPriceWithVAT: number;
                        itemPrice: number;
                    }
                    export class BasketVoucher {
                        voucherNumber: string;
                        quantity: number;
                        isValid: boolean;
                        isUsable: boolean;
                        processed: boolean;
                        name: string;
                        description: string;
                        valueType: Honeycomb.Common.Enums.VoucherValueType;
                        useType: Honeycomb.Common.Enums.DiscountApplicationType;
                        shortNumber: string;
                        number: string;
                        value: number;
                        validMonths: number;
                        message: string;
                        hasProductLimit: boolean;
                        usableForProducts: Array<string>;
                        usabilityStatus: Honeycomb.Common.Enums.VoucherUsabilityStatus;
                        allowPartialUse: boolean;
                        voucherID: number;
                        templateID: number;
                    }
                    export class ClientGivenDiscount {
                        clientGivenDiscountID: number;
                        value: number;
                        discountUID: string;
                        valueType: Honeycomb.Common.Enums.DiscountValueType;
                        number: number;
                        description: string;
                        processed: boolean;
                        updatedBy?: number;
                    }
                    export class DelayedAction {
                        actionTag: Honeycomb.Tenant.Transactions.IService.Model.PromotionTag;
                        actionType: Honeycomb.Common.Enums.PromotionActionType;
                        actionParameters: any;
                    }
                    export class DropDownItem {
                        name: string;
                        value: any;
                        locKey: string;
                    }
                    export class ExpandedBasketItem {
                        itemOrder: number;
                        itemTags: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionTag>;
                        actionTags: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionTag>;
                        itemDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemDiscount>;
                        basketItemRef: Honeycomb.Tenant.Transactions.IService.Model.BasketItem;
                        itemPrice: number;
                    }
                    export class ModelEntity {
                        entityType: Honeycomb.Common.Enums.PromotionEntityType;
                        parameters: any;
                        name: string;
                        typeNumber: number;
                        localizedName: string;
                        localizedDescription: string;
                        inputs: Array<Honeycomb.Tenant.Transactions.IService.Model.ModelEntityInput>;
                    }
                    export class ModelEntityInput {
                        caption: string;
                        help: string;
                        editorType: Honeycomb.Tenant.Transactions.IService.Model.EditorType;
                        inputValueType: Honeycomb.Tenant.Transactions.IService.Model.InputValueType;
                        propertyName: string;
                        defaultValue: string;
                        visibleCondition: string;
                        codeListName: string;
                        hidden: boolean;
                        dropDownSourceType: Honeycomb.Tenant.Transactions.IService.Model.DropDownSourceType;
                        dropDownItems: Array<Honeycomb.Tenant.Transactions.IService.Model.DropDownItem>;
                    }
                    export class OrderAndUserContainer {
                        order: Honeycomb.Tenant.Transactions.IService.Model.OrderContainer;
                        user: Honeycomb.Tenant.Contact.IService.User;
                        promotionCodes: Array<string>;
                        conditionTags: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionTag>;
                        actionTags: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionTag>;
                        delayedActions: Array<Honeycomb.Tenant.Transactions.IService.Model.DelayedAction>;
                        expandedBasketItems: Array<Honeycomb.Tenant.Transactions.IService.Model.ExpandedBasketItem>;
                        gifts: Array<Honeycomb.Tenant.Transactions.IService.Model.ExpandedBasketItem>;
                    }
                    export class OrderContainer {
                        sessionID: number;
                        paymentID: number;
                        shippingID: number;
                        priceWithVAT?: boolean;
                        storedTransactionID?: number;
                        userID?: number;
                        transactionStatus?: number;
                        note: string;
                        deliveryNote: string;
                        currencyIsoCode: string;
                        dontPoolFromServer: boolean;
                        locationID?: number;
                        decimalPlacesOrder: number;
                        decimalPlacesItem: number;
                        attributes: { [key: string]: string };
                        userOptions: { [key: string]: number };
                        basket: Honeycomb.Tenant.Transactions.IService.Model.BasketContainer;
                        services: Honeycomb.Tenant.Transactions.IService.Model.BasketServiceContainer;
                        totalRetailPrice: number;
                        totalRetailPriceWithVAT: number;
                        totalRetailPriceWithoutVAT: number;
                        shippingAddress: Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact;
                        billingAddress: Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact;
                        contacts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact>;
                        user: Honeycomb.Tenant.Transactions.IService.Model.OrderContainerUser;
                        updatedBy?: number;
                        updatedByContactUserID?: number;
                        attachedDocuments: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument>;
                        availableServices: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketServiceItem>;
                    }
                    export class OrderContainerUser {
                        userID: number;
                        userNumber: string;
                    }
                    export class Promotion {
                        promotionID: number;
                        description: string;
                        code: string;
                        name: string;
                        group: string;
                        executionOrder: number;
                        isActive: boolean;
                        stopExecution: boolean;
                        nameDef: string;
                        descriptionDef: string;
                        promotionSchedules: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionSchedule>;
                        promotionItemRules: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionItemRule>;
                        promotionConditions: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionCondition>;
                        promotionActions: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionAction>;
                        promotionRestriction: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionRestriction>;
                        promotionRestrictionNext: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionRestriction>;
                        promotionLocations: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionLocation>;
                        conditionExpression: string;
                    }
                    export class PromotionAction {
                        promotionActionID: number;
                        promotionID: number;
                        executionOrder: number;
                        promotionActionType: Honeycomb.Common.Enums.PromotionActionType;
                        paramsJson: string;
                        paramsObj: any;
                        tagName: string;
                        promotion: Honeycomb.Tenant.Transactions.IService.Model.Promotion;
                    }
                    export class PromotionCalendar {
                        items: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendarItem>;
                        products: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendarProduct>;
                        promotions: Array<Honeycomb.Tenant.Transactions.IService.Model.Promotion>;
                    }
                    export class PromotionCalendarFilter {
                        elements: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendarFilterElement>;
                    }
                    export class PromotionCalendarFilterElement {
                        attributeTypeName: string;
                        value: string;
                        categoryCode: string;
                        productNumber: string;
                    }
                    export class PromotionCalendarItem {
                        promotionID: number;
                        promotionCode: string;
                        intervals: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionCalendarItemInterval>;
                        productIDs: Array<number>;
                    }
                    export class PromotionCalendarItemInterval {
                        start: Date;
                        end: Date;
                        days: Array<number>;
                    }
                    export class PromotionCalendarProduct {
                        productID: number;
                        productNumber: string;
                        productText: string;
                    }
                    export class PromotionCondition {
                        promotionConditionID: number;
                        parentPromotionConditionID?: number;
                        promotionID: number;
                        promotionConditionType: Honeycomb.Common.Enums.PromotionConditionType;
                        paramsObj: any;
                        paramsJson: string;
                        tagName: string;
                        executionOrder: number;
                        parentPromotionCondition: Honeycomb.Tenant.Transactions.IService.Model.PromotionCondition;
                        childPromotionConditions: Array<Honeycomb.Tenant.Transactions.IService.Model.PromotionCondition>;
                        promotion: Honeycomb.Tenant.Transactions.IService.Model.Promotion;
                    }
                    export class PromotionEditorModel {
                        entities: Array<Honeycomb.Tenant.Transactions.IService.Model.ModelEntity>;
                    }
                    export class PromotionExportRequest {
                        promotionIDs: Array<number>;
                    }
                    export class PromotionExportResult {
                        token: string;
                        fileName: string;
                    }
                    export class PromotionFilter {
                        disableDateFilter: boolean;
                        includeInactive: boolean;
                        cacheKey: string;
                    }
                    export class PromotionItemRule {
                        promotionItemRuleID: number;
                        promotionID: number;
                        promotionItemRuleType: Honeycomb.Common.Enums.PromotionItemRuleType;
                        paramsObj: any;
                        paramsJson: string;
                        tagName: string;
                        executionOrder: number;
                        promotion: Honeycomb.Tenant.Transactions.IService.Model.Promotion;
                    }
                    export class PromotionLocation {
                        promotionID: number;
                        locationID: number;
                        promotion: Honeycomb.Tenant.Transactions.IService.Model.Promotion;
                    }
                    export class PromotionRestriction {
                        promotionID: number;
                        nextPromotionID: number;
                        promotion: Honeycomb.Tenant.Transactions.IService.Model.Promotion;
                        nextPromotion: Honeycomb.Tenant.Transactions.IService.Model.Promotion;
                    }
                    export class PromotionSchedule {
                        promotionScheduleID: number;
                        promotionID: number;
                        dateFrom: Date;
                        dateTo: Date;
                        dayOfWeek: string;
                        dayOfWeekSet: Array<number>;
                        hourFrom?: number;
                        hourTo?: number;
                        executionOrder: number;
                        promotion: Honeycomb.Tenant.Transactions.IService.Model.Promotion;
                    }
                    export class PromotionTag {
                        promoCode: string;
                        tagName: string;
                    }
                    export class PTBasket {
                        items: Array<Honeycomb.Tenant.Transactions.IService.Model.PTItem>;
                    }
                    export class PTContainer {
                        userNumber: string;
                        basket: Honeycomb.Tenant.Transactions.IService.Model.PTBasket;
                    }
                    export class PTItem {
                        productNumber: string;
                        quantity: number;
                    }
                    export class UserBasket {
                        userBasketId: number;
                        userId?: number;
                        sessionId?: number;
                        created: Date;
                        abandonedMessageSent?: Date;
                        items: Array<Honeycomb.Tenant.Transactions.IService.Model.UserBasketItem>;
                    }
                    export class UserBasketItem {
                        userBasketItemId: number;
                        userBasketId: number;
                        productVariantId: number;
                        quantity: number;
                        note: string;
                    }
                    export class VoucherExchangeRequest {
                        voucherTemplateID: number;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {
                    export namespace Editions {

                        export class HeaderMappedAttributes {
                            dontAgreeHeureka: boolean;
                            gaClientID: string;
                            gaTransactionPayload: string;
                            attributeTypes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {
                    export namespace Omnichannel {

                        export class ProductAvailability {
                            locationID: number;
                            productVariantID: number;
                            quantity: number;
                            requestedQuantity: number;
                            avialability: Honeycomb.Common.Enums.ProductAvailabilityType;
                            deliveryDate: Date;
                        }
                        export class ProductAvailabilityRequest {
                            locationID?: number;
                            formulaId: number;
                            productVariantIDs: Array<number>;
                            requestedQuantities: { [key: number]: number };
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {
                    export namespace Transaction {

                        export class AttachedDocument {
                            attachedDocumentId: number;
                            headerId: number;
                            attachedDocumentPurpose: Honeycomb.Common.Enums.AttachedDocumentPurpose;
                            note: string;
                            recordUID: string;
                            name: string;
                            created: Date;
                        }
                        export class Attribute {
                            attributeId: number;
                            attributeTypeId: number;
                            attrValue: string;
                            attributeType: Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttributeType;
                        }
                        export class AttributeType {
                            attributeTypeId: number;
                            meaning: Honeycomb.Common.Enums.AttributeMeaning.TransactionHeaderAttributeMeaning;
                            name: string;
                            description: string;
                            dataType: Honeycomb.Common.Enums.DataType;
                            codeListId?: number;
                        }
                        export class Discount {
                            discountId: number;
                            discountType: Honeycomb.Common.Enums.DiscountValueType;
                            discountOrigin: Honeycomb.Common.Enums.DiscountOrigin;
                            value: number;
                            evaluatedPrice: number;
                            description: string;
                            promotionCode: string;
                            promotionNote: string;
                            updatedBy?: number;
                        }
                        export class Header {
                            headerId: number;
                            encryptedHeaderId: string;
                            companyID?: number;
                            recVer: number;
                            type: Honeycomb.Common.Enums.TransactionType;
                            transactionTypeKey: string;
                            tranNo: number;
                            transactionDate: Date;
                            status: number;
                            transactionStatusKey: string;
                            finalPrice: number;
                            finalPriceWithVAT: number;
                            finalPriceWithoutVAT: number;
                            totalVAT: number;
                            totalVoucherDiscounts: number;
                            currencyIsoCode: string;
                            priceIncludesVAT: boolean;
                            totalCurrencySymbol: string;
                            locationID: number;
                            discountTotalItems: number;
                            vatValues: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            priceWithVATValues: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            priceWithoutVATValues: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            vatPercentage: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            batchNumber?: number;
                            note: string;
                            createdBy?: number;
                            createdByUserNumber: string;
                            userID?: number;
                            updatedBy?: number;
                            created: Date;
                            modified: Date;
                            headerUid: string;
                            attachedDocuments: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument>;
                            headerAttributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Attribute>;
                            contacts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact>;
                            discounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Discount>;
                            items: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item>;
                            itemsHistory: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemHistory>;
                            notes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Note>;
                            payments: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment>;
                            shipping: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping>;
                            statusLog: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.StatusLog>;
                            serviceItems: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ServiceItem>;
                            vouchers: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Voucher>;
                            localizedKeys: { [key: string]: string };
                            mappedAttributes: Honeycomb.Tenant.Transactions.IService.Model.Editions.HeaderMappedAttributes;
                            attributes: { [key: string]: string };
                            onlinePaymentURL: string;
                        }
                        export class HeaderUpdate {
                            origin: string;
                            status: number;
                            updatedBy?: number;
                            attachedDocuments: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument>;
                            headerAttributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Attribute>;
                            notes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Note>;
                            shipping: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping>;
                            statusLog: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.StatusLog>;
                        }
                        export class HeaderWithPromotions {
                            presents: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.PromotionPresent>;
                            headerId: number;
                            encryptedHeaderId: string;
                            companyID?: number;
                            recVer: number;
                            type: Honeycomb.Common.Enums.TransactionType;
                            transactionTypeKey: string;
                            tranNo: number;
                            transactionDate: Date;
                            status: number;
                            transactionStatusKey: string;
                            finalPrice: number;
                            finalPriceWithVAT: number;
                            finalPriceWithoutVAT: number;
                            totalVAT: number;
                            totalVoucherDiscounts: number;
                            currencyIsoCode: string;
                            priceIncludesVAT: boolean;
                            totalCurrencySymbol: string;
                            locationID: number;
                            discountTotalItems: number;
                            vatValues: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            priceWithVATValues: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            priceWithoutVATValues: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            vatPercentage: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.VatValuePair>;
                            batchNumber?: number;
                            note: string;
                            createdBy?: number;
                            createdByUserNumber: string;
                            userID?: number;
                            updatedBy?: number;
                            created: Date;
                            modified: Date;
                            headerUid: string;
                            attachedDocuments: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.AttachedDocument>;
                            headerAttributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Attribute>;
                            contacts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionContact>;
                            discounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Discount>;
                            items: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Item>;
                            itemsHistory: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemHistory>;
                            notes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Note>;
                            payments: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Payment>;
                            shipping: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Shipping>;
                            statusLog: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.StatusLog>;
                            serviceItems: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ServiceItem>;
                            vouchers: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Voucher>;
                            localizedKeys: { [key: string]: string };
                            mappedAttributes: Honeycomb.Tenant.Transactions.IService.Model.Editions.HeaderMappedAttributes;
                            attributes: { [key: string]: string };
                            onlinePaymentURL: string;
                        }
                        export class Item {
                            itemId: number;
                            itemNumber: number;
                            productName: string;
                            productNumber: string;
                            originalQuantity: number;
                            quantity: number;
                            retailPrice: number;
                            originalPrice: number;
                            basePrice?: number;
                            finalPriceBeforeDiscount: number;
                            finalPriceWithoutVATBeforeDiscount: number;
                            finalPriceWithVATBeforeDiscount: number;
                            finalPrice: number;
                            finalPriceWithoutVAT: number;
                            finalPriceWithVAT: number;
                            purchasePrice?: number;
                            userEnteredPrice?: number;
                            usedRewardsPoints: number;
                            priceWithVAT: boolean;
                            vatId: number;
                            vatValue: number;
                            totalDiscount: number;
                            vatPercent: number;
                            measurementUnitId: number;
                            measurementUnit: string;
                            measurementUnitDecimals: number;
                            measurementSymbol: string;
                            productGroupName: string;
                            manufacturerPartNumber: string;
                            note: string;
                            gtin: string;
                            variantText: string;
                            productId?: number;
                            productVariantId?: number;
                            updatedBy?: number;
                            firstImageUrl: string;
                            itemUID?: string;
                            parentItemUID?: string;
                            status: number;
                            noDiscount: boolean;
                            calcDiscount: number;
                            productAttributes: { [key: string]: string };
                            itemAttributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemAttribute>;
                            itemDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemDiscount>;
                            itemVariables: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemVariable>;
                            itemStatusLog: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemStatusLog>;
                            productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                            itemCategories: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemCategory>;
                        }
                        export class ItemAttribute {
                            itemAttributeId: number;
                            itemAttributeTypeId: number;
                            attrValue: string;
                            itemAttributeType: Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemAttributeType;
                        }
                        export class ItemAttributeType {
                            itemAttributeTypeId: number;
                            meaning: Honeycomb.Common.Enums.AttributeMeaning.TransactionItemAttributeMeaning;
                            name: string;
                            description: string;
                            dataType: Honeycomb.Common.Enums.DataType;
                            codeListId?: number;
                        }
                        export class ItemCategory {
                            itemCategoryId: number;
                            itemId: number;
                            categoryId: number;
                            shortName: string;
                        }
                        export class ItemDiscount {
                            itemDiscountId: number;
                            valueType: Honeycomb.Common.Enums.DiscountValueType;
                            discountOrigin: Honeycomb.Common.Enums.DiscountOrigin;
                            value: number;
                            discountNumber: number;
                            description: string;
                            promotionCode: string;
                            discountUID?: string;
                            promotionNote: string;
                            updatedBy?: number;
                        }
                        export class ItemHistory {
                            validFromUTC: Date;
                            validToUTC: Date;
                            itemId: number;
                            itemNumber: number;
                            productName: string;
                            productNumber: string;
                            originalQuantity: number;
                            quantity: number;
                            retailPrice: number;
                            originalPrice: number;
                            basePrice?: number;
                            finalPriceBeforeDiscount: number;
                            finalPriceWithoutVATBeforeDiscount: number;
                            finalPriceWithVATBeforeDiscount: number;
                            finalPrice: number;
                            finalPriceWithoutVAT: number;
                            finalPriceWithVAT: number;
                            purchasePrice?: number;
                            userEnteredPrice?: number;
                            usedRewardsPoints: number;
                            priceWithVAT: boolean;
                            vatId: number;
                            vatValue: number;
                            totalDiscount: number;
                            vatPercent: number;
                            measurementUnitId: number;
                            measurementUnit: string;
                            measurementUnitDecimals: number;
                            measurementSymbol: string;
                            productGroupName: string;
                            manufacturerPartNumber: string;
                            note: string;
                            gtin: string;
                            variantText: string;
                            productId?: number;
                            productVariantId?: number;
                            updatedBy?: number;
                            firstImageUrl: string;
                            itemUID?: string;
                            parentItemUID?: string;
                            status: number;
                            noDiscount: boolean;
                            calcDiscount: number;
                            productAttributes: { [key: string]: string };
                            itemAttributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemAttribute>;
                            itemDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemDiscount>;
                            itemVariables: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemVariable>;
                            itemStatusLog: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemStatusLog>;
                            productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                            itemCategories: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemCategory>;
                        }
                        export class ItemStatusLog {
                            statusId: number;
                            itemId: number;
                            status: number;
                            dateCreated: Date;
                            userId?: number;
                        }
                        export class ItemVariable {
                            itemVariableId: number;
                            variableName: string;
                            variableIndex: number;
                            variableValue: string;
                        }
                        export class ItemWithTransaction {
                            type: Honeycomb.Common.Enums.TransactionType;
                            tranNo: number;
                            transactionDate: Date;
                            itemId: number;
                            itemNumber: number;
                            productName: string;
                            productNumber: string;
                            originalQuantity: number;
                            quantity: number;
                            retailPrice: number;
                            originalPrice: number;
                            basePrice?: number;
                            finalPriceBeforeDiscount: number;
                            finalPriceWithoutVATBeforeDiscount: number;
                            finalPriceWithVATBeforeDiscount: number;
                            finalPrice: number;
                            finalPriceWithoutVAT: number;
                            finalPriceWithVAT: number;
                            purchasePrice?: number;
                            userEnteredPrice?: number;
                            usedRewardsPoints: number;
                            priceWithVAT: boolean;
                            vatId: number;
                            vatValue: number;
                            totalDiscount: number;
                            vatPercent: number;
                            measurementUnitId: number;
                            measurementUnit: string;
                            measurementUnitDecimals: number;
                            measurementSymbol: string;
                            productGroupName: string;
                            manufacturerPartNumber: string;
                            note: string;
                            gtin: string;
                            variantText: string;
                            productId?: number;
                            productVariantId?: number;
                            updatedBy?: number;
                            firstImageUrl: string;
                            itemUID?: string;
                            parentItemUID?: string;
                            status: number;
                            noDiscount: boolean;
                            calcDiscount: number;
                            productAttributes: { [key: string]: string };
                            itemAttributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemAttribute>;
                            itemDiscounts: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemDiscount>;
                            itemVariables: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemVariable>;
                            itemStatusLog: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemStatusLog>;
                            productImages: Array<Honeycomb.Tenant.Catalog.IService.ProductImageSerializable>;
                            itemCategories: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.ItemCategory>;
                        }
                        export class Note {
                            noteID: number;
                            headerID: number;
                            content: string;
                            validFromUTC: Date;
                            validToUTC: Date;
                            noteType: Honeycomb.Common.Enums.TransactionNoteType;
                            updatedBy?: number;
                            header: Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header;
                        }
                        export class Payment {
                            paymentId: number;
                            paymentTypeCode: string;
                            paymentCost: number;
                            currencyIsoCode: string;
                            currencySymbol: string;
                            transactionNumber: string;
                            paymentNumber: number;
                            paymentAmount: number;
                            lookupPaymentID: number;
                            paymentStatus: Honeycomb.Common.Enums.PaymentStatus;
                            paymentProviderID?: number;
                            paymentStatusKey: string;
                            paymentTypeKey: string;
                            currencyRate?: number;
                            localPaymentAmount?: number;
                            paymentRequestDataJson: string;
                            paymentResponseDataJson: string;
                            updatedBy?: number;
                            paymentAttributes: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.PaymentAttribute>;
                        }
                        export class PaymentAttribute {
                            paymentAttributeId: number;
                            paymentAttributeTypeId: number;
                            attrValue: string;
                            paymentAttributeType: Honeycomb.Tenant.Transactions.IService.Model.Transaction.PaymentAttributeType;
                        }
                        export class PaymentAttributeType {
                            paymentAttributeTypeId: number;
                            meaning: Honeycomb.Common.Enums.AttributeMeaning.TransactionPaymentAttributeMeaning;
                            name: string;
                            description: string;
                            dataType: Honeycomb.Common.Enums.DataType;
                            codeListId?: number;
                        }
                        export class PDFFormContent {
                            pdfFormContentID: number;
                            itemUID?: string;
                            transactionUID?: string;
                            content: string;
                            pdfFormRecordUID: string;
                            contentTableName: string;
                        }
                        export class PromotionPresent {
                            productVariantIDs: Array<number>;
                            productNumbers: Array<string>;
                            products: Array<Honeycomb.Tenant.Catalog.IService.Product>;
                            promotionCode: string;
                            promotionDescription: string;
                            presentCount: number;
                        }
                        export class ServiceItem {
                            serviceItemId: number;
                            type: Honeycomb.Common.Enums.ServiceItemType;
                            referredItemId: number;
                            code: string;
                            description: string;
                            quantity: number;
                            retailPrice: number;
                            finalPrice: number;
                            finalPriceWithoutVAT: number;
                            finalPriceWithVAT: number;
                            vatId: number;
                            vatValue: number;
                            vatPercent: number;
                            updatedBy?: number;
                            priceWithVAT: boolean;
                            serviceItemUID?: string;
                        }
                        export class Shipping {
                            shippingId: number;
                            packageNumber: string;
                            shippingTypeId: number;
                            shippingCost: number;
                            shippingTypeCode: string;
                            nameKey: string;
                            descriptionKey: string;
                            dateCreated: Date;
                            validFromUTC: Date;
                            validToUTC: Date;
                            shippingStatus: Honeycomb.Common.Enums.ShippingStatus;
                            updatedBy?: number;
                            shippingStatusKey: string;
                            expectedDeliveryDate?: Date;
                        }
                        export class StatusLog {
                            statusId: number;
                            status: number;
                            dateCreated: Date;
                            userId?: number;
                            statusName: string;
                        }
                        export class TransactionContact {
                            contactId: number;
                            contactType: Honeycomb.Common.Enums.ContactType;
                            number: string;
                            addressType: Honeycomb.Common.Enums.AddressType;
                            companyName: string;
                            companyIdn: string;
                            companyTin: string;
                            initials: string;
                            firstName: string;
                            middleName: string;
                            lastName: string;
                            jobTitle: string;
                            salutation: string;
                            addressLine1: string;
                            addressLine2: string;
                            city: string;
                            zip: string;
                            countryIsocode: string;
                            email: string;
                            phoneNr: string;
                            ignoreSaving: boolean;
                            userID?: number;
                            updatedBy?: number;
                            contactMeaning: Honeycomb.Common.Enums.ContactMeaning;
                        }
                        export class TransactionSaveResult {
                            encryptedTransactionID: string;
                            tranNo: number;
                            transactionID: number;
                            transactionUID: string;
                            items: Array<Honeycomb.Tenant.Transactions.IService.Model.BasketItem>;
                            splittedTransactions: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.TransactionSaveResult>;
                        }
                        export class TransactionSelect {
                            userID: number;
                            userNumber: string;
                            userUID?: string;
                            searchString: string;
                            transactionType: Honeycomb.Common.Enums.TransactionType;
                            transactionStatus: number;
                            dateSince?: Date;
                            dateTill?: Date;
                            returnRich: boolean;
                            pageSize?: number;
                            page: number;
                            sortColumn: string;
                            sortDirection: Honeycomb.Common.SortDirection;
                        }
                        export class TransactionSplitResult {
                            headers: Array<Honeycomb.Tenant.Transactions.IService.Model.Transaction.Header>;
                        }
                        export class TransactionStatus {
                            transactionStatusID: number;
                            status: number;
                            foreignStatus: string;
                            meaning: Honeycomb.Common.Enums.TransactionStatusMeaning;
                            name: string;
                            description: string;
                        }
                        export class VatValuePair {
                        }
                        export class Voucher {
                            voucherId: number;
                            headerId: number;
                            name: string;
                            description: string;
                            shortNumber: string;
                            number: string;
                            valueType: number;
                            useType: number;
                            value: number;
                            validMonths: number;
                            appliedPrice: number;
                            vatId: number;
                            vatValue: number;
                            vatPercent: number;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Vouchers {
            export namespace IService {
                export namespace Model {
                    export namespace Editions {

                        export class VoucherMappedAttributes {
                            stripImage: string;
                            walletBackground: string;
                            productName: string;
                            subtitle: string;
                            attributeTypes: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttributeType>;
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Vouchers {
            export namespace IService {
                export namespace Model {

                    export class ReleaseVoucherBlocationRequest {
                        transactionUniqueIdentifier: string;
                    }
                    export class Voucher {
                        voucherID: number;
                        templateID: number;
                        number: string;
                        shortNumber: string;
                        userID?: number;
                        value: number;
                        usedCount?: number;
                        status: Honeycomb.Common.Enums.VoucherStatus;
                        statusKey: string;
                        created: Date;
                        modified: Date;
                        validSince?: Date;
                        validTill?: Date;
                        template: Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate;
                        attributes: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttribute>;
                        purchases: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherPurchase>;
                        history: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherHistory>;
                        mappedAttributes: Honeycomb.Tenant.Vouchers.IService.Model.Editions.VoucherMappedAttributes;
                        voucherTemplateLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateLimitation>;
                        error: string;
                        valueType: Honeycomb.Common.Enums.VoucherValueType;
                        useType: Honeycomb.Common.Enums.DiscountApplicationType;
                        cultureInfo: any;
                        formatedValueLabel: string;
                        formatedValue: string;
                        countedValidTill?: Date;
                    }
                    export class VoucherAttribute {
                        voucherAttributeId: number;
                        voucherId: number;
                        voucherAttributeTypeId: number;
                        value: string;
                        voucherAttributeType: Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttributeType;
                    }
                    export class VoucherAttributeType {
                        voucherAttributeTypeId: number;
                        meaning: Honeycomb.Common.Enums.AttributeMeaning.VoucherAttributeMeaning;
                        name: string;
                        description: string;
                        dataType: Honeycomb.Common.Enums.DataType;
                        codeListId?: number;
                    }
                    export class VoucherCreateExistingNumberRequest {
                        voucherTemplateName: string;
                        number: string;
                        value: number;
                        userID?: number;
                        validSince?: Date;
                        validTill?: Date;
                    }
                    export class VoucherCreateExistingNumberResponse {
                        voucher: Honeycomb.Tenant.Vouchers.IService.Model.Voucher;
                    }
                    export class VoucherCreateRequest {
                        voucherTemplateId: number;
                        voucherTemplateName: string;
                        operationMetadata: string;
                        userId?: number;
                        userNumber: string;
                        count: number;
                        setUsable: boolean;
                        value?: number;
                        attributes: { [key: string]: string };
                    }
                    export class VoucherEditableDetail {
                        name: string;
                        description: string;
                        number: string;
                        status: Honeycomb.Common.Enums.VoucherStatus;
                        validSince?: Date;
                        validTill?: Date;
                        value: number;
                        valueType: Honeycomb.Common.Enums.VoucherValueType;
                        note: string;
                        purchaseTranNo: string;
                        currencyISOCode: string;
                        purchaseTranDate?: Date;
                    }
                    export class VoucherHistory {
                        historyId: number;
                        voucherId: number;
                        operation: Honeycomb.Common.Enums.VoucherOperation;
                        status: Honeycomb.Common.Enums.VoucherStatus;
                        operationMetadata: string;
                        value: number;
                        appliedValue?: number;
                        created: Date;
                        name: string;
                        description: string;
                        valueType: Honeycomb.Common.Enums.VoucherValueType;
                        useType: Honeycomb.Common.Enums.DiscountApplicationType;
                        number: string;
                        shortNumber: string;
                    }
                    export class VoucherPrintable {
                        template: Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate;
                        voucher: Honeycomb.Tenant.Vouchers.IService.Model.Voucher;
                        user: Honeycomb.Tenant.Contact.IService.User;
                    }
                    export class VoucherProductInfo {
                        productNumber: string;
                        quantity: number;
                        totalPrice: number;
                        totalDiscount: number;
                    }
                    export class VoucherPurchase {
                        voucherPurchaseID: number;
                        voucherID: number;
                        transactionNumber: string;
                        transactionNote: string;
                        transactionDate?: Date;
                    }
                    export class VoucherRechargeRequest {
                        number: string;
                        value: number;
                        operationMetadata: string;
                    }
                    export class VoucherRechargeResponse {
                        voucher: Honeycomb.Tenant.Vouchers.IService.Model.Voucher;
                    }
                    export class VouchersChangeStatusRequest {
                        voucherIDs: Array<number>;
                        voucherNumbers: Array<string>;
                        status: Honeycomb.Common.Enums.VoucherStatus;
                        validSince?: Date;
                        validTill?: Date;
                        metadata: string;
                    }
                    export class VoucherStack {
                        voucherStackID: number;
                        templateID: number;
                        number: string;
                        shortNumber: string;
                        free: boolean;
                        created: Date;
                    }
                    export class VoucherStackCodesImportRequest {
                        codes: string;
                    }
                    export class VoucherTemplate {
                        voucherTemplateId: number;
                        name: string;
                        description: string;
                        nameDef: string;
                        descriptionDef: string;
                        group: string;
                        valueType: Honeycomb.Common.Enums.VoucherValueType;
                        useType: Honeycomb.Common.Enums.DiscountApplicationType;
                        value: number;
                        valueCurrencyISOCode: string;
                        isActive: boolean;
                        numberMaskId?: number;
                        maxUsedByUser: number;
                        numberOfRepetitions?: number;
                        numberMask: string;
                        shortNumberMaskId?: number;
                        shortNumberMask: string;
                        discountRelativeValueType: Honeycomb.Common.Enums.DiscountRelativeValueType;
                        validityRelative?: number;
                        validityFixed?: Date;
                        validSince?: Date;
                        discountValidityType: Honeycomb.Common.Enums.DiscountValidityType;
                        useNumberStack: boolean;
                        stackFreeNumbersCount: number;
                        fixedCode: string;
                        loyaltyPointsExchange: number;
                        minTransactionPrice: number;
                        minTransactionCurrencyISOCode: string;
                        layoutTemplateId: number;
                        layoutTemplateParameters: string;
                        allowPartialUse: boolean;
                        created: Date;
                        updateTime: Date;
                        voucherTemplateAttributes: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateAttribute>;
                        voucherTemplateLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateLimitation>;
                        voucherTemplateLocations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateLocation>;
                        voucherTemplateCodeListLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateCodeListLimitation>;
                        voucherTemplateProductLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateProductLimitation>;
                        voucherStack: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherStack>;
                        productSets: Array<number>;
                        categories: Array<number>;
                        locations: Array<number>;
                    }
                    export class VoucherTemplateAttribute {
                        voucherTemplateAttributeId: number;
                        voucherTemplateId: number;
                        voucherAttributeTypeId: number;
                        value: string;
                        voucherAttributeType: Honeycomb.Tenant.Vouchers.IService.Model.VoucherAttributeType;
                    }
                    export class VoucherTemplateCodeListLimitation {
                        voucherTemplateCodeListLimitationID?: number;
                        voucherTemplateID?: number;
                        codeListID: number;
                        codeID: number;
                        codeKey: string;
                        codeListName: string;
                        voucherTemplate: Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate;
                    }
                    export class VoucherTemplateLimitation {
                        voucherTemplateLimitationID: number;
                        voucherTemplateID: number;
                        productSetID?: number;
                        productSetName: string;
                        categoryID?: number;
                        categoryName: string;
                        voucherTemplate: Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplate;
                    }
                    export class VoucherTemplateLocation {
                        voucherTemplateLocationID: number;
                        voucherTemplateID: number;
                        locationID: number;
                        created: Date;
                    }
                    export class VoucherTemplateProductLimitation {
                        voucherTemplateProductLimitationID: number;
                        voucherTemplateID: number;
                        productAttributeTypeID: number;
                        attributeValue: string;
                    }
                    export class VoucherUseRequest {
                        number: string;
                        transactionUniqueIdentifier: string;
                        operationMetadata: string;
                        value?: number;
                        productNumbers: Array<string>;
                        productsInfo: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherProductInfo>;
                        loyaltyLevels: Array<string>;
                        locationNumber: string;
                        localCurrencyISOCode: string;
                        log: boolean;
                        attributes: { [key: string]: string };
                    }
                    export class VoucherUseResponse {
                        isValid: boolean;
                        isUsable: boolean;
                        usabilityStatus: Honeycomb.Common.Enums.VoucherUsabilityStatus;
                        originalRequest: Honeycomb.Tenant.Vouchers.IService.Model.VoucherUseRequest;
                        message: string;
                        voucher: Honeycomb.Tenant.Vouchers.IService.Model.Voucher;
                        usableForProducts: Array<string>;
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace TenantManagement {
        export namespace IService {

            export class APIResult {
                typeScript: string;
            }
            export class AuthenticateResultViewModel {
                iD: number;
                companyName: string;
                primaryEmail: string;
                foreignKeyID: string;
                firstName: string;
                lastName: string;
                loginName: string;
                langISOCode: string;
                currencyISOCode: string;
            }
            export class AuthenticateViewModel {
                loginName: string;
                password: string;
            }
            export class ChangePasswordRequestViewModel {
                tenantId: number;
                newPassword: string;
                passwordConfirmation: string;
                originalPassword: string;
            }
            export class ModifyTenantLogoViewModel {
                logoType: number;
                content: Array<number>;
            }
            export class RegisterViewModel {
                foreignKeyID: string;
                companyName: string;
                primaryEmail: string;
                iN: string;
                fiscalID: string;
                firstName: string;
                lastNameName: string;
                zipCode: string;
                street: string;
                streetNumber: string;
                region: string;
                country: string;
                phoneNr: string;
                langISOCode: string;
                currencyISOCode: string;
                loginName: string;
                password: string;
                passwordConfirmation: string;
                description: string;
                logoImages: Array<Honeycomb.TenantManagement.IService.ModifyTenantLogoViewModel>;
            }
            export class TenantLogoRequestViewModel {
                tenantId: number;
                logoType: number;
            }
            export class TenantLogoViewModel {
                tenantId: number;
                logoType: number;
                content: Array<number>;
                tst: Array<number>;
            }
            export class TenantsByIdsRequestViewModel {
                tenantIds: Array<number>;
            }
            export class TenantShortViewModel {
                tenantID: number;
                tenantHash: string;
                tenantName: string;
            }
            export class TenantViewModel {
                iD: number;
                loginName: string;
                foreignKeyID: string;
                companyName: string;
                primaryEmail: string;
                iN: string;
                fiscalID: string;
                firstName: string;
                lastName: string;
                zipCode: string;
                street: string;
                streetNumber: string;
                region: string;
                country: string;
                phoneNr: string;
                langISOCode: string;
                currencyISOCode: string;
                description: string;
                tenantUID: string;
                tenantHash: string;
                edition: Honeycomb.Common.Enums.Edition;
            }
            export class UpdateViewModel {
                iD: number;
                foreignKeyID: string;
                loginName: string;
                companyName: string;
                primaryEmail: string;
                iN: string;
                fiscalID: string;
                firstName: string;
                lastNameName: string;
                zipCode: string;
                street: string;
                streetNumber: string;
                region: string;
                country: string;
                phoneNr: string;
                langISOCode: string;
                currencyISOCode: string;
                description: string;
                logoImages: Array<Honeycomb.TenantManagement.IService.ModifyTenantLogoViewModel>;
            }
        }
    }
}

export namespace Honeycomb {
    export namespace TenantManagement {
        export namespace IService {
            export namespace Models {

                export class APIFilter {
                    elements: Array<Honeycomb.TenantManagement.IService.Models.APIFilterElement>;
                }
                export class APIFilterElement {
                    controller: string;
                    methods: Array<string>;
                    ignoredMethods: Array<string>;
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Auth {
        export namespace IService {

        }
    }
}

export namespace Honeycomb {
    export namespace CentralService {
        export namespace IService {
            export namespace ViewModel {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace DataProviders {

        }
    }
}

export namespace Honeycomb {
    export namespace Common {

    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Db {

        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Db {
            export namespace ProductDiscounts {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Elastic {

        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace ImportExport {
            export namespace Interface {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace ImportExport {
            export namespace Model {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {

        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {
            export namespace Middlewares {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Mvc {
            export namespace Services {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Shipping {

        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace ViewModels {

        }
    }
}

export namespace Honeycomb {
    export namespace LookupTables {
        export namespace IService {
            export namespace Model {
                export namespace BusinessLogic {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace LookupTables {
        export namespace IService {
            export namespace Model {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {
                export namespace ViewModels {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {
                export namespace ViewModels {
                    export namespace GITHub {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Catalog {
            export namespace IService {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Catalog {
            export namespace IService {
                export namespace Models {
                    export namespace Editions {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {
                export namespace ViewModel {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Contact {
            export namespace IService {
                export namespace ViewModel {
                    export namespace Editions {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Model {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Model {
                    export namespace Editions {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Model {
                    export namespace Schedule {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace LookupTables {
            export namespace IService {
                export namespace Schedule {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Controllers {
                    export namespace v1 {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Model {
                    export namespace Container {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace PublicAPI {
            export namespace IService {
                export namespace Model {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace PBI {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace Segments {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace Stats {
                        export namespace Container {

                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {
                export namespace Model {
                    export namespace Stats {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Reports {
            export namespace IService {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {
                export namespace Model {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {
                export namespace Model {
                    export namespace Notification {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Tasker {
            export namespace IService {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace TemplateEngine {
            export namespace IService {
                export namespace Model {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace TemplateEngine {
            export namespace IService {
                export namespace ViewModel {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {
                    export namespace Editions {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {
                    export namespace Omnichannel {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {
                    export namespace Transaction {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Vouchers {
            export namespace IService {
                export namespace Model {
                    export namespace Editions {

                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Vouchers {
            export namespace IService {
                export namespace Model {

                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace TenantManagement {
        export namespace IService {

        }
    }
}

export namespace Honeycomb {
    export namespace TenantManagement {
        export namespace IService {
            export namespace Models {

            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Elastic {

            export enum PanelGroupType {
                unset = 0,
                distinct = 1,
                range = 2,
                filters = 3
            }
            export enum PanelItemType {
                unset = 0,
                distinctValue = 1,
                simpleFilter = 2
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Enums {

            export enum AddressType {
                unknown = 0,
                billing = 1,
                shipping = 2
            }
            export enum AggregateFunction {
                none = 0,
                groupBy = 1,
                sum = 2,
                max = 3,
                min = 4,
                count = 5,
                avg = 6,
                hide = 7
            }
            export enum AggregatorType {
                heureka_cz = 1,
                zbozi_cz = 2,
                google = 3,
                heureka_sk = 4
            }
            export enum AppleInfoTextPlacement {
                back = 0,
                auxiliary = 1,
                secondary = 2
            }
            export enum AttachedDocumentPurpose {
                unknown = 0,
                invoice = 1,
                deliveryNotice = 2
            }
            export enum AttachmentType {
                notSet = 0,
                media = 1,
                storage = 2
            }
            export enum AttendanceSubType {
                notSet = 0,
                standard = 1,
                doctor = 2,
                vacation = 3,
                businessTrip = 4
            }
            export enum AttendanceType {
                notSet = 0,
                enter = 1,
                exit = 2
            }
            export enum BackgroundType {
                notSet = 0,
                categoryBackground = 1,
                catalogBanner = 2
            }
            export enum CategoryAttributeMeaning {
                undefined = 0
            }
            export enum CategoryExtensionTypeEnum {
                customContent1 = 1,
                customContent2 = 2,
                customContent3 = 3,
                customContent4 = 4
            }
            export enum CertificateType {
                unknown = 0,
                appleWalletXC509 = 1,
                googleWalletJSONAPISecure = 2,
                gPwebpayPublicCer = 3,
                uctenkovka = 4
            }
            export enum CodeListType {
                fixedList = 0,
                queryable = 1
            }
            export enum ColumnFilterComparison {
                undefined = 0,
                contains = 1,
                notContains = 2,
                equals = 3,
                notEqual = 4,
                startsWith = 5,
                endsWith = 6,
                lessThan = 7,
                lessThanOrEquals = 8,
                greaterThan = 9,
                greaterThanOrEquals = 10,
                inRange = 11
            }
            export enum ContactMeaning {
                creator = 0,
                customer = 1,
                supplier = 2,
                sales = 3
            }
            export enum ContactType {
                unknown = 0,
                company = 1,
                person = 2
            }
            export enum CumulatedMovementGroupType {
                undefined = 0,
                correction = 1,
                correctionStockTaking = 2,
                goodsTransferIn = 3,
                goodsTransferOut = 4,
                goodsIn = 5,
                sale = 6,
                goodsOut = 7
            }
            export enum DataGroup {
                undefined = 0,
                product = 1,
                transaction = 2,
                user = 3
            }
            export enum DataType {
                unknown = 0,
                boolean = 1,
                number = 2,
                text = 3,
                dateTime = 4,
                image = 5,
                money = 6,
                integerNumber = 7,
                shortDate = 8,
                codeList = 9,
                html = 10,
                location = 11,
                categories = 12,
                media = 13,
                survey = 14,
                fixedEnum = 15,
                dateRange = 16,
                numberRange = 17,
                localizedTexts = 18,
                dimensions = 19,
                arrayOfStrings = 20,
                arrayOfNumbers = 22,
                arrayOfDates = 23,
                multicurrencyPrice = 24,
                localizationKey = 25,
                stringDictionary = 26,
                nutsRegion = 27,
                link = 28,
                receipt = 29,
                longText = 30,
                voucherTemplate = 31,
                productFilterAttributes = 32,
                jsonObject = 33,
                productNumber = 34,
                multiLang = 35
            }
            export enum DayOfWeek {
                none = 0,
                sunday = 1,
                monday = 2,
                tuesday = 4,
                wednesday = 8,
                thursday = 16,
                friday = 32,
                weekdays = 62,
                saturday = 64,
                weekends = 65,
                daily = 127
            }
            export enum DbOperation {
                upsert = 0,
                delete = 1
            }
            export enum DialogType {
                unknown = 0,
                text = 1,
                contactFilter = 2,
                productFilter = 3
            }
            export enum DiscountApplicationType {
                notSet = 0,
                oneTime = 1,
                repeatedly = 2,
                repeatedlyValueDecreasing = 3,
                repeatedlyCountDecreasing = 4
            }
            export enum DiscountOrigin {
                notSet = 0,
                voucher = 1,
                plugin = 2,
                clientHeader = 3,
                clientItem = 4,
                promotion = 5
            }
            export enum DiscountRelativeValueType {
                notSet = 0,
                day = 1,
                week = 2,
                month = 3
            }
            export enum DiscountResultType {
                giveDiscount = 0,
                overridePrice = 1
            }
            export enum DiscountValidityType {
                relative = 0,
                static = 1
            }
            export enum DiscountValueType {
                value = 0,
                percent = 1,
                setPrice = 2,
                unspecified = 99
            }
            export enum DistributeBatchType {
                undefined = 0,
                automatic = 1,
                manual = 2
            }
            export enum DistributeResult {
                notDistributed = 0,
                ok = 1,
                incorrectNumber = 2,
                emptySMS = 3,
                tooLongSMS = 4,
                incorrectEmail = 5,
                otherError = 100
            }
            export enum DistributionBatchState {
                unknown = 0,
                created = 1,
                beingProcessed = 2,
                finished = 3
            }
            export enum DistributionChannelType {
                notSet = 0,
                smS = 1,
                email = 2
            }
            export enum Edition {
                undefined = 0,
                all_editions = 1,
                drugstore_b2c = 2,
                entertainment_b2c = 3,
                industry_b2b = 4,
                noneshop = 5,
                publictransport_b2c = 6,
                toystore_b2c = 7,
                wineshop_b2b = 8,
                fashion_b2c = 9
            }
            export enum ElasticSource {
                unused = 0,
                product = 1,
                phrase = 2,
                user = 3,
                category = 4,
                tree = 5,
                transaction = 6,
                media = 7,
                slotplan = 8,
                event = 9,
                term = 10,
                booking = 11,
                rating = 12,
                post = 13
            }
            export enum EntityDataType {
                unknown = 0,
                transaction = 1,
                product = 2,
                user = 3,
                voucher = 4,
                transactionList = 6,
                productList = 7,
                userList = 8,
                userBasket = 9,
                report = 10,
                statTransaction = 11,
                newsletter = 12,
                task = 13
            }
            export enum ExportContentSelector {
                undefined = 0,
                allUnexported = 1,
                sinceTillDate = 2,
                all = 4,
                allSelected = 8
            }
            export enum ExternalAccountType {
                notSet = 0,
                google = 1,
                facebook = 2,
                twitter = 3
            }
            export enum FileExportLevel {
                all = 0,
                required = 1,
                optional = 3,
                attributes = 6
            }
            export enum FilterDialogItemType {
                unknown = 0,
                text = 1,
                filter = 2
            }
            export enum FilterEditorType {
                none = 0,
                text = 1,
                number = 2,
                numberRange = 3,
                date = 4,
                dateRange = 5,
                codeList = 6,
                productSuggester = 7,
                userSuggester = 8,
                userJobSuggester = 9,
                bit = 10
            }
            export enum FilterPurpose {
                undefined = 0,
                userList = 1,
                productList = 2
            }
            export enum FilterQueryType {
                unknown = 0,
                fallback = 1
            }
            export enum Gender {
                undefined = 0,
                man = 1,
                woman = 2
            }
            export enum GoogleWalletInstanceStatus {
                unknown = 1,
                generated = 2,
                saved = 3,
                deleted = 4
            }
            export enum HTMLViewContentType {
                common = 0,
                newsletter = 2,
                report = 3
            }
            export enum HtmlViewParameterType {
                parameter = 0,
                style = 1
            }
            export enum ImageType {
                unknown = 0,
                thumbnail = 1,
                detail = 2
            }
            export enum ImportResultOperation {
                unknown = 0,
                skiped = 1,
                updated = 2,
                inserted = 3,
                deleted = 4
            }
            export enum InterfaceType {
                undefined = 0,
                import = 1,
                export = 2
            }
            export enum JobGroupType {
                unknown = 0,
                store = 1,
                franchise = 2,
                regionalManager = 3
            }
            export enum JobPermissionMeaning {
                notAllowed = 0,
                allowed = 1,
                allowedAfterTime = 2,
                allowedTillNextWeek = 3,
                onlyMyLocations = 4,
                onlyInterested = 5
            }
            export enum ListType {
                userDefined = 0,
                system = 1
            }
            export enum MaskType {
                transactionNumber = 0,
                voucherNumber = 1,
                voucherShortNumber = 2,
                user = 3,
                gtin = 4,
                productNumber = 5,
                tagNumber = 6
            }
            export enum MediaCarrier {
                undefined = 0,
                email = 1,
                weB = 2,
                preview = 3
            }
            export enum MediaOrigin {
                notSet = 0,
                system = 1,
                user = 2
            }
            export enum MediaPurpose {
                undefined = 0,
                systemRequired = 1,
                newsletter = 2
            }
            export enum MediaType {
                notSet = 0,
                dynamic = 1,
                staticFile = 2,
                newsletter = 3
            }
            export enum ObjectAttributeSource {
                undefined = 0,
                user = 1,
                tag = 2,
                product = 3,
                voucher = 4
            }
            export enum OccurenceState {
                new = 0,
                published = 1,
                cancelled = 2,
                archived = 3,
                running = 4
            }
            export enum PaletteEntryType {
                color = 0,
                font = 1
            }
            export enum PasswordHashType {
                internal = 0,
                mD5 = 1,
                bcrypT = 2
            }
            export enum PaymentMedium {
                undefined = 0,
                cash = 1,
                card = 2,
                cheque = 3
            }
            export enum PaymentProviderType {
                notSet = 0,
                goPay = 1,
                gPwebpay = 2
            }
            export enum PaymentStatus {
                unknown = 0,
                created = 1,
                paymentMethodChosen = 2,
                authorized = 3,
                cancelled = 4,
                timeouted = 5,
                refunded = 6,
                partiallyRefunded = 7,
                pending = 99,
                paid = 100
            }
            export enum PeriodType {
                once = 0,
                day = 1,
                week = 2,
                month = 3,
                year = 4
            }
            export enum PermissionType {
                canAssignTo = 1,
                assignedCanRead = 2
            }
            export enum PortalAgenda {
                undefined = 0,
                product = 1,
                contact = 2,
                ordersOverview = 3,
                productCategory = 4,
                vouchersOverview = 5,
                promotionsOverview = 6,
                tenantLocalizations = 7,
                tagEmissions = 8,
                globalLocalizations = 9,
                voucherTemplatesList = 10,
                storageList = 11,
                attendance = 12,
                inputLocalizations = 13,
                activityLocalizations = 14,
                operationLocalizations = 15,
                taskStatuses = 16,
                userSubstitutions = 17
            }
            export enum PriceType {
                notSet = 0,
                actionPrice = 1,
                viP = 2,
                actual = 3,
                retail = 4,
                recommended = 5,
                original = 6,
                purchase = 7,
                promoPrice = 99
            }
            export enum Priority {
                notSet = 0,
                low = 1,
                normal = 2,
                high = 3,
                critical = 4
            }
            export enum ProductAvailabilityType {
                undefined = 0,
                availableAtStore = 1,
                mustBeSupplied = 2,
                notAvailable = 3
            }
            export enum ProductCategoryProvider {
                tpomM = 0,
                heureka = 1,
                zbozi = 2,
                google = 3,
                heureka_sk = 4
            }
            export enum ProductRatingStatus {
                none = 0,
                created = 1,
                accepted = 2,
                rejected = 3
            }
            export enum ProductRelationType {
                notSet = 0,
                ancillaryGoods = 1,
                relatedProduct = 2
            }
            export enum ProductType {
                standard = 0,
                composed = 1,
                service = 2
            }
            export enum PromotionActionType {
                headerDiscount = 1,
                itemDiscount = 2,
                itemGroupedDiscount = 3,
                sendSMS = 4,
                addBasketPresent = 5,
                addPresentGrouped = 6,
                addSameAsPresent = 7,
                itemDiscountFromAttribute = 8,
                retailPriceToOriginal = 9,
                jingleAction = 10,
                promoPriceFromProductPrices = 11,
                discountFromUserAttribute = 12,
                fixSetPrice = 13,
                serviceItemDiscount = 14
            }
            export enum PromotionConditionType {
                aggregatedProperty = 1,
                logicalOperator = 2,
                orderProperty = 3,
                userProperty = 4,
                userAttribute = 5,
                agQuantity = 6,
                agRetailPrice = 7,
                serviceItemProperty = 8,
                voucherPromoCode = 9,
                addressProperty = 10,
                voucherTemplate = 11,
                userAnonymous = 12,
                totalVoucherless = 13
            }
            export enum PromotionEntityType {
                itemRule = 1,
                condition = 2,
                action = 3
            }
            export enum PromotionItemRuleType {
                propertyValueComparer = 1,
                attributeValueComparer = 2,
                allPass = 3,
                itemInProductSet = 4,
                originalRetailPriceComparer = 5
            }
            export enum QueryablePurpose {
                undefined = 0,
                report = 1,
                segment = 2
            }
            export enum QueryableType {
                view = 0,
                storedProcedure = 1
            }
            export enum RawHeaderDataMeaning {
                unknown = 0,
                note = 1,
                transactionPrintout = 2,
                eeT_ActionTime = 3,
                eeT_PKP = 4,
                eeT_BKP = 5,
                eeT_FIK = 6,
                eeT_TIN = 7,
                eeT_Price = 8,
                eeT_SimpleMode = 9,
                savedPrice = 10,
                eeT_Result = 11
            }
            export enum RawItemDataMeaning {
                unknown = 0,
                mainCategory = 1,
                brand = 2,
                season = 3,
                manufacturer = 4,
                supplier = 5,
                gender = 6,
                material = 7,
                lifeCycle = 8
            }
            export enum RelatedUserType {
                undefined = 0,
                customer = 1,
                salesMan = 2,
                projectManager = 3
            }
            export enum RenderResult {
                html = 0,
                pdf = 1,
                sms = 2
            }
            export enum ReportActionType {
                none = 0,
                addUserToClub = 1,
                addProductToSet = 2,
                showEmailDetail = 3,
                removeUsersFromClub = 4
            }
            export enum ReportColumnPinType {
                none = 0,
                left = 1,
                right = 2
            }
            export enum ReportColumnSortDirection {
                undefined = 0,
                asc = 1,
                desc = 2
            }
            export enum ReportDataModel {
                unknown = 0,
                clientSide = 1,
                serverSide = 2
            }
            export enum ReportDetailType {
                none = 0,
                product = 1,
                user = 2,
                voucher = 3,
                media = 4,
                transaction = 5,
                report = 6,
                portalUser = 37,
                columnContent = 42,
                attendanceDetail = 55,
                productQuestion = 57
            }
            export enum ReportLayoutType {
                auto = 0,
                desktop = 1,
                mobile = 2
            }
            export enum ReportPrintFormat {
                a3 = 1,
                a4 = 2
            }
            export enum ReportPrintOrientation {
                portrait = 1,
                landscape = 2
            }
            export enum ReportSourceType {
                pbI = 1,
                remoteUrl = 2
            }
            export enum ReportType {
                undefined = 0,
                public = 1,
                internal = 2,
                detail = 3,
                customFunction = 4,
                frontendDataSource = 5
            }
            export enum RequiredEmailConfirmationType {
                notRequired = 0,
                primaryEmailConfirmation = 1,
                changePrimaryEmailConfirmation = 2
            }
            export enum RewardReason {
                notSet = 0,
                manualAdd = 1,
                manualRemove = 2,
                createdByDoc = 3,
                removedByDoc = 4,
                automaticAdd = 5,
                automaticRemove = 6,
                posAdd = 7,
                posRemove = 8
            }
            export enum RuleRunContext {
                onMessage = 0,
                inController = 1,
                inProductFeed = 2
            }
            export enum ServiceItemState {
                valid = 0,
                geoRestricted = 1,
                incompleteAddress = 2,
                invalidAddress = 3,
                noBasketItems = 4,
                unknownProvider = 5,
                errorResponse = 6,
                providerFailed = 7,
                itemOverLimit = 8,
                itemRestricted = 9,
                overWeightLimit = 10,
                unableToValidate = 11,
                itemUnderLimit = 12,
                unsuitable = 13,
                disabled = 14,
                digitalMismatch = 15
            }
            export enum ServiceItemType {
                freeItem = 0,
                shippingService = 1,
                paymentService = 2,
                recyclingFee = 3
            }
            export enum SettingValueType {
                text = 1,
                integer = 2,
                elasticQueryTemplate = 3,
                byteArray = 4,
                stringDictionary = 5,
                boolean = 6,
                decimal = 7
            }
            export enum ShippingFeature {
                storePickup = 0,
                digital = 1,
                homeDelivery = 2,
                parcelShop = 3,
                freight = 4,
                parAvion = 5,
                billing = 6
            }
            export enum ShippingLimitType {
                weight = 0,
                width = 1,
                height = 2,
                depth = 3,
                maxSide = 4,
                midSide = 5,
                minSide = 6,
                volume = 7,
                sideSum = 8,
                doubleSideSum = 9,
                maxPerimeter = 10,
                maxPeriLength = 11,
                minPeriLength = 12
            }
            export enum ShippingOptionsProviderType {
                czechPostOffice = 1,
                zasilkovna = 2,
                ulozenkaV3 = 3,
                dpdParcelShop = 4,
                czechPostBalikovna = 5,
                geisPoint = 6,
                dpdPickupSK = 7,
                czechPostBalikovnaV2 = 8,
                zasilkovnaV4 = 9,
                glsParcelShop = 10,
                packeta = 11
            }
            export enum ShippingPriceProviderType {
                dhL = 1,
                czechPostOffice = 2,
                dpD = 3,
                kuryr = 4
            }
            export enum ShippingStatus {
                notProcessed = 0,
                readyForPickUp = 1,
                notSent = 2,
                sent = 3,
                delivered = 4,
                returned = 5,
                cancelled = 6
            }
            export enum ScheduleAction {
                undefined = 0,
                opened = 1,
                event = 2
            }
            export enum SiteMapURLType {
                productDetail = 0,
                category = 1,
                rootPage = 2
            }
            export enum SQLJoinType {
                none = 0,
                inner = 1,
                outer = 2
            }
            export enum SuggestionType {
                searchPhrase = 0,
                brand = 1,
                category = 2,
                product = 3,
                article = 4,
                glossary = 5
            }
            export enum SurveyAnswerType {
                yesNo = 0,
                oneToTen = 1,
                stars5 = 2,
                oneOfOptions = 3,
                moreOfOptions = 4,
                freeText = 5,
                number = 6,
                dateTime = 7,
                shortText = 8,
                time = 9
            }
            export enum SurveyMotivationType {
                none = 0,
                discountVoucher = 1,
                bonusPoints = 2
            }
            export enum SurveyType {
                permanent = 0,
                oneTimeFill = 1
            }
            export enum TagRequestStatus {
                undefined = 0,
                created = 1,
                completed = 2
            }
            export enum TagStatus {
                unknown = 0,
                valid = 1,
                blocked = 2
            }
            export enum TaskMainFilter {
                all = 0,
                myTasks = 1,
                assgnedByMe = 2,
                myLocations = 3
            }
            export enum TaskRead {
                notSet = 0,
                read = 1,
                notRead = 2
            }
            export enum TaskRelation {
                notSet = 0,
                creator = 1,
                assigner = 2,
                assignee = 3,
                observer = 4,
                responsible = 5
            }
            export enum TaskState {
                unknown = 0,
                new = 1,
                inProgress = 2,
                finished = 3,
                archived = 10
            }
            export enum TaskType {
                unknown = 0,
                templated = 1,
                freeTask = 2,
                repairRequest = 3
            }
            export enum TemplateInputDataType {
                notSet = 0,
                categoryNames = 1,
                productNumbers = 2,
                eanCodes = 3,
                voucherParams = 4
            }
            export enum TransactionNoteType {
                unknown = 0,
                deliveryNote = 1,
                orderNote = 2,
                airMeNote = 3,
                whishListNote = 4,
                wishListEmail = 5,
                customerQuestion = 6
            }
            export enum TransactionStatusMeaning {
                notSet = 0,
                created = 1,
                approved = 2,
                awaitingPayment = 3,
                inTransport = 4,
                inInternalTransport = 5,
                internalProcessing = 6,
                readyOnStore = 8,
                finished = 10,
                cancelled = 20,
                incomplete = 21,
                paymentFailed = 22
            }
            export enum TransactionType {
                undefined = 0,
                order = 1,
                sale = 2,
                wishList = 3,
                offer = 4,
                demand = 5,
                question = 6,
                absoluteStock = 99
            }
            export enum UiType {
                notSet = 0,
                inputText = 1,
                inputNumber = 2,
                file = 3,
                checkbox = 4,
                rating = 5,
                geoLocation = 6,
                textarea = 7
            }
            export enum UpsertAction {
                upsert = 0,
                insert = 1,
                update = 2
            }
            export enum UserHandleType {
                twitter = 0,
                facebook = 1,
                google = 2,
                instagram = 3,
                snapChat = 4,
                email = 5,
                phone = 6,
                web = 7,
                uctenkovka = 8
            }
            export enum UserInterestType {
                viewDetail = 0,
                inBasket = 1,
                baught = 2
            }
            export enum UserOptionMeaning {
                undefined = 0,
                userDataProcessing = 1,
                termsAndConditions = 2,
                newsletter = 3,
                surveysHeureka = 4,
                watchDog = 5,
                dontPrintReceipt = 6,
                loyaltyMember = 7,
                registerUser = 8
            }
            export enum UserTagType {
                unknown = 0,
                userCard = 1
            }
            export enum UserTriggerUsage {
                undefined = 0,
                oneTime = 1,
                permanent = 2
            }
            export enum VATType {
                undefined = 0,
                basic = 1,
                lower1 = 2,
                lower2 = 3,
                lower3 = 4,
                zero = 5
            }
            export enum VoucherOperation {
                undefined = 0,
                created = 1,
                statusChanged = 2,
                used = 3,
                recharged = 4,
                importUpdate = 5,
                valueManualChange = 6,
                statusManualChange = 7,
                dateValidityManualChange = 8,
                purchasePriceHistoryManualChange = 9,
                verify = 10,
                assignUser = 11
            }
            export enum VoucherStatus {
                issued = 0,
                usable = 1,
                used = 2,
                blocked = 3
            }
            export enum VoucherUsabilityStatus {
                notFound = 0,
                usable = 1,
                used = 2,
                blocked = 3,
                expired = 4,
                notValidForLocation = 5,
                notValidForProducts = 6,
                notEnoughValue = 7,
                invalidCurrency = 8,
                blockedByOtherTransaction = 9,
                cannotUseWholeValue = 10,
                cannotUseValue = 11,
                notValidForLoyaltyLevels = 12
            }
            export enum VoucherValueType {
                valueDiscount = 0,
                percentDiscount = 1,
                promoCode = 2,
                booking = 3,
                giftCertificate = 4,
                unspecified = 99
            }
            export enum WalletAppleField {
                undefined = 0,
                logoText = 1,
                header = 2,
                primary = 3,
                secondary = 4,
                auxiliary = 5,
                back = 6,
                backgroundColor = 7,
                labelColor = 8,
                foregroundColor = 9,
                barcodeValue = 10,
                logoImage = 11,
                iconImage = 12,
                thumbnailImage = 13,
                stripImage = 14
            }
            export enum WalletBarcodeType {
                barcodE_TYPE_UNSPECIFIED = 0,
                qR_CODE = 1,
                pdF_417 = 2,
                azteC = 3,
                codE_128 = 4,
                codE_39 = 5,
                codabaR = 7,
                datA_MATRIX = 8,
                eaN_8 = 9,
                eaN_13 = 10,
                itF_14 = 11,
                upC_A = 12,
                texT_ONLY = 13
            }
            export enum WalletGoogleField {
                undefined = 0,
                issuerName = 1,
                programName = 2,
                loyaltyPoints = 3,
                secondaryLoyaltyPoints = 4,
                topRowSubheader = 5,
                topRowHeader = 6,
                accountName = 7,
                accountId = 8,
                rewardsTierLabel = 9,
                rewardsTier = 10,
                textModulesData = 11,
                messages = 12,
                pinLabel = 13,
                pin = 14,
                balanceUpdateTime = 15,
                details = 16,
                finePrint = 17,
                validTimeInterval = 18,
                provider = 19,
                hexBackgroundColor = 20,
                hexFontColor = 21,
                barcodeValue = 22,
                heroImage = 23,
                logoImage = 24,
                row1Left = 25,
                row1Middle = 26,
                row1Right = 27,
                row2Left = 28,
                row2Middle = 29,
                row2Right = 30,
                row3Left = 31,
                row3Middle = 32,
                row3Right = 33,
                localizedTitle = 34
            }
            export enum WalletImageType {
                undefined = 0,
                thumbnail = 1,
                strip = 2
            }
            export enum WalletTemplateDataSource {
                none = 0,
                media = 1,
                primaryContact = 2,
                userAttributes = 3,
                userCard = 4,
                voucher = 5,
                voucherTemplate = 6,
                voucherAttributes = 7,
                userCardAttributes = 8,
                user = 9,
                userRewards = 10
            }
            export enum WalletTemplateTarget {
                undefined = 0,
                apple = 1,
                google = 2
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace Enums {
            export namespace AttributeMeaning {

                export enum LocationAttributeMeaning {
                    undefined = 0,
                    parkingAvailable = 1,
                    wheelchairAccess = 2,
                    centralBranch = 3,
                    assignedToEcommerce = 4,
                    localizedName = 5,
                    openingHours = 6,
                    openedAtSaturday = 7,
                    openedAtSunday = 8,
                    risk = 9,
                    riskReason = 10,
                    lastStockTaking = 11,
                    lastManagerCheck = 12,
                    storeEvents = 13,
                    contractValidity = 14
                }
                export enum ProductAttributeMeaning {
                    undefined = 0,
                    simpleVariant = 1,
                    dateOfAvailability = 2,
                    freeDelivery = 4,
                    specialProduct = 5,
                    recommended = 6,
                    new = 7,
                    productDimension = 8,
                    packageDimension = 9,
                    customerChoice = 10,
                    videoScript = 11,
                    videoURL = 12,
                    sale = 13,
                    brand = 14,
                    packageKind = 15,
                    batteriesIncluded = 16,
                    frequency = 17,
                    batteriesNeeded = 18,
                    sounded = 19,
                    material = 20,
                    loadCapacity = 21,
                    numberOfParts = 22,
                    targetGroup = 23,
                    batteryType = 24,
                    ageRange = 25,
                    originCountry = 26,
                    materialExtraction = 27,
                    packageIncluded = 28,
                    photoComment = 29,
                    producer = 30,
                    usage = 31,
                    warning = 32,
                    acidContent = 33,
                    alcoholVolume = 34,
                    colour = 35,
                    usageRecomendation = 36,
                    dateOfHarvest = 37,
                    dateOfLateHarvest = 38,
                    flavour = 39,
                    lifetime = 40,
                    expirationDate = 41,
                    residualSugar = 42,
                    scent = 43,
                    sugarContent = 44,
                    temperatureRange = 45,
                    mainVineKind = 46,
                    vineTraining = 47,
                    quality = 48,
                    productionRegion = 49,
                    species = 50,
                    productionVillage = 51,
                    vineKind = 52,
                    label = 53,
                    vineyardSoil = 54,
                    vineyard = 55,
                    vineyardEstablished = 56,
                    containerType = 57,
                    containerVolume = 58,
                    containerWeight = 59,
                    margin = 60,
                    ageGroup = 61,
                    appliedDiscount = 62,
                    noSugarVolume = 63,
                    useBeforeDate = 64,
                    optimalMature = 65,
                    wineYear = 66,
                    packageWeight = 67,
                    complexity = 68,
                    phaseOfMatter = 69,
                    openBeforeUse = 70,
                    attributiveWine = 71,
                    recyclingFee = 72,
                    ordering = 73,
                    inAction = 74,
                    publiclyKnown = 75,
                    lastChance = 76,
                    backgroundImage = 77,
                    detailURL = 88,
                    voucherTemplateID = 89,
                    rewardPoints = 90,
                    promotionCode = 91,
                    actionValidity = 92,
                    variantName = 93,
                    variantText = 94
                }
                export enum RoleAttributeMeaning {
                    undefined = 0,
                    forbiddenPages = 1,
                    loginRedirectURL = 2,
                    taskerDashboardLayout = 3,
                    taskerMyStoreReport = 4
                }
                export enum TagAttributeMeaning {
                    undefined = 0,
                    points = 1,
                    stripImage = 2,
                    walletBackgroundColor = 3,
                    pin = 4
                }
                export enum TaskAttributeMeaning {
                    undefined = 0,
                    externalSupplier = 1
                }
                export enum TransactionHeaderAttributeMeaning {
                    undefined = 0,
                    dontAgreeHeureka = 1,
                    gaClientID = 2,
                    gaTransactionPayload = 3,
                    pdfFormSession = 4,
                    transactionLanguage = 5
                }
                export enum TransactionItemAttributeMeaning {
                    undefined = 0,
                    pdfFormFields = 1
                }
                export enum TransactionPaymentAttributeMeaning {
                    undefined = 0,
                    cardNumber = 1,
                    providerCode = 2,
                    bankCode = 3
                }
                export enum UserAttributeMeaning {
                    undefined = 0,
                    age = 1,
                    birthDay = 2,
                    childrenCount = 3,
                    childAge = 4,
                    childBirthday = 5,
                    childName = 6,
                    childGender = 7,
                    maritalStatus = 8,
                    savedTotalPrice = 9,
                    loyaltyRemainingDays = 10,
                    discount = 11,
                    walletPersonalInfo = 12,
                    foreignUserID = 13,
                    birthYear = 14
                }
                export enum VoucherAttributeMeaning {
                    undefined = 0,
                    arrivalStation = 1,
                    departureStation = 2,
                    dateOfArrival = 3,
                    dateOfDeparture = 4,
                    seatNumber = 5,
                    rowNumber = 6,
                    firstStation = 7,
                    lastStation = 8,
                    platformNumber = 9,
                    trackNumber = 10,
                    trackPrice = 11,
                    trainStatus = 12,
                    trackTarif = 13,
                    stripImage = 14,
                    walletBackground = 15,
                    dateOfEvent = 16,
                    class = 17,
                    promotionCode = 18,
                    productName = 19,
                    subtitle = 20,
                    rewardsPointsCount = 21,
                    valueForDiscountedItems = 22
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Common {

        export enum ImageOrientation {
            landscape = 0,
            portrait = 1
        }
        export enum ServicePorts {
            centralServiceRemote = 443,
            tenantContactService = 8363,
            tenantLookupTablesService = 8364,
            tenantManagementDatabaseManagementService = 8365,
            tenantManagementService = 8366,
            lookupTablesService = 8367,
            authService = 8368,
            tenantVouchersService = 8369,
            tenantCatalogService = 8370,
            tenantAdminService = 8371,
            tenantDocumentStorage = 8372,
            tenantTransactionService = 8373,
            tenantTemplateEngineService = 8375,
            centralService = 8376,
            tenantReportService = 8377,
            tenantPublicAPIService = 8378,
            tenantTaskerService = 8381
        }
        export enum SortDirection {
            asc = 0,
            desc = 1
        }
    }
}

export namespace Honeycomb {
    export namespace Common {
        export namespace ImportExport {
            export namespace Model {

                export enum ImportExportFileFormat {
                    csV = 1,
                    xlsX = 2,
                    ziP = 3,
                    xmL = 4
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace LookupTables {
        export namespace IService {
            export namespace Model {
                export namespace BusinessLogic {

                    export enum MethodRunOrder {
                        beforeControllerMethod = 0,
                        afterControllerMethod = 1
                    }
                    export enum RuleActionType {
                        none = 0,
                        condition = 1,
                        action = 2,
                        actionTry = 3,
                        actionCatch = 4,
                        ruleCatch = 5,
                        return = 6,
                        forEach = 7
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {
                export namespace Enums {

                    export enum AttributeTypeEnum {
                        admin_RoleAttributeTypes = 1,
                        catalog_CategoryAttributeTypes = 2,
                        catalog_ProductAttributeTypes = 3,
                        contact_TagAttributeTypes = 4,
                        contact_UserAttributeTypes = 5,
                        lookup_LocationAttributeTypes = 6,
                        trans_AttributeTypes = 7,
                        trans_ItemAttributeTypes = 8,
                        trans_PaymentAttributeTypes = 9,
                        voucher_VoucherAttributeTypes = 10
                    }
                    export enum ComponentFeature {
                        none = 0,
                        configuration = 1,
                        filter = 2,
                        skin = 4
                    }
                    export enum ComponentFramework {
                        iceberg = 0,
                        vuejs = 1,
                        vuejs2 = 2
                    }
                    export enum ComponentType {
                        none = 0,
                        productCatalog = 1,
                        basket = 2,
                        productSearch = 4,
                        productDetail = 8,
                        dynamicMenu = 16,
                        levelMenu = 32,
                        filterPanel = 64,
                        templateRenderer = 128,
                        userInfoLine = 256,
                        userAccount = 512,
                        general = 1024,
                        ratingStars = 2048,
                        survey = 4096,
                        mediaRenderer = 8192,
                        htmlView = 16384,
                        htmlTable = 32768
                    }
                    export enum FilterType {
                        notSet = 0,
                        text = 1,
                        integer = 2,
                        boolean = 3,
                        rangeInt = 4,
                        select = 5,
                        multiselect = 6
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Admin {
            export namespace IService {
                export namespace ViewModels {
                    export namespace GITHub {

                        export enum GITHubFileLocation {
                            unknown = 0,
                            vueComponent = 1,
                            publishConfig = 2
                        }
                        export enum GITHubFileState {
                            unknown = 0,
                            upToDate = 1,
                            newer = 2,
                            older = 3,
                            notInGIT = 4
                        }
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Catalog {
            export namespace IService {

                export enum PanelGroupType {
                    unset = 0,
                    distinct = 1,
                    decimalRange = 2,
                    filters = 3
                }
                export enum PanelItemType {
                    unset = 0,
                    distinctValue = 1,
                    simpleFilter = 2,
                    rangeMin = 3,
                    rangeUserMin = 4,
                    rangeUserMax = 5,
                    rangeMax = 6
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace TemplateEngine {
            export namespace IService {
                export namespace Model {

                    export enum InputEditorTypeType {
                        commaSeparatedValues = 0,
                        voucherParameters = 1
                    }
                }
            }
        }
    }
}

export namespace Honeycomb {
    export namespace Tenant {
        export namespace Transactions {
            export namespace IService {
                export namespace Model {

                    export enum DropDownSourceType {
                        undefined = 0,
                        codeList = 1,
                        tagNames = 2,
                        enum = 3,
                        productProperties = 4,
                        productAttributes = 5,
                        itemProperties = 6,
                        itemAttributes = 7,
                        transactionProperties = 8,
                        transactionAttributes = 9,
                        userProperties = 10,
                        userAttributes = 11,
                        productSets = 12,
                        serviceItemProperties = 13,
                        addressProperties = 14
                    }
                    export enum EditorType {
                        undefined = 0,
                        text = 1,
                        number = 2,
                        date = 3,
                        dropdown = 4,
                        multilineText = 5,
                        multiselect = 6,
                        checkbox = 7
                    }
                    export enum InputValueType {
                        default = 0,
                        numberArray = 1,
                        stringArray = 2
                    }
                }
            }
        }
    }
}

export namespace PublicAPI {
    export namespace Model {
        export namespace v1 {
            export namespace Enums {

                export enum PAUserTagType {
                    unknown = 0,
                    userCard = 1
                }
            }
        }
    }
}

export namespace System {

    export enum DayOfWeek {
        sunday = 0,
        monday = 1,
        tuesday = 2,
        wednesday = 3,
        thursday = 4,
        friday = 5,
        saturday = 6
    }
}

