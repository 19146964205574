import { DistributionChannelType }          from './distribution-channel-type.enum';
import { WalletBarcodeType }                from './wallet-barcode-type.enum';
import { DiscountApplicationType }          from './discount-application-type.enum';
import { Globals }                          from './globals';
import { DomSanitizer, SafeStyle }          from '../../../node_modules/@angular/platform-browser';
import { TemplateParameterModel }           from './template-parameter.model';
import { TemplateStyleModel }               from './template-style.model';
import { TemplateAttributeModel }           from './template-attribute.model';
import { MediaTypeEnum }                    from './media-type.enum';
import { TranslationService }               from '../shared/translation.service';
import { ProductSetModel }                  from '../product/product-set/product-set.model';
import { DataTypeEnum } from './data-type.enum';
import { Honeycomb } from '../shared/honeycomb-api/honeycomb-api';

export class MediaModel {

    public mediaID: number;

    public name: string;

    public group: string;

    public companyID: number;

    public systemReserved: boolean;

    public description: string;

    public comment: string;

    public mediaType = MediaTypeEnum.Dynamic;

    public mediaOrigin: MediaOrigin;

    public mediaPurpose: MediaPurpose;
    public requiredOptionTypeID: number | undefined;

    public tenantTemplateID: number;

    public tenantTemplateName: string;

    public tenantTemplateCaption: string;

    public tenantTemplateDescription: string;

    public tenantTemplateExists = true;

    public recordUid: string;

    public dataInputType: TemplateInputDataType;

    public tenantTemplates: any;

    // Loaded from TenantTemplate
    public entityDataType: EntityDataType;

    public dataInputData: string;

    public dataInputDataText: string;

    public useTemplateData: boolean;

    // New media settings

    public mediaSourceType: MediaSourceType;

    public mediaLibraryTemplateID: number;

    public mediaParameters: any[];

    public mediaStyles: any[];

    public mediaClubs: MediaClubModel[];

    public attachedMedia: DistributionChannelAttachment[];

    public configurationID: number;

    public configurationName: string;

    public mediaWebUrl: string;

    // Discount BEGIN
    public voucherTemplateID: number;

    public voucherDiscountType: VoucherValueType;

    public discountApplicationType: DiscountApplicationType;

    public discountMaskLong: string;

    public discountMaskShort: string;

    public voucherFixedCode: string;

    public discountValidityRelative: number;

    public discountRelativeValueType: Honeycomb.Common.Enums.DiscountRelativeValueType;

    public discountValidityFixed: Date;

    public discountValidSince: Date;

    /**
     * Valied for google wallet offer type
     */
    public discountEndNoteDays: number;

    public discountEndNote: string;

    public discountValidityType: number;

    public discountValue: number;

    public numberOfRepetitions: number;

    public maxUsedByUser: number;

    public valueCurrencyISOCode: string;

    public generateNewCode: boolean;

    public voucherProductSets: Array<ProductSetModel> = [];

    public voucherCategories: Array<number> = [];

    public voucherLocations: Array<number> = [];

    public voucherTemplateLimitations: Array<VoucherTemplateLimitation> = [];

    public voucherTemplateAttributes: Array<VoucherTemplateAttribute> = [];

    public voucherTemplateLocations: Array<VoucherTemplateLocation> = [];

    public voucherTemplateProductLimitations: Array<VoucherTemplateProductLimitation> = [];

    public voucherTemplateCodeListLimitations: Array<Honeycomb.Tenant.Vouchers.IService.Model.VoucherTemplateCodeListLimitation> = [];

    public voucherLoyaltyPointsExchange: number;

    public voucherMinTransactionPrice: number;

    public voucherMinTransactionCurrencyISOCode: string;

    public useNumberStack: boolean;

    public mediaHtmlViews: Array<MediaHtmlView> = [];

    public numberMaskId: number;

    public shortNumberMaskId: number;
    // Discount END

    // Wallet BEGIN

    public useWallet: boolean;

    public walletAppleTemplateID: number;

    public walletGoogleTemplateID: number;

    public walletImageType: WalletImageType;

    public walletBarcodeType: WalletBarcodeType;

    public walletTextColor: string;

    public walletLabelColor: string;

    public walletBackgroundColor: string;

    public walletLogoUID: string;

    public walletGoogleLogoUID: string;

    public walletLogoText: string;

    public walletImageUID: string;

    public walletGoogleImageUID: string;

    public walletTitle: string;

    public walletPrimaryDescription: string;

    public walletAddCompanyInfo: string;

    public walletInfos: WalletInfo[];

    public walletChangeMessage: string;

    /*
        Checkbox "Update wallets" - wallets tab
     */
    public updateWallets: boolean;

    // tslint:disable-next-line: no-use-before-declare
    public distributionChannelEmail = new DistributionChannelEmail();

    // tslint:disable-next-line: no-use-before-declare
    public distributionChannelSms = new DistributionChannelSms();

    public initialized = false;

    public smsPlaceholders: string[];

    public placeholders: Array<PlaceholderModel> = [];

    public distributionItemsCount: number = 0;

    public recipient: string;

    constructor() {
    }

    public placeholdersFill(trans: TranslationService) {
        if (this.smsPlaceholders && this.smsPlaceholders.length > 0) {
            let i = 1;
            this.placeholders = this.smsPlaceholders.map(p => {
                // tslint:disable-next-line: no-use-before-declare
                let a = new PlaceholderModel();
                a.placehoderIndex = i;
                a.placeholder = p;
                a.placeholderTranslation = trans.instant('admin.web.sms.' + p);
                return a;
            });
        }
    }

  public getHeroImage(globals: Globals, sanitizer: DomSanitizer): SafeStyle {
    let imgUid = this.walletImageUID || globals.getNoImageUID;

    return sanitizer.bypassSecurityTrustStyle('url(\'' +
      globals.GetUrlPrefix() +
      '/api/gallery/ThumbByID/512/' +
      imgUid +
      '?TenantID=' +
      globals.getTenantID() +
      '\')');
  }

  public getProgramLogo(globals: Globals, sanitizer: DomSanitizer): SafeStyle {
    let imgUid = this.walletLogoUID || globals.getNoImageUID;

    return sanitizer.bypassSecurityTrustStyle('url(\'' +
      globals.GetUrlPrefix() +
      '/api/gallery/ThumbByID/128/' +
      imgUid +
      '?TenantID=' +
      globals.getTenantID() +
      '\')');
  }

  public getBarcodeSample(globals: Globals, sanitizer: DomSanitizer): SafeStyle {
    let barcodeFileName = '';

    switch (this.walletBarcodeType) {
      case WalletBarcodeType.QRCode:
          barcodeFileName = '/img/wallet_qr.gif';
        break;
        case WalletBarcodeType.Aztec:
          barcodeFileName = '/img/wallet_aztec.gif';
        break;
        case WalletBarcodeType.Code128:
          barcodeFileName = '/img/wallet_code128.gif';
        break;
        case WalletBarcodeType.PDF417:
          barcodeFileName = '/img/wallet_pdf417.gif';
        break;
      default:
        barcodeFileName = '/img/wallet_code128.gif';
        break;
    }

    return sanitizer.bypassSecurityTrustStyle('url(\'' +  globals.GetUrlPrefix() + barcodeFileName + '\')');
  }
}

export class TemplateRendererConfig {
    fixedEmails = '';
}

// export enum MediaType {
//     NotSet = 0,
//     Dynamic = 1,
//     StaticFile = 2
// }

export enum MediaOrigin {
    NotSet = 0,
    System = 1,
    User = 2
}

export enum MediaPurpose {
    Undefined = 0,
    SystemRequired = 1,
    Newsletter = 2
}

export enum TemplateInputDataType {
    NotSet = 0,
    CategoryNames = 1,
    ProductNumbers = 2,
    EANCodes = 3,
    VoucherParams = 4
}

export enum AppleInfoTextPlacement {
    Back      = 0,
    Auxiliary = 1,
    Secondary = 2
}

export enum EntityDataType {
    Unknown         = 0,
    Transaction     = 1,
    Product         = 2,
    User            = 3,
    Voucher         = 4,
    // Media           = 5,
    TransactionList = 6,
    ProductList     = 7,
    UserList        = 8,
    UserBasket      = 9,
    Report          = 10,
    StatTransaction = 11,
    Newsletter      = 12,
    Task            = 13
}

export enum VoucherValueType {
    valueDiscount = 0,
    percentDiscount = 1,
    promoCode = 2,
    booking = 3,
    giftCertificate = 4,
    unspecified = 99
}

export enum DistributeResult {
    Ok = 0,
    IncorrectNumber = 1,
    EmptySMS = 2,
    ToLongSMS = 3,
    IncorrectEmail = 4,
    OtherError = 100
}

export enum TransactionNoteType {
    Unknown         = 0,
    DeliveryNote    = 1,
    OrderNote       = 2,
    AirMeNote       = 3
}

export class VoucherMaskLimitation {
    voucherValueType: VoucherValueType;
    masks: string[];
}

export class VoucherMaskSettings {
    valueTypes: VoucherMaskLimitation[];
}

export class MediaClubModel {
    mediaId: number;
    clubID: number;
}

export class  DistributionChannel {
    distributionChannelId: number;
    mediaId: number;
    type: DistributionChannelType = DistributionChannelType.NotSet;
    active: boolean;
}

export class  DistributionChannelResultModel {
    distributionChannelResultId: number;
    distributionChannelId: number;
    userNumber: string;
    contactValue: string;
    result: DistributeResult;
    created: Date;
    shown: Date;
}

export class MediaDistributionResultModel {
    media: MediaModel;
    result: DistributionChannelResultModel;
}

export class DistributionChannelEmail extends DistributionChannel {

    constructor() {
        super();
        this.type = DistributionChannelType.Email;
        this.active = true; // by default
    }

    addAsAttachment = false;

    subject: string;

    header: string;

    // Quill editor workaroud
    headerBind: string;

    footer: string;

    // Quill editor workaroud
    footerBind: string;

    senderEmail: string;

    senderName: string;

    saveToStorage: boolean;

    distributionChannelAttachments: DistributionChannelAttachment[] = [];
}

export class DistributionChannelSms extends DistributionChannel {
    constructor() {
        super();
        this.type = DistributionChannelType.SMS;
    }
    smsText: string;
}



export class DistributeModel {
    mediaName: string;
    userId: number; // UserId / or / UserNumber should be filled if the distribution goes to a registered user
    userNumber: string;
    recipient: string; // Recipient must be always filled. It contains either the phone number or email
    entityDataID: string;
}

export class PlaceholderModel {
    constructor(placeholderIndex?: number, placeholder?: string, placeholderTranslation?: string) {
        this.placehoderIndex = placeholderIndex;
        this.placeholder = placeholder;
        this.placeholderTranslation = placeholderTranslation;
    }

    placehoderIndex: number;
    placeholder: string;
    placeholderTranslation: string;
}

export class DataSourceInput {
    inputDataType: TemplateInputDataType;
    description: string;
    inputEditorType: InputEditorTypeType;
}

export class DistributionChannelAttachment {
    mediaID: number = null;

    name: string = null;

    recordUid: string = null;

    attachmentType: AttachmentType;
}

export class WalletInfo {
    walletInfoID: number;
    infoName: string;
    infoText: string;
    sortOrder: number;
    infoTextGoogle: string;
    appleInfoTextPlacement: AppleInfoTextPlacement;
}

export class CodeRowInfo {
    codeId: number;
    codeListId: number;
    orderIndex: number;
    codeKey: string;
    displayValue: string;
}

export class VoucherTemplateLimitation {
    voucherTemplateLimitationID: Number;

    voucherTemplateID: number;

    productSetID: number;

    productSetName: string;

    categoryID: number;

    categoryName: string;
}

export class VoucherTemplateLocation {
    voucherTemplateLocationID: number;
    voucherTemplateID: number;
    locationID: number;
    created: Date | string;
}

export class TenantTemplates {
    tenantID: number;

    tenantTemplateTypeID: number;

    entityDataType: EntityDataType;

    entityDataTypeCode: string;

    name: string;

    description: string;

    caption: string;

    globalTemplate: boolean;

    jSTemplateTypeID: string;

    jSTemplateTypeShortID: string;

    jSTemplateName: string;

    globalTenant: boolean;

    tenantTemplateParameters: TemplateParameterModel[];

    tenantTemplateStyles: TemplateStyleModel[];

    tenantTemplateAttributes: TemplateAttributeModel[];

    jsReportServerUrl: string;

    jsReportServerPort: number;
}

export class VoucherTemplateAttribute {

    value: string;

    voucherAttributeTypeId: number;

    voucherTemplateAttributeId: number;

    voucherTemplateId: number;

    voucherAttributeType: VoucherAttributeType;
}

export class VoucherAttributeType {

    codeListId: number;

    dataType: DataTypeEnum;

    description: string;

    meaning: number;

    name: string;

    voucherAttributeTypeId: number;
}

export class VoucherTemplateProductLimitation {
    voucherTemplateProductLimitationID: number;
    voucherTemplateID: number;
    productAttributeTypeID: number;
    attributeValue: string;
}

export enum InputEditorTypeType {
    CommaSeparatedValues = 0,
    VoucherParameters = 1
}

export enum ShareTarget {
    NotSet = 0,
    Email = 1,
    Sms = 2
}

export enum WalletImageType {
    Undefined = 0,
    Thumbnail = 1,
    Strip = 2
}

export enum DiscountValidityType {
    Relative  = 0,
    Fixed     = 1
}

export enum AttachmentType {
    NotSet = 0,
    Media = 1,
    Storage = 2
}

export enum MediaSourceType {
    NotSet = 0,
    MediaLibrary = 1,
    TenantTemplates = 2
}

export enum MediaTypeTabVisibility {
                           //   Data    Vouch  Email   SMS     Web     Wallet  Distribution
    Transaction	    = 29,  //   0       0      1       1       1       0       1
    Product	        = 21,  //   0       0      1       0       1       0       1
    User            = 31,  //   0       0      1       1       1       1       1
    Voucher         = 63,  //   0       1      1       1       1       1       1
    Media           = 29,  //   0       0      1       1       1       0       1
    TransactionList = 68,  //   1       0      0       0       1       0       0
    ProductList	    = 85,  //   1       0      1       0       1       0       1
    UserList        = 85   //   1       0      1       0       1       0       1
}

export enum MediaTabBitwise {
    // tslint:disable:no-bitwise
    Data            = 1 << 6,
    Voucher         = 1 << 5,
    Email           = 1 << 4,
    Sms             = 1 << 3,
    Web             = 1 << 2,
    Wallet          = 1 << 1,
    Distribution    = 1 << 0
    // tslint:enable:no-bitwise
}

export class HtmlView {
    htmlViewID: number;
    name: string;
    description: string;
    group: string;
    html: string;
    vss: string;
    jsBackend: string;
    jsFrontend: string;
    parametersJson: string;
    stylesJson: string;
    contentType: HTMLViewContentType;
    created: Date | string;
}

export class HtmlViewParameter {
    name: string;
    description: string;
    value: string;
    defaultValue: string;
    valueType: string;
}

export class HtmlViewStyle {
    name: string;
    description: string;
    value: string;
    defaultValue: string;
}

export class MediaHtmlView {
    mediaHtmlViewID: number;
    mediaID: number;
    htmlViewID: number;
    displayOrder: number;
    htmlView: HtmlView;
    parameters: MediaHtmlViewParameter[] = [];

    // not mapperd, used for internal editor purpose
    editorId: number;
}

export class MediaHtmlViewParameter {
    mediaHtmlViewsParameterID: number;
    mediaHtmlViewID: number;
    type: MediaHtmlViewParameterType;
    name: string;
    value: string;
}


export enum HTMLViewContentType {
    Common = 0,
    Newsletter = 2,
    Report = 3
}

export enum MediaHtmlViewParameterType {
    Parameter = 0,
    Style = 1
}

export class MediaExportResult {
    token: string;
    fileName: string;
}

export class MediaExportRequest {
    mediaIDs: number[];
}
